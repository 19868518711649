.AdminGSMToolsPage {
	.PacketSender {
		.Inputs {
			display: flex;
			justify-content: space-between;
		}
		margin-bottom: 50px;
	}
	.RFLog {
		width: 100%;
		table {
			width: 100%;
			font-size: 12px;
			font-family: monospace;
		}
	}
}
