@import '../../../variables.scss';

.device-choice__container {
	.deviceList {
		.device {
			display: flex;
			// align-content: center;
			align-items: center;
			padding: 6px 0;

			i {
				color: $primary-color;
				font-size: 18px;
			}

			.title {
				font-weight: bold;
				margin: 0 8px 0 16px;
			}
		}

		.buttons {
			display: flex;
			justify-content: center;
		}
	}
}
