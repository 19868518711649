.sticky-footer {
	display: flex;
	justify-content: space-between;
	background: #f1f1f1;
	padding: 20px;
	position: sticky;
	bottom: 0;
	border-top: 1px solid #aaa;
	z-index: 10;
}
