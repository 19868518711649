.LoginPage {
	background: black;
	.PageContainer {
		height: 100vh;
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;

		.LogoContainer {
			margin: 20px;

			.Logo {
				// border: 1px solid red;
				width: 220px;
				margin-bottom: 20px;
			}
		}

		video {
			filter: brightness(0.5);
			height: 100%;
			object-fit: cover;
			width: 100%;
			display: block;
			position: absolute;
			inset: 0;
		}

		form {
			position: absolute;

			padding: 20px;
			margin: 30px;
			// background: #ffffff11;
			border-radius: 10px;
		}
	}
}
