@import '../../../../../../variables.scss';

.DeviceListGroup {
	.heading {
		font-weight: bold;
		padding: $default-padding;
		text-align: center;
		cursor: pointer;
	}

	.DeviceListItem {
		display: flex;
		cursor: pointer;
		padding: 3px 16px 2px;
		align-items: center;

		&:first-child {
			border-top: 1px solid #eee;
		}

		border-bottom: 1px solid #eee;

		transition: background-color 250ms ease-in-out;

		&:hover {
			background-color: #efefef;
		}

		&.selected {
			background-color: #e1e1e1;
		}

		.icon {
			font-size: 20px;
			margin-right: 8px;
			color: $primary-color;

			.bad {
				color: red;
			}
		}

		.isConfigured {
			color: green;
			margin-right: 6px;
		}

		.notConfigured {
			color: grey;
			margin-right: 6px;
		}

		.content {
			.firstLine {
				flex-grow: 1;
				display: flex;
				align-items: center;
				flex-direction: row;
				gap: 8px;

				.deviceId {
					font-weight: bold;
					font-size: 1rem;
				}

				.name {
					color: blue;
					color: #aaa;
					white-space: nowrap;
				}

				.statusIcons {
					display: flex;
					gap: 8px;
				}

				.info {
					font-weight: bold;
					white-space: nowrap;
				}
			}

			.secondLine {
				color: #aaa;
				font-size: 12px;
			}
		}
	}
}

.lighting-profile-icon-box {
	width: 1.5rem;
	aspect-ratio: 1 / 1;
	border-radius: 50%;

	display: grid;
	place-items: center;

	& > i {
		color: white;
	}
}
