.device-item {
	& .wants-view-on-map-btn {
		width: 0.5rem;
		aspect-ratio: 1/1;
		min-width: 0px;
		min-height: 0px;
		opacity: 0;
		transition: opacity 150ms ease-in-out;

		& > .bp5-icon {
			& > svg {
				width: 0.75rem;
			}
		}
	}

	&:hover {
		& .wants-view-on-map-btn {
			opacity: 1;
		}
	}
}
