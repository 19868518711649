@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/@blueprintjs/core/lib/css/blueprint.css';
@import '../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css';

@import '../node_modules/@blueprintjs/popover2/lib/css/blueprint-popover2.css';

@import '../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import '../node_modules/@blueprintjs/popover2/lib/css/blueprint-popover2.css';
@import '../node_modules/@blueprintjs/select/lib/css/blueprint-select.css';
@import '../node_modules/@blueprintjs/datetime2/lib/css/blueprint-datetime2.css';

@import '../node_modules/@fortawesome/fontawesome-pro/css/all.min.css';

@import './variables.scss';

* {
	box-sizing: border-box;
}

html {
	background: black;
}

body {
	height: 100dvh;
	overflow: hidden;
}

#App {
	height: 100%;
	// overflow: hidden;
}

.bp5-menu.scrollable {
	max-height: 25rem;
	overflow-y: scroll;
}

.spinner {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.spinner-icon {
	& svg {
		width: 1rem;
		height: 1rem;
		stroke: white;
		fill: white;
	}

	&.white {
		& .bp5-spinner-head {
			stroke: white;
		}
	}
}

.container-shadow {
	box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #1114181a;
}

.no-data {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	user-select: none;
}

.bp5-tab {
	&.has-active-events,
	&.has-active-events:hover {
		color: $pt-intent-warning;
		&.has-errors {
			color: $pt-intent-danger;
		}
		// background-color: transparentize($pt-intent-warning, 0.75) !important;
	}
}

.white-bg {
	background-color: white;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
