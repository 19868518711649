@import '../../variables.scss';

$sidebarWidth: 300px;

.MapPage {
	.PageContainer {
		display: flex;
		position: relative;

		transition: padding-left 250ms ease-in-out;
	}

	.sideBar {
		width: $sidebarWidth;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 11;

		overflow-y: scroll;

		transform: translateX(-100%);
		&.open {
			transform: translateX(0%);
		}

		// transition: transform 250ms ease-in-out;
	}

	.fakeSideBar {
		width: 0px;

		&.open {
			width: $sidebarWidth;
		}

		// transition: width 250ms ease-in-out;
	}

	.MapContainer {
		position: relative;
		flex-grow: 1;

		.sidebarControl {
			position: absolute;
			top: 0.25rem;
			left: 0.25rem;
			z-index: 99;
		}
	}
}

.time {
	font-weight: bold;
	font-size: 11px;
	margin: 0px 4px;
	border: 1px solid rgb(192, 191, 191);
	padding: 4px;
	border-radius: 7px;
	white-space: nowrap;
}

.container {
	height: 100%;
	border-right: 1px solid $divider-small-color;
}

.sidebarTabs {
	.bp5-tab-list {
		justify-content: center;
	}

	.bp5-tab-panel {
		margin-top: 0;
	}
}
