#qr-preview {
	width: 100%;
	aspect-ratio: 1 / 1;
	object-fit: cover;
	object-position: center;
}

.qr-dialog {
	& .status {
		font-weight: bold;
		font-size: 1rem;
	}
}
