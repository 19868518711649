.device-info-block {
	& > .content {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
}

.properties-block {
	& > .content {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
}
