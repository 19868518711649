.events-tab {
	& > header {
		padding: 0.5rem;
		display: flex;
		gap: 0.5rem;

		& > * {
			flex-grow: 1;

			& button {
				width: 100%;
			}
		}
	}
}

.event-item {
	display: flex;
	align-items: start;
	gap: 16px;

	padding: 0.5rem;
	width: 100%;

	cursor: pointer;

	&:first-child {
		border-top: 1px solid #eee;
	}

	transition: background-color 250ms ease-in-out;

	border-bottom: 1px solid #eee;

	&:hover {
		background-color: #efefef;
	}

	&.selected {
		background-color: #e1e1e1;
	}

	.icon {
		// make the color orange
		color: #ff8c00;
		font-size: 24px;
	}

	&.error {
		& .icon {
			color: red;
		}
	}

	&.inactive {
		& .icon {
			opacity: 0.5;
		}
	}

	// &.inactive {
	// 	& .icon {
	// 		color: #707070;
	// 	}
	// }

	.deviceId {
		font-weight: bold;
		font-size: 1rem;
		margin-bottom: 0.5rem;

		.type {
			font-weight: normal;
		}
	}

	strong {
		margin-right: 5px;
	}
}

.description-block {
	& ul {
		padding-left: 0;
		margin-bottom: 0;
	}
}
