.profiles-devices-sidebar {
	position: fixed;
	height: 100dvh;
	width: 300px;
	z-index: 10;
	left: 0;
	top: 0;

	background-color: white;
	box-shadow: 0 0 0.5rem 0rem rgba(0, 0, 0, 0.3);
	padding: 0.5rem;
	gap: 0.5rem;

	display: flex;
	flex-direction: column;

	transform: translateX(-100%);

	&.open {
		transform: translateX(0%);
	}

	transition: transform 250ms ease-in-out;

	& > header {
		display: flex;
		gap: 0.5rem;
		& > .bp5-input-group {
			flex: 1;
		}
	}

	& > .DeviceList {
		overflow-x: hidden;

		& .LightProfile {
			& > .LightProfile__box {
				& > i {
					font-size: 1rem;
				}
			}
		}
	}
}
