.ConsolePage {
	.codeView {
		font-family: monospace;
		width: 100%;
		height: 30%;
		resize: vertical;
		padding: 8px;
	}
	.PageContainer {
		overflow-y: scroll;
	}
	.output {
		padding: 8px;
		max-width: 100%;
		overflow-x: scroll;
		white-space: pre-wrap;
		background: rgb(39, 40, 34);
		margin-top: 10px;
		.outputHeader {
			color: white;
			font-weight: bold;
			margin-bottom: 10px;
		}
	}
}
