@import '../variables.scss';

.DevicePicker {
	width: 300px;
	height: 500px;
	display: flex;
	flex-direction: column;

	.content {
		overflow: scroll;
		border: 16px solid white;

		.profileTitle {
			font-weight: bold;
			font-size: 16px;
			position: sticky;
			top: 0;
			background: white;
			z-index: 10;
			padding: 5px;
		}

		.deviceList {
			padding-left: 8px;
			padding-top: 8px;
		}

		.Device {
			label {
				display: flex;
				align-items: center;
			}
			i {
				color: $primary-color;
				margin-right: 8px;
			}
			.deviceId {
				font-weight: bold;
			}
		}
	}

	.buttons {
		padding: 16px;
		display: flex;
		justify-content: right;
		background: #eee;
		border-top: 1px solid #aaa;
	}
}
