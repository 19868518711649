.Placeholder {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&.overlay {
		z-index: 19;
		position: absolute;
		top: 0;
		left: 0;

		opacity: 1;
		background-color: rgba(0, 0, 0, 0.3);
		backdrop-filter: blur(5px);

		transition: opacity 250ms ease-in-out;

		&:not(.overlay-visible) {
			pointer-events: none;
			opacity: 0;
		}
	}

	& > div {
		margin: 6px 0;
	}

	.icon {
		font-size: 40px;
	}

	.label {
		font-weight: bold;
	}
}
