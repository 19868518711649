.dali-override-dialog {
	& * {
		margin: 0;
	}

	& > .bp5-dialog-body {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		& > .brightness-block {
			display: flex;
			flex-direction: column;

			& span {
				user-select: none;
			}
		}
	}

	& .duration-block {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	& .error-message {
		color: red;
	}
}
