.replace-dialog {
	& .body {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	& .header {
		font-weight: bold;
		font-size: 1rem;
		margin: 0;
	}

	& .replace-btn {
		text-transform: capitalize;
	}
}

.error-message {
	color: red;
}
