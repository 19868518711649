.ConfirmPopover {
	.message {
		padding: 16px;
		padding-bottom: 0;
		font-weight: bold;
		text-align: center;
	}
	.buttons {
		display: flex;
		gap: 8px;
		padding: 16px;
	}
}
