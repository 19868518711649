.DeviceCommandViewer {
	.command {
		border-top: 1px solid #ddd;
		margin: 0 -16px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 16px 0;

		.section {
			padding: 0 16px;

			.title {
				font-size: 12px;
				font-weight: bold;
				margin-bottom: 2px;
			}

			.content {
				white-space: pre;
				font-family: monospace;
				font-size: 12px;
				overflow: scroll;
				padding: 4px;
				background: #fafafa;
				border: 1px solid #ccc;
				border-radius: 4px;
				flex-grow: 1;
			}

			&.name .content {
				font-size: 14px;
				font-weight: bold;
				margin-bottom: 2px;
				padding: 0;
				border: 0;
				background: none;
				color: #2d72d2;
			}

			.switcher {
				display: flex;
				gap: 8px;
			}
		}

		.oneRow {
			display: flex;
			> * {
				flex-grow: 1;
				flex-shrink: 1;
				flex-basis: 1;
			}
		}
	}
}
