.AdminRFToolsPage {
	.PageContainer {
		display: flex;
		flex-direction: column;
	}
	.PacketSender {
		.Inputs {
			display: flex;
			justify-content: space-between;
		}
		margin-bottom: 50px;
	}
	.RFLog {
		flex: 1;
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow-y: scroll;
		flex: 1;

		table {
			width: 100%;
			font-size: 12px;
			font-family: monospace;
		}
	}
}
