@import '../../../../variables.scss';

.MapPage .ProfileDetails {
	background: #f1f1f1;
	min-width: 360px;
	overflow: scroll;
	border-right: 1px solid $divider-small-color;
	position: relative;
	overflow-x: hidden;

	.closeButton {
		position: absolute;
		right: 8px;
		top: 8px;
	}

	.content {
		padding: 16px 32px;

		& .heading {
			font-weight: bold;
			margin-bottom: 16px;
		}
	}
}
