.AdminRFTrafficPage {
	.RFLog {
		width: 100%;
		table {
			width: 100%;
			font-size: 12px;
			// font-family: monospace;

			td {
				vertical-align: middle;
			}

			.colCommand {
				white-space: nowrap;
				small {
					color: #999;
				}
			}

			.colPayload {
			}

			.payloadHex {
				font-family: monospace;
			}

			.payloadJson {
				font-family: monospace;
				font-size: 10px;
				color: #999;
			}
		}
	}
}
