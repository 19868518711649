.bp5-multi-select{
  min-width:150px;
}

.bp5-multi-select-popover .bp5-popover-content{
  padding:5px;
}
.bp5-multi-select-popover .bp5-multi-select-popover-default-width{
  max-width:350px;
}
.bp5-multi-select-popover .bp5-multi-select-popover-tag-input-margin{
  margin-bottom:1px;
}
.bp5-multi-select-popover .bp5-menu{
  margin-left:-5px;
  margin-right:-5px;
  max-height:300px;
  max-width:400px;
  overflow:auto;
}
.bp5-multi-select-popover.bp5-popover-match-target-width{
  width:100%;
}
.bp5-multi-select-popover.bp5-popover-match-target-width .bp5-menu{
  max-width:none;
  min-width:0;
}
.bp5-omnibar{
  filter:blur(0);
  opacity:1;
  background-color:#ffffff;
  border-radius:2px;
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 4px 8px rgba(17, 20, 24, 0.2), 0 18px 46px 6px rgba(17, 20, 24, 0.2);
  left:calc(50% - 250px);
  top:20vh;
  width:500px;
  z-index:21;
}
.bp5-omnibar.bp5-overlay-enter, .bp5-omnibar.bp5-overlay-appear{
  filter:blur(20px);
  opacity:0;
}
.bp5-omnibar.bp5-overlay-enter-active, .bp5-omnibar.bp5-overlay-appear-active{
  filter:blur(0);
  opacity:1;
  transition-delay:0;
  transition-duration:200ms;
  transition-property:filter, opacity;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-omnibar.bp5-overlay-exit{
  filter:blur(0);
  opacity:1;
}
.bp5-omnibar.bp5-overlay-exit-active{
  filter:blur(20px);
  opacity:0;
  transition-delay:0;
  transition-duration:200ms;
  transition-property:filter, opacity;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-omnibar .bp5-input{
  background-color:transparent;
  border-radius:0;
}
.bp5-omnibar .bp5-input, .bp5-omnibar .bp5-input:focus{
  box-shadow:none;
}
.bp5-omnibar .bp5-menu{
  background-color:transparent;
  border-radius:0;
  box-shadow:inset 0 1px 0 rgba(17, 20, 24, 0.15);
  max-height:calc(60vh - 40px);
  overflow:auto;
}
.bp5-omnibar .bp5-menu:empty{
  display:none;
}
.bp5-dark .bp5-omnibar, .bp5-omnibar.bp5-dark{
  background-color:#383e47;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 4px 8px rgba(17, 20, 24, 0.4), 0 18px 46px 6px rgba(17, 20, 24, 0.4);
}

.bp5-omnibar-overlay .bp5-overlay-backdrop{
  background-color:rgba(17, 20, 24, 0.2);
}
.bp5-select-popover .bp5-popover-content{
  padding:5px;
}
.bp5-select-popover .bp5-input-group{
  margin-bottom:0;
}
.bp5-select-popover .bp5-menu{
  margin-left:-5px;
  margin-right:-5px;
  max-height:300px;
  max-width:400px;
  overflow:auto;
  padding:0 5px;
}
.bp5-select-popover .bp5-menu:not(:first-child){
  padding-top:5px;
}
.bp5-select-popover.bp5-popover-match-target-width{
  width:100%;
}
.bp5-select-popover.bp5-popover-match-target-width .bp5-menu{
  max-width:none;
  min-width:0;
}
.bp5-suggest-popover .bp5-menu{
  max-height:300px;
  max-width:400px;
  overflow:auto;
}
.bp5-suggest-popover.bp5-popover-match-target-width{
  width:100%;
}
.bp5-suggest-popover.bp5-popover-match-target-width .bp5-menu{
  max-width:none;
  min-width:0;
}