@charset "UTF-8";
html{
  box-sizing:border-box;
}

*,
*::before,
*::after{
  box-sizing:inherit;
}

body{
  font-size:14px;
  font-weight:400;
  letter-spacing:0;
  line-height:1.28581;
  text-transform:none;
  color:#1c2127;
  font-family:-apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "blueprint-icons-16", sans-serif;
}

p{
  margin-bottom:10px;
  margin-top:0;
}

small{
  font-size:12px;
}

strong{
  font-weight:600;
}

::-moz-selection{
  background:rgba(125, 188, 255, 0.6);
}

::selection{
  background:rgba(125, 188, 255, 0.6);
}
.bp5-icon{
  display:inline-block;
  flex:0 0 auto;
  vertical-align:text-bottom;
}
.bp5-icon:not(:empty)::before{
  content:"" !important;
  content:unset !important;
}
.bp5-icon > svg{
  display:block;
}
.bp5-icon > svg:not([fill]){
  fill:currentcolor;
}
.bp5-icon.bp5-icon-muted svg{
  fill-opacity:15%;
  overflow:visible;
}
.bp5-icon.bp5-icon-muted svg path{
  stroke:#8f99a8;
  stroke-opacity:50%;
  stroke-width:0.5px;
}
.bp5-dark .bp5-icon .bp5-icon-muted svg{
  fill-opacity:20%;
}

span.bp5-icon-standard{
  font-family:"blueprint-icons-16", sans-serif;
  font-size:16px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:16px;
  line-height:1;
  width:16px;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  display:inline-block;
}

span.bp5-icon-large{
  font-family:"blueprint-icons-20", sans-serif;
  font-size:20px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:20px;
  line-height:1;
  width:20px;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  display:inline-block;
}

span.bp5-icon:empty{
  font-family:"blueprint-icons-20";
  font-size:inherit;
  font-style:normal;
  font-weight:400;
  line-height:1;
}
span.bp5-icon:empty::before{
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
}
span.bp5-icon:empty.bp5-icon-standard{
  font-size:16px;
}
span.bp5-icon:empty.bp5-icon-large{
  font-size:20px;
}

.bp5-icon-add::before{
  content:"\f109";
}

.bp5-icon-add-clip::before{
  content:"\f101";
}

.bp5-icon-add-column-left::before{
  content:"\f102";
}

.bp5-icon-add-column-right::before{
  content:"\f103";
}

.bp5-icon-add-location::before{
  content:"\f104";
}

.bp5-icon-add-row-bottom::before{
  content:"\f105";
}

.bp5-icon-add-row-top::before{
  content:"\f106";
}

.bp5-icon-add-to-artifact::before{
  content:"\f107";
}

.bp5-icon-add-to-folder::before{
  content:"\f108";
}

.bp5-icon-aimpoints-target::before{
  content:"\f335";
}

.bp5-icon-airplane::before{
  content:"\f10a";
}

.bp5-icon-align-center::before{
  content:"\f10b";
}

.bp5-icon-align-justify::before{
  content:"\f10c";
}

.bp5-icon-align-left::before{
  content:"\f10d";
}

.bp5-icon-align-right::before{
  content:"\f10e";
}

.bp5-icon-alignment-bottom::before{
  content:"\f10f";
}

.bp5-icon-alignment-horizontal-center::before{
  content:"\f110";
}

.bp5-icon-alignment-left::before{
  content:"\f111";
}

.bp5-icon-alignment-right::before{
  content:"\f112";
}

.bp5-icon-alignment-top::before{
  content:"\f113";
}

.bp5-icon-alignment-vertical-center::before{
  content:"\f114";
}

.bp5-icon-ammunition::before{
  content:"\f342";
}

.bp5-icon-anchor::before{
  content:"\f330";
}

.bp5-icon-annotation::before{
  content:"\f115";
}

.bp5-icon-antenna::before{
  content:"\f116";
}

.bp5-icon-app-header::before{
  content:"\f117";
}

.bp5-icon-application::before{
  content:"\f118";
}

.bp5-icon-applications::before{
  content:"\f119";
}

.bp5-icon-archive::before{
  content:"\f11a";
}

.bp5-icon-area-of-interest::before{
  content:"\f11b";
}

.bp5-icon-array::before{
  content:"\f121";
}

.bp5-icon-array-boolean::before{
  content:"\f11c";
}

.bp5-icon-array-date::before{
  content:"\f11d";
}

.bp5-icon-array-floating-point::before{
  content:"\f32d";
}

.bp5-icon-array-numeric::before{
  content:"\f11e";
}

.bp5-icon-array-string::before{
  content:"\f11f";
}

.bp5-icon-array-timestamp::before{
  content:"\f120";
}

.bp5-icon-arrow-bottom-left::before{
  content:"\f122";
}

.bp5-icon-arrow-bottom-right::before{
  content:"\f123";
}

.bp5-icon-arrow-down::before{
  content:"\f124";
}

.bp5-icon-arrow-left::before{
  content:"\f125";
}

.bp5-icon-arrow-right::before{
  content:"\f126";
}

.bp5-icon-arrow-top-left::before{
  content:"\f127";
}

.bp5-icon-arrow-top-right::before{
  content:"\f128";
}

.bp5-icon-arrow-up::before{
  content:"\f129";
}

.bp5-icon-arrows-horizontal::before{
  content:"\f12a";
}

.bp5-icon-arrows-vertical::before{
  content:"\f12b";
}

.bp5-icon-asterisk::before{
  content:"\f12c";
}

.bp5-icon-at::before{
  content:"\f331";
}

.bp5-icon-automatic-updates::before{
  content:"\f12d";
}

.bp5-icon-axle::before{
  content:"\f338";
}

.bp5-icon-backlink::before{
  content:"\f12e";
}

.bp5-icon-backward-ten::before{
  content:"\f35c";
}

.bp5-icon-badge::before{
  content:"\f12f";
}

.bp5-icon-ban-circle::before{
  content:"\f130";
}

.bp5-icon-bank-account::before{
  content:"\f131";
}

.bp5-icon-barcode::before{
  content:"\f132";
}

.bp5-icon-binary-number::before{
  content:"\f357";
}

.bp5-icon-blank::before{
  content:"\f133";
}

.bp5-icon-blocked-person::before{
  content:"\f134";
}

.bp5-icon-bold::before{
  content:"\f135";
}

.bp5-icon-book::before{
  content:"\f136";
}

.bp5-icon-bookmark::before{
  content:"\f137";
}

.bp5-icon-box::before{
  content:"\f138";
}

.bp5-icon-briefcase::before{
  content:"\f139";
}

.bp5-icon-bring-data::before{
  content:"\f13a";
}

.bp5-icon-bring-forward::before{
  content:"\f354";
}

.bp5-icon-bug::before{
  content:"\f32e";
}

.bp5-icon-buggy::before{
  content:"\f13b";
}

.bp5-icon-build::before{
  content:"\f13c";
}

.bp5-icon-bullseye::before{
  content:"\f359";
}

.bp5-icon-calculator::before{
  content:"\f13d";
}

.bp5-icon-calendar::before{
  content:"\f13e";
}

.bp5-icon-camera::before{
  content:"\f13f";
}

.bp5-icon-caret-down::before{
  content:"\f140";
}

.bp5-icon-caret-left::before{
  content:"\f141";
}

.bp5-icon-caret-right::before{
  content:"\f142";
}

.bp5-icon-caret-up::before{
  content:"\f143";
}

.bp5-icon-cargo-ship::before{
  content:"\f144";
}

.bp5-icon-cell-tower::before{
  content:"\f145";
}

.bp5-icon-changes::before{
  content:"\f146";
}

.bp5-icon-chart::before{
  content:"\f147";
}

.bp5-icon-chat::before{
  content:"\f148";
}

.bp5-icon-chevron-backward::before{
  content:"\f149";
}

.bp5-icon-chevron-down::before{
  content:"\f14a";
}

.bp5-icon-chevron-forward::before{
  content:"\f14b";
}

.bp5-icon-chevron-left::before{
  content:"\f14c";
}

.bp5-icon-chevron-right::before{
  content:"\f14d";
}

.bp5-icon-chevron-up::before{
  content:"\f14e";
}

.bp5-icon-circle::before{
  content:"\f153";
}

.bp5-icon-circle-arrow-down::before{
  content:"\f14f";
}

.bp5-icon-circle-arrow-left::before{
  content:"\f150";
}

.bp5-icon-circle-arrow-right::before{
  content:"\f151";
}

.bp5-icon-circle-arrow-up::before{
  content:"\f152";
}

.bp5-icon-citation::before{
  content:"\f154";
}

.bp5-icon-clean::before{
  content:"\f155";
}

.bp5-icon-clip::before{
  content:"\f156";
}

.bp5-icon-clipboard::before{
  content:"\f157";
}

.bp5-icon-clipboard-file::before{
  content:"\f35b";
}

.bp5-icon-cloud::before{
  content:"\f15a";
}

.bp5-icon-cloud-download::before{
  content:"\f158";
}

.bp5-icon-cloud-server::before{
  content:"\f35a";
}

.bp5-icon-cloud-tick::before{
  content:"\f34e";
}

.bp5-icon-cloud-upload::before{
  content:"\f159";
}

.bp5-icon-code::before{
  content:"\f15c";
}

.bp5-icon-code-block::before{
  content:"\f15b";
}

.bp5-icon-cog::before{
  content:"\f15d";
}

.bp5-icon-collapse-all::before{
  content:"\f15e";
}

.bp5-icon-color-fill::before{
  content:"\f328";
}

.bp5-icon-column-layout::before{
  content:"\f15f";
}

.bp5-icon-comment::before{
  content:"\f160";
}

.bp5-icon-comparison::before{
  content:"\f161";
}

.bp5-icon-compass::before{
  content:"\f162";
}

.bp5-icon-compressed::before{
  content:"\f163";
}

.bp5-icon-confirm::before{
  content:"\f164";
}

.bp5-icon-console::before{
  content:"\f165";
}

.bp5-icon-contrast::before{
  content:"\f166";
}

.bp5-icon-control::before{
  content:"\f167";
}

.bp5-icon-credit-card::before{
  content:"\f168";
}

.bp5-icon-crop::before{
  content:"\f353";
}

.bp5-icon-cross::before{
  content:"\f169";
}

.bp5-icon-cross-circle::before{
  content:"\f336";
}

.bp5-icon-crown::before{
  content:"\f16a";
}

.bp5-icon-css-style::before{
  content:"\f36b";
}

.bp5-icon-cube::before{
  content:"\f16d";
}

.bp5-icon-cube-add::before{
  content:"\f16b";
}

.bp5-icon-cube-remove::before{
  content:"\f16c";
}

.bp5-icon-curly-braces::before{
  content:"\f358";
}

.bp5-icon-curved-range-chart::before{
  content:"\f16e";
}

.bp5-icon-cut::before{
  content:"\f16f";
}

.bp5-icon-cycle::before{
  content:"\f170";
}

.bp5-icon-dashboard::before{
  content:"\f171";
}

.bp5-icon-data-connection::before{
  content:"\f172";
}

.bp5-icon-data-lineage::before{
  content:"\f173";
}

.bp5-icon-data-search::before{
  content:"\f36f";
}

.bp5-icon-data-sync::before{
  content:"\f36c";
}

.bp5-icon-database::before{
  content:"\f174";
}

.bp5-icon-delete::before{
  content:"\f175";
}

.bp5-icon-delta::before{
  content:"\f176";
}

.bp5-icon-derive-column::before{
  content:"\f177";
}

.bp5-icon-desktop::before{
  content:"\f178";
}

.bp5-icon-detection::before{
  content:"\f341";
}

.bp5-icon-diagnosis::before{
  content:"\f179";
}

.bp5-icon-diagram-tree::before{
  content:"\f17a";
}

.bp5-icon-direction-left::before{
  content:"\f17b";
}

.bp5-icon-direction-right::before{
  content:"\f17c";
}

.bp5-icon-disable::before{
  content:"\f17d";
}

.bp5-icon-divide::before{
  content:"\f327";
}

.bp5-icon-document::before{
  content:"\f180";
}

.bp5-icon-document-open::before{
  content:"\f17e";
}

.bp5-icon-document-share::before{
  content:"\f17f";
}

.bp5-icon-dollar::before{
  content:"\f181";
}

.bp5-icon-dot::before{
  content:"\f182";
}

.bp5-icon-double-caret-horizontal::before{
  content:"\f183";
}

.bp5-icon-double-caret-vertical::before{
  content:"\f184";
}

.bp5-icon-double-chevron-down::before{
  content:"\f185";
}

.bp5-icon-double-chevron-left::before{
  content:"\f186";
}

.bp5-icon-double-chevron-right::before{
  content:"\f187";
}

.bp5-icon-double-chevron-up::before{
  content:"\f188";
}

.bp5-icon-doughnut-chart::before{
  content:"\f189";
}

.bp5-icon-download::before{
  content:"\f18a";
}

.bp5-icon-drag-handle-horizontal::before{
  content:"\f18b";
}

.bp5-icon-drag-handle-vertical::before{
  content:"\f18c";
}

.bp5-icon-draw::before{
  content:"\f18d";
}

.bp5-icon-drawer-left::before{
  content:"\f18f";
}

.bp5-icon-drawer-left-filled::before{
  content:"\f18e";
}

.bp5-icon-drawer-right::before{
  content:"\f191";
}

.bp5-icon-drawer-right-filled::before{
  content:"\f190";
}

.bp5-icon-drive-time::before{
  content:"\f192";
}

.bp5-icon-duplicate::before{
  content:"\f193";
}

.bp5-icon-edit::before{
  content:"\f194";
}

.bp5-icon-eject::before{
  content:"\f195";
}

.bp5-icon-emoji::before{
  content:"\f196";
}

.bp5-icon-endnote::before{
  content:"\f356";
}

.bp5-icon-endorsed::before{
  content:"\f197";
}

.bp5-icon-envelope::before{
  content:"\f198";
}

.bp5-icon-equals::before{
  content:"\f199";
}

.bp5-icon-eraser::before{
  content:"\f19a";
}

.bp5-icon-error::before{
  content:"\f19b";
}

.bp5-icon-euro::before{
  content:"\f19c";
}

.bp5-icon-excavator::before{
  content:"\f36d";
}

.bp5-icon-exchange::before{
  content:"\f19d";
}

.bp5-icon-exclude-row::before{
  content:"\f19e";
}

.bp5-icon-expand-all::before{
  content:"\f19f";
}

.bp5-icon-explain::before{
  content:"\f34d";
}

.bp5-icon-export::before{
  content:"\f1a0";
}

.bp5-icon-eye-off::before{
  content:"\f1a1";
}

.bp5-icon-eye-on::before{
  content:"\f1a2";
}

.bp5-icon-eye-open::before{
  content:"\f1a3";
}

.bp5-icon-fast-backward::before{
  content:"\f1a4";
}

.bp5-icon-fast-forward::before{
  content:"\f1a5";
}

.bp5-icon-feed::before{
  content:"\f1a7";
}

.bp5-icon-feed-subscribed::before{
  content:"\f1a6";
}

.bp5-icon-film::before{
  content:"\f1a8";
}

.bp5-icon-filter::before{
  content:"\f1ad";
}

.bp5-icon-filter-keep::before{
  content:"\f1a9";
}

.bp5-icon-filter-list::before{
  content:"\f1aa";
}

.bp5-icon-filter-open::before{
  content:"\f1ab";
}

.bp5-icon-filter-remove::before{
  content:"\f1ac";
}

.bp5-icon-flag::before{
  content:"\f1ae";
}

.bp5-icon-flame::before{
  content:"\f1af";
}

.bp5-icon-flash::before{
  content:"\f1b0";
}

.bp5-icon-floating-point::before{
  content:"\f32c";
}

.bp5-icon-floppy-disk::before{
  content:"\f1b1";
}

.bp5-icon-flow-branch::before{
  content:"\f1b2";
}

.bp5-icon-flow-end::before{
  content:"\f1b3";
}

.bp5-icon-flow-linear::before{
  content:"\f1b4";
}

.bp5-icon-flow-review::before{
  content:"\f1b6";
}

.bp5-icon-flow-review-branch::before{
  content:"\f1b5";
}

.bp5-icon-flows::before{
  content:"\f1b7";
}

.bp5-icon-folder-close::before{
  content:"\f1b8";
}

.bp5-icon-folder-new::before{
  content:"\f1b9";
}

.bp5-icon-folder-open::before{
  content:"\f1ba";
}

.bp5-icon-folder-shared::before{
  content:"\f1bc";
}

.bp5-icon-folder-shared-open::before{
  content:"\f1bb";
}

.bp5-icon-follower::before{
  content:"\f1bd";
}

.bp5-icon-following::before{
  content:"\f1be";
}

.bp5-icon-font::before{
  content:"\f1bf";
}

.bp5-icon-fork::before{
  content:"\f1c0";
}

.bp5-icon-form::before{
  content:"\f1c1";
}

.bp5-icon-forward-ten::before{
  content:"\f35d";
}

.bp5-icon-fuel::before{
  content:"\f323";
}

.bp5-icon-full-circle::before{
  content:"\f1c2";
}

.bp5-icon-full-stacked-chart::before{
  content:"\f1c3";
}

.bp5-icon-fullscreen::before{
  content:"\f1c4";
}

.bp5-icon-function::before{
  content:"\f1c5";
}

.bp5-icon-gantt-chart::before{
  content:"\f1c6";
}

.bp5-icon-generate::before{
  content:"\f34c";
}

.bp5-icon-geofence::before{
  content:"\f1c7";
}

.bp5-icon-geolocation::before{
  content:"\f1c8";
}

.bp5-icon-geosearch::before{
  content:"\f1c9";
}

.bp5-icon-geotime::before{
  content:"\f344";
}

.bp5-icon-git-branch::before{
  content:"\f1ca";
}

.bp5-icon-git-commit::before{
  content:"\f1cb";
}

.bp5-icon-git-merge::before{
  content:"\f1cc";
}

.bp5-icon-git-new-branch::before{
  content:"\f1cd";
}

.bp5-icon-git-pull::before{
  content:"\f1ce";
}

.bp5-icon-git-push::before{
  content:"\f1cf";
}

.bp5-icon-git-repo::before{
  content:"\f1d0";
}

.bp5-icon-glass::before{
  content:"\f1d1";
}

.bp5-icon-globe::before{
  content:"\f1d3";
}

.bp5-icon-globe-network::before{
  content:"\f1d2";
}

.bp5-icon-graph::before{
  content:"\f1d5";
}

.bp5-icon-graph-remove::before{
  content:"\f1d4";
}

.bp5-icon-greater-than::before{
  content:"\f1d7";
}

.bp5-icon-greater-than-or-equal-to::before{
  content:"\f1d6";
}

.bp5-icon-grid::before{
  content:"\f1d9";
}

.bp5-icon-grid-view::before{
  content:"\f1d8";
}

.bp5-icon-group-item::before{
  content:"\f34a";
}

.bp5-icon-group-objects::before{
  content:"\f1da";
}

.bp5-icon-grouped-bar-chart::before{
  content:"\f1db";
}

.bp5-icon-hand::before{
  content:"\f1e0";
}

.bp5-icon-hand-down::before{
  content:"\f1dc";
}

.bp5-icon-hand-left::before{
  content:"\f1dd";
}

.bp5-icon-hand-right::before{
  content:"\f1de";
}

.bp5-icon-hand-up::before{
  content:"\f1df";
}

.bp5-icon-hat::before{
  content:"\f1e1";
}

.bp5-icon-header::before{
  content:"\f1e5";
}

.bp5-icon-header-one::before{
  content:"\f1e2";
}

.bp5-icon-header-three::before{
  content:"\f1e3";
}

.bp5-icon-header-two::before{
  content:"\f1e4";
}

.bp5-icon-headset::before{
  content:"\f1e6";
}

.bp5-icon-heart::before{
  content:"\f1e8";
}

.bp5-icon-heart-broken::before{
  content:"\f1e7";
}

.bp5-icon-heat-grid::before{
  content:"\f1e9";
}

.bp5-icon-heatmap::before{
  content:"\f1ea";
}

.bp5-icon-helicopter::before{
  content:"\f1eb";
}

.bp5-icon-help::before{
  content:"\f1ec";
}

.bp5-icon-helper-management::before{
  content:"\f1ed";
}

.bp5-icon-high-priority::before{
  content:"\f1ee";
}

.bp5-icon-high-voltage-pole::before{
  content:"\f333";
}

.bp5-icon-highlight::before{
  content:"\f1ef";
}

.bp5-icon-history::before{
  content:"\f1f0";
}

.bp5-icon-home::before{
  content:"\f1f1";
}

.bp5-icon-horizontal-bar-chart::before{
  content:"\f1f4";
}

.bp5-icon-horizontal-bar-chart-asc::before{
  content:"\f1f2";
}

.bp5-icon-horizontal-bar-chart-desc::before{
  content:"\f1f3";
}

.bp5-icon-horizontal-distribution::before{
  content:"\f1f5";
}

.bp5-icon-horizontal-inbetween::before{
  content:"\f329";
}

.bp5-icon-hurricane::before{
  content:"\f1f6";
}

.bp5-icon-id-number::before{
  content:"\f1f7";
}

.bp5-icon-image-rotate-left::before{
  content:"\f1f8";
}

.bp5-icon-image-rotate-right::before{
  content:"\f1f9";
}

.bp5-icon-import::before{
  content:"\f1fa";
}

.bp5-icon-inbox::before{
  content:"\f1ff";
}

.bp5-icon-inbox-filtered::before{
  content:"\f1fb";
}

.bp5-icon-inbox-geo::before{
  content:"\f1fc";
}

.bp5-icon-inbox-search::before{
  content:"\f1fd";
}

.bp5-icon-inbox-update::before{
  content:"\f1fe";
}

.bp5-icon-info-sign::before{
  content:"\f200";
}

.bp5-icon-inheritance::before{
  content:"\f201";
}

.bp5-icon-inherited-group::before{
  content:"\f202";
}

.bp5-icon-inner-join::before{
  content:"\f203";
}

.bp5-icon-input::before{
  content:"\f34b";
}

.bp5-icon-insert::before{
  content:"\f204";
}

.bp5-icon-intelligence::before{
  content:"\f337";
}

.bp5-icon-intersection::before{
  content:"\f205";
}

.bp5-icon-ip-address::before{
  content:"\f206";
}

.bp5-icon-issue::before{
  content:"\f209";
}

.bp5-icon-issue-closed::before{
  content:"\f207";
}

.bp5-icon-issue-new::before{
  content:"\f208";
}

.bp5-icon-italic::before{
  content:"\f20a";
}

.bp5-icon-join-table::before{
  content:"\f20b";
}

.bp5-icon-key::before{
  content:"\f215";
}

.bp5-icon-key-backspace::before{
  content:"\f20c";
}

.bp5-icon-key-command::before{
  content:"\f20d";
}

.bp5-icon-key-control::before{
  content:"\f20e";
}

.bp5-icon-key-delete::before{
  content:"\f20f";
}

.bp5-icon-key-enter::before{
  content:"\f210";
}

.bp5-icon-key-escape::before{
  content:"\f211";
}

.bp5-icon-key-option::before{
  content:"\f212";
}

.bp5-icon-key-shift::before{
  content:"\f213";
}

.bp5-icon-key-tab::before{
  content:"\f214";
}

.bp5-icon-known-vehicle::before{
  content:"\f216";
}

.bp5-icon-lab-test::before{
  content:"\f217";
}

.bp5-icon-label::before{
  content:"\f218";
}

.bp5-icon-layer::before{
  content:"\f21a";
}

.bp5-icon-layer-outline::before{
  content:"\f219";
}

.bp5-icon-layers::before{
  content:"\f21b";
}

.bp5-icon-layout::before{
  content:"\f225";
}

.bp5-icon-layout-auto::before{
  content:"\f21c";
}

.bp5-icon-layout-balloon::before{
  content:"\f21d";
}

.bp5-icon-layout-bottom-row-three-tiles::before{
  content:"\f364";
}

.bp5-icon-layout-bottom-row-two-tiles::before{
  content:"\f363";
}

.bp5-icon-layout-circle::before{
  content:"\f21e";
}

.bp5-icon-layout-grid::before{
  content:"\f21f";
}

.bp5-icon-layout-group-by::before{
  content:"\f220";
}

.bp5-icon-layout-hierarchy::before{
  content:"\f221";
}

.bp5-icon-layout-left-column-three-tiles::before{
  content:"\f366";
}

.bp5-icon-layout-left-column-two-tiles::before{
  content:"\f365";
}

.bp5-icon-layout-linear::before{
  content:"\f222";
}

.bp5-icon-layout-right-column-three-tiles::before{
  content:"\f368";
}

.bp5-icon-layout-right-column-two-tiles::before{
  content:"\f367";
}

.bp5-icon-layout-skew-grid::before{
  content:"\f223";
}

.bp5-icon-layout-sorted-clusters::before{
  content:"\f224";
}

.bp5-icon-layout-three-columns::before{
  content:"\f361";
}

.bp5-icon-layout-three-rows::before{
  content:"\f362";
}

.bp5-icon-layout-top-row-three-tiles::before{
  content:"\f36a";
}

.bp5-icon-layout-top-row-two-tiles::before{
  content:"\f369";
}

.bp5-icon-layout-two-columns::before{
  content:"\f35f";
}

.bp5-icon-layout-two-rows::before{
  content:"\f360";
}

.bp5-icon-learning::before{
  content:"\f226";
}

.bp5-icon-left-join::before{
  content:"\f227";
}

.bp5-icon-lengthen-text::before{
  content:"\f33e";
}

.bp5-icon-less-than::before{
  content:"\f229";
}

.bp5-icon-less-than-or-equal-to::before{
  content:"\f228";
}

.bp5-icon-lifesaver::before{
  content:"\f22a";
}

.bp5-icon-lightbulb::before{
  content:"\f22b";
}

.bp5-icon-lightning::before{
  content:"\f22c";
}

.bp5-icon-link::before{
  content:"\f22d";
}

.bp5-icon-list::before{
  content:"\f230";
}

.bp5-icon-list-columns::before{
  content:"\f22e";
}

.bp5-icon-list-detail-view::before{
  content:"\f22f";
}

.bp5-icon-locate::before{
  content:"\f231";
}

.bp5-icon-lock::before{
  content:"\f232";
}

.bp5-icon-locomotive::before{
  content:"\f33b";
}

.bp5-icon-log-in::before{
  content:"\f233";
}

.bp5-icon-log-out::before{
  content:"\f234";
}

.bp5-icon-low-voltage-pole::before{
  content:"\f332";
}

.bp5-icon-manual::before{
  content:"\f235";
}

.bp5-icon-manually-entered-data::before{
  content:"\f236";
}

.bp5-icon-many-to-many::before{
  content:"\f237";
}

.bp5-icon-many-to-one::before{
  content:"\f238";
}

.bp5-icon-map::before{
  content:"\f23b";
}

.bp5-icon-map-create::before{
  content:"\f239";
}

.bp5-icon-map-marker::before{
  content:"\f23a";
}

.bp5-icon-maximize::before{
  content:"\f23c";
}

.bp5-icon-media::before{
  content:"\f23d";
}

.bp5-icon-menu::before{
  content:"\f240";
}

.bp5-icon-menu-closed::before{
  content:"\f23e";
}

.bp5-icon-menu-open::before{
  content:"\f23f";
}

.bp5-icon-merge-columns::before{
  content:"\f241";
}

.bp5-icon-merge-links::before{
  content:"\f242";
}

.bp5-icon-microphone::before{
  content:"\f343";
}

.bp5-icon-minimize::before{
  content:"\f243";
}

.bp5-icon-minus::before{
  content:"\f244";
}

.bp5-icon-mobile-phone::before{
  content:"\f245";
}

.bp5-icon-mobile-video::before{
  content:"\f246";
}

.bp5-icon-modal::before{
  content:"\f248";
}

.bp5-icon-modal-filled::before{
  content:"\f247";
}

.bp5-icon-model::before{
  content:"\f33d";
}

.bp5-icon-moon::before{
  content:"\f249";
}

.bp5-icon-more::before{
  content:"\f24a";
}

.bp5-icon-mountain::before{
  content:"\f24b";
}

.bp5-icon-move::before{
  content:"\f24c";
}

.bp5-icon-mugshot::before{
  content:"\f24d";
}

.bp5-icon-multi-select::before{
  content:"\f24e";
}

.bp5-icon-music::before{
  content:"\f24f";
}

.bp5-icon-nest::before{
  content:"\f250";
}

.bp5-icon-new-drawing::before{
  content:"\f251";
}

.bp5-icon-new-grid-item::before{
  content:"\f252";
}

.bp5-icon-new-layer::before{
  content:"\f253";
}

.bp5-icon-new-layers::before{
  content:"\f254";
}

.bp5-icon-new-link::before{
  content:"\f255";
}

.bp5-icon-new-object::before{
  content:"\f256";
}

.bp5-icon-new-person::before{
  content:"\f257";
}

.bp5-icon-new-prescription::before{
  content:"\f258";
}

.bp5-icon-new-shield::before{
  content:"\f349";
}

.bp5-icon-new-text-box::before{
  content:"\f259";
}

.bp5-icon-ninja::before{
  content:"\f25a";
}

.bp5-icon-not-equal-to::before{
  content:"\f25b";
}

.bp5-icon-notifications::before{
  content:"\f25e";
}

.bp5-icon-notifications-snooze::before{
  content:"\f25c";
}

.bp5-icon-notifications-updated::before{
  content:"\f25d";
}

.bp5-icon-numbered-list::before{
  content:"\f25f";
}

.bp5-icon-numerical::before{
  content:"\f260";
}

.bp5-icon-office::before{
  content:"\f261";
}

.bp5-icon-offline::before{
  content:"\f262";
}

.bp5-icon-oil-field::before{
  content:"\f263";
}

.bp5-icon-one-column::before{
  content:"\f264";
}

.bp5-icon-one-to-many::before{
  content:"\f265";
}

.bp5-icon-one-to-one::before{
  content:"\f266";
}

.bp5-icon-open-application::before{
  content:"\f32b";
}

.bp5-icon-outdated::before{
  content:"\f267";
}

.bp5-icon-output::before{
  content:"\f370";
}

.bp5-icon-page-layout::before{
  content:"\f268";
}

.bp5-icon-panel-stats::before{
  content:"\f269";
}

.bp5-icon-panel-table::before{
  content:"\f26a";
}

.bp5-icon-paperclip::before{
  content:"\f26b";
}

.bp5-icon-paragraph::before{
  content:"\f26c";
}

.bp5-icon-paste-variable::before{
  content:"\f346";
}

.bp5-icon-path::before{
  content:"\f26e";
}

.bp5-icon-path-search::before{
  content:"\f26d";
}

.bp5-icon-pause::before{
  content:"\f26f";
}

.bp5-icon-people::before{
  content:"\f270";
}

.bp5-icon-percentage::before{
  content:"\f271";
}

.bp5-icon-person::before{
  content:"\f272";
}

.bp5-icon-phone::before{
  content:"\f273";
}

.bp5-icon-phone-call::before{
  content:"\f347";
}

.bp5-icon-phone-forward::before{
  content:"\f348";
}

.bp5-icon-pie-chart::before{
  content:"\f274";
}

.bp5-icon-pin::before{
  content:"\f275";
}

.bp5-icon-pivot::before{
  content:"\f277";
}

.bp5-icon-pivot-table::before{
  content:"\f276";
}

.bp5-icon-play::before{
  content:"\f278";
}

.bp5-icon-playbook::before{
  content:"\f324";
}

.bp5-icon-plus::before{
  content:"\f279";
}

.bp5-icon-polygon-filter::before{
  content:"\f27a";
}

.bp5-icon-power::before{
  content:"\f27b";
}

.bp5-icon-predictive-analysis::before{
  content:"\f27c";
}

.bp5-icon-prescription::before{
  content:"\f27d";
}

.bp5-icon-presentation::before{
  content:"\f27e";
}

.bp5-icon-print::before{
  content:"\f27f";
}

.bp5-icon-projects::before{
  content:"\f280";
}

.bp5-icon-properties::before{
  content:"\f281";
}

.bp5-icon-property::before{
  content:"\f282";
}

.bp5-icon-publish-function::before{
  content:"\f283";
}

.bp5-icon-pulse::before{
  content:"\f284";
}

.bp5-icon-rain::before{
  content:"\f285";
}

.bp5-icon-random::before{
  content:"\f286";
}

.bp5-icon-range-ring::before{
  content:"\f371";
}

.bp5-icon-record::before{
  content:"\f287";
}

.bp5-icon-rect-height::before{
  content:"\f325";
}

.bp5-icon-rect-width::before{
  content:"\f326";
}

.bp5-icon-rectangle::before{
  content:"\f321";
}

.bp5-icon-redo::before{
  content:"\f288";
}

.bp5-icon-refresh::before{
  content:"\f289";
}

.bp5-icon-regex::before{
  content:"\f32f";
}

.bp5-icon-regression-chart::before{
  content:"\f28a";
}

.bp5-icon-remove::before{
  content:"\f290";
}

.bp5-icon-remove-column::before{
  content:"\f28d";
}

.bp5-icon-remove-column-left::before{
  content:"\f28b";
}

.bp5-icon-remove-column-right::before{
  content:"\f28c";
}

.bp5-icon-remove-row-bottom::before{
  content:"\f28e";
}

.bp5-icon-remove-row-top::before{
  content:"\f28f";
}

.bp5-icon-repeat::before{
  content:"\f291";
}

.bp5-icon-reset::before{
  content:"\f292";
}

.bp5-icon-resolve::before{
  content:"\f293";
}

.bp5-icon-rig::before{
  content:"\f294";
}

.bp5-icon-right-join::before{
  content:"\f295";
}

.bp5-icon-ring::before{
  content:"\f296";
}

.bp5-icon-rocket::before{
  content:"\f298";
}

.bp5-icon-rocket-slant::before{
  content:"\f297";
}

.bp5-icon-rotate-document::before{
  content:"\f299";
}

.bp5-icon-rotate-page::before{
  content:"\f29a";
}

.bp5-icon-route::before{
  content:"\f29b";
}

.bp5-icon-satellite::before{
  content:"\f29c";
}

.bp5-icon-saved::before{
  content:"\f29d";
}

.bp5-icon-scatter-plot::before{
  content:"\f29e";
}

.bp5-icon-search::before{
  content:"\f2a2";
}

.bp5-icon-search-around::before{
  content:"\f29f";
}

.bp5-icon-search-template::before{
  content:"\f2a0";
}

.bp5-icon-search-text::before{
  content:"\f2a1";
}

.bp5-icon-segmented-control::before{
  content:"\f2a3";
}

.bp5-icon-select::before{
  content:"\f2a4";
}

.bp5-icon-selection::before{
  content:"\f2a5";
}

.bp5-icon-send-backward::before{
  content:"\f355";
}

.bp5-icon-send-message::before{
  content:"\f2a6";
}

.bp5-icon-send-to::before{
  content:"\f2a9";
}

.bp5-icon-send-to-graph::before{
  content:"\f2a7";
}

.bp5-icon-send-to-map::before{
  content:"\f2a8";
}

.bp5-icon-sensor::before{
  content:"\f33c";
}

.bp5-icon-series-add::before{
  content:"\f2aa";
}

.bp5-icon-series-configuration::before{
  content:"\f2ab";
}

.bp5-icon-series-derived::before{
  content:"\f2ac";
}

.bp5-icon-series-filtered::before{
  content:"\f2ad";
}

.bp5-icon-series-search::before{
  content:"\f2ae";
}

.bp5-icon-settings::before{
  content:"\f2af";
}

.bp5-icon-shapes::before{
  content:"\f2b0";
}

.bp5-icon-share::before{
  content:"\f2b1";
}

.bp5-icon-shared-filter::before{
  content:"\f2b2";
}

.bp5-icon-shield::before{
  content:"\f2b3";
}

.bp5-icon-ship::before{
  content:"\f2b4";
}

.bp5-icon-shop::before{
  content:"\f2b5";
}

.bp5-icon-shopping-cart::before{
  content:"\f2b6";
}

.bp5-icon-shorten-text::before{
  content:"\f33f";
}

.bp5-icon-signal-search::before{
  content:"\f2b7";
}

.bp5-icon-sim-card::before{
  content:"\f2b8";
}

.bp5-icon-slash::before{
  content:"\f2b9";
}

.bp5-icon-small-cross::before{
  content:"\f2ba";
}

.bp5-icon-small-info-sign::before{
  content:"\f334";
}

.bp5-icon-small-minus::before{
  content:"\f2bb";
}

.bp5-icon-small-plus::before{
  content:"\f2bc";
}

.bp5-icon-small-square::before{
  content:"\f2bd";
}

.bp5-icon-small-tick::before{
  content:"\f2be";
}

.bp5-icon-snowflake::before{
  content:"\f2bf";
}

.bp5-icon-soccer-ball::before{
  content:"\f350";
}

.bp5-icon-social-media::before{
  content:"\f2c0";
}

.bp5-icon-sort::before{
  content:"\f2c7";
}

.bp5-icon-sort-alphabetical::before{
  content:"\f2c2";
}

.bp5-icon-sort-alphabetical-desc::before{
  content:"\f2c1";
}

.bp5-icon-sort-asc::before{
  content:"\f2c3";
}

.bp5-icon-sort-desc::before{
  content:"\f2c4";
}

.bp5-icon-sort-numerical::before{
  content:"\f2c6";
}

.bp5-icon-sort-numerical-desc::before{
  content:"\f2c5";
}

.bp5-icon-spell-check::before{
  content:"\f340";
}

.bp5-icon-split-columns::before{
  content:"\f2c8";
}

.bp5-icon-sports-stadium::before{
  content:"\f351";
}

.bp5-icon-square::before{
  content:"\f2c9";
}

.bp5-icon-stacked-chart::before{
  content:"\f2ca";
}

.bp5-icon-stadium-geometry::before{
  content:"\f2cb";
}

.bp5-icon-star::before{
  content:"\f2cd";
}

.bp5-icon-star-empty::before{
  content:"\f2cc";
}

.bp5-icon-step-backward::before{
  content:"\f2ce";
}

.bp5-icon-step-chart::before{
  content:"\f2cf";
}

.bp5-icon-step-forward::before{
  content:"\f2d0";
}

.bp5-icon-stop::before{
  content:"\f2d1";
}

.bp5-icon-stopwatch::before{
  content:"\f2d2";
}

.bp5-icon-strikethrough::before{
  content:"\f2d3";
}

.bp5-icon-style::before{
  content:"\f2d4";
}

.bp5-icon-subscript::before{
  content:"\f339";
}

.bp5-icon-superscript::before{
  content:"\f33a";
}

.bp5-icon-swap-horizontal::before{
  content:"\f2d5";
}

.bp5-icon-swap-vertical::before{
  content:"\f2d6";
}

.bp5-icon-switch::before{
  content:"\f2d7";
}

.bp5-icon-symbol-circle::before{
  content:"\f2d8";
}

.bp5-icon-symbol-cross::before{
  content:"\f2d9";
}

.bp5-icon-symbol-diamond::before{
  content:"\f2da";
}

.bp5-icon-symbol-rectangle::before{
  content:"\f322";
}

.bp5-icon-symbol-square::before{
  content:"\f2db";
}

.bp5-icon-symbol-triangle-down::before{
  content:"\f2dc";
}

.bp5-icon-symbol-triangle-up::before{
  content:"\f2dd";
}

.bp5-icon-syringe::before{
  content:"\f2de";
}

.bp5-icon-table-sync::before{
  content:"\f36e";
}

.bp5-icon-tag::before{
  content:"\f2df";
}

.bp5-icon-take-action::before{
  content:"\f2e0";
}

.bp5-icon-tank::before{
  content:"\f2e1";
}

.bp5-icon-target::before{
  content:"\f2e2";
}

.bp5-icon-taxi::before{
  content:"\f2e3";
}

.bp5-icon-team::before{
  content:"\f352";
}

.bp5-icon-temperature::before{
  content:"\f2e4";
}

.bp5-icon-text-highlight::before{
  content:"\f2e5";
}

.bp5-icon-th::before{
  content:"\f2ea";
}

.bp5-icon-th-derived::before{
  content:"\f2e6";
}

.bp5-icon-th-disconnect::before{
  content:"\f2e7";
}

.bp5-icon-th-filtered::before{
  content:"\f2e8";
}

.bp5-icon-th-list::before{
  content:"\f2e9";
}

.bp5-icon-third-party::before{
  content:"\f2eb";
}

.bp5-icon-thumbs-down::before{
  content:"\f2ec";
}

.bp5-icon-thumbs-up::before{
  content:"\f2ed";
}

.bp5-icon-tick::before{
  content:"\f2ef";
}

.bp5-icon-tick-circle::before{
  content:"\f2ee";
}

.bp5-icon-time::before{
  content:"\f2f0";
}

.bp5-icon-timeline-area-chart::before{
  content:"\f2f1";
}

.bp5-icon-timeline-bar-chart::before{
  content:"\f2f2";
}

.bp5-icon-timeline-events::before{
  content:"\f2f3";
}

.bp5-icon-timeline-line-chart::before{
  content:"\f2f4";
}

.bp5-icon-tint::before{
  content:"\f2f5";
}

.bp5-icon-torch::before{
  content:"\f2f6";
}

.bp5-icon-tractor::before{
  content:"\f2f7";
}

.bp5-icon-train::before{
  content:"\f2f8";
}

.bp5-icon-translate::before{
  content:"\f2f9";
}

.bp5-icon-trash::before{
  content:"\f2fa";
}

.bp5-icon-tree::before{
  content:"\f2fb";
}

.bp5-icon-trending-down::before{
  content:"\f2fc";
}

.bp5-icon-trending-up::before{
  content:"\f2fd";
}

.bp5-icon-trophy::before{
  content:"\f34f";
}

.bp5-icon-truck::before{
  content:"\f2fe";
}

.bp5-icon-two-columns::before{
  content:"\f2ff";
}

.bp5-icon-unarchive::before{
  content:"\f300";
}

.bp5-icon-underline::before{
  content:"\f301";
}

.bp5-icon-undo::before{
  content:"\f302";
}

.bp5-icon-ungroup-objects::before{
  content:"\f303";
}

.bp5-icon-unknown-vehicle::before{
  content:"\f304";
}

.bp5-icon-unlink::before{
  content:"\f345";
}

.bp5-icon-unlock::before{
  content:"\f305";
}

.bp5-icon-unpin::before{
  content:"\f306";
}

.bp5-icon-unresolve::before{
  content:"\f307";
}

.bp5-icon-updated::before{
  content:"\f308";
}

.bp5-icon-upload::before{
  content:"\f309";
}

.bp5-icon-user::before{
  content:"\f30a";
}

.bp5-icon-variable::before{
  content:"\f30b";
}

.bp5-icon-vector::before{
  content:"\f35e";
}

.bp5-icon-vertical-bar-chart-asc::before{
  content:"\f30c";
}

.bp5-icon-vertical-bar-chart-desc::before{
  content:"\f30d";
}

.bp5-icon-vertical-distribution::before{
  content:"\f30e";
}

.bp5-icon-vertical-inbetween::before{
  content:"\f32a";
}

.bp5-icon-video::before{
  content:"\f30f";
}

.bp5-icon-virus::before{
  content:"\f310";
}

.bp5-icon-volume-down::before{
  content:"\f311";
}

.bp5-icon-volume-off::before{
  content:"\f312";
}

.bp5-icon-volume-up::before{
  content:"\f313";
}

.bp5-icon-walk::before{
  content:"\f314";
}

.bp5-icon-warning-sign::before{
  content:"\f315";
}

.bp5-icon-waterfall-chart::before{
  content:"\f316";
}

.bp5-icon-waves::before{
  content:"\f317";
}

.bp5-icon-widget::before{
  content:"\f31b";
}

.bp5-icon-widget-button::before{
  content:"\f318";
}

.bp5-icon-widget-footer::before{
  content:"\f319";
}

.bp5-icon-widget-header::before{
  content:"\f31a";
}

.bp5-icon-wind::before{
  content:"\f31c";
}

.bp5-icon-wrench::before{
  content:"\f31d";
}

.bp5-icon-zoom-in::before{
  content:"\f31e";
}

.bp5-icon-zoom-out::before{
  content:"\f31f";
}

.bp5-icon-zoom-to-fit::before{
  content:"\f320";
}

.bp5-text-muted{
  color:#5f6b7c;
}

.bp5-text-disabled{
  color:rgba(95, 107, 124, 0.6);
}

.bp5-running-text hr{
  border-color:rgba(17, 20, 24, 0.15);
}

a{
  color:#215db0;
}
a:hover{
  color:#215db0;
}
a .bp5-icon, a .bp5-icon-standard, a .bp5-icon-large{
  color:inherit;
}
a code{
  color:inherit;
}

.bp5-code,
.bp5-running-text code{
  background:rgba(255, 255, 255, 0.7);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2);
  color:#5f6b7c;
}
a > .bp5-code,
a > .bp5-running-text code{
  color:#2d72d2;
}

.bp5-code-block,
.bp5-running-text pre{
  background:rgba(255, 255, 255, 0.7);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.15);
  color:#1c2127;
}
.bp5-code-block > code,
.bp5-running-text pre > code{
  background:none;
  box-shadow:none;
  color:inherit;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-code-block,
  .bp5-running-text pre{
    border:1px solid buttonborder;
    box-shadow:none;
  }
}

.bp5-key,
.bp5-running-text kbd{
  background:#ffffff;
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  color:#5f6b7c;
}

.bp5-icon.bp5-intent-primary, .bp5-icon-standard.bp5-intent-primary, .bp5-icon-large.bp5-intent-primary{
  color:#215db0;
}
.bp5-icon.bp5-intent-success, .bp5-icon-standard.bp5-intent-success, .bp5-icon-large.bp5-intent-success{
  color:#1c6e42;
}
.bp5-icon.bp5-intent-warning, .bp5-icon-standard.bp5-intent-warning, .bp5-icon-large.bp5-intent-warning{
  color:#935610;
}
.bp5-icon.bp5-intent-danger, .bp5-icon-standard.bp5-intent-danger, .bp5-icon-large.bp5-intent-danger{
  color:#ac2f33;
}
.bp5-heading{
  color:#1c2127;
  font-weight:600;
  margin:0 0 10px;
  padding:0;
}
.bp5-dark .bp5-heading{
  color:#f6f7f9;
}
.bp5-heading.bp5-text-muted{
  color:#5f6b7c;
}
.bp5-dark .bp5-heading.bp5-text-muted{
  color:#abb3bf;
}

.bp5-running-text h1, h1.bp5-heading{
  font-size:36px;
  line-height:40px;
}

.bp5-running-text h2, h2.bp5-heading{
  font-size:28px;
  line-height:32px;
}

.bp5-running-text h3, h3.bp5-heading{
  font-size:22px;
  line-height:25px;
}

.bp5-running-text h4, h4.bp5-heading{
  font-size:18px;
  line-height:21px;
}

.bp5-running-text h5, h5.bp5-heading{
  font-size:16px;
  line-height:19px;
}

.bp5-running-text h6, h6.bp5-heading{
  font-size:14px;
  line-height:16px;
}
.bp5-ui-text{
  font-size:14px;
  font-weight:400;
  letter-spacing:0;
  line-height:1.28581;
  text-transform:none;
}

.bp5-monospace-text{
  font-family:monospace;
  text-transform:none;
}

.bp5-text-overflow-ellipsis{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  word-wrap:normal;
}
.bp5-running-text{
  font-size:14px;
  line-height:1.5;
}
.bp5-running-text h1{
  color:#1c2127;
  font-weight:600;
  margin-bottom:20px;
  margin-top:40px;
}
.bp5-dark .bp5-running-text h1{
  color:#f6f7f9;
}
.bp5-running-text h1.bp5-text-muted{
  color:#5f6b7c;
}
.bp5-dark .bp5-running-text h1.bp5-text-muted{
  color:#abb3bf;
}
.bp5-running-text h2{
  color:#1c2127;
  font-weight:600;
  margin-bottom:20px;
  margin-top:40px;
}
.bp5-dark .bp5-running-text h2{
  color:#f6f7f9;
}
.bp5-running-text h2.bp5-text-muted{
  color:#5f6b7c;
}
.bp5-dark .bp5-running-text h2.bp5-text-muted{
  color:#abb3bf;
}
.bp5-running-text h3{
  color:#1c2127;
  font-weight:600;
  margin-bottom:20px;
  margin-top:40px;
}
.bp5-dark .bp5-running-text h3{
  color:#f6f7f9;
}
.bp5-running-text h3.bp5-text-muted{
  color:#5f6b7c;
}
.bp5-dark .bp5-running-text h3.bp5-text-muted{
  color:#abb3bf;
}
.bp5-running-text h4{
  color:#1c2127;
  font-weight:600;
  margin-bottom:20px;
  margin-top:40px;
}
.bp5-dark .bp5-running-text h4{
  color:#f6f7f9;
}
.bp5-running-text h4.bp5-text-muted{
  color:#5f6b7c;
}
.bp5-dark .bp5-running-text h4.bp5-text-muted{
  color:#abb3bf;
}
.bp5-running-text h5{
  color:#1c2127;
  font-weight:600;
  margin-bottom:20px;
  margin-top:40px;
}
.bp5-dark .bp5-running-text h5{
  color:#f6f7f9;
}
.bp5-running-text h5.bp5-text-muted{
  color:#5f6b7c;
}
.bp5-dark .bp5-running-text h5.bp5-text-muted{
  color:#abb3bf;
}
.bp5-running-text h6{
  color:#1c2127;
  font-weight:600;
  margin-bottom:20px;
  margin-top:40px;
}
.bp5-dark .bp5-running-text h6{
  color:#f6f7f9;
}
.bp5-running-text h6.bp5-text-muted{
  color:#5f6b7c;
}
.bp5-dark .bp5-running-text h6.bp5-text-muted{
  color:#abb3bf;
}
.bp5-running-text hr{
  border:none;
  border-bottom:1px solid rgba(17, 20, 24, 0.15);
  margin:20px 0;
}
.bp5-running-text p{
  margin:0 0 10px;
  padding:0;
}
.bp5-text-large{
  font-size:16px;
}

.bp5-text-small{
  font-size:12px;
}
a{
  text-decoration:none;
}
a:hover{
  cursor:pointer;
  text-decoration:underline;
}
.bp5-code, .bp5-running-text code{
  font-family:monospace;
  text-transform:none;
  border-radius:2px;
  font-size:smaller;
  padding:2px 5px;
}

.bp5-code-block, .bp5-running-text pre{
  font-family:monospace;
  text-transform:none;
  border-radius:2px;
  display:block;
  font-size:13px;
  line-height:1.4;
  margin:10px 0;
  padding:13px 15px 12px;
  word-break:break-all;
  word-wrap:break-word;
}
.bp5-code-block > code, .bp5-running-text pre > code{
  font-size:inherit;
  padding:0;
}

.bp5-key, .bp5-running-text kbd{
  align-items:center;
  border-radius:2px;
  display:inline-flex;
  font-family:inherit;
  font-size:12px;
  height:24px;
  justify-content:center;
  line-height:24px;
  min-width:24px;
  padding:2px 4px;
  vertical-align:middle;
}
.bp5-key .bp5-icon, .bp5-running-text kbd .bp5-icon, .bp5-key .bp5-icon-standard, .bp5-running-text kbd .bp5-icon-standard, .bp5-key .bp5-icon-large, .bp5-running-text kbd .bp5-icon-large{
  margin-right:5px;
}
.bp5-blockquote, .bp5-running-text blockquote{
  border-left:solid 4px rgba(171, 179, 191, 0.5);
  margin:0 0 10px;
  padding:0 20px;
}
.bp5-dark .bp5-blockquote, .bp5-dark .bp5-running-text blockquote, .bp5-running-text .bp5-dark blockquote{
  border-color:rgba(115, 128, 145, 0.5);
}
.bp5-list, .bp5-running-text ul,
.bp5-running-text ol{
  margin:10px 0;
  padding-left:30px;
}
.bp5-list li:not(:last-child), .bp5-running-text ul li:not(:last-child),
.bp5-running-text ol li:not(:last-child){
  margin-bottom:5px;
}
.bp5-list ol, .bp5-running-text ul ol,
.bp5-running-text ol ol,
.bp5-list ul,
.bp5-running-text ul ul,
.bp5-running-text ol ul{
  margin-top:5px;
}

.bp5-list-unstyled{
  list-style:none;
  margin:0;
  padding:0;
}
.bp5-list-unstyled li{
  padding:0;
}
.bp5-rtl{
  text-align:right;
}
.bp5-dark{
  color:#f6f7f9;
}
.bp5-dark .bp5-text-muted{
  color:#abb3bf;
}
.bp5-dark .bp5-text-disabled{
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-running-text hr{
  border-color:rgba(255, 255, 255, 0.2);
}
.bp5-dark a{
  color:#8abbff;
}
.bp5-dark a:hover{
  color:#8abbff;
}
.bp5-dark a .bp5-icon, .bp5-dark a .bp5-icon-standard, .bp5-dark a .bp5-icon-large{
  color:inherit;
}
.bp5-dark a code{
  color:inherit;
}
.bp5-dark .bp5-code,
.bp5-dark .bp5-running-text code{
  background:rgba(17, 20, 24, 0.3);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color:#abb3bf;
}
a > .bp5-dark .bp5-code,
a > .bp5-dark .bp5-running-text code{
  color:inherit;
}
.bp5-dark .bp5-code-block,
.bp5-dark .bp5-running-text pre{
  background:rgba(17, 20, 24, 0.3);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color:#f6f7f9;
}
.bp5-dark .bp5-code-block > code,
.bp5-dark .bp5-running-text pre > code{
  background:none;
  box-shadow:none;
  color:inherit;
}
.bp5-dark .bp5-key,
.bp5-dark .bp5-running-text kbd{
  background:#383e47;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
  color:#abb3bf;
}
.bp5-dark .bp5-icon.bp5-intent-primary, .bp5-dark .bp5-icon-standard.bp5-intent-primary, .bp5-dark .bp5-icon-large.bp5-intent-primary{
  color:#8abbff;
}
.bp5-dark .bp5-icon.bp5-intent-success, .bp5-dark .bp5-icon-standard.bp5-intent-success, .bp5-dark .bp5-icon-large.bp5-intent-success{
  color:#72ca9b;
}
.bp5-dark .bp5-icon.bp5-intent-warning, .bp5-dark .bp5-icon-standard.bp5-intent-warning, .bp5-dark .bp5-icon-large.bp5-intent-warning{
  color:#fbb360;
}
.bp5-dark .bp5-icon.bp5-intent-danger, .bp5-dark .bp5-icon-standard.bp5-intent-danger, .bp5-dark .bp5-icon-large.bp5-intent-danger{
  color:#fa999c;
}

:focus{
  outline:rgba(33, 93, 176, 0.752) solid 2px;
  outline-offset:2px;
  -moz-outline-radius:6px;
}
.bp5-dark :focus{
  outline-color:rgba(138, 187, 255, 0.752);
}

.bp5-focus-disabled :focus:not(.bp5-focus-style-manager-ignore *){
  outline:none !important;
}
.bp5-focus-disabled :focus:not(.bp5-focus-style-manager-ignore *) ~ .bp5-control-indicator{
  outline:none !important;
}

.bp5-dark{
  color-scheme:dark;
}
.bp5-alert{
  max-width:400px;
  padding:20px;
}

.bp5-alert-body{
  display:flex;
}
.bp5-alert-body .bp5-icon{
  font-size:40px;
  margin-right:20px;
  margin-top:0;
}

.bp5-alert-contents{
  word-break:break-word;
}

.bp5-alert-footer{
  display:flex;
  flex-direction:row-reverse;
  margin-top:10px;
}
.bp5-alert-footer .bp5-button{
  margin-left:10px;
}
.bp5-breadcrumbs{
  align-items:center;
  cursor:default;
  display:flex;
  flex-wrap:wrap;
  height:30px;
  list-style:none;
  margin:0;
  padding:0;
}
.bp5-breadcrumbs > li{
  align-items:center;
  display:flex;
}
.bp5-breadcrumbs > li::after{
  background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%235f6b7c'/%3e%3c/svg%3e");
  content:"";
  display:block;
  height:16px;
  margin:0 5px;
  width:16px;
}
.bp5-breadcrumbs > li:last-of-type::after{
  display:none;
}

.bp5-breadcrumb,
.bp5-breadcrumb-current,
.bp5-breadcrumbs-collapsed{
  align-items:center;
  display:inline-flex;
  font-size:16px;
}

.bp5-breadcrumb,
.bp5-breadcrumbs-collapsed{
  color:#5f6b7c;
}

.bp5-breadcrumb:hover{
  text-decoration:none;
}
.bp5-breadcrumb.bp5-disabled{
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}
.bp5-breadcrumb .bp5-icon{
  margin-right:5px;
}

.bp5-breadcrumb-current{
  color:inherit;
  font-weight:600;
}
.bp5-breadcrumb-current .bp5-input{
  font-size:inherit;
  font-weight:inherit;
  vertical-align:baseline;
}

.bp5-breadcrumbs-collapsed{
  background:rgba(143, 153, 168, 0.15);
  border:none;
  border-radius:2px;
  cursor:pointer;
  margin-right:2px;
  padding:1px 5px;
  vertical-align:text-bottom;
}
.bp5-breadcrumbs-collapsed::before{
  background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cg fill='%235f6b7c'%3e%3cpath d='M2 6.03a2 2 0 100 4 2 2 0 100-4zM14 6.03a2 2 0 100 4 2 2 0 100-4zM8 6.03a2 2 0 100 4 2 2 0 100-4z'/%3e%3c/g%3e%3c/svg%3e") center no-repeat;
  content:"";
  display:block;
  height:16px;
  width:16px;
}
.bp5-breadcrumbs-collapsed:hover{
  background:rgba(143, 153, 168, 0.3);
  color:#1c2127;
  text-decoration:none;
}

.bp5-dark .bp5-breadcrumb,
.bp5-dark .bp5-breadcrumbs-collapsed{
  color:#abb3bf;
}
.bp5-dark .bp5-breadcrumbs > li::after{
  background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%23abb3bf'/%3e%3c/svg%3e");
  color:#abb3bf;
}
.bp5-dark .bp5-breadcrumb.bp5-disabled{
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-breadcrumb-current{
  color:#f6f7f9;
}
.bp5-dark .bp5-breadcrumbs-collapsed{
  background:rgba(143, 153, 168, 0.2);
}
.bp5-dark .bp5-breadcrumbs-collapsed::before{
  background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cg fill='%23abb3bf'%3e%3cpath d='M2 6.03a2 2 0 100 4 2 2 0 100-4zM14 6.03a2 2 0 100 4 2 2 0 100-4zM8 6.03a2 2 0 100 4 2 2 0 100-4z'/%3e%3c/g%3e%3c/svg%3e") center no-repeat;
}
.bp5-dark .bp5-breadcrumbs-collapsed:hover{
  background:rgba(143, 153, 168, 0.3);
  color:#f6f7f9;
}
.bp5-button{
  display:inline-flex;
  flex-direction:row;
  align-items:center;
  border:none;
  border-radius:2px;
  cursor:pointer;
  font-size:14px;
  justify-content:center;
  padding:5px 10px;
  text-align:left;
  vertical-align:middle;
  min-height:30px;
  min-width:30px;
}
.bp5-button > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-button > .bp5-fill{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-button::before,
.bp5-button > *{
  margin-right:7px;
}
.bp5-button:empty::before,
.bp5-button > :last-child{
  margin-right:0;
}
.bp5-button:empty{
  padding:0 !important;
}
.bp5-button:disabled, .bp5-button.bp5-disabled{
  cursor:not-allowed;
}
.bp5-button.bp5-fill{
  display:flex;
  width:100%;
}
.bp5-button.bp5-align-right, .bp5-align-right .bp5-button{
  text-align:right;
}
.bp5-button.bp5-align-left, .bp5-align-left .bp5-button{
  text-align:left;
}
.bp5-button:not([class*=bp5-intent-]){
  background-color:#f6f7f9;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color:#1c2127;
}
.bp5-button:not([class*=bp5-intent-]):hover{
  background-clip:padding-box;
  background-color:#edeff2;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-button:not([class*=bp5-intent-]):active, .bp5-button:not([class*=bp5-intent-]).bp5-active{
  background-color:#dce0e5;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button:not([class*=bp5-intent-]):active, .bp5-button:not([class*=bp5-intent-]).bp5-active{
    background:highlight;
  }
}
.bp5-button:not([class*=bp5-intent-]):disabled, .bp5-button:not([class*=bp5-intent-]).bp5-disabled{
  background-color:rgba(211, 216, 222, 0.5);
  box-shadow:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
  outline:none;
}
.bp5-button:not([class*=bp5-intent-]):disabled.bp5-active, .bp5-button:not([class*=bp5-intent-]):disabled.bp5-active:hover, .bp5-button:not([class*=bp5-intent-]).bp5-disabled.bp5-active, .bp5-button:not([class*=bp5-intent-]).bp5-disabled.bp5-active:hover{
  background:rgba(211, 216, 222, 0.7);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button:not([class*=bp5-intent-]){
    border:1px solid buttonborder;
  }
}
.bp5-button.bp5-intent-primary{
  background-color:#2d72d2;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color:#ffffff;
}
.bp5-button.bp5-intent-primary:hover, .bp5-button.bp5-intent-primary:active, .bp5-button.bp5-intent-primary.bp5-active{
  color:#ffffff;
}
.bp5-button.bp5-intent-primary:hover{
  background-color:#215db0;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-button.bp5-intent-primary:active, .bp5-button.bp5-intent-primary.bp5-active{
  background-color:#184a90;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-button.bp5-intent-primary:disabled, .bp5-button.bp5-intent-primary.bp5-disabled{
  background-color:rgba(45, 114, 210, 0.5);
  border-color:transparent;
  box-shadow:none;
  color:rgba(255, 255, 255, 0.6);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button.bp5-intent-primary:disabled, .bp5-button.bp5-intent-primary.bp5-disabled{
    border-color:graytext;
    color:graytext;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button.bp5-intent-primary{
    border:1px solid buttonborder;
    box-shadow:none;
  }
}
.bp5-button.bp5-intent-success{
  background-color:#238551;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color:#ffffff;
}
.bp5-button.bp5-intent-success:hover, .bp5-button.bp5-intent-success:active, .bp5-button.bp5-intent-success.bp5-active{
  color:#ffffff;
}
.bp5-button.bp5-intent-success:hover{
  background-color:#1c6e42;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-button.bp5-intent-success:active, .bp5-button.bp5-intent-success.bp5-active{
  background-color:#165a36;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-button.bp5-intent-success:disabled, .bp5-button.bp5-intent-success.bp5-disabled{
  background-color:rgba(35, 133, 81, 0.5);
  border-color:transparent;
  box-shadow:none;
  color:rgba(255, 255, 255, 0.6);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button.bp5-intent-success:disabled, .bp5-button.bp5-intent-success.bp5-disabled{
    border-color:graytext;
    color:graytext;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button.bp5-intent-success{
    border:1px solid buttonborder;
    box-shadow:none;
  }
}
.bp5-button.bp5-intent-warning{
  background-color:#c87619;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color:#ffffff;
}
.bp5-button.bp5-intent-warning:hover, .bp5-button.bp5-intent-warning:active, .bp5-button.bp5-intent-warning.bp5-active{
  color:#ffffff;
}
.bp5-button.bp5-intent-warning:hover{
  background-color:#935610;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-button.bp5-intent-warning:active, .bp5-button.bp5-intent-warning.bp5-active{
  background-color:#77450d;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-button.bp5-intent-warning:disabled, .bp5-button.bp5-intent-warning.bp5-disabled{
  background-color:rgba(200, 118, 25, 0.5);
  border-color:transparent;
  box-shadow:none;
  color:rgba(255, 255, 255, 0.6);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button.bp5-intent-warning:disabled, .bp5-button.bp5-intent-warning.bp5-disabled{
    border-color:graytext;
    color:graytext;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button.bp5-intent-warning{
    border:1px solid buttonborder;
    box-shadow:none;
  }
}
.bp5-button.bp5-intent-danger{
  background-color:#cd4246;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color:#ffffff;
}
.bp5-button.bp5-intent-danger:hover, .bp5-button.bp5-intent-danger:active, .bp5-button.bp5-intent-danger.bp5-active{
  color:#ffffff;
}
.bp5-button.bp5-intent-danger:hover{
  background-color:#ac2f33;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-button.bp5-intent-danger:active, .bp5-button.bp5-intent-danger.bp5-active{
  background-color:#8e292c;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-button.bp5-intent-danger:disabled, .bp5-button.bp5-intent-danger.bp5-disabled{
  background-color:rgba(205, 66, 70, 0.5);
  border-color:transparent;
  box-shadow:none;
  color:rgba(255, 255, 255, 0.6);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button.bp5-intent-danger:disabled, .bp5-button.bp5-intent-danger.bp5-disabled{
    border-color:graytext;
    color:graytext;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button.bp5-intent-danger{
    border:1px solid buttonborder;
    box-shadow:none;
  }
}
.bp5-button[class*=bp5-intent-] .bp5-button-spinner .bp5-spinner-head{
  stroke:#ffffff;
}
.bp5-button.bp5-large, .bp5-large .bp5-button{
  min-height:40px;
  min-width:40px;
  font-size:16px;
  padding:5px 15px;
}
.bp5-button.bp5-large::before,
.bp5-button.bp5-large > *, .bp5-large .bp5-button::before,
.bp5-large .bp5-button > *{
  margin-right:10px;
}
.bp5-button.bp5-large:empty::before,
.bp5-button.bp5-large > :last-child, .bp5-large .bp5-button:empty::before,
.bp5-large .bp5-button > :last-child{
  margin-right:0;
}
.bp5-button.bp5-small, .bp5-small .bp5-button{
  min-height:24px;
  min-width:24px;
  padding:0 7px;
}
.bp5-button.bp5-loading{
  position:relative;
}
.bp5-button.bp5-loading[class*=bp5-icon-]::before{
  visibility:hidden;
}
.bp5-button.bp5-loading .bp5-button-spinner{
  margin:0;
  position:absolute;
}
.bp5-button.bp5-loading > :not(.bp5-button-spinner){
  visibility:hidden;
}
.bp5-button[class*=bp5-icon-]::before{
  font-family:"blueprint-icons-16", sans-serif;
  font-size:16px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:16px;
  line-height:1;
  width:16px;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  color:#5f6b7c;
}
.bp5-button .bp5-icon, .bp5-button .bp5-icon-standard, .bp5-button .bp5-icon-large{
  color:#5f6b7c;
}
.bp5-button .bp5-icon.bp5-align-right, .bp5-button .bp5-icon-standard.bp5-align-right, .bp5-button .bp5-icon-large.bp5-align-right{
  margin-left:7px;
}
.bp5-button .bp5-icon:first-child:last-child,
.bp5-button .bp5-spinner + .bp5-icon:last-child{
  margin:0 -7px;
}
.bp5-dark .bp5-button:not([class*=bp5-intent-]){
  background-color:#383e47;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
  color:#f6f7f9;
}
.bp5-dark .bp5-button:not([class*=bp5-intent-]):hover, .bp5-dark .bp5-button:not([class*=bp5-intent-]):active, .bp5-dark .bp5-button:not([class*=bp5-intent-]).bp5-active{
  color:#f6f7f9;
}
.bp5-dark .bp5-button:not([class*=bp5-intent-]):hover{
  background-color:#2f343c;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-button:not([class*=bp5-intent-]):active, .bp5-dark .bp5-button:not([class*=bp5-intent-]).bp5-active{
  background-color:#1c2127;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-button:not([class*=bp5-intent-]):disabled, .bp5-dark .bp5-button:not([class*=bp5-intent-]).bp5-disabled{
  background-color:rgba(64, 72, 84, 0.5);
  box-shadow:none;
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-button:not([class*=bp5-intent-]):disabled.bp5-active, .bp5-dark .bp5-button:not([class*=bp5-intent-]).bp5-disabled.bp5-active{
  background:rgba(64, 72, 84, 0.7);
}
.bp5-dark .bp5-button:not([class*=bp5-intent-]) .bp5-button-spinner .bp5-spinner-head{
  background:rgba(17, 20, 24, 0.5);
  stroke:#8f99a8;
}
.bp5-dark .bp5-button:not([class*=bp5-intent-])[class*=bp5-icon-]::before{
  color:#abb3bf;
}
.bp5-dark .bp5-button:not([class*=bp5-intent-]) .bp5-icon:not([class*=bp5-intent-]), .bp5-dark .bp5-button:not([class*=bp5-intent-]) .bp5-icon-standard:not([class*=bp5-intent-]), .bp5-dark .bp5-button:not([class*=bp5-intent-]) .bp5-icon-large:not([class*=bp5-intent-]){
  color:#abb3bf;
}
.bp5-dark .bp5-button[class*=bp5-intent-]{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-dark .bp5-button[class*=bp5-intent-]:hover{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-dark .bp5-button[class*=bp5-intent-]:active, .bp5-dark .bp5-button[class*=bp5-intent-].bp5-active{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-button[class*=bp5-intent-]:disabled, .bp5-dark .bp5-button[class*=bp5-intent-].bp5-disabled{
  box-shadow:none;
  color:rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-button[class*=bp5-intent-] .bp5-button-spinner .bp5-spinner-head{
  stroke:#8f99a8;
}
.bp5-button:disabled::before,
.bp5-button:disabled .bp5-icon, .bp5-button:disabled .bp5-icon-standard, .bp5-button:disabled .bp5-icon-large, .bp5-button.bp5-disabled::before,
.bp5-button.bp5-disabled .bp5-icon, .bp5-button.bp5-disabled .bp5-icon-standard, .bp5-button.bp5-disabled .bp5-icon-large, .bp5-button[class*=bp5-intent-]::before,
.bp5-button[class*=bp5-intent-] .bp5-icon, .bp5-button[class*=bp5-intent-] .bp5-icon-standard, .bp5-button[class*=bp5-intent-] .bp5-icon-large{
  color:inherit !important;
}
.bp5-button.bp5-minimal{
  background:none;
  box-shadow:none;
}
.bp5-button.bp5-minimal:hover{
  background:rgba(143, 153, 168, 0.15);
  box-shadow:none;
  color:#1c2127;
  text-decoration:none;
}
.bp5-button.bp5-minimal:active, .bp5-button.bp5-minimal.bp5-active{
  background:rgba(143, 153, 168, 0.3);
  box-shadow:none;
  color:#1c2127;
}
.bp5-button.bp5-minimal:disabled, .bp5-button.bp5-minimal:disabled:hover, .bp5-button.bp5-minimal.bp5-disabled, .bp5-button.bp5-minimal.bp5-disabled:hover{
  background:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}
.bp5-button.bp5-minimal:disabled.bp5-active, .bp5-button.bp5-minimal:disabled:hover.bp5-active, .bp5-button.bp5-minimal.bp5-disabled.bp5-active, .bp5-button.bp5-minimal.bp5-disabled:hover.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-dark .bp5-button.bp5-minimal{
  background:none;
  box-shadow:none;
  color:#ffffff;
}
.bp5-dark .bp5-button.bp5-minimal:hover, .bp5-dark .bp5-button.bp5-minimal:active, .bp5-dark .bp5-button.bp5-minimal.bp5-active{
  background:none;
  box-shadow:none;
  color:#ffffff;
}
.bp5-dark .bp5-button.bp5-minimal:hover{
  background:rgba(143, 153, 168, 0.15);
}
.bp5-dark .bp5-button.bp5-minimal:active, .bp5-dark .bp5-button.bp5-minimal.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-dark .bp5-button.bp5-minimal:disabled, .bp5-dark .bp5-button.bp5-minimal:disabled:hover, .bp5-dark .bp5-button.bp5-minimal.bp5-disabled, .bp5-dark .bp5-button.bp5-minimal.bp5-disabled:hover{
  background:none;
  color:rgba(171, 179, 191, 0.6);
  cursor:not-allowed;
}
.bp5-dark .bp5-button.bp5-minimal:disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal:disabled:hover.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-disabled:hover.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-button.bp5-minimal.bp5-intent-primary{
  color:#215db0;
}
.bp5-button.bp5-minimal.bp5-intent-primary:hover, .bp5-button.bp5-minimal.bp5-intent-primary:active, .bp5-button.bp5-minimal.bp5-intent-primary.bp5-active{
  background:none;
  box-shadow:none;
  color:#215db0;
}
.bp5-button.bp5-minimal.bp5-intent-primary:hover{
  background:rgba(45, 114, 210, 0.15);
  color:#215db0;
}
.bp5-button.bp5-minimal.bp5-intent-primary:active, .bp5-button.bp5-minimal.bp5-intent-primary.bp5-active{
  background:rgba(45, 114, 210, 0.3);
  color:#184a90;
}
.bp5-button.bp5-minimal.bp5-intent-primary:disabled, .bp5-button.bp5-minimal.bp5-intent-primary.bp5-disabled{
  background:none;
  color:rgba(33, 93, 176, 0.5);
}
.bp5-button.bp5-minimal.bp5-intent-primary:disabled.bp5-active, .bp5-button.bp5-minimal.bp5-intent-primary.bp5-disabled.bp5-active{
  background:rgba(45, 114, 210, 0.3);
}
.bp5-button.bp5-minimal.bp5-intent-primary .bp5-button-spinner .bp5-spinner-head{
  stroke:#215db0;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary{
  color:#8abbff;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary:hover{
  background:rgba(45, 114, 210, 0.2);
  color:#8abbff;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary:active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary.bp5-active{
  background:rgba(45, 114, 210, 0.3);
  color:#99c4ff;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary:disabled, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary.bp5-disabled{
  background:none;
  color:rgba(138, 187, 255, 0.5);
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary:disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary.bp5-disabled.bp5-active{
  background:rgba(45, 114, 210, 0.3);
}
.bp5-button.bp5-minimal.bp5-intent-success{
  color:#1c6e42;
}
.bp5-button.bp5-minimal.bp5-intent-success:hover, .bp5-button.bp5-minimal.bp5-intent-success:active, .bp5-button.bp5-minimal.bp5-intent-success.bp5-active{
  background:none;
  box-shadow:none;
  color:#1c6e42;
}
.bp5-button.bp5-minimal.bp5-intent-success:hover{
  background:rgba(35, 133, 81, 0.15);
  color:#1c6e42;
}
.bp5-button.bp5-minimal.bp5-intent-success:active, .bp5-button.bp5-minimal.bp5-intent-success.bp5-active{
  background:rgba(35, 133, 81, 0.3);
  color:#165a36;
}
.bp5-button.bp5-minimal.bp5-intent-success:disabled, .bp5-button.bp5-minimal.bp5-intent-success.bp5-disabled{
  background:none;
  color:rgba(28, 110, 66, 0.5);
}
.bp5-button.bp5-minimal.bp5-intent-success:disabled.bp5-active, .bp5-button.bp5-minimal.bp5-intent-success.bp5-disabled.bp5-active{
  background:rgba(35, 133, 81, 0.3);
}
.bp5-button.bp5-minimal.bp5-intent-success .bp5-button-spinner .bp5-spinner-head{
  stroke:#1c6e42;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-success{
  color:#72ca9b;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-success:hover{
  background:rgba(35, 133, 81, 0.2);
  color:#72ca9b;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-success:active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-success.bp5-active{
  background:rgba(35, 133, 81, 0.3);
  color:#7cd7a2;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-success:disabled, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-success.bp5-disabled{
  background:none;
  color:rgba(114, 202, 155, 0.5);
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-success:disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-success.bp5-disabled.bp5-active{
  background:rgba(35, 133, 81, 0.3);
}
.bp5-button.bp5-minimal.bp5-intent-warning{
  color:#935610;
}
.bp5-button.bp5-minimal.bp5-intent-warning:hover, .bp5-button.bp5-minimal.bp5-intent-warning:active, .bp5-button.bp5-minimal.bp5-intent-warning.bp5-active{
  background:none;
  box-shadow:none;
  color:#935610;
}
.bp5-button.bp5-minimal.bp5-intent-warning:hover{
  background:rgba(200, 118, 25, 0.15);
  color:#935610;
}
.bp5-button.bp5-minimal.bp5-intent-warning:active, .bp5-button.bp5-minimal.bp5-intent-warning.bp5-active{
  background:rgba(200, 118, 25, 0.3);
  color:#77450d;
}
.bp5-button.bp5-minimal.bp5-intent-warning:disabled, .bp5-button.bp5-minimal.bp5-intent-warning.bp5-disabled{
  background:none;
  color:rgba(147, 86, 16, 0.5);
}
.bp5-button.bp5-minimal.bp5-intent-warning:disabled.bp5-active, .bp5-button.bp5-minimal.bp5-intent-warning.bp5-disabled.bp5-active{
  background:rgba(200, 118, 25, 0.3);
}
.bp5-button.bp5-minimal.bp5-intent-warning .bp5-button-spinner .bp5-spinner-head{
  stroke:#935610;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning{
  color:#fbb360;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning:hover{
  background:rgba(200, 118, 25, 0.2);
  color:#fbb360;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning:active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning.bp5-active{
  background:rgba(200, 118, 25, 0.3);
  color:#f5c186;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning:disabled, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning.bp5-disabled{
  background:none;
  color:rgba(251, 179, 96, 0.5);
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning:disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning.bp5-disabled.bp5-active{
  background:rgba(200, 118, 25, 0.3);
}
.bp5-button.bp5-minimal.bp5-intent-danger{
  color:#ac2f33;
}
.bp5-button.bp5-minimal.bp5-intent-danger:hover, .bp5-button.bp5-minimal.bp5-intent-danger:active, .bp5-button.bp5-minimal.bp5-intent-danger.bp5-active{
  background:none;
  box-shadow:none;
  color:#ac2f33;
}
.bp5-button.bp5-minimal.bp5-intent-danger:hover{
  background:rgba(205, 66, 70, 0.15);
  color:#ac2f33;
}
.bp5-button.bp5-minimal.bp5-intent-danger:active, .bp5-button.bp5-minimal.bp5-intent-danger.bp5-active{
  background:rgba(205, 66, 70, 0.3);
  color:#8e292c;
}
.bp5-button.bp5-minimal.bp5-intent-danger:disabled, .bp5-button.bp5-minimal.bp5-intent-danger.bp5-disabled{
  background:none;
  color:rgba(172, 47, 51, 0.5);
}
.bp5-button.bp5-minimal.bp5-intent-danger:disabled.bp5-active, .bp5-button.bp5-minimal.bp5-intent-danger.bp5-disabled.bp5-active{
  background:rgba(205, 66, 70, 0.3);
}
.bp5-button.bp5-minimal.bp5-intent-danger .bp5-button-spinner .bp5-spinner-head{
  stroke:#ac2f33;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger{
  color:#fa999c;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger:hover{
  background:rgba(205, 66, 70, 0.2);
  color:#fa999c;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger:active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger.bp5-active{
  background:rgba(205, 66, 70, 0.3);
  color:#ffa1a4;
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger:disabled, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger.bp5-disabled{
  background:none;
  color:rgba(250, 153, 156, 0.5);
}
.bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger:disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger.bp5-disabled.bp5-active{
  background:rgba(205, 66, 70, 0.3);
}
.bp5-button.bp5-outlined{
  background:none;
  box-shadow:none;
  border:1px solid rgba(28, 33, 39, 0.2);
  box-sizing:border-box;
}
.bp5-button.bp5-outlined:hover{
  background:rgba(143, 153, 168, 0.15);
  box-shadow:none;
  color:#1c2127;
  text-decoration:none;
}
.bp5-button.bp5-outlined:active, .bp5-button.bp5-outlined.bp5-active{
  background:rgba(143, 153, 168, 0.3);
  box-shadow:none;
  color:#1c2127;
}
.bp5-button.bp5-outlined:disabled, .bp5-button.bp5-outlined:disabled:hover, .bp5-button.bp5-outlined.bp5-disabled, .bp5-button.bp5-outlined.bp5-disabled:hover{
  background:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}
.bp5-button.bp5-outlined:disabled.bp5-active, .bp5-button.bp5-outlined:disabled:hover.bp5-active, .bp5-button.bp5-outlined.bp5-disabled.bp5-active, .bp5-button.bp5-outlined.bp5-disabled:hover.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-dark .bp5-button.bp5-outlined{
  background:none;
  box-shadow:none;
  color:#ffffff;
}
.bp5-dark .bp5-button.bp5-outlined:hover, .bp5-dark .bp5-button.bp5-outlined:active, .bp5-dark .bp5-button.bp5-outlined.bp5-active{
  background:none;
  box-shadow:none;
  color:#ffffff;
}
.bp5-dark .bp5-button.bp5-outlined:hover{
  background:rgba(143, 153, 168, 0.15);
}
.bp5-dark .bp5-button.bp5-outlined:active, .bp5-dark .bp5-button.bp5-outlined.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-dark .bp5-button.bp5-outlined:disabled, .bp5-dark .bp5-button.bp5-outlined:disabled:hover, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled:hover{
  background:none;
  color:rgba(171, 179, 191, 0.6);
  cursor:not-allowed;
}
.bp5-dark .bp5-button.bp5-outlined:disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined:disabled:hover.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled:hover.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-button.bp5-outlined.bp5-intent-primary{
  color:#215db0;
}
.bp5-button.bp5-outlined.bp5-intent-primary:hover, .bp5-button.bp5-outlined.bp5-intent-primary:active, .bp5-button.bp5-outlined.bp5-intent-primary.bp5-active{
  background:none;
  box-shadow:none;
  color:#215db0;
}
.bp5-button.bp5-outlined.bp5-intent-primary:hover{
  background:rgba(45, 114, 210, 0.15);
  color:#215db0;
}
.bp5-button.bp5-outlined.bp5-intent-primary:active, .bp5-button.bp5-outlined.bp5-intent-primary.bp5-active{
  background:rgba(45, 114, 210, 0.3);
  color:#184a90;
}
.bp5-button.bp5-outlined.bp5-intent-primary:disabled, .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled{
  background:none;
  color:rgba(33, 93, 176, 0.5);
}
.bp5-button.bp5-outlined.bp5-intent-primary:disabled.bp5-active, .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled.bp5-active{
  background:rgba(45, 114, 210, 0.3);
}
.bp5-button.bp5-outlined.bp5-intent-primary .bp5-button-spinner .bp5-spinner-head{
  stroke:#215db0;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary{
  color:#8abbff;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary:hover{
  background:rgba(45, 114, 210, 0.2);
  color:#8abbff;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary:active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary.bp5-active{
  background:rgba(45, 114, 210, 0.3);
  color:#99c4ff;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled{
  background:none;
  color:rgba(138, 187, 255, 0.5);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary:disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled.bp5-active{
  background:rgba(45, 114, 210, 0.3);
}
.bp5-button.bp5-outlined.bp5-intent-success{
  color:#1c6e42;
}
.bp5-button.bp5-outlined.bp5-intent-success:hover, .bp5-button.bp5-outlined.bp5-intent-success:active, .bp5-button.bp5-outlined.bp5-intent-success.bp5-active{
  background:none;
  box-shadow:none;
  color:#1c6e42;
}
.bp5-button.bp5-outlined.bp5-intent-success:hover{
  background:rgba(35, 133, 81, 0.15);
  color:#1c6e42;
}
.bp5-button.bp5-outlined.bp5-intent-success:active, .bp5-button.bp5-outlined.bp5-intent-success.bp5-active{
  background:rgba(35, 133, 81, 0.3);
  color:#165a36;
}
.bp5-button.bp5-outlined.bp5-intent-success:disabled, .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled{
  background:none;
  color:rgba(28, 110, 66, 0.5);
}
.bp5-button.bp5-outlined.bp5-intent-success:disabled.bp5-active, .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled.bp5-active{
  background:rgba(35, 133, 81, 0.3);
}
.bp5-button.bp5-outlined.bp5-intent-success .bp5-button-spinner .bp5-spinner-head{
  stroke:#1c6e42;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success{
  color:#72ca9b;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success:hover{
  background:rgba(35, 133, 81, 0.2);
  color:#72ca9b;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success:active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-success.bp5-active{
  background:rgba(35, 133, 81, 0.3);
  color:#7cd7a2;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled{
  background:none;
  color:rgba(114, 202, 155, 0.5);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success:disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled.bp5-active{
  background:rgba(35, 133, 81, 0.3);
}
.bp5-button.bp5-outlined.bp5-intent-warning{
  color:#935610;
}
.bp5-button.bp5-outlined.bp5-intent-warning:hover, .bp5-button.bp5-outlined.bp5-intent-warning:active, .bp5-button.bp5-outlined.bp5-intent-warning.bp5-active{
  background:none;
  box-shadow:none;
  color:#935610;
}
.bp5-button.bp5-outlined.bp5-intent-warning:hover{
  background:rgba(200, 118, 25, 0.15);
  color:#935610;
}
.bp5-button.bp5-outlined.bp5-intent-warning:active, .bp5-button.bp5-outlined.bp5-intent-warning.bp5-active{
  background:rgba(200, 118, 25, 0.3);
  color:#77450d;
}
.bp5-button.bp5-outlined.bp5-intent-warning:disabled, .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled{
  background:none;
  color:rgba(147, 86, 16, 0.5);
}
.bp5-button.bp5-outlined.bp5-intent-warning:disabled.bp5-active, .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled.bp5-active{
  background:rgba(200, 118, 25, 0.3);
}
.bp5-button.bp5-outlined.bp5-intent-warning .bp5-button-spinner .bp5-spinner-head{
  stroke:#935610;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning{
  color:#fbb360;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning:hover{
  background:rgba(200, 118, 25, 0.2);
  color:#fbb360;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning:active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning.bp5-active{
  background:rgba(200, 118, 25, 0.3);
  color:#f5c186;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled{
  background:none;
  color:rgba(251, 179, 96, 0.5);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning:disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled.bp5-active{
  background:rgba(200, 118, 25, 0.3);
}
.bp5-button.bp5-outlined.bp5-intent-danger{
  color:#ac2f33;
}
.bp5-button.bp5-outlined.bp5-intent-danger:hover, .bp5-button.bp5-outlined.bp5-intent-danger:active, .bp5-button.bp5-outlined.bp5-intent-danger.bp5-active{
  background:none;
  box-shadow:none;
  color:#ac2f33;
}
.bp5-button.bp5-outlined.bp5-intent-danger:hover{
  background:rgba(205, 66, 70, 0.15);
  color:#ac2f33;
}
.bp5-button.bp5-outlined.bp5-intent-danger:active, .bp5-button.bp5-outlined.bp5-intent-danger.bp5-active{
  background:rgba(205, 66, 70, 0.3);
  color:#8e292c;
}
.bp5-button.bp5-outlined.bp5-intent-danger:disabled, .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled{
  background:none;
  color:rgba(172, 47, 51, 0.5);
}
.bp5-button.bp5-outlined.bp5-intent-danger:disabled.bp5-active, .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled.bp5-active{
  background:rgba(205, 66, 70, 0.3);
}
.bp5-button.bp5-outlined.bp5-intent-danger .bp5-button-spinner .bp5-spinner-head{
  stroke:#ac2f33;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger{
  color:#fa999c;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger:hover{
  background:rgba(205, 66, 70, 0.2);
  color:#fa999c;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger:active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger.bp5-active{
  background:rgba(205, 66, 70, 0.3);
  color:#ffa1a4;
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled{
  background:none;
  color:rgba(250, 153, 156, 0.5);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger:disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled.bp5-active{
  background:rgba(205, 66, 70, 0.3);
}
.bp5-button.bp5-outlined:disabled, .bp5-button.bp5-outlined.bp5-disabled, .bp5-button.bp5-outlined:disabled:hover, .bp5-button.bp5-outlined.bp5-disabled:hover{
  border-color:rgba(95, 107, 124, 0.1);
}
.bp5-dark .bp5-button.bp5-outlined{
  border-color:rgba(255, 255, 255, 0.4);
}
.bp5-dark .bp5-button.bp5-outlined:disabled, .bp5-dark .bp5-button.bp5-outlined:disabled:hover, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled:hover{
  border-color:rgba(255, 255, 255, 0.2);
}
.bp5-button.bp5-outlined.bp5-intent-primary{
  border-color:rgba(33, 93, 176, 0.6);
}
.bp5-button.bp5-outlined.bp5-intent-primary:disabled, .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled{
  border-color:rgba(33, 93, 176, 0.2);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary{
  border-color:rgba(138, 187, 255, 0.6);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled{
  border-color:rgba(138, 187, 255, 0.2);
}
.bp5-button.bp5-outlined.bp5-intent-success{
  border-color:rgba(28, 110, 66, 0.6);
}
.bp5-button.bp5-outlined.bp5-intent-success:disabled, .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled{
  border-color:rgba(28, 110, 66, 0.2);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success{
  border-color:rgba(114, 202, 155, 0.6);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled{
  border-color:rgba(114, 202, 155, 0.2);
}
.bp5-button.bp5-outlined.bp5-intent-warning{
  border-color:rgba(147, 86, 16, 0.6);
}
.bp5-button.bp5-outlined.bp5-intent-warning:disabled, .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled{
  border-color:rgba(147, 86, 16, 0.2);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning{
  border-color:rgba(251, 179, 96, 0.6);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled{
  border-color:rgba(251, 179, 96, 0.2);
}
.bp5-button.bp5-outlined.bp5-intent-danger{
  border-color:rgba(172, 47, 51, 0.6);
}
.bp5-button.bp5-outlined.bp5-intent-danger:disabled, .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled{
  border-color:rgba(172, 47, 51, 0.2);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger{
  border-color:rgba(250, 153, 156, 0.6);
}
.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled{
  border-color:rgba(250, 153, 156, 0.2);
}
.bp5-button.bp5-intent-warning{
  background:#fbb360;
  color:#1c2127;
}
.bp5-button.bp5-intent-warning:not(.bp5-disabled).bp5-icon > svg{
  fill:rgba(28, 33, 39, 0.7);
}
.bp5-button.bp5-intent-warning:not(.bp5-disabled):not(.bp5-minimal):not(.bp5-outlined):hover{
  background:#ec9a3c;
  color:#1c2127;
}
.bp5-button.bp5-intent-warning:not(.bp5-disabled):not(.bp5-minimal):not(.bp5-outlined):active, .bp5-button.bp5-intent-warning:not(.bp5-disabled):not(.bp5-minimal):not(.bp5-outlined).bp5-active{
  background:#c87619;
  color:#1c2127;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button.bp5-intent-warning:not(.bp5-disabled):not(.bp5-minimal):not(.bp5-outlined):active, .bp5-button.bp5-intent-warning:not(.bp5-disabled):not(.bp5-minimal):not(.bp5-outlined).bp5-active{
    background:highlight;
  }
}
.bp5-button.bp5-intent-warning:disabled, .bp5-button.bp5-intent-warning.bp5-disabled{
  background:rgba(200, 118, 25, 0.5);
  color:rgba(28, 33, 39, 0.35);
}
.bp5-dark .bp5-button.bp5-intent-warning:disabled, .bp5-dark .bp5-button.bp5-intent-warning.bp5-disabled{
  color:rgba(28, 33, 39, 0.6);
}
.bp5-button.bp5-intent-warning.bp5-minimal, .bp5-button.bp5-intent-warning.bp5-outlined{
  background:none;
}
.bp5-dark .bp5-button.bp5-intent-warning.bp5-minimal:not(.bp5-disabled).bp5-icon > svg, .bp5-dark .bp5-button.bp5-intent-warning.bp5-outlined:not(.bp5-disabled).bp5-icon > svg{
  fill:#fbb360;
}

a.bp5-button{
  text-align:center;
  text-decoration:none;
  transition:none;
}
a.bp5-button, a.bp5-button:hover, a.bp5-button:active{
  color:#1c2127;
}
a.bp5-button.bp5-disabled{
  color:rgba(95, 107, 124, 0.6);
}

.bp5-button-text{
  flex:0 1 auto;
}

.bp5-button.bp5-align-left .bp5-button-text, .bp5-button.bp5-align-right .bp5-button-text,
.bp5-button-group.bp5-align-left .bp5-button-text,
.bp5-button-group.bp5-align-right .bp5-button-text{
  flex:1 1 auto;
}
.bp5-button-group{
  display:inline-flex;
}
.bp5-button-group .bp5-button{
  flex:0 0 auto;
  position:relative;
  z-index:4;
}
.bp5-button-group .bp5-button:focus{
  z-index:5;
}
.bp5-button-group .bp5-button:hover{
  z-index:6;
}
.bp5-button-group .bp5-button:active, .bp5-button-group .bp5-button.bp5-active{
  z-index:7;
}
.bp5-button-group .bp5-button:disabled, .bp5-button-group .bp5-button.bp5-disabled{
  z-index:3;
}
.bp5-button-group .bp5-button[class*=bp5-intent-]{
  z-index:9;
}
.bp5-button-group .bp5-button[class*=bp5-intent-]:focus{
  z-index:10;
}
.bp5-button-group .bp5-button[class*=bp5-intent-]:hover{
  z-index:11;
}
.bp5-button-group .bp5-button[class*=bp5-intent-]:active, .bp5-button-group .bp5-button[class*=bp5-intent-].bp5-active{
  z-index:12;
}
.bp5-button-group .bp5-button[class*=bp5-intent-]:disabled, .bp5-button-group .bp5-button[class*=bp5-intent-].bp5-disabled{
  z-index:8;
}
.bp5-button-group:not(.bp5-minimal) > .bp5-popover-wrapper:not(:first-child) .bp5-button,
.bp5-button-group:not(.bp5-minimal) > .bp5-button:not(:first-child), .bp5-button-group.bp5-outlined > .bp5-popover-wrapper:not(:first-child) .bp5-button,
.bp5-button-group.bp5-outlined > .bp5-button:not(:first-child){
  border-bottom-left-radius:0;
  border-top-left-radius:0;
}
.bp5-button-group:not(.bp5-minimal) > .bp5-popover-wrapper:not(:last-child) .bp5-button,
.bp5-button-group:not(.bp5-minimal) > .bp5-button:not(:last-child), .bp5-button-group.bp5-outlined > .bp5-popover-wrapper:not(:last-child) .bp5-button,
.bp5-button-group.bp5-outlined > .bp5-button:not(:last-child){
  border-bottom-right-radius:0;
  border-top-right-radius:0;
}
.bp5-button-group:not(.bp5-minimal):not(.bp5-outlined) > .bp5-popover-wrapper:not(:last-child) .bp5-button,
.bp5-button-group:not(.bp5-minimal):not(.bp5-outlined) > .bp5-button:not(:last-child){
  margin-right:-1px;
}
.bp5-button-group.bp5-minimal .bp5-button, .bp5-button-group.bp5-outlined .bp5-button{
  background:none;
  box-shadow:none;
}
.bp5-button-group.bp5-minimal .bp5-button:hover, .bp5-button-group.bp5-outlined .bp5-button:hover{
  background:rgba(143, 153, 168, 0.15);
  box-shadow:none;
  color:#1c2127;
  text-decoration:none;
}
.bp5-button-group.bp5-minimal .bp5-button:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-active, .bp5-button-group.bp5-outlined .bp5-button:active, .bp5-button-group.bp5-outlined .bp5-button.bp5-active{
  background:rgba(143, 153, 168, 0.3);
  box-shadow:none;
  color:#1c2127;
}
.bp5-button-group.bp5-minimal .bp5-button:disabled, .bp5-button-group.bp5-minimal .bp5-button:disabled:hover, .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled, .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled:hover, .bp5-button-group.bp5-outlined .bp5-button:disabled, .bp5-button-group.bp5-outlined .bp5-button:disabled:hover, .bp5-button-group.bp5-outlined .bp5-button.bp5-disabled, .bp5-button-group.bp5-outlined .bp5-button.bp5-disabled:hover{
  background:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}
.bp5-button-group.bp5-minimal .bp5-button:disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button:disabled:hover.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled:hover.bp5-active, .bp5-button-group.bp5-outlined .bp5-button:disabled.bp5-active, .bp5-button-group.bp5-outlined .bp5-button:disabled:hover.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-disabled.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-disabled:hover.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button{
  background:none;
  box-shadow:none;
  color:#ffffff;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button:hover, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button:hover, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button:active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-active{
  background:none;
  box-shadow:none;
  color:#ffffff;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button:hover, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button:hover{
  background:rgba(143, 153, 168, 0.15);
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button:active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button:disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button:disabled:hover, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled:hover, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button:disabled, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button:disabled:hover, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-disabled, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-disabled:hover{
  background:none;
  color:rgba(171, 179, 191, 0.6);
  cursor:not-allowed;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button:disabled:hover.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled:hover.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button:disabled:hover.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-disabled:hover.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary{
  color:#215db0;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:hover, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary:hover, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary:active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary.bp5-active{
  background:none;
  box-shadow:none;
  color:#215db0;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:hover, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary:hover{
  background:rgba(45, 114, 210, 0.15);
  color:#215db0;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary:active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary.bp5-active{
  background:rgba(45, 114, 210, 0.3);
  color:#184a90;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:disabled, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-disabled, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary:disabled, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary.bp5-disabled{
  background:none;
  color:rgba(33, 93, 176, 0.5);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-disabled.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary:disabled.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary.bp5-disabled.bp5-active{
  background:rgba(45, 114, 210, 0.3);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary .bp5-button-spinner .bp5-spinner-head, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary .bp5-button-spinner .bp5-spinner-head{
  stroke:#215db0;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary{
  color:#8abbff;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:hover, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary:hover{
  background:rgba(45, 114, 210, 0.2);
  color:#8abbff;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary:active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary.bp5-active{
  background:rgba(45, 114, 210, 0.3);
  color:#99c4ff;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-disabled, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary:disabled, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary.bp5-disabled{
  background:none;
  color:rgba(138, 187, 255, 0.5);
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-primary.bp5-disabled.bp5-active{
  background:rgba(45, 114, 210, 0.3);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success{
  color:#1c6e42;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:hover, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success:hover, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success:active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success.bp5-active{
  background:none;
  box-shadow:none;
  color:#1c6e42;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:hover, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success:hover{
  background:rgba(35, 133, 81, 0.15);
  color:#1c6e42;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success:active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success.bp5-active{
  background:rgba(35, 133, 81, 0.3);
  color:#165a36;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:disabled, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-disabled, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success:disabled, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success.bp5-disabled{
  background:none;
  color:rgba(28, 110, 66, 0.5);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-disabled.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success:disabled.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success.bp5-disabled.bp5-active{
  background:rgba(35, 133, 81, 0.3);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success .bp5-button-spinner .bp5-spinner-head, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success .bp5-button-spinner .bp5-spinner-head{
  stroke:#1c6e42;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success{
  color:#72ca9b;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:hover, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success:hover{
  background:rgba(35, 133, 81, 0.2);
  color:#72ca9b;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success:active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success.bp5-active{
  background:rgba(35, 133, 81, 0.3);
  color:#7cd7a2;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-disabled, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success:disabled, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success.bp5-disabled{
  background:none;
  color:rgba(114, 202, 155, 0.5);
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-success.bp5-disabled.bp5-active{
  background:rgba(35, 133, 81, 0.3);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning{
  color:#935610;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:hover, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning:hover, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning:active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning.bp5-active{
  background:none;
  box-shadow:none;
  color:#935610;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:hover, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning:hover{
  background:rgba(200, 118, 25, 0.15);
  color:#935610;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning:active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning.bp5-active{
  background:rgba(200, 118, 25, 0.3);
  color:#77450d;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:disabled, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-disabled, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning:disabled, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning.bp5-disabled{
  background:none;
  color:rgba(147, 86, 16, 0.5);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-disabled.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning:disabled.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning.bp5-disabled.bp5-active{
  background:rgba(200, 118, 25, 0.3);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning .bp5-button-spinner .bp5-spinner-head, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning .bp5-button-spinner .bp5-spinner-head{
  stroke:#935610;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning{
  color:#fbb360;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:hover, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning:hover{
  background:rgba(200, 118, 25, 0.2);
  color:#fbb360;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning:active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning.bp5-active{
  background:rgba(200, 118, 25, 0.3);
  color:#f5c186;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-disabled, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning:disabled, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning.bp5-disabled{
  background:none;
  color:rgba(251, 179, 96, 0.5);
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-warning.bp5-disabled.bp5-active{
  background:rgba(200, 118, 25, 0.3);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger{
  color:#ac2f33;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:hover, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger:hover, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger:active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger.bp5-active{
  background:none;
  box-shadow:none;
  color:#ac2f33;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:hover, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger:hover{
  background:rgba(205, 66, 70, 0.15);
  color:#ac2f33;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger:active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger.bp5-active{
  background:rgba(205, 66, 70, 0.3);
  color:#8e292c;
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:disabled, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-disabled, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger:disabled, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger.bp5-disabled{
  background:none;
  color:rgba(172, 47, 51, 0.5);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-disabled.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger:disabled.bp5-active, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger.bp5-disabled.bp5-active{
  background:rgba(205, 66, 70, 0.3);
}
.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger .bp5-button-spinner .bp5-spinner-head, .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger .bp5-button-spinner .bp5-spinner-head{
  stroke:#ac2f33;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger{
  color:#fa999c;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:hover, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger:hover{
  background:rgba(205, 66, 70, 0.2);
  color:#fa999c;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger:active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger.bp5-active{
  background:rgba(205, 66, 70, 0.3);
  color:#ffa1a4;
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-disabled, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger:disabled, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger.bp5-disabled{
  background:none;
  color:rgba(250, 153, 156, 0.5);
}
.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-outlined .bp5-button.bp5-intent-danger.bp5-disabled.bp5-active{
  background:rgba(205, 66, 70, 0.3);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-button-group.bp5-minimal:not(:first-child), .bp5-button-group.bp5-outlined:not(:first-child){
    border-bottom-left-radius:0;
    border-left:none;
    border-top-left-radius:0;
  }
  .bp5-button-group.bp5-minimal:not(:last-child), .bp5-button-group.bp5-outlined:not(:last-child){
    border-bottom-right-radius:0;
    border-top-right-radius:0;
    margin-right:-1px;
  }
}
.bp5-button-group.bp5-outlined > .bp5-button{
  border:1px solid rgba(28, 33, 39, 0.2);
  box-sizing:border-box;
}
.bp5-button-group.bp5-outlined > .bp5-button:disabled, .bp5-button-group.bp5-outlined > .bp5-button.bp5-disabled, .bp5-button-group.bp5-outlined > .bp5-button:disabled:hover, .bp5-button-group.bp5-outlined > .bp5-button.bp5-disabled:hover{
  border-color:rgba(95, 107, 124, 0.1);
}
.bp5-dark .bp5-button-group.bp5-outlined > .bp5-button{
  border-color:rgba(255, 255, 255, 0.4);
}
.bp5-dark .bp5-button-group.bp5-outlined > .bp5-button:disabled, .bp5-dark .bp5-button-group.bp5-outlined > .bp5-button:disabled:hover, .bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-disabled, .bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-disabled:hover{
  border-color:rgba(255, 255, 255, 0.2);
}
.bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-primary{
  border-color:rgba(33, 93, 176, 0.6);
}
.bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-primary:disabled, .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-primary.bp5-disabled{
  border-color:rgba(33, 93, 176, 0.2);
}
.bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-primary{
  border-color:rgba(138, 187, 255, 0.6);
}
.bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-primary:disabled, .bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-primary.bp5-disabled{
  border-color:rgba(138, 187, 255, 0.2);
}
.bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-success{
  border-color:rgba(28, 110, 66, 0.6);
}
.bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-success:disabled, .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-success.bp5-disabled{
  border-color:rgba(28, 110, 66, 0.2);
}
.bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-success{
  border-color:rgba(114, 202, 155, 0.6);
}
.bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-success:disabled, .bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-success.bp5-disabled{
  border-color:rgba(114, 202, 155, 0.2);
}
.bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-warning{
  border-color:rgba(147, 86, 16, 0.6);
}
.bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-warning:disabled, .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-warning.bp5-disabled{
  border-color:rgba(147, 86, 16, 0.2);
}
.bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-warning{
  border-color:rgba(251, 179, 96, 0.6);
}
.bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-warning:disabled, .bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-warning.bp5-disabled{
  border-color:rgba(251, 179, 96, 0.2);
}
.bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-danger{
  border-color:rgba(172, 47, 51, 0.6);
}
.bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-danger:disabled, .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-danger.bp5-disabled{
  border-color:rgba(172, 47, 51, 0.2);
}
.bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-danger{
  border-color:rgba(250, 153, 156, 0.6);
}
.bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-danger:disabled, .bp5-dark .bp5-button-group.bp5-outlined > .bp5-button.bp5-intent-danger.bp5-disabled{
  border-color:rgba(250, 153, 156, 0.2);
}
.bp5-button-group.bp5-outlined:not(.bp5-vertical) > .bp5-button:not(:last-child){
  border-right:none;
}
.bp5-button-group .bp5-popover-wrapper,
.bp5-button-group .bp5-popover-target{
  display:flex;
  flex:1 1 auto;
}
.bp5-button-group.bp5-fill{
  display:flex;
  width:100%;
}
.bp5-button-group .bp5-button.bp5-fill, .bp5-button-group.bp5-fill .bp5-button:not(.bp5-fixed){
  flex:1 1 auto;
}
.bp5-button-group.bp5-vertical{
  align-items:stretch;
  flex-direction:column;
  vertical-align:top;
}
.bp5-button-group.bp5-vertical.bp5-fill{
  height:100%;
  width:unset;
}
.bp5-button-group.bp5-vertical .bp5-button{
  margin-right:0 !important;
  width:100%;
}
.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-popover-wrapper:first-child .bp5-button,
.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-button:first-child, .bp5-button-group.bp5-vertical.bp5-outlined > .bp5-popover-wrapper:first-child .bp5-button,
.bp5-button-group.bp5-vertical.bp5-outlined > .bp5-button:first-child{
  border-radius:2px 2px 0 0;
}
.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-popover-wrapper:last-child .bp5-button,
.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-button:last-child, .bp5-button-group.bp5-vertical.bp5-outlined > .bp5-popover-wrapper:last-child .bp5-button,
.bp5-button-group.bp5-vertical.bp5-outlined > .bp5-button:last-child{
  border-radius:0 0 2px 2px;
}
.bp5-button-group.bp5-vertical:not(.bp5-minimal):not(.bp5-outlined) > .bp5-popover-wrapper:not(:last-child) .bp5-button,
.bp5-button-group.bp5-vertical:not(.bp5-minimal):not(.bp5-outlined) > .bp5-button:not(:last-child){
  margin-bottom:-1px;
}
.bp5-button-group.bp5-vertical.bp5-outlined > .bp5-button:not(:last-child){
  border-bottom:none;
}
.bp5-button-group.bp5-align-left .bp5-button{
  text-align:left;
}
.bp5-callout{
  font-size:14px;
  line-height:1.5;
  border-radius:2px;
  padding:15px;
  position:relative;
  width:100%;
}
.bp5-callout:not(.bp5-minimal){
  background-color:rgba(143, 153, 168, 0.15);
}
.bp5-callout[class*=bp5-icon-]{
  padding-left:38px;
}
.bp5-callout[class*=bp5-icon-]::before{
  font-family:"blueprint-icons-16", sans-serif;
  font-size:16px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:16px;
  line-height:1;
  width:16px;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  color:#5f6b7c;
  left:15px;
  position:absolute;
  top:17px;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-callout{
    border:1px solid buttonborder;
  }
}
.bp5-callout.bp5-callout-icon{
  padding-left:38px;
}
.bp5-callout.bp5-callout-icon > .bp5-icon:first-child{
  color:#5f6b7c;
  left:15px;
  position:absolute;
  top:17px;
}
.bp5-callout .bp5-heading{
  line-height:16px;
  margin-bottom:0;
  margin-top:2px;
}
.bp5-callout.bp5-callout-has-body-content .bp5-heading{
  margin-bottom:5px;
}
.bp5-callout.bp5-compact{
  padding:10px;
}
.bp5-callout.bp5-compact.bp5-callout-icon{
  padding-left:33px;
}
.bp5-callout.bp5-compact.bp5-callout-icon > .bp5-icon:first-child{
  left:10px;
  top:12px;
}
.bp5-dark .bp5-callout:not(.bp5-minimal){
  background-color:rgba(143, 153, 168, 0.2);
}
.bp5-dark .bp5-callout[class*=bp5-icon-]::before, .bp5-dark .bp5-callout.bp5-callout-icon > .bp5-icon:first-child{
  color:#abb3bf;
}
.bp5-callout.bp5-intent-primary{
  color:#215db0;
}
.bp5-callout.bp5-intent-primary:not(.bp5-minimal){
  background-color:rgba(45, 114, 210, 0.1);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-callout.bp5-intent-primary{
    border:1px solid buttonborder;
  }
}
.bp5-callout.bp5-intent-primary[class*=bp5-icon-]::before,
.bp5-callout.bp5-intent-primary > .bp5-icon:first-child,
.bp5-callout.bp5-intent-primary .bp5-heading{
  color:#215db0;
}
.bp5-dark .bp5-callout.bp5-intent-primary{
  color:#8abbff;
}
.bp5-dark .bp5-callout.bp5-intent-primary:not(.bp5-minimal){
  background-color:rgba(45, 114, 210, 0.2);
}
.bp5-dark .bp5-callout.bp5-intent-primary[class*=bp5-icon-]::before,
.bp5-dark .bp5-callout.bp5-intent-primary > .bp5-icon:first-child,
.bp5-dark .bp5-callout.bp5-intent-primary .bp5-heading{
  color:#8abbff;
}
.bp5-callout.bp5-intent-success{
  color:#1c6e42;
}
.bp5-callout.bp5-intent-success:not(.bp5-minimal){
  background-color:rgba(35, 133, 81, 0.1);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-callout.bp5-intent-success{
    border:1px solid buttonborder;
  }
}
.bp5-callout.bp5-intent-success[class*=bp5-icon-]::before,
.bp5-callout.bp5-intent-success > .bp5-icon:first-child,
.bp5-callout.bp5-intent-success .bp5-heading{
  color:#1c6e42;
}
.bp5-dark .bp5-callout.bp5-intent-success{
  color:#72ca9b;
}
.bp5-dark .bp5-callout.bp5-intent-success:not(.bp5-minimal){
  background-color:rgba(35, 133, 81, 0.2);
}
.bp5-dark .bp5-callout.bp5-intent-success[class*=bp5-icon-]::before,
.bp5-dark .bp5-callout.bp5-intent-success > .bp5-icon:first-child,
.bp5-dark .bp5-callout.bp5-intent-success .bp5-heading{
  color:#72ca9b;
}
.bp5-callout.bp5-intent-warning{
  color:#935610;
}
.bp5-callout.bp5-intent-warning:not(.bp5-minimal){
  background-color:rgba(200, 118, 25, 0.1);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-callout.bp5-intent-warning{
    border:1px solid buttonborder;
  }
}
.bp5-callout.bp5-intent-warning[class*=bp5-icon-]::before,
.bp5-callout.bp5-intent-warning > .bp5-icon:first-child,
.bp5-callout.bp5-intent-warning .bp5-heading{
  color:#935610;
}
.bp5-dark .bp5-callout.bp5-intent-warning{
  color:#fbb360;
}
.bp5-dark .bp5-callout.bp5-intent-warning:not(.bp5-minimal){
  background-color:rgba(200, 118, 25, 0.2);
}
.bp5-dark .bp5-callout.bp5-intent-warning[class*=bp5-icon-]::before,
.bp5-dark .bp5-callout.bp5-intent-warning > .bp5-icon:first-child,
.bp5-dark .bp5-callout.bp5-intent-warning .bp5-heading{
  color:#fbb360;
}
.bp5-callout.bp5-intent-danger{
  color:#ac2f33;
}
.bp5-callout.bp5-intent-danger:not(.bp5-minimal){
  background-color:rgba(205, 66, 70, 0.1);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-callout.bp5-intent-danger{
    border:1px solid buttonborder;
  }
}
.bp5-callout.bp5-intent-danger[class*=bp5-icon-]::before,
.bp5-callout.bp5-intent-danger > .bp5-icon:first-child,
.bp5-callout.bp5-intent-danger .bp5-heading{
  color:#ac2f33;
}
.bp5-dark .bp5-callout.bp5-intent-danger{
  color:#fa999c;
}
.bp5-dark .bp5-callout.bp5-intent-danger:not(.bp5-minimal){
  background-color:rgba(205, 66, 70, 0.2);
}
.bp5-dark .bp5-callout.bp5-intent-danger[class*=bp5-icon-]::before,
.bp5-dark .bp5-callout.bp5-intent-danger > .bp5-icon:first-child,
.bp5-dark .bp5-callout.bp5-intent-danger .bp5-heading{
  color:#fa999c;
}
.bp5-callout.bp5-intent-primary a{
  text-decoration:underline;
}
.bp5-callout.bp5-intent-primary a:hover{
  color:#184a90;
}
.bp5-dark .bp5-callout.bp5-intent-primary a:hover{
  color:#99c4ff;
}
.bp5-running-text .bp5-callout{
  margin:20px 0;
}
.bp5-card{
  background-color:#ffffff;
  border-radius:2px;
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.15);
  padding:20px;
  transition:transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-card.bp5-dark, .bp5-dark .bp5-card{
  background-color:#2f343c;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-card{
    border:1px solid buttonborder;
    box-shadow:none;
  }
}

.bp5-elevation-0{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.15);
}
.bp5-elevation-0.bp5-dark, .bp5-dark .bp5-elevation-0{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-elevation-0{
    border:1px solid buttonborder;
  }
}

.bp5-elevation-1{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-elevation-1.bp5-dark, .bp5-dark .bp5-elevation-1{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-elevation-1{
    border:1px solid buttonborder;
  }
}

.bp5-elevation-2{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2), 0 2px 6px rgba(17, 20, 24, 0.2);
}
.bp5-elevation-2.bp5-dark, .bp5-dark .bp5-elevation-2{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px rgba(17, 20, 24, 0.4), 0 2px 6px rgba(17, 20, 24, 0.4);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-elevation-2{
    border:1px solid buttonborder;
  }
}

.bp5-elevation-3{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
}
.bp5-elevation-3.bp5-dark, .bp5-dark .bp5-elevation-3{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-elevation-3{
    border:1px solid buttonborder;
  }
}

.bp5-elevation-4{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 4px 8px rgba(17, 20, 24, 0.2), 0 18px 46px 6px rgba(17, 20, 24, 0.2);
}
.bp5-elevation-4.bp5-dark, .bp5-dark .bp5-elevation-4{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 4px 8px rgba(17, 20, 24, 0.4), 0 18px 46px 6px rgba(17, 20, 24, 0.4);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-elevation-4{
    border:1px solid buttonborder;
  }
}

.bp5-card.bp5-compact{
  padding:15px;
}

.bp5-card.bp5-interactive:hover{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  cursor:pointer;
}
.bp5-card.bp5-interactive:hover.bp5-dark, .bp5-dark .bp5-card.bp5-interactive:hover{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp5-card.bp5-interactive.bp5-selected{
  box-shadow:0 0 0 3px rgba(76, 144, 240, 0.2), 0 0 0 1px #4c90f0;
}
.bp5-card.bp5-interactive.bp5-selected.bp5-dark, .bp5-dark .bp5-card.bp5-interactive.bp5-selected{
  box-shadow:0 0 0 3px rgba(138, 187, 255, 0.4), 0 0 0 1px #8abbff;
}
.bp5-card.bp5-interactive:active{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  transition-duration:0;
}
.bp5-card.bp5-interactive:active.bp5-dark, .bp5-dark .bp5-card.bp5-interactive:active{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
}
.bp5-card-list{
  overflow:auto;
  padding:0;
  width:100%;
}
.bp5-card-list > .bp5-card{
  align-items:center;
  border-radius:0;
  box-shadow:none;
  display:flex;
  min-height:51px;
  padding:10px 20px;
}
.bp5-card-list > .bp5-card.bp5-interactive:hover, .bp5-card-list > .bp5-card.bp5-interactive:active{
  background-color:#f6f7f9;
  box-shadow:none;
}
.bp5-dark .bp5-card-list > .bp5-card.bp5-interactive:hover, .bp5-dark .bp5-card-list > .bp5-card.bp5-interactive:active{
  background-color:#383e47;
}
.bp5-card-list > .bp5-card.bp5-selected{
  background-color:#edeff2;
  box-shadow:none;
}
.bp5-dark .bp5-card-list > .bp5-card.bp5-selected{
  background-color:#404854;
  box-shadow:none;
}
.bp5-card-list > .bp5-card:not(:last-child){
  border-bottom:1px solid rgba(17, 20, 24, 0.1);
}
.bp5-dark .bp5-card-list > .bp5-card:not(:last-child){
  border-color:rgba(255, 255, 255, 0.1);
}
.bp5-card-list.bp5-compact{
  padding:0;
}
.bp5-card-list.bp5-compact > .bp5-card{
  min-height:45px;
  padding:7px 15px;
}
.bp5-dark .bp5-card-list{
  padding:1px;
}
.bp5-card-list:not(.bp5-card-list-bordered){
  border-radius:0;
  box-shadow:none;
}
.bp5-dark .bp5-card-list:not(.bp5-card-list-bordered){
  margin:1px;
  width:calc(100% - 2px);
}
.bp5-collapse{
  height:0;
  overflow-y:hidden;
  transition:height 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-collapse .bp5-collapse-body{
  transition:transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-collapse .bp5-collapse-body[aria-hidden=true]{
  display:none;
}
.bp5-context-menu-virtual-target{
  position:fixed;
}
.bp5-card.bp5-control-card, .bp5-card-list > .bp5-card.bp5-control-card{
  min-height:auto;
  padding:0;
}

.bp5-control-card .bp5-control.bp5-control.bp5-control{
  align-items:flex-start;
  display:flex;
  gap:10px;
  margin:0;
  padding:20px;
  width:100%;
}
.bp5-control-card .bp5-control.bp5-control.bp5-control.bp5-align-left{
  flex-direction:row;
  justify-content:flex-start;
}
.bp5-control-card .bp5-control.bp5-control.bp5-control.bp5-align-right{
  flex-direction:row-reverse;
  justify-content:space-between;
}
.bp5-card-list .bp5-control-card .bp5-control.bp5-control.bp5-control{
  padding:20px;
}
.bp5-card-list.bp5-compact .bp5-control-card .bp5-control.bp5-control.bp5-control{
  padding:15px;
}
.bp5-control-card .bp5-control.bp5-control.bp5-control .bp5-control-indicator{
  margin:0;
}
.bp5-control-card.bp5-compact .bp5-control.bp5-control.bp5-control{
  padding:15px;
}
.bp5-divider{
  border-bottom:1px solid rgba(17, 20, 24, 0.15);
  border-right:1px solid rgba(17, 20, 24, 0.15);
  margin:5px;
}
.bp5-dark .bp5-divider{
  border-color:rgba(255, 255, 255, 0.2);
}
.bp5-dialog-container{
  opacity:1;
  transform:scale(1);
  align-items:center;
  display:flex;
  justify-content:center;
  min-height:100%;
  pointer-events:none;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  width:100%;
}
.bp5-dialog-container.bp5-overlay-enter > .bp5-dialog, .bp5-dialog-container.bp5-overlay-appear > .bp5-dialog{
  opacity:0;
  transform:scale(0.5);
}
.bp5-dialog-container.bp5-overlay-enter-active > .bp5-dialog, .bp5-dialog-container.bp5-overlay-appear-active > .bp5-dialog{
  opacity:1;
  transform:scale(1);
  transition-delay:0;
  transition-duration:300ms;
  transition-property:opacity, transform;
  transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp5-dialog-container.bp5-overlay-exit > .bp5-dialog{
  opacity:1;
  transform:scale(1);
}
.bp5-dialog-container.bp5-overlay-exit-active > .bp5-dialog{
  opacity:0;
  transform:scale(0.5);
  transition-delay:0;
  transition-duration:300ms;
  transition-property:opacity, transform;
  transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
}

.bp5-dialog{
  background:#f6f7f9;
  border-radius:4px;
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  display:flex;
  flex-direction:column;
  margin:30px 0;
  pointer-events:all;
  -webkit-user-select:text;
     -moz-user-select:text;
      -ms-user-select:text;
          user-select:text;
  width:500px;
}
.bp5-dialog:focus{
  outline:0;
}
.bp5-dialog.bp5-dark, .bp5-dark .bp5-dialog{
  background:#252a31;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
  color:#f6f7f9;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-dialog{
    border:1px solid buttonborder;
  }
}

.bp5-dialog-header{
  align-items:center;
  background:#ffffff;
  border-radius:4px 4px 0 0;
  box-shadow:0 1px 0 rgba(17, 20, 24, 0.15);
  display:flex;
  flex:0 0 auto;
  min-height:40px;
  padding:5px;
  padding-left:15px;
  z-index:0;
}
.bp5-dialog-header .bp5-icon-large,
.bp5-dialog-header .bp5-icon{
  color:#5f6b7c;
  flex:0 0 auto;
  margin-left:-3px;
  margin-right:7.5px;
}
.bp5-dialog-header .bp5-heading{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  word-wrap:normal;
  flex:1 1 auto;
  line-height:inherit;
  margin:0;
}
.bp5-dialog-header .bp5-heading:last-child{
  margin-right:15px;
}
.bp5-dark .bp5-dialog-header{
  background:#2f343c;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}
.bp5-dark .bp5-dialog-header .bp5-icon-large,
.bp5-dark .bp5-dialog-header .bp5-icon{
  color:#abb3bf;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-dialog-header{
    border-bottom:1px solid buttonborder;
  }
}

.bp5-dialog-body{
  flex:1 1 auto;
  margin:15px;
}

.bp5-dialog-body-scroll-container{
  margin:0;
  max-height:70vh;
  overflow:auto;
  padding:15px;
}

.bp5-dialog-footer{
  flex:0 0 auto;
  margin:15px;
}

.bp5-dialog-footer-fixed{
  align-items:center;
  background-color:#ffffff;
  border-radius:0 0 4px 4px;
  border-top:1px solid rgba(17, 20, 24, 0.15);
  display:flex;
  gap:15px;
  justify-content:space-between;
  margin:0;
  padding:10px 10px 10px 15px;
}
.bp5-dark .bp5-dialog-footer-fixed{
  background:#383e47;
  border-top:1px solid rgba(255, 255, 255, 0.2);
}

.bp5-dialog-footer-main-section{
  flex:1 1 auto;
}

.bp5-dialog-footer-actions{
  display:flex;
  justify-content:flex-end;
}
.bp5-dialog-footer-actions .bp5-button{
  margin-left:10px;
}
.bp5-multistep-dialog-panels{
  display:flex;
}
.bp5-multistep-dialog-panels:first-child .bp5-dialog-step-container:first-child{
  border-radius:4px 0 0 0;
}
.bp5-multistep-dialog-panels:first-child .bp5-multistep-dialog-right-panel{
  border-top-right-radius:4px;
}
.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels{
  flex-direction:column;
}
.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels:first-child .bp5-dialog-step-container:first-child{
  border-radius:4px 0 0 0;
}
.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels:first-child .bp5-dialog-step-container:last-child{
  border-radius:0 4px 0 0;
}
.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-multistep-dialog-left-panel{
  flex-direction:row;
}
.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-dialog-step-container{
  flex-grow:1;
}
.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-dialog-step-container:not(:first-child){
  border-left:1px solid rgba(17, 20, 24, 0.15);
}
.bp5-dark .bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-dialog-step-container{
  border-color:rgba(17, 20, 24, 0.4);
}
.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-multistep-dialog-right-panel{
  border-left:none;
}
.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-multistep-dialog-right-panel,
.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-multistep-dialog-footer{
  border-radius:0 0 4px 4px;
}
.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels{
  flex-direction:row-reverse;
}
.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels:first-child .bp5-multistep-dialog-right-panel{
  border-radius:4px 0 0 4px;
}
.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels:first-child .bp5-dialog-step-container:first-child{
  border-radius:0 4px 0 0;
}
.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels .bp5-multistep-dialog-left-panel{
  border-radius:0 0 4px 0;
}
.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels .bp5-multistep-dialog-right-panel{
  border-left:none;
  border-radius:4px 0 0 4px;
  border-right:1px solid rgba(17, 20, 24, 0.15);
}
.bp5-dark .bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels .bp5-multistep-dialog-right-panel{
  border-color:rgba(17, 20, 24, 0.4);
}
.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels .bp5-dialog-footer{
  border-bottom-left-radius:0;
}

.bp5-multistep-dialog-left-panel{
  display:flex;
  flex:1;
  flex-direction:column;
}
.bp5-dark .bp5-multistep-dialog-left-panel{
  background:#252a31;
  border-bottom:1px solid rgba(255, 255, 255, 0.2);
  border-bottom-left-radius:4px;
  border-left:1px solid rgba(255, 255, 255, 0.2);
}

.bp5-multistep-dialog-right-panel{
  background-color:#f6f7f9;
  border-left:1px solid rgba(17, 20, 24, 0.15);
  border-radius:0 0 4px 0;
  flex:3;
  min-width:0;
}
.bp5-dark .bp5-multistep-dialog-right-panel{
  background-color:#2f343c;
  border-bottom:1px solid rgba(255, 255, 255, 0.2);
  border-bottom-right-radius:4px;
  border-left:1px solid rgba(255, 255, 255, 0.2);
  border-right:1px solid rgba(255, 255, 255, 0.2);
}

.bp5-dialog-step-container{
  background-color:#f6f7f9;
  border-bottom:1px solid rgba(17, 20, 24, 0.15);
}
.bp5-dark .bp5-dialog-step-container{
  background:#2f343c;
  border-bottom:1px solid rgba(255, 255, 255, 0.2);
}
.bp5-dialog-step-container.bp5-dialog-step-viewed{
  background-color:#ffffff;
}
.bp5-dark .bp5-dialog-step-container.bp5-dialog-step-viewed{
  background:#383e47;
}

.bp5-dialog-step{
  align-items:center;
  border-radius:4px;
  cursor:not-allowed;
  display:flex;
  margin:4px;
  padding:6px 14px;
}
.bp5-dark .bp5-dialog-step{
  background:#2f343c;
}
.bp5-dialog-step-viewed .bp5-dialog-step{
  background-color:#ffffff;
  cursor:pointer;
}
.bp5-dark .bp5-dialog-step-viewed .bp5-dialog-step{
  background:#383e47;
}
.bp5-dialog-step:hover{
  background-color:#f6f7f9;
}
.bp5-dark .bp5-dialog-step:hover{
  background:#2f343c;
}

.bp5-dialog-step-icon{
  align-items:center;
  background-color:rgba(95, 107, 124, 0.6);
  border-radius:50%;
  color:#ffffff;
  display:flex;
  height:25px;
  justify-content:center;
  width:25px;
}
.bp5-dark .bp5-dialog-step-icon{
  background-color:rgba(171, 179, 191, 0.6);
}
.bp5-active.bp5-dialog-step-viewed .bp5-dialog-step-icon{
  background-color:#4c90f0;
}
.bp5-dialog-step-viewed .bp5-dialog-step-icon{
  background-color:#8f99a8;
}

.bp5-dialog-step-title{
  color:rgba(95, 107, 124, 0.6);
  flex:1;
  padding-left:10px;
}
.bp5-dark .bp5-dialog-step-title{
  color:rgba(171, 179, 191, 0.6);
}
.bp5-active.bp5-dialog-step-viewed .bp5-dialog-step-title{
  color:#4c90f0;
}
.bp5-dialog-step-viewed:not(.bp5-active) .bp5-dialog-step-title{
  color:#1c2127;
}
.bp5-dark .bp5-dialog-step-viewed:not(.bp5-active) .bp5-dialog-step-title{
  color:#f6f7f9;
}
.bp5-drawer{
  background:#ffffff;
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 4px 8px rgba(17, 20, 24, 0.2), 0 18px 46px 6px rgba(17, 20, 24, 0.2);
  display:flex;
  flex-direction:column;
  margin:0;
  padding:0;
}
.bp5-drawer:focus{
  outline:0;
}
.bp5-drawer.bp5-position-top{
  height:50%;
  left:0;
  right:0;
  top:0;
}
.bp5-drawer.bp5-position-top.bp5-overlay-enter, .bp5-drawer.bp5-position-top.bp5-overlay-appear{
  transform:translateY(-100%);
}
.bp5-drawer.bp5-position-top.bp5-overlay-enter-active, .bp5-drawer.bp5-position-top.bp5-overlay-appear-active{
  transform:translateY(0);
  transition-delay:0;
  transition-duration:200ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-drawer.bp5-position-top.bp5-overlay-exit{
  transform:translateY(0);
}
.bp5-drawer.bp5-position-top.bp5-overlay-exit-active{
  transform:translateY(-100%);
  transition-delay:0;
  transition-duration:100ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-drawer.bp5-position-top{
    border-bottom:1px solid buttonborder;
  }
}
.bp5-drawer.bp5-position-bottom{
  bottom:0;
  height:50%;
  left:0;
  right:0;
}
.bp5-drawer.bp5-position-bottom.bp5-overlay-enter, .bp5-drawer.bp5-position-bottom.bp5-overlay-appear{
  transform:translateY(100%);
}
.bp5-drawer.bp5-position-bottom.bp5-overlay-enter-active, .bp5-drawer.bp5-position-bottom.bp5-overlay-appear-active{
  transform:translateY(0);
  transition-delay:0;
  transition-duration:200ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-drawer.bp5-position-bottom.bp5-overlay-exit{
  transform:translateY(0);
}
.bp5-drawer.bp5-position-bottom.bp5-overlay-exit-active{
  transform:translateY(100%);
  transition-delay:0;
  transition-duration:100ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-drawer.bp5-position-bottom{
    border-top:1px solid buttonborder;
  }
}
.bp5-drawer.bp5-position-left{
  bottom:0;
  left:0;
  top:0;
  width:50%;
}
.bp5-drawer.bp5-position-left.bp5-overlay-enter, .bp5-drawer.bp5-position-left.bp5-overlay-appear{
  transform:translateX(-100%);
}
.bp5-drawer.bp5-position-left.bp5-overlay-enter-active, .bp5-drawer.bp5-position-left.bp5-overlay-appear-active{
  transform:translateX(0);
  transition-delay:0;
  transition-duration:200ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-drawer.bp5-position-left.bp5-overlay-exit{
  transform:translateX(0);
}
.bp5-drawer.bp5-position-left.bp5-overlay-exit-active{
  transform:translateX(-100%);
  transition-delay:0;
  transition-duration:100ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-drawer.bp5-position-left{
    border-right:1px solid buttonborder;
  }
}
.bp5-drawer.bp5-position-right{
  bottom:0;
  right:0;
  top:0;
  width:50%;
}
.bp5-drawer.bp5-position-right.bp5-overlay-enter, .bp5-drawer.bp5-position-right.bp5-overlay-appear{
  transform:translateX(100%);
}
.bp5-drawer.bp5-position-right.bp5-overlay-enter-active, .bp5-drawer.bp5-position-right.bp5-overlay-appear-active{
  transform:translateX(0);
  transition-delay:0;
  transition-duration:200ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-drawer.bp5-position-right.bp5-overlay-exit{
  transform:translateX(0);
}
.bp5-drawer.bp5-position-right.bp5-overlay-exit-active{
  transform:translateX(100%);
  transition-delay:0;
  transition-duration:100ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-drawer.bp5-position-right{
    border-left:1px solid buttonborder;
  }
}
.bp5-drawer.bp5-dark, .bp5-dark .bp5-drawer{
  background:#383e47;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
  color:#f6f7f9;
}

.bp5-drawer-header{
  align-items:center;
  border-radius:0;
  box-shadow:0 1px 0 rgba(17, 20, 24, 0.15);
  display:flex;
  flex:0 0 auto;
  min-height:40px;
  padding:5px;
  padding-left:20px;
  position:relative;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-drawer-header{
    border-bottom:1px solid buttonborder;
  }
}
.bp5-drawer-header .bp5-icon-large,
.bp5-drawer-header .bp5-icon{
  color:#5f6b7c;
  flex:0 0 auto;
  margin-right:10px;
}
.bp5-drawer-header .bp5-heading{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  word-wrap:normal;
  flex:1 1 auto;
  line-height:inherit;
  margin:0;
}
.bp5-drawer-header .bp5-heading:last-child{
  margin-right:20px;
}
.bp5-dark .bp5-drawer-header{
  box-shadow:0 1px 0 rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-drawer-header .bp5-icon-large,
.bp5-dark .bp5-drawer-header .bp5-icon{
  color:#abb3bf;
}

.bp5-drawer-body{
  flex:1 1 auto;
  line-height:18px;
  overflow:auto;
}

.bp5-drawer-footer{
  box-shadow:inset 0 1px 0 rgba(17, 20, 24, 0.15);
  flex:0 0 auto;
  padding:10px 20px;
  position:relative;
}
.bp5-dark .bp5-drawer-footer{
  box-shadow:inset 0 1px 0 rgba(17, 20, 24, 0.4);
}
.bp5-editable-text{
  cursor:text;
  display:inline-block;
  max-width:100%;
  position:relative;
  vertical-align:top;
  white-space:nowrap;
}
.bp5-editable-text::before{
  bottom:-2px;
  left:-2px;
  position:absolute;
  right:-2px;
  top:-2px;
  border-radius:2px;
  content:"";
  transition:background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-editable-text:hover::before{
  box-shadow:0 0 0 0 rgba(33, 93, 176, 0), 0 0 0 0 rgba(33, 93, 176, 0), inset 0 0 0 1px rgba(17, 20, 24, 0.15);
}
.bp5-editable-text.bp5-editable-text-editing::before{
  background-color:#ffffff;
  box-shadow:inset 0 0 0 1px rgba(33, 93, 176, 0.752), 0 0 0 1px rgba(33, 93, 176, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-editable-text:not(.bp5-disabled)::before{
    border:1px solid buttonborder;
  }
}
.bp5-editable-text.bp5-intent-primary .bp5-editable-text-input::-moz-placeholder{
  color:#2d72d2;
}
.bp5-editable-text.bp5-intent-primary .bp5-editable-text-input:-ms-input-placeholder{
  color:#2d72d2;
}
.bp5-editable-text.bp5-intent-primary .bp5-editable-text-content,
.bp5-editable-text.bp5-intent-primary .bp5-editable-text-input,
.bp5-editable-text.bp5-intent-primary .bp5-editable-text-input::placeholder{
  color:#2d72d2;
}
.bp5-editable-text.bp5-intent-primary:hover::before{
  box-shadow:0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px rgba(45, 114, 210, 0.4);
}
.bp5-editable-text.bp5-intent-primary.bp5-editable-text-editing::before{
  box-shadow:inset 0 0 0 1px rgba(45, 114, 210, 0.752), 0 0 0 1px rgba(45, 114, 210, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-editable-text.bp5-intent-success .bp5-editable-text-input::-moz-placeholder{
  color:#238551;
}
.bp5-editable-text.bp5-intent-success .bp5-editable-text-input:-ms-input-placeholder{
  color:#238551;
}
.bp5-editable-text.bp5-intent-success .bp5-editable-text-content,
.bp5-editable-text.bp5-intent-success .bp5-editable-text-input,
.bp5-editable-text.bp5-intent-success .bp5-editable-text-input::placeholder{
  color:#238551;
}
.bp5-editable-text.bp5-intent-success:hover::before{
  box-shadow:0 0 0 0 rgba(35, 133, 81, 0), 0 0 0 0 rgba(35, 133, 81, 0), inset 0 0 0 1px rgba(35, 133, 81, 0.4);
}
.bp5-editable-text.bp5-intent-success.bp5-editable-text-editing::before{
  box-shadow:inset 0 0 0 1px rgba(35, 133, 81, 0.752), 0 0 0 1px rgba(35, 133, 81, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-editable-text.bp5-intent-warning .bp5-editable-text-input::-moz-placeholder{
  color:#c87619;
}
.bp5-editable-text.bp5-intent-warning .bp5-editable-text-input:-ms-input-placeholder{
  color:#c87619;
}
.bp5-editable-text.bp5-intent-warning .bp5-editable-text-content,
.bp5-editable-text.bp5-intent-warning .bp5-editable-text-input,
.bp5-editable-text.bp5-intent-warning .bp5-editable-text-input::placeholder{
  color:#c87619;
}
.bp5-editable-text.bp5-intent-warning:hover::before{
  box-shadow:0 0 0 0 rgba(200, 118, 25, 0), 0 0 0 0 rgba(200, 118, 25, 0), inset 0 0 0 1px rgba(200, 118, 25, 0.4);
}
.bp5-editable-text.bp5-intent-warning.bp5-editable-text-editing::before{
  box-shadow:inset 0 0 0 1px rgba(200, 118, 25, 0.752), 0 0 0 1px rgba(200, 118, 25, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-editable-text.bp5-intent-danger .bp5-editable-text-input::-moz-placeholder{
  color:#cd4246;
}
.bp5-editable-text.bp5-intent-danger .bp5-editable-text-input:-ms-input-placeholder{
  color:#cd4246;
}
.bp5-editable-text.bp5-intent-danger .bp5-editable-text-content,
.bp5-editable-text.bp5-intent-danger .bp5-editable-text-input,
.bp5-editable-text.bp5-intent-danger .bp5-editable-text-input::placeholder{
  color:#cd4246;
}
.bp5-editable-text.bp5-intent-danger:hover::before{
  box-shadow:0 0 0 0 rgba(205, 66, 70, 0), 0 0 0 0 rgba(205, 66, 70, 0), inset 0 0 0 1px rgba(205, 66, 70, 0.4);
}
.bp5-editable-text.bp5-intent-danger.bp5-editable-text-editing::before{
  box-shadow:inset 0 0 0 1px rgba(205, 66, 70, 0.752), 0 0 0 1px rgba(205, 66, 70, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-dark .bp5-editable-text:hover::before{
  box-shadow:0 0 0 0 rgba(138, 187, 255, 0), 0 0 0 0 rgba(138, 187, 255, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}
.bp5-dark .bp5-editable-text.bp5-editable-text-editing::before{
  background-color:rgba(17, 20, 24, 0.3);
  box-shadow:inset 0 0 0 1px rgba(138, 187, 255, 0.752), 0 0 0 1px rgba(138, 187, 255, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-editable-text.bp5-disabled::before{
  box-shadow:none;
}
.bp5-dark .bp5-editable-text.bp5-intent-primary .bp5-editable-text-input::-moz-placeholder{
  color:#8abbff;
}
.bp5-dark .bp5-editable-text.bp5-intent-primary .bp5-editable-text-input:-ms-input-placeholder{
  color:#8abbff;
}
.bp5-dark .bp5-editable-text.bp5-intent-primary .bp5-editable-text-content,
.bp5-dark .bp5-editable-text.bp5-intent-primary .bp5-editable-text-input,
.bp5-dark .bp5-editable-text.bp5-intent-primary .bp5-editable-text-input::placeholder{
  color:#8abbff;
}
.bp5-dark .bp5-editable-text.bp5-intent-primary:hover::before{
  box-shadow:0 0 0 0 rgba(138, 187, 255, 0), 0 0 0 0 rgba(138, 187, 255, 0), inset 0 0 0 1px rgba(138, 187, 255, 0.4);
}
.bp5-dark .bp5-editable-text.bp5-intent-primary.bp5-editable-text-editing::before{
  box-shadow:inset 0 0 0 1px rgba(138, 187, 255, 0.752), 0 0 0 1px rgba(138, 187, 255, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-editable-text.bp5-intent-success .bp5-editable-text-input::-moz-placeholder{
  color:#72ca9b;
}
.bp5-dark .bp5-editable-text.bp5-intent-success .bp5-editable-text-input:-ms-input-placeholder{
  color:#72ca9b;
}
.bp5-dark .bp5-editable-text.bp5-intent-success .bp5-editable-text-content,
.bp5-dark .bp5-editable-text.bp5-intent-success .bp5-editable-text-input,
.bp5-dark .bp5-editable-text.bp5-intent-success .bp5-editable-text-input::placeholder{
  color:#72ca9b;
}
.bp5-dark .bp5-editable-text.bp5-intent-success:hover::before{
  box-shadow:0 0 0 0 rgba(114, 202, 155, 0), 0 0 0 0 rgba(114, 202, 155, 0), inset 0 0 0 1px rgba(114, 202, 155, 0.4);
}
.bp5-dark .bp5-editable-text.bp5-intent-success.bp5-editable-text-editing::before{
  box-shadow:inset 0 0 0 1px rgba(114, 202, 155, 0.752), 0 0 0 1px rgba(114, 202, 155, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-editable-text.bp5-intent-warning .bp5-editable-text-input::-moz-placeholder{
  color:#fbb360;
}
.bp5-dark .bp5-editable-text.bp5-intent-warning .bp5-editable-text-input:-ms-input-placeholder{
  color:#fbb360;
}
.bp5-dark .bp5-editable-text.bp5-intent-warning .bp5-editable-text-content,
.bp5-dark .bp5-editable-text.bp5-intent-warning .bp5-editable-text-input,
.bp5-dark .bp5-editable-text.bp5-intent-warning .bp5-editable-text-input::placeholder{
  color:#fbb360;
}
.bp5-dark .bp5-editable-text.bp5-intent-warning:hover::before{
  box-shadow:0 0 0 0 rgba(251, 179, 96, 0), 0 0 0 0 rgba(251, 179, 96, 0), inset 0 0 0 1px rgba(251, 179, 96, 0.4);
}
.bp5-dark .bp5-editable-text.bp5-intent-warning.bp5-editable-text-editing::before{
  box-shadow:inset 0 0 0 1px rgba(251, 179, 96, 0.752), 0 0 0 1px rgba(251, 179, 96, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-editable-text.bp5-intent-danger .bp5-editable-text-input::-moz-placeholder{
  color:#fa999c;
}
.bp5-dark .bp5-editable-text.bp5-intent-danger .bp5-editable-text-input:-ms-input-placeholder{
  color:#fa999c;
}
.bp5-dark .bp5-editable-text.bp5-intent-danger .bp5-editable-text-content,
.bp5-dark .bp5-editable-text.bp5-intent-danger .bp5-editable-text-input,
.bp5-dark .bp5-editable-text.bp5-intent-danger .bp5-editable-text-input::placeholder{
  color:#fa999c;
}
.bp5-dark .bp5-editable-text.bp5-intent-danger:hover::before{
  box-shadow:0 0 0 0 rgba(250, 153, 156, 0), 0 0 0 0 rgba(250, 153, 156, 0), inset 0 0 0 1px rgba(250, 153, 156, 0.4);
}
.bp5-dark .bp5-editable-text.bp5-intent-danger.bp5-editable-text-editing::before{
  box-shadow:inset 0 0 0 1px rgba(250, 153, 156, 0.752), 0 0 0 1px rgba(250, 153, 156, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-editable-text.bp5-disabled::before{
  box-shadow:none !important;
}

.bp5-editable-text-input,
.bp5-editable-text-content{
  color:inherit;
  display:inherit;
  font:inherit;
  letter-spacing:inherit;
  max-width:inherit;
  min-width:inherit;
  position:relative;
  resize:none;
  text-transform:inherit;
  vertical-align:top;
}

.bp5-editable-text-input{
  background:none;
  border:none;
  box-shadow:none;
  padding:0;
  white-space:pre-wrap;
  width:100%;
}
.bp5-editable-text-input::-moz-placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-editable-text-input:-ms-input-placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-editable-text-input::placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-editable-text-input:focus{
  outline:none;
}
.bp5-editable-text-input::-ms-clear{
  display:none;
}

.bp5-editable-text-content{
  overflow:hidden;
  padding-right:2px;
  text-overflow:ellipsis;
  white-space:pre;
}
.bp5-editable-text-editing > .bp5-editable-text-content{
  left:0;
  position:absolute;
  visibility:hidden;
}
.bp5-editable-text-placeholder > .bp5-editable-text-content{
  color:#5f6b7c;
}
.bp5-dark .bp5-editable-text-placeholder > .bp5-editable-text-content{
  color:#abb3bf;
}

.bp5-editable-text.bp5-multiline{
  display:block;
}
.bp5-editable-text.bp5-multiline .bp5-editable-text-content{
  overflow:auto;
  white-space:pre-wrap;
  word-wrap:break-word;
}
.bp5-entity-title{
  align-items:center;
  display:flex;
  gap:7px;
  min-width:0;
}
.bp5-entity-title-icon-container.bp5-entity-title-has-subtitle{
  align-self:flex-start;
}
.bp5-entity-title-icon-container:not(.bp5-entity-title-has-subtitle){
  align-items:center;
  display:flex;
}
.bp5-entity-title-text{
  display:flex;
  flex-direction:column;
}
.bp5-entity-title-title-and-tags{
  align-items:center;
  display:flex;
  flex-direction:row;
  gap:5px;
}
.bp5-entity-title-tags-container{
  display:flex;
  gap:2px;
  margin-left:5px;
}
.bp5-entity-title-title{
  margin-bottom:0;
  min-width:0;
  overflow-wrap:break-word;
}
.bp5-entity-title-subtitle{
  font-size:12px;
  margin-top:2px;
}
.bp5-entity-title-ellipsize, .bp5-entity-title-ellipsize .bp5-entity-title-text{
  overflow:hidden;
}
.bp5-entity-title-heading-h1 .bp5-icon-container{
  align-items:center;
  display:flex;
  height:40px;
}
.bp5-entity-title-heading-h2 .bp5-icon-container{
  align-items:center;
  display:flex;
  height:32px;
}
.bp5-entity-title-heading-h3 .bp5-icon-container{
  align-items:center;
  display:flex;
  height:25px;
}
.bp5-entity-title-heading-h4 .bp5-icon-container{
  align-items:center;
  display:flex;
  height:21px;
}
.bp5-entity-title-heading-h5 .bp5-icon-container{
  align-items:center;
  display:flex;
  height:19px;
}
.bp5-entity-title-heading-h6 .bp5-icon-container{
  align-items:center;
  display:flex;
  height:16px;
}
.bp5-entity-title-heading-h1, .bp5-entity-title-heading-h2, .bp5-entity-title-heading-h3{
  gap:15px;
}
.bp5-entity-title-heading-h1 .bp5-entity-title-status-tag, .bp5-entity-title-heading-h2 .bp5-entity-title-status-tag, .bp5-entity-title-heading-h3 .bp5-entity-title-status-tag{
  margin-left:10px;
}
.bp5-entity-title-heading-h1 .bp5-entity-title-subtitle, .bp5-entity-title-heading-h2 .bp5-entity-title-subtitle, .bp5-entity-title-heading-h3 .bp5-entity-title-subtitle{
  font-size:14px;
}
.bp5-entity-title-heading-h4, .bp5-entity-title-heading-h5, .bp5-entity-title-heading-h6{
  gap:10px;
}
.bp5-entity-title-heading-h4 .bp5-entity-title-subtitle, .bp5-entity-title-heading-h5 .bp5-entity-title-subtitle, .bp5-entity-title-heading-h6 .bp5-entity-title-subtitle{
  font-size:12px;
}
.bp5-divider{
  border-bottom:1px solid rgba(17, 20, 24, 0.15);
  border-right:1px solid rgba(17, 20, 24, 0.15);
  margin:5px;
}
.bp5-dark .bp5-divider{
  border-color:rgba(255, 255, 255, 0.2);
}
.bp5-control-group{
  transform:translateZ(0);
  display:flex;
  flex-direction:row;
  align-items:stretch;
}
.bp5-control-group > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-control-group > .bp5-fill{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-control-group .bp5-button,
.bp5-control-group .bp5-html-select,
.bp5-control-group .bp5-input,
.bp5-control-group .bp5-select{
  position:relative;
}
.bp5-control-group .bp5-input{
  z-index:2;
}
.bp5-control-group .bp5-input:focus{
  z-index:14;
}
.bp5-control-group .bp5-input[class*=bp5-intent]{
  z-index:13;
}
.bp5-control-group .bp5-input[class*=bp5-intent]:focus{
  z-index:15;
}
.bp5-control-group .bp5-input[readonly], .bp5-control-group .bp5-input:disabled, .bp5-control-group .bp5-input.bp5-disabled{
  z-index:1;
}
.bp5-control-group .bp5-input-group[class*=bp5-intent] .bp5-input{
  z-index:13;
}
.bp5-control-group .bp5-input-group[class*=bp5-intent] .bp5-input:focus{
  z-index:15;
}
.bp5-control-group .bp5-button,
.bp5-control-group .bp5-html-select select,
.bp5-control-group .bp5-select select{
  transform:translateZ(0);
  z-index:4;
}
.bp5-control-group .bp5-button:focus,
.bp5-control-group .bp5-html-select select:focus,
.bp5-control-group .bp5-select select:focus{
  z-index:5;
}
.bp5-control-group .bp5-button:hover,
.bp5-control-group .bp5-html-select select:hover,
.bp5-control-group .bp5-select select:hover{
  z-index:6;
}
.bp5-control-group .bp5-button:active,
.bp5-control-group .bp5-html-select select:active,
.bp5-control-group .bp5-select select:active{
  z-index:7;
}
.bp5-control-group .bp5-button[readonly], .bp5-control-group .bp5-button:disabled, .bp5-control-group .bp5-button.bp5-disabled,
.bp5-control-group .bp5-html-select select[readonly],
.bp5-control-group .bp5-html-select select:disabled,
.bp5-control-group .bp5-html-select select.bp5-disabled,
.bp5-control-group .bp5-select select[readonly],
.bp5-control-group .bp5-select select:disabled,
.bp5-control-group .bp5-select select.bp5-disabled{
  z-index:3;
}
.bp5-control-group .bp5-button[class*=bp5-intent],
.bp5-control-group .bp5-html-select select[class*=bp5-intent],
.bp5-control-group .bp5-select select[class*=bp5-intent]{
  z-index:9;
}
.bp5-control-group .bp5-button[class*=bp5-intent]:focus,
.bp5-control-group .bp5-html-select select[class*=bp5-intent]:focus,
.bp5-control-group .bp5-select select[class*=bp5-intent]:focus{
  z-index:10;
}
.bp5-control-group .bp5-button[class*=bp5-intent]:hover,
.bp5-control-group .bp5-html-select select[class*=bp5-intent]:hover,
.bp5-control-group .bp5-select select[class*=bp5-intent]:hover{
  z-index:11;
}
.bp5-control-group .bp5-button[class*=bp5-intent]:active,
.bp5-control-group .bp5-html-select select[class*=bp5-intent]:active,
.bp5-control-group .bp5-select select[class*=bp5-intent]:active{
  z-index:12;
}
.bp5-control-group .bp5-button[class*=bp5-intent][readonly], .bp5-control-group .bp5-button[class*=bp5-intent]:disabled, .bp5-control-group .bp5-button[class*=bp5-intent].bp5-disabled,
.bp5-control-group .bp5-html-select select[class*=bp5-intent][readonly],
.bp5-control-group .bp5-html-select select[class*=bp5-intent]:disabled,
.bp5-control-group .bp5-html-select select[class*=bp5-intent].bp5-disabled,
.bp5-control-group .bp5-select select[class*=bp5-intent][readonly],
.bp5-control-group .bp5-select select[class*=bp5-intent]:disabled,
.bp5-control-group .bp5-select select[class*=bp5-intent].bp5-disabled{
  z-index:8;
}
.bp5-control-group .bp5-input-group > .bp5-icon,
.bp5-control-group .bp5-input-group > .bp5-button,
.bp5-control-group .bp5-input-group > .bp5-input-left-container,
.bp5-control-group .bp5-input-group > .bp5-input-action{
  z-index:16;
}
.bp5-control-group .bp5-select::after,
.bp5-control-group .bp5-html-select::after,
.bp5-control-group .bp5-select > .bp5-icon,
.bp5-control-group .bp5-html-select > .bp5-icon{
  z-index:17;
}
.bp5-control-group .bp5-html-select:focus-within,
.bp5-control-group .bp5-select:focus-within{
  z-index:5;
}
.bp5-control-group:not(.bp5-vertical) > :not(:last-child){
  margin-right:2px;
}
.bp5-control-group .bp5-numeric-input:not(:first-child) .bp5-input-group{
  border-bottom-left-radius:0;
  border-top-left-radius:0;
}
.bp5-control-group.bp5-fill{
  width:100%;
}
.bp5-control-group > .bp5-fill{
  flex:1 1 auto;
}
.bp5-control-group.bp5-fill > *:not(.bp5-fixed){
  flex:1 1 auto;
}
.bp5-control-group.bp5-vertical{
  flex-direction:column;
}
.bp5-control-group.bp5-vertical > :not(:last-child){
  margin-bottom:2px;
}
.bp5-control{
  cursor:pointer;
  display:block;
  margin-bottom:10px;
  position:relative;
  text-transform:none;
}
.bp5-control input:checked ~ .bp5-control-indicator{
  background-color:#2d72d2;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2);
  color:#ffffff;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control input:checked ~ .bp5-control-indicator{
    background-color:highlight;
    border:1px solid highlight;
  }
}
.bp5-control:hover input:checked ~ .bp5-control-indicator{
  background-color:#215db0;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control:hover input:checked ~ .bp5-control-indicator{
    background-color:highlight;
  }
}
.bp5-control input:not(:disabled):active:checked ~ .bp5-control-indicator{
  background:#184a90;
}
.bp5-control input:disabled:checked ~ .bp5-control-indicator{
  background:rgba(45, 114, 210, 0.5);
  box-shadow:none;
  color:rgba(255, 255, 255, 0.6);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control input:disabled:checked ~ .bp5-control-indicator{
    background-color:graytext;
    border-color:graytext;
  }
}
.bp5-dark .bp5-control input:checked ~ .bp5-control-indicator{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-dark .bp5-control input:checked ~ .bp5-control-indicator{
    border:1px solid buttonborder;
  }
}
.bp5-dark .bp5-control:hover input:checked ~ .bp5-control-indicator{
  background-color:#215db0;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.bp5-dark .bp5-control input:not(:disabled):active:checked ~ .bp5-control-indicator{
  background-color:#184a90;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.bp5-dark .bp5-control input:disabled:checked ~ .bp5-control-indicator{
  background:rgba(45, 114, 210, 0.5);
  box-shadow:none;
  color:rgba(255, 255, 255, 0.6);
}
.bp5-control:not(.bp5-align-right){
  padding-left:26px;
}
.bp5-control:not(.bp5-align-right) .bp5-control-indicator{
  margin-left:-26px;
}
.bp5-control.bp5-align-right{
  padding-right:26px;
}
.bp5-control.bp5-align-right .bp5-control-indicator{
  margin-right:-26px;
}
.bp5-control.bp5-disabled{
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}
.bp5-control.bp5-inline{
  display:inline-block;
  margin-right:20px;
}
.bp5-control input{
  left:0;
  opacity:0;
  position:absolute;
  top:0;
  z-index:-1;
}
.bp5-control .bp5-control-indicator{
  background-clip:padding-box;
  background-color:transparent;
  border:none;
  box-shadow:inset 0 0 0 1px #738091;
  cursor:pointer;
  display:inline-block;
  font-size:16px;
  height:1em;
  margin-right:10px;
  margin-top:-3px;
  position:relative;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  vertical-align:middle;
  width:1em;
}
.bp5-control .bp5-control-indicator::before{
  content:"";
  display:block;
  height:1em;
  width:1em;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control .bp5-control-indicator{
    border:1px solid buttonborder;
  }
  .bp5-control .bp5-control-indicator::before{
    margin-left:-1px;
    margin-top:-1px;
  }
}
.bp5-control:hover .bp5-control-indicator{
  background-color:rgba(143, 153, 168, 0.15);
}
.bp5-control input:not(:disabled):active ~ .bp5-control-indicator{
  background:rgba(143, 153, 168, 0.3);
  box-shadow:inset 0 0 0 1px #738091;
}
.bp5-control input:disabled ~ .bp5-control-indicator{
  background:rgba(143, 153, 168, 0.15);
  box-shadow:none;
  cursor:not-allowed;
}
.bp5-control input:focus ~ .bp5-control-indicator{
  outline:rgba(33, 93, 176, 0.752) solid 2px;
  outline-offset:2px;
  -moz-outline-radius:6px;
}
.bp5-dark .bp5-control input:focus ~ .bp5-control-indicator{
  outline-color:rgba(138, 187, 255, 0.752);
}
.bp5-control.bp5-align-right .bp5-control-indicator{
  float:right;
  margin-left:10px;
  margin-top:1px;
}
.bp5-control.bp5-large{
  font-size:16px;
}
.bp5-control.bp5-large:not(.bp5-align-right){
  padding-left:30px;
}
.bp5-control.bp5-large:not(.bp5-align-right) .bp5-control-indicator{
  margin-left:-30px;
}
.bp5-control.bp5-large.bp5-align-right{
  padding-right:30px;
}
.bp5-control.bp5-large.bp5-align-right .bp5-control-indicator{
  margin-right:-30px;
}
.bp5-control.bp5-large .bp5-control-indicator{
  font-size:20px;
}
.bp5-control.bp5-large.bp5-align-right .bp5-control-indicator{
  margin-top:0;
}
.bp5-control.bp5-checkbox input:indeterminate ~ .bp5-control-indicator{
  background-color:#2d72d2;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2);
  color:#ffffff;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control.bp5-checkbox input:indeterminate ~ .bp5-control-indicator{
    background-color:highlight;
    border:1px solid highlight;
  }
}
.bp5-control.bp5-checkbox:hover input:indeterminate ~ .bp5-control-indicator{
  background-color:#215db0;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control.bp5-checkbox:hover input:indeterminate ~ .bp5-control-indicator{
    background-color:highlight;
  }
}
.bp5-control.bp5-checkbox input:not(:disabled):active:indeterminate ~ .bp5-control-indicator{
  background:#184a90;
}
.bp5-control.bp5-checkbox input:disabled:indeterminate ~ .bp5-control-indicator{
  background:rgba(45, 114, 210, 0.5);
  box-shadow:none;
  color:rgba(255, 255, 255, 0.6);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control.bp5-checkbox input:disabled:indeterminate ~ .bp5-control-indicator{
    background-color:graytext;
    border-color:graytext;
  }
}
.bp5-dark .bp5-control.bp5-checkbox input:indeterminate ~ .bp5-control-indicator{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-dark .bp5-control.bp5-checkbox input:indeterminate ~ .bp5-control-indicator{
    border:1px solid buttonborder;
  }
}
.bp5-dark .bp5-control.bp5-checkbox:hover input:indeterminate ~ .bp5-control-indicator{
  background-color:#215db0;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.bp5-dark .bp5-control.bp5-checkbox input:not(:disabled):active:indeterminate ~ .bp5-control-indicator{
  background-color:#184a90;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.bp5-dark .bp5-control.bp5-checkbox input:disabled:indeterminate ~ .bp5-control-indicator{
  background:rgba(45, 114, 210, 0.5);
  box-shadow:none;
  color:rgba(255, 255, 255, 0.6);
}
.bp5-control.bp5-checkbox .bp5-control-indicator{
  border-radius:2px;
}
.bp5-control.bp5-checkbox input:checked ~ .bp5-control-indicator::before{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='white'/%3e%3c/svg%3e");
}
.bp5-control.bp5-checkbox input:indeterminate ~ .bp5-control-indicator::before{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='white'/%3e%3c/svg%3e");
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control.bp5-checkbox input:checked:not(:disabled) ~ .bp5-control-indicator::before{
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23111418'/%3e%3c/svg%3e");
  }
  .bp5-control.bp5-checkbox input:indeterminate:not(:disabled) ~ .bp5-control-indicator::before{
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='%23111418'/%3e%3c/svg%3e");
  }
  .bp5-control.bp5-checkbox input:disabled ~ .bp5-control-indicator{
    border-color:graytext;
  }
}
.bp5-control.bp5-radio .bp5-control-indicator{
  border-radius:50%;
}
.bp5-control.bp5-radio input:checked ~ .bp5-control-indicator::before{
  background-image:radial-gradient(#ffffff, #ffffff 28%, transparent 32%);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control.bp5-radio input:checked ~ .bp5-control-indicator::before{
    background:highlight;
    height:12px;
    margin-left:1px;
    margin-top:1px;
    width:12px;
  }
}
.bp5-control.bp5-radio input:checked:disabled ~ .bp5-control-indicator::before{
  opacity:0.5;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control.bp5-radio input:checked:disabled ~ .bp5-control-indicator::before{
    background:graytext;
  }
}
.bp5-control.bp5-radio input:focus ~ .bp5-control-indicator{
  -moz-outline-radius:16px;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control.bp5-radio input:disabled ~ .bp5-control-indicator{
    border-color:graytext;
  }
}
.bp5-control.bp5-switch input ~ .bp5-control-indicator{
  background:rgba(143, 153, 168, 0.3);
  color:#1c2127;
}
.bp5-control.bp5-switch:hover input ~ .bp5-control-indicator{
  background:rgba(143, 153, 168, 0.4);
}
.bp5-control.bp5-switch input:not(:disabled):active ~ .bp5-control-indicator{
  background:rgba(143, 153, 168, 0.5);
}
.bp5-control.bp5-switch input:disabled ~ .bp5-control-indicator{
  background:rgba(143, 153, 168, 0.15);
  color:rgba(95, 107, 124, 0.6);
}
.bp5-control.bp5-switch input:disabled ~ .bp5-control-indicator::before{
  background:rgba(255, 255, 255, 0.8);
  box-shadow:none;
}
.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator{
  background:#2d72d2;
  color:#ffffff;
}
.bp5-control.bp5-switch:hover input:checked ~ .bp5-control-indicator{
  background:#215db0;
}
.bp5-control.bp5-switch input:checked:not(:disabled):active ~ .bp5-control-indicator{
  background:#184a90;
}
.bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator{
  background:rgba(45, 114, 210, 0.5);
  color:rgba(255, 255, 255, 0.6);
}
.bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator::before{
  background:rgba(255, 255, 255, 0.5);
  box-shadow:none;
}
.bp5-control.bp5-switch:not(.bp5-align-right){
  padding-left:38px;
}
.bp5-control.bp5-switch:not(.bp5-align-right) .bp5-control-indicator{
  margin-left:-38px;
}
.bp5-control.bp5-switch.bp5-align-right{
  padding-right:38px;
}
.bp5-control.bp5-switch.bp5-align-right .bp5-control-indicator{
  margin-right:-38px;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control.bp5-switch input:checked ~ .bp5-control-indicator{
    background:highlight;
    border:1px solid buttonborder;
  }
  .bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator{
    background-color:graytext;
  }
  .bp5-control.bp5-switch input:not(:checked):disabled ~ .bp5-control-indicator{
    border-color:graytext;
  }
  .bp5-control.bp5-switch input:not(:checked):disabled ~ .bp5-control-indicator::before{
    border-color:graytext;
  }
  .bp5-control.bp5-switch:hover input:checked ~ .bp5-control-indicator{
    background:highlight;
  }
}
.bp5-control.bp5-switch .bp5-control-indicator{
  border:none;
  border-radius:1.75em;
  box-shadow:none !important;
  min-width:1.75em;
  transition:background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  width:auto;
}
.bp5-control.bp5-switch .bp5-control-indicator::before{
  background:#ffffff;
  border-radius:50%;
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.5);
  height:calc(1em - 4px);
  left:0;
  margin:2px;
  position:absolute;
  transition:left 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  width:calc(1em - 4px);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control.bp5-switch .bp5-control-indicator::before{
    border:1px solid buttonborder;
    margin-top:1px;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-control.bp5-switch .bp5-control-indicator{
    border:1px solid buttonborder;
  }
}
.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator::before{
  left:calc(100% - 1em);
}
.bp5-control.bp5-switch.bp5-large:not(.bp5-align-right){
  padding-left:45px;
}
.bp5-control.bp5-switch.bp5-large:not(.bp5-align-right) .bp5-control-indicator{
  margin-left:-45px;
}
.bp5-control.bp5-switch.bp5-large.bp5-align-right{
  padding-right:45px;
}
.bp5-control.bp5-switch.bp5-large.bp5-align-right .bp5-control-indicator{
  margin-right:-45px;
}
.bp5-dark .bp5-control.bp5-switch input ~ .bp5-control-indicator{
  background:rgba(17, 20, 24, 0.5);
  color:#f6f7f9;
}
.bp5-dark .bp5-control.bp5-switch:hover input ~ .bp5-control-indicator{
  background:rgba(17, 20, 24, 0.8);
}
.bp5-dark .bp5-control.bp5-switch input:not(:disabled):active ~ .bp5-control-indicator{
  background:rgba(17, 20, 24, 0.9);
}
.bp5-dark .bp5-control.bp5-switch input:disabled ~ .bp5-control-indicator{
  background:rgba(143, 153, 168, 0.15);
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-control.bp5-switch input:disabled ~ .bp5-control-indicator::before{
  background:rgba(171, 179, 191, 0.5);
  box-shadow:none;
}
.bp5-dark .bp5-control.bp5-switch input:checked ~ .bp5-control-indicator{
  background:#2d72d2;
  color:#ffffff;
}
.bp5-dark .bp5-control.bp5-switch:hover input:checked ~ .bp5-control-indicator{
  background:#215db0;
}
.bp5-dark .bp5-control.bp5-switch input:checked:not(:disabled):active ~ .bp5-control-indicator{
  background:#184a90;
}
.bp5-dark .bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator{
  background:rgba(45, 114, 210, 0.5);
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator::before{
  background:rgba(255, 255, 255, 0.3);
  box-shadow:none;
}
.bp5-dark .bp5-control.bp5-switch .bp5-control-indicator::before{
  background:#abb3bf;
}
.bp5-dark .bp5-control.bp5-switch input:checked ~ .bp5-control-indicator::before{
  background:#ffffff;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-dark .bp5-control.bp5-switch input:checked ~ .bp5-control-indicator{
    background:highlight;
    border:1px solid buttonborder;
  }
  .bp5-dark .bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator{
    background-color:graytext;
  }
  .bp5-dark .bp5-control.bp5-switch input:not(:checked):disabled ~ .bp5-control-indicator{
    border-color:graytext;
  }
  .bp5-dark .bp5-control.bp5-switch input:not(:checked):disabled ~ .bp5-control-indicator::before{
    border-color:graytext;
  }
  .bp5-dark .bp5-control.bp5-switch:hover input:checked ~ .bp5-control-indicator{
    background:highlight;
  }
}
.bp5-control.bp5-switch .bp5-switch-inner-text{
  font-size:0.7em;
  text-align:center;
}
.bp5-control.bp5-switch .bp5-control-indicator-child:first-child{
  line-height:0;
  margin-left:0.5em;
  margin-right:1.2em;
  visibility:hidden;
}
.bp5-control.bp5-switch .bp5-control-indicator-child:last-child{
  line-height:1em;
  margin-left:1.2em;
  margin-right:0.5em;
  visibility:visible;
}
.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator .bp5-control-indicator-child:first-child{
  line-height:1em;
  visibility:visible;
}
.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator .bp5-control-indicator-child:last-child{
  line-height:0;
  visibility:hidden;
}
.bp5-dark .bp5-control{
  color:#f6f7f9;
}
.bp5-dark .bp5-control.bp5-disabled{
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-control .bp5-control-indicator{
  background-color:transparent;
  box-shadow:inset 0 0 0 1px #8f99a8;
}
.bp5-dark .bp5-control:hover .bp5-control-indicator{
  background-color:rgba(143, 153, 168, 0.15);
}
.bp5-dark .bp5-control input:not(:disabled):active ~ .bp5-control-indicator{
  background:rgba(143, 153, 168, 0.3);
  box-shadow:inset 0 0 0 1px #8f99a8;
}
.bp5-dark .bp5-control input:disabled ~ .bp5-control-indicator{
  background:rgba(143, 153, 168, 0.15);
  box-shadow:none;
  cursor:not-allowed;
}
.bp5-dark .bp5-control.bp5-checkbox input:disabled:checked ~ .bp5-control-indicator, .bp5-dark .bp5-control.bp5-checkbox input:disabled:indeterminate ~ .bp5-control-indicator{
  background:rgba(45, 114, 210, 0.5);
}
.bp5-file-input{
  cursor:pointer;
  display:inline-block;
  height:30px;
  position:relative;
}
.bp5-file-input input{
  margin:0;
  min-width:200px;
  opacity:0;
}
.bp5-file-input input:disabled + .bp5-file-upload-input, .bp5-file-input input.bp5-disabled + .bp5-file-upload-input{
  background:rgba(211, 216, 222, 0.5);
  box-shadow:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
  resize:none;
}
.bp5-file-input input:disabled + .bp5-file-upload-input::-moz-placeholder, .bp5-file-input input.bp5-disabled + .bp5-file-upload-input::-moz-placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-file-input input:disabled + .bp5-file-upload-input:-ms-input-placeholder, .bp5-file-input input.bp5-disabled + .bp5-file-upload-input:-ms-input-placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-file-input input:disabled + .bp5-file-upload-input::placeholder, .bp5-file-input input.bp5-disabled + .bp5-file-upload-input::placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-file-input input:disabled + .bp5-file-upload-input::after, .bp5-file-input input.bp5-disabled + .bp5-file-upload-input::after{
  background-color:rgba(211, 216, 222, 0.5);
  box-shadow:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
  outline:none;
}
.bp5-dark .bp5-file-input input:disabled + .bp5-file-upload-input, .bp5-dark .bp5-file-input input.bp5-disabled + .bp5-file-upload-input{
  background:rgba(64, 72, 84, 0.5);
  box-shadow:none;
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-file-input input:disabled + .bp5-file-upload-input::after, .bp5-dark .bp5-file-input input.bp5-disabled + .bp5-file-upload-input::after{
  background-color:rgba(64, 72, 84, 0.5);
  box-shadow:none;
  color:rgba(171, 179, 191, 0.6);
}
.bp5-file-input.bp5-file-input-has-selection .bp5-file-upload-input{
  color:#1c2127;
}
.bp5-dark .bp5-file-input.bp5-file-input-has-selection .bp5-file-upload-input{
  color:#f6f7f9;
}
.bp5-file-input.bp5-fill{
  width:100%;
}
.bp5-file-input.bp5-large, .bp5-large .bp5-file-input{
  height:40px;
}
.bp5-file-input.bp5-small, .bp5-small .bp5-file-input{
  height:24px;
}
.bp5-file-input .bp5-file-upload-input-custom-text::after{
  content:attr(bp5-button-text);
}

.bp5-file-upload-input{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  background:#ffffff;
  border:none;
  border-radius:2px;
  box-shadow:0 0 0 0 rgba(33, 93, 176, 0), 0 0 0 0 rgba(33, 93, 176, 0), inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.3);
  color:#1c2127;
  font-size:14px;
  font-weight:400;
  height:30px;
  line-height:30px;
  outline:none;
  padding:0 10px;
  transition:box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align:middle;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  word-wrap:normal;
  color:rgba(95, 107, 124, 0.6);
  left:0;
  padding-right:80px;
  position:absolute;
  right:0;
  top:0;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.bp5-file-upload-input::-moz-placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-file-upload-input:-ms-input-placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-file-upload-input::placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-file-upload-input:focus, .bp5-file-upload-input.bp5-active{
  box-shadow:inset 0 0 0 1px rgba(33, 93, 176, 0.752), 0 0 0 1px rgba(33, 93, 176, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-file-upload-input[type=search], .bp5-file-upload-input.bp5-round{
  border-radius:30px;
  box-sizing:border-box;
  padding-left:10px;
}
.bp5-file-upload-input[readonly]{
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.15);
}
.bp5-file-upload-input:disabled, .bp5-file-upload-input.bp5-disabled{
  background:rgba(211, 216, 222, 0.5);
  box-shadow:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
  resize:none;
}
.bp5-file-upload-input:disabled::-moz-placeholder, .bp5-file-upload-input.bp5-disabled::-moz-placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-file-upload-input:disabled:-ms-input-placeholder, .bp5-file-upload-input.bp5-disabled:-ms-input-placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-file-upload-input:disabled::placeholder, .bp5-file-upload-input.bp5-disabled::placeholder{
  color:rgba(95, 107, 124, 0.6);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-file-upload-input{
    border:1px solid buttonborder;
  }
}
.bp5-file-upload-input::after{
  background-color:#f6f7f9;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color:#1c2127;
  min-height:24px;
  min-width:24px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  word-wrap:normal;
  border-radius:2px;
  content:"Browse";
  line-height:24px;
  margin:3px;
  position:absolute;
  right:0;
  text-align:center;
  top:0;
  width:70px;
}
.bp5-file-upload-input:hover::after{
  background-clip:padding-box;
  background-color:#edeff2;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-file-upload-input:active::after{
  background-color:#dce0e5;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-file-upload-input:active::after{
    background:highlight;
  }
}
.bp5-large .bp5-file-upload-input{
  font-size:16px;
  height:40px;
  line-height:40px;
  padding-right:95px;
}
.bp5-large .bp5-file-upload-input[type=search], .bp5-large .bp5-file-upload-input.bp5-round{
  padding:0 15px;
}
.bp5-large .bp5-file-upload-input::after{
  min-height:30px;
  min-width:30px;
  line-height:30px;
  margin:5px;
  width:85px;
}
.bp5-small .bp5-file-upload-input{
  font-size:12px;
  height:24px;
  line-height:24px;
  padding-left:8px;
  padding-right:8px;
  padding-right:65px;
}
.bp5-small .bp5-file-upload-input[type=search], .bp5-small .bp5-file-upload-input.bp5-round{
  padding:0 12px;
}
.bp5-small .bp5-file-upload-input::after{
  min-height:20px;
  min-width:20px;
  line-height:20px;
  margin:2px;
  width:55px;
}
.bp5-dark .bp5-file-upload-input{
  background:rgba(17, 20, 24, 0.3);
  box-shadow:0 0 0 0 rgba(138, 187, 255, 0), 0 0 0 0 rgba(138, 187, 255, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
  color:#f6f7f9;
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-file-upload-input::-moz-placeholder{
  color:#abb3bf;
}
.bp5-dark .bp5-file-upload-input:-ms-input-placeholder{
  color:#abb3bf;
}
.bp5-dark .bp5-file-upload-input::placeholder{
  color:#abb3bf;
}
.bp5-dark .bp5-file-upload-input:focus{
  box-shadow:inset 0 0 0 1px rgba(138, 187, 255, 0.752), 0 0 0 1px rgba(138, 187, 255, 0.752);
}
.bp5-dark .bp5-file-upload-input[readonly]{
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-file-upload-input:disabled, .bp5-dark .bp5-file-upload-input.bp5-disabled{
  background:rgba(64, 72, 84, 0.5);
  box-shadow:none;
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-file-upload-input::after{
  background-color:#383e47;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
  color:#f6f7f9;
}
.bp5-dark .bp5-file-upload-input:hover::after{
  background-color:#2f343c;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-file-upload-input:active::after{
  background-color:#1c2127;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp5-file-upload-input::after{
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
}
.bp5-form-group{
  display:flex;
  flex-direction:column;
  margin:0 0 15px;
}
.bp5-form-group label.bp5-label{
  margin-bottom:5px;
}
.bp5-form-group .bp5-control{
  margin-top:7px;
}
.bp5-form-group .bp5-form-group-sub-label,
.bp5-form-group .bp5-form-helper-text{
  color:#5f6b7c;
  font-size:12px;
}
.bp5-form-group .bp5-form-group-sub-label{
  margin-bottom:5px;
}
.bp5-form-group .bp5-form-helper-text{
  margin-top:5px;
}
.bp5-form-group.bp5-intent-primary .bp5-form-helper-text{
  color:#215db0;
}
.bp5-form-group.bp5-intent-success .bp5-form-helper-text{
  color:#1c6e42;
}
.bp5-form-group.bp5-intent-warning .bp5-form-helper-text{
  color:#935610;
}
.bp5-form-group.bp5-intent-danger .bp5-form-helper-text{
  color:#ac2f33;
}
.bp5-form-group.bp5-fill{
  width:100%;
}
.bp5-form-group.bp5-inline{
  align-items:flex-start;
  flex-direction:row;
}
.bp5-form-group.bp5-inline.bp5-large label.bp5-label{
  line-height:40px;
  margin:0 10px 0 0;
}
.bp5-form-group.bp5-inline label.bp5-label{
  line-height:30px;
  margin:0 10px 0 0;
}
.bp5-form-group.bp5-disabled .bp5-label,
.bp5-form-group.bp5-disabled .bp5-text-muted,
.bp5-form-group.bp5-disabled .bp5-form-group-sub-label,
.bp5-form-group.bp5-disabled .bp5-form-helper-text{
  color:rgba(95, 107, 124, 0.6) !important;
}
.bp5-dark .bp5-form-group.bp5-intent-primary .bp5-form-helper-text{
  color:#8abbff;
}
.bp5-dark .bp5-form-group.bp5-intent-success .bp5-form-helper-text{
  color:#72ca9b;
}
.bp5-dark .bp5-form-group.bp5-intent-warning .bp5-form-helper-text{
  color:#fbb360;
}
.bp5-dark .bp5-form-group.bp5-intent-danger .bp5-form-helper-text{
  color:#fa999c;
}
.bp5-dark .bp5-form-group .bp5-form-group-sub-label,
.bp5-dark .bp5-form-group .bp5-form-helper-text{
  color:#abb3bf;
}
.bp5-dark .bp5-form-group.bp5-disabled .bp5-label,
.bp5-dark .bp5-form-group.bp5-disabled .bp5-text-muted,
.bp5-dark .bp5-form-group.bp5-disabled .bp5-form-group-sub-label,
.bp5-dark .bp5-form-group.bp5-disabled .bp5-form-helper-text{
  color:rgba(171, 179, 191, 0.6) !important;
}
.bp5-input-group{
  display:block;
  position:relative;
}
.bp5-input-group .bp5-input{
  position:relative;
  width:100%;
}
.bp5-input-group .bp5-input:not(:first-child){
  padding-left:30px;
}
.bp5-input-group .bp5-input:not(:last-child){
  padding-right:30px;
}
.bp5-input-group .bp5-input-action,
.bp5-input-group > .bp5-input-left-container,
.bp5-input-group > .bp5-button,
.bp5-input-group > .bp5-icon{
  position:absolute;
  top:0;
}
.bp5-input-group .bp5-input-action:first-child,
.bp5-input-group > .bp5-input-left-container:first-child,
.bp5-input-group > .bp5-button:first-child,
.bp5-input-group > .bp5-icon:first-child{
  left:0;
}
.bp5-input-group .bp5-input-action:last-child,
.bp5-input-group > .bp5-input-left-container:last-child,
.bp5-input-group > .bp5-button:last-child,
.bp5-input-group > .bp5-icon:last-child{
  right:0;
}
.bp5-input-group .bp5-button{
  min-height:24px;
  min-width:24px;
  margin:3px;
  padding:0 7px;
}
.bp5-input-group .bp5-button:empty{
  padding:0;
}
.bp5-input-group > .bp5-input-left-container,
.bp5-input-group > .bp5-icon{
  z-index:1;
}
.bp5-input-group > .bp5-input-left-container > .bp5-icon,
.bp5-input-group > .bp5-icon{
  color:#5f6b7c;
}
.bp5-input-group > .bp5-input-left-container > .bp5-icon:empty,
.bp5-input-group > .bp5-icon:empty{
  font-family:"blueprint-icons-16", sans-serif;
  font-size:16px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:16px;
  line-height:1;
  width:16px;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
}
.bp5-input-group > .bp5-input-left-container > .bp5-icon,
.bp5-input-group > .bp5-icon,
.bp5-input-group .bp5-input-action > .bp5-spinner{
  margin:7px;
}
.bp5-input-group .bp5-tag{
  margin:5px;
}
.bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:not(:hover):not(:focus),
.bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:not(:hover):not(:focus){
  color:#5f6b7c;
}
.bp5-dark .bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:not(:hover):not(:focus),
.bp5-dark .bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:not(:hover):not(:focus){
  color:#abb3bf;
}
.bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon, .bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon-standard, .bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon-large,
.bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon,
.bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon-standard,
.bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon-large{
  color:#5f6b7c;
}
.bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:disabled,
.bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:disabled{
  color:rgba(95, 107, 124, 0.6) !important;
}
.bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:disabled .bp5-icon, .bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:disabled .bp5-icon-standard, .bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:disabled .bp5-icon-large,
.bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:disabled .bp5-icon,
.bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:disabled .bp5-icon-standard,
.bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:disabled .bp5-icon-large{
  color:rgba(95, 107, 124, 0.6) !important;
}
.bp5-input-group.bp5-disabled{
  cursor:not-allowed;
}
.bp5-input-group.bp5-disabled .bp5-icon{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-input-group.bp5-large .bp5-button{
  min-height:30px;
  min-width:30px;
  margin:5px;
}
.bp5-input-group.bp5-large > .bp5-input-left-container > .bp5-icon,
.bp5-input-group.bp5-large > .bp5-icon,
.bp5-input-group.bp5-large .bp5-input-action > .bp5-spinner{
  margin:12px;
}
.bp5-input-group.bp5-large .bp5-input{
  font-size:16px;
  height:40px;
  line-height:40px;
}
.bp5-input-group.bp5-large .bp5-input[type=search], .bp5-input-group.bp5-large .bp5-input.bp5-round{
  padding:0 15px;
}
.bp5-input-group.bp5-large .bp5-input:not(:first-child){
  padding-left:40px;
}
.bp5-input-group.bp5-large .bp5-input:not(:last-child){
  padding-right:40px;
}
.bp5-input-group.bp5-small .bp5-button{
  min-height:20px;
  min-width:20px;
  margin:2px;
}
.bp5-input-group.bp5-small .bp5-tag{
  min-height:20px;
  min-width:20px;
  margin:2px;
}
.bp5-input-group.bp5-small > .bp5-input-left-container > .bp5-icon,
.bp5-input-group.bp5-small > .bp5-icon,
.bp5-input-group.bp5-small .bp5-input-action > .bp5-spinner{
  margin:4px;
}
.bp5-input-group.bp5-small .bp5-input{
  font-size:12px;
  height:24px;
  line-height:24px;
  padding-left:8px;
  padding-right:8px;
}
.bp5-input-group.bp5-small .bp5-input[type=search], .bp5-input-group.bp5-small .bp5-input.bp5-round{
  padding:0 12px;
}
.bp5-input-group.bp5-small .bp5-input:not(:first-child){
  padding-left:24px;
}
.bp5-input-group.bp5-small .bp5-input:not(:last-child){
  padding-right:24px;
}
.bp5-input-group.bp5-fill{
  flex:1 1 auto;
  width:100%;
}
.bp5-input-group.bp5-round .bp5-button,
.bp5-input-group.bp5-round .bp5-input,
.bp5-input-group.bp5-round .bp5-tag{
  border-radius:30px;
}
.bp5-dark .bp5-input-group .bp5-icon{
  color:#abb3bf;
}
.bp5-dark .bp5-input-group.bp5-disabled .bp5-icon{
  color:rgba(171, 179, 191, 0.6);
}
.bp5-input-group.bp5-intent-primary .bp5-input{
  box-shadow:0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px #2d72d2, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.3);
}
.bp5-input-group.bp5-intent-primary .bp5-input:focus{
  box-shadow:inset 0 0 0 1px rgba(45, 114, 210, 0.752), 0 0 0 2px rgba(45, 114, 210, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-input-group.bp5-intent-primary .bp5-input[readonly]{
  box-shadow:inset 0 0 0 1px #2d72d2;
}
.bp5-input-group.bp5-intent-primary .bp5-input:disabled, .bp5-input-group.bp5-intent-primary .bp5-input.bp5-disabled{
  box-shadow:none;
}
.bp5-dark .bp5-input-group.bp5-intent-primary .bp5-input{
  box-shadow:0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), inset 0 0 0 1px #4c90f0, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input-group.bp5-intent-primary .bp5-input:focus{
  box-shadow:inset 0 0 0 1px rgba(76, 144, 240, 0.752), 0 0 0 2px rgba(76, 144, 240, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input-group.bp5-intent-primary .bp5-input[readonly]{
  box-shadow:inset 0 0 0 1px #4c90f0;
}
.bp5-dark .bp5-input-group.bp5-intent-primary .bp5-input:disabled, .bp5-dark .bp5-input-group.bp5-intent-primary .bp5-input.bp5-disabled{
  box-shadow:none;
}
.bp5-input-group.bp5-intent-primary > .bp5-icon{
  color:#215db0;
}
.bp5-dark .bp5-input-group.bp5-intent-primary > .bp5-icon{
  color:#8abbff;
}
.bp5-input-group.bp5-intent-success .bp5-input{
  box-shadow:0 0 0 0 rgba(35, 133, 81, 0), 0 0 0 0 rgba(35, 133, 81, 0), inset 0 0 0 1px #238551, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.3);
}
.bp5-input-group.bp5-intent-success .bp5-input:focus{
  box-shadow:inset 0 0 0 1px rgba(35, 133, 81, 0.752), 0 0 0 2px rgba(35, 133, 81, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-input-group.bp5-intent-success .bp5-input[readonly]{
  box-shadow:inset 0 0 0 1px #238551;
}
.bp5-input-group.bp5-intent-success .bp5-input:disabled, .bp5-input-group.bp5-intent-success .bp5-input.bp5-disabled{
  box-shadow:none;
}
.bp5-dark .bp5-input-group.bp5-intent-success .bp5-input{
  box-shadow:0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), inset 0 0 0 1px #32a467, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input-group.bp5-intent-success .bp5-input:focus{
  box-shadow:inset 0 0 0 1px rgba(50, 164, 103, 0.752), 0 0 0 2px rgba(50, 164, 103, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input-group.bp5-intent-success .bp5-input[readonly]{
  box-shadow:inset 0 0 0 1px #32a467;
}
.bp5-dark .bp5-input-group.bp5-intent-success .bp5-input:disabled, .bp5-dark .bp5-input-group.bp5-intent-success .bp5-input.bp5-disabled{
  box-shadow:none;
}
.bp5-input-group.bp5-intent-success > .bp5-icon{
  color:#1c6e42;
}
.bp5-dark .bp5-input-group.bp5-intent-success > .bp5-icon{
  color:#72ca9b;
}
.bp5-input-group.bp5-intent-warning .bp5-input{
  box-shadow:0 0 0 0 rgba(200, 118, 25, 0), 0 0 0 0 rgba(200, 118, 25, 0), inset 0 0 0 1px #c87619, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.3);
}
.bp5-input-group.bp5-intent-warning .bp5-input:focus{
  box-shadow:inset 0 0 0 1px rgba(200, 118, 25, 0.752), 0 0 0 2px rgba(200, 118, 25, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-input-group.bp5-intent-warning .bp5-input[readonly]{
  box-shadow:inset 0 0 0 1px #c87619;
}
.bp5-input-group.bp5-intent-warning .bp5-input:disabled, .bp5-input-group.bp5-intent-warning .bp5-input.bp5-disabled{
  box-shadow:none;
}
.bp5-dark .bp5-input-group.bp5-intent-warning .bp5-input{
  box-shadow:0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input-group.bp5-intent-warning .bp5-input:focus{
  box-shadow:inset 0 0 0 1px rgba(236, 154, 60, 0.752), 0 0 0 2px rgba(236, 154, 60, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input-group.bp5-intent-warning .bp5-input[readonly]{
  box-shadow:inset 0 0 0 1px #ec9a3c;
}
.bp5-dark .bp5-input-group.bp5-intent-warning .bp5-input:disabled, .bp5-dark .bp5-input-group.bp5-intent-warning .bp5-input.bp5-disabled{
  box-shadow:none;
}
.bp5-input-group.bp5-intent-warning > .bp5-icon{
  color:#935610;
}
.bp5-dark .bp5-input-group.bp5-intent-warning > .bp5-icon{
  color:#fbb360;
}
.bp5-input-group.bp5-intent-danger .bp5-input{
  box-shadow:0 0 0 0 rgba(205, 66, 70, 0), 0 0 0 0 rgba(205, 66, 70, 0), inset 0 0 0 1px #cd4246, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.3);
}
.bp5-input-group.bp5-intent-danger .bp5-input:focus{
  box-shadow:inset 0 0 0 1px rgba(205, 66, 70, 0.752), 0 0 0 2px rgba(205, 66, 70, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-input-group.bp5-intent-danger .bp5-input[readonly]{
  box-shadow:inset 0 0 0 1px #cd4246;
}
.bp5-input-group.bp5-intent-danger .bp5-input:disabled, .bp5-input-group.bp5-intent-danger .bp5-input.bp5-disabled{
  box-shadow:none;
}
.bp5-dark .bp5-input-group.bp5-intent-danger .bp5-input{
  box-shadow:0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), inset 0 0 0 1px #e76a6e, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input-group.bp5-intent-danger .bp5-input:focus{
  box-shadow:inset 0 0 0 1px rgba(231, 106, 110, 0.752), 0 0 0 2px rgba(231, 106, 110, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input-group.bp5-intent-danger .bp5-input[readonly]{
  box-shadow:inset 0 0 0 1px #e76a6e;
}
.bp5-dark .bp5-input-group.bp5-intent-danger .bp5-input:disabled, .bp5-dark .bp5-input-group.bp5-intent-danger .bp5-input.bp5-disabled{
  box-shadow:none;
}
.bp5-input-group.bp5-intent-danger > .bp5-icon{
  color:#ac2f33;
}
.bp5-dark .bp5-input-group.bp5-intent-danger > .bp5-icon{
  color:#fa999c;
}
.bp5-input{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  background:#ffffff;
  border:none;
  border-radius:2px;
  box-shadow:0 0 0 0 rgba(33, 93, 176, 0), 0 0 0 0 rgba(33, 93, 176, 0), inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.3);
  color:#1c2127;
  font-size:14px;
  font-weight:400;
  height:30px;
  line-height:30px;
  outline:none;
  padding:0 10px;
  transition:box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align:middle;
}
.bp5-input::-moz-placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-input:-ms-input-placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-input::placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-input:focus, .bp5-input.bp5-active{
  box-shadow:inset 0 0 0 1px rgba(33, 93, 176, 0.752), 0 0 0 1px rgba(33, 93, 176, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-input[type=search], .bp5-input.bp5-round{
  border-radius:30px;
  box-sizing:border-box;
  padding-left:10px;
}
.bp5-input[readonly]{
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.15);
}
.bp5-input:disabled, .bp5-input.bp5-disabled{
  background:rgba(211, 216, 222, 0.5);
  box-shadow:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
  resize:none;
}
.bp5-input:disabled::-moz-placeholder, .bp5-input.bp5-disabled::-moz-placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-input:disabled:-ms-input-placeholder, .bp5-input.bp5-disabled:-ms-input-placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-input:disabled::placeholder, .bp5-input.bp5-disabled::placeholder{
  color:rgba(95, 107, 124, 0.6);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-input{
    border:1px solid buttonborder;
  }
}
.bp5-input.bp5-large{
  font-size:16px;
  height:40px;
  line-height:40px;
}
.bp5-input.bp5-large[type=search], .bp5-input.bp5-large.bp5-round{
  padding:0 15px;
}
.bp5-input.bp5-small{
  font-size:12px;
  height:24px;
  line-height:24px;
  padding-left:8px;
  padding-right:8px;
}
.bp5-input.bp5-small[type=search], .bp5-input.bp5-small.bp5-round{
  padding:0 12px;
}
.bp5-input.bp5-fill{
  flex:1 1 auto;
  width:100%;
}
.bp5-dark .bp5-input{
  background:rgba(17, 20, 24, 0.3);
  box-shadow:0 0 0 0 rgba(138, 187, 255, 0), 0 0 0 0 rgba(138, 187, 255, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
  color:#f6f7f9;
}
.bp5-dark .bp5-input::-moz-placeholder{
  color:#abb3bf;
}
.bp5-dark .bp5-input:-ms-input-placeholder{
  color:#abb3bf;
}
.bp5-dark .bp5-input::placeholder{
  color:#abb3bf;
}
.bp5-dark .bp5-input:focus{
  box-shadow:inset 0 0 0 1px rgba(138, 187, 255, 0.752), 0 0 0 1px rgba(138, 187, 255, 0.752);
}
.bp5-dark .bp5-input[readonly]{
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-input:disabled, .bp5-dark .bp5-input.bp5-disabled{
  background:rgba(64, 72, 84, 0.5);
  box-shadow:none;
  color:rgba(171, 179, 191, 0.6);
}
.bp5-input.bp5-intent-primary{
  box-shadow:0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px #2d72d2, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.3);
}
.bp5-input.bp5-intent-primary:focus{
  box-shadow:inset 0 0 0 1px rgba(45, 114, 210, 0.752), 0 0 0 2px rgba(45, 114, 210, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-input.bp5-intent-primary[readonly]{
  box-shadow:inset 0 0 0 1px #2d72d2;
}
.bp5-input.bp5-intent-primary:disabled, .bp5-input.bp5-intent-primary.bp5-disabled{
  box-shadow:none;
}
.bp5-dark .bp5-input.bp5-intent-primary{
  box-shadow:0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), inset 0 0 0 1px #4c90f0, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input.bp5-intent-primary:focus{
  box-shadow:inset 0 0 0 1px rgba(76, 144, 240, 0.752), 0 0 0 2px rgba(76, 144, 240, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input.bp5-intent-primary[readonly]{
  box-shadow:inset 0 0 0 1px #4c90f0;
}
.bp5-dark .bp5-input.bp5-intent-primary:disabled, .bp5-dark .bp5-input.bp5-intent-primary.bp5-disabled{
  box-shadow:none;
}
.bp5-input.bp5-intent-success{
  box-shadow:0 0 0 0 rgba(35, 133, 81, 0), 0 0 0 0 rgba(35, 133, 81, 0), inset 0 0 0 1px #238551, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.3);
}
.bp5-input.bp5-intent-success:focus{
  box-shadow:inset 0 0 0 1px rgba(35, 133, 81, 0.752), 0 0 0 2px rgba(35, 133, 81, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-input.bp5-intent-success[readonly]{
  box-shadow:inset 0 0 0 1px #238551;
}
.bp5-input.bp5-intent-success:disabled, .bp5-input.bp5-intent-success.bp5-disabled{
  box-shadow:none;
}
.bp5-dark .bp5-input.bp5-intent-success{
  box-shadow:0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), inset 0 0 0 1px #32a467, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input.bp5-intent-success:focus{
  box-shadow:inset 0 0 0 1px rgba(50, 164, 103, 0.752), 0 0 0 2px rgba(50, 164, 103, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input.bp5-intent-success[readonly]{
  box-shadow:inset 0 0 0 1px #32a467;
}
.bp5-dark .bp5-input.bp5-intent-success:disabled, .bp5-dark .bp5-input.bp5-intent-success.bp5-disabled{
  box-shadow:none;
}
.bp5-input.bp5-intent-warning{
  box-shadow:0 0 0 0 rgba(200, 118, 25, 0), 0 0 0 0 rgba(200, 118, 25, 0), inset 0 0 0 1px #c87619, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.3);
}
.bp5-input.bp5-intent-warning:focus{
  box-shadow:inset 0 0 0 1px rgba(200, 118, 25, 0.752), 0 0 0 2px rgba(200, 118, 25, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-input.bp5-intent-warning[readonly]{
  box-shadow:inset 0 0 0 1px #c87619;
}
.bp5-input.bp5-intent-warning:disabled, .bp5-input.bp5-intent-warning.bp5-disabled{
  box-shadow:none;
}
.bp5-dark .bp5-input.bp5-intent-warning{
  box-shadow:0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input.bp5-intent-warning:focus{
  box-shadow:inset 0 0 0 1px rgba(236, 154, 60, 0.752), 0 0 0 2px rgba(236, 154, 60, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input.bp5-intent-warning[readonly]{
  box-shadow:inset 0 0 0 1px #ec9a3c;
}
.bp5-dark .bp5-input.bp5-intent-warning:disabled, .bp5-dark .bp5-input.bp5-intent-warning.bp5-disabled{
  box-shadow:none;
}
.bp5-input.bp5-intent-danger{
  box-shadow:0 0 0 0 rgba(205, 66, 70, 0), 0 0 0 0 rgba(205, 66, 70, 0), inset 0 0 0 1px #cd4246, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.3);
}
.bp5-input.bp5-intent-danger:focus{
  box-shadow:inset 0 0 0 1px rgba(205, 66, 70, 0.752), 0 0 0 2px rgba(205, 66, 70, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-input.bp5-intent-danger[readonly]{
  box-shadow:inset 0 0 0 1px #cd4246;
}
.bp5-input.bp5-intent-danger:disabled, .bp5-input.bp5-intent-danger.bp5-disabled{
  box-shadow:none;
}
.bp5-dark .bp5-input.bp5-intent-danger{
  box-shadow:0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), inset 0 0 0 1px #e76a6e, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input.bp5-intent-danger:focus{
  box-shadow:inset 0 0 0 1px rgba(231, 106, 110, 0.752), 0 0 0 2px rgba(231, 106, 110, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-input.bp5-intent-danger[readonly]{
  box-shadow:inset 0 0 0 1px #e76a6e;
}
.bp5-dark .bp5-input.bp5-intent-danger:disabled, .bp5-dark .bp5-input.bp5-intent-danger.bp5-disabled{
  box-shadow:none;
}
.bp5-input::-ms-clear{
  display:none;
}

@supports (-webkit-touch-callout: none){
  input.bp5-input:disabled, input.bp5-input.bp5-disabled{
    opacity:1;
    -webkit-text-fill-color:rgba(95, 107, 124, 0.6);
  }
  .bp5-dark input.bp5-input:disabled, .bp5-dark input.bp5-input.bp5-disabled{
    -webkit-text-fill-color:rgba(171, 179, 191, 0.6);
  }
}
textarea.bp5-input{
  max-width:100%;
  padding:10px;
}
textarea.bp5-input, textarea.bp5-input.bp5-large, textarea.bp5-input.bp5-small{
  height:auto;
  line-height:inherit;
}
textarea.bp5-input.bp5-small{
  padding:8px;
}

.bp5-text-area.bp5-text-area-auto-resize{
  resize:horizontal;
}
label.bp5-label{
  display:block;
  margin-bottom:15px;
  margin-top:0;
}
label.bp5-label .bp5-html-select,
label.bp5-label .bp5-input,
label.bp5-label .bp5-select,
label.bp5-label .bp5-slider,
label.bp5-label .bp5-popover-wrapper{
  display:block;
  margin-top:5px;
  text-transform:none;
}
label.bp5-label .bp5-button-group{
  margin-top:5px;
}
label.bp5-label .bp5-select select,
label.bp5-label .bp5-html-select select{
  font-weight:400;
  vertical-align:top;
  width:100%;
}
label.bp5-label .bp5-control-group{
  margin-top:5px;
}
label.bp5-label .bp5-control-group > .bp5-button-group,
label.bp5-label .bp5-control-group > .bp5-html-select,
label.bp5-label .bp5-control-group > .bp5-input,
label.bp5-label .bp5-control-group > .bp5-select,
label.bp5-label .bp5-control-group > .bp5-slider,
label.bp5-label .bp5-control-group > .bp5-popover-wrapper{
  margin-top:0;
}
label.bp5-label.bp5-disabled,
label.bp5-label.bp5-disabled .bp5-text-muted{
  color:rgba(95, 107, 124, 0.6);
}
label.bp5-label.bp5-inline{
  line-height:30px;
}
label.bp5-label.bp5-inline .bp5-html-select,
label.bp5-label.bp5-inline .bp5-input,
label.bp5-label.bp5-inline .bp5-input-group,
label.bp5-label.bp5-inline .bp5-select,
label.bp5-label.bp5-inline .bp5-popover-wrapper{
  display:inline-block;
  margin:0 0 0 5px;
  vertical-align:top;
}
label.bp5-label.bp5-inline .bp5-button-group{
  margin:0 0 0 5px;
}
label.bp5-label.bp5-inline .bp5-input-group .bp5-input{
  margin-left:0;
}
label.bp5-label.bp5-inline.bp5-large{
  line-height:40px;
}
label.bp5-label.bp5-inline .bp5-control-group{
  margin:0 0 0 5px;
}
label.bp5-label.bp5-inline .bp5-control-group > .bp5-button-group,
label.bp5-label.bp5-inline .bp5-control-group > .bp5-html-select,
label.bp5-label.bp5-inline .bp5-control-group > .bp5-input,
label.bp5-label.bp5-inline .bp5-control-group > .bp5-select,
label.bp5-label.bp5-inline .bp5-control-group > .bp5-slider,
label.bp5-label.bp5-inline .bp5-control-group > .bp5-popover-wrapper{
  margin:0;
}
label.bp5-label:not(.bp5-inline) .bp5-popover-target{
  display:block;
}
.bp5-dark label.bp5-label{
  color:#f6f7f9;
}
.bp5-dark label.bp5-label.bp5-disabled,
.bp5-dark label.bp5-label.bp5-disabled .bp5-text-muted{
  color:rgba(171, 179, 191, 0.6);
}
.bp5-numeric-input .bp5-button-group.bp5-vertical > .bp5-button{
  flex:1 1 11px;
  min-height:0;
  padding:0;
  width:24px;
}
.bp5-numeric-input.bp5-large .bp5-button-group.bp5-vertical > .bp5-button{
  width:40px;
}
.bp5-numeric-input.bp5-small .bp5-button-group.bp5-vertical > .bp5-button{
  width:24px;
}

form{
  display:block;
}
.bp5-html-select select,
.bp5-select select{
  display:inline-flex;
  flex-direction:row;
  align-items:center;
  border:none;
  border-radius:2px;
  cursor:pointer;
  font-size:14px;
  justify-content:center;
  padding:5px 10px;
  text-align:left;
  vertical-align:middle;
  background-color:#f6f7f9;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color:#1c2127;
  -moz-appearance:none;
  -webkit-appearance:none;
  border-radius:2px;
  height:30px;
  padding:0 30px 0 10px;
  width:100%;
}
.bp5-html-select select > *,
.bp5-select select > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-html-select select > .bp5-fill,
.bp5-select select > .bp5-fill{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-html-select select::before,
.bp5-select select::before,
.bp5-html-select select > *,
.bp5-select select > *{
  margin-right:7px;
}
.bp5-html-select select:empty::before,
.bp5-select select:empty::before,
.bp5-html-select select > :last-child,
.bp5-select select > :last-child{
  margin-right:0;
}
.bp5-html-select select:hover,
.bp5-select select:hover{
  background-clip:padding-box;
  background-color:#edeff2;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-html-select select:active,
.bp5-select select:active, .bp5-html-select select.bp5-active,
.bp5-select select.bp5-active{
  background-color:#dce0e5;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-html-select select:active,
  .bp5-select select:active, .bp5-html-select select.bp5-active,
  .bp5-select select.bp5-active{
    background:highlight;
  }
}
.bp5-html-select select:disabled,
.bp5-select select:disabled, .bp5-html-select select.bp5-disabled,
.bp5-select select.bp5-disabled{
  background-color:rgba(211, 216, 222, 0.5);
  box-shadow:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
  outline:none;
}
.bp5-html-select select:disabled.bp5-active,
.bp5-select select:disabled.bp5-active, .bp5-html-select select.bp5-disabled.bp5-active,
.bp5-select select.bp5-disabled.bp5-active{
  background:rgba(211, 216, 222, 0.7);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-html-select select,
  .bp5-select select{
    border:1px solid buttonborder;
  }
}

.bp5-html-select.bp5-minimal select,
.bp5-select.bp5-minimal select{
  background:none;
  box-shadow:none;
}
.bp5-html-select.bp5-minimal select:hover,
.bp5-select.bp5-minimal select:hover{
  background:rgba(143, 153, 168, 0.15);
  box-shadow:none;
  color:#1c2127;
  text-decoration:none;
}
.bp5-html-select.bp5-minimal select:active,
.bp5-select.bp5-minimal select:active, .bp5-html-select.bp5-minimal select.bp5-active,
.bp5-select.bp5-minimal select.bp5-active{
  background:rgba(143, 153, 168, 0.3);
  box-shadow:none;
  color:#1c2127;
}
.bp5-html-select.bp5-minimal select:disabled,
.bp5-select.bp5-minimal select:disabled, .bp5-html-select.bp5-minimal select.bp5-disabled,
.bp5-select.bp5-minimal select.bp5-disabled{
  background:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}
.bp5-html-select.bp5-minimal select:disabled.bp5-active,
.bp5-select.bp5-minimal select:disabled.bp5-active, .bp5-html-select.bp5-minimal select.bp5-disabled.bp5-active,
.bp5-select.bp5-minimal select.bp5-disabled.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-dark .bp5-html-select.bp5-minimal select, .bp5-html-select.bp5-minimal .bp5-dark select,
.bp5-dark .bp5-select.bp5-minimal select,
.bp5-select.bp5-minimal .bp5-dark select{
  background:none;
  box-shadow:none;
  color:#ffffff;
}
.bp5-dark .bp5-html-select.bp5-minimal select:hover, .bp5-html-select.bp5-minimal .bp5-dark select:hover,
.bp5-dark .bp5-select.bp5-minimal select:hover,
.bp5-select.bp5-minimal .bp5-dark select:hover, .bp5-dark .bp5-html-select.bp5-minimal select:active, .bp5-html-select.bp5-minimal .bp5-dark select:active,
.bp5-dark .bp5-select.bp5-minimal select:active,
.bp5-select.bp5-minimal .bp5-dark select:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-active{
  background:none;
  box-shadow:none;
  color:#ffffff;
}
.bp5-dark .bp5-html-select.bp5-minimal select:hover, .bp5-html-select.bp5-minimal .bp5-dark select:hover,
.bp5-dark .bp5-select.bp5-minimal select:hover,
.bp5-select.bp5-minimal .bp5-dark select:hover{
  background:rgba(143, 153, 168, 0.15);
}
.bp5-dark .bp5-html-select.bp5-minimal select:active, .bp5-html-select.bp5-minimal .bp5-dark select:active,
.bp5-dark .bp5-select.bp5-minimal select:active,
.bp5-select.bp5-minimal .bp5-dark select:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-dark .bp5-html-select.bp5-minimal select:disabled, .bp5-html-select.bp5-minimal .bp5-dark select:disabled,
.bp5-dark .bp5-select.bp5-minimal select:disabled,
.bp5-select.bp5-minimal .bp5-dark select:disabled, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-disabled,
.bp5-dark .bp5-select.bp5-minimal select.bp5-disabled,
.bp5-select.bp5-minimal .bp5-dark select.bp5-disabled{
  background:none;
  color:rgba(171, 179, 191, 0.6);
  cursor:not-allowed;
}
.bp5-dark .bp5-html-select.bp5-minimal select:disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select:disabled.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select:disabled.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select:disabled.bp5-active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-disabled.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-disabled.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-disabled.bp5-active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-html-select.bp5-minimal select.bp5-intent-primary,
.bp5-select.bp5-minimal select.bp5-intent-primary{
  color:#215db0;
}
.bp5-html-select.bp5-minimal select.bp5-intent-primary:hover,
.bp5-select.bp5-minimal select.bp5-intent-primary:hover, .bp5-html-select.bp5-minimal select.bp5-intent-primary:active,
.bp5-select.bp5-minimal select.bp5-intent-primary:active, .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-primary.bp5-active{
  background:none;
  box-shadow:none;
  color:#215db0;
}
.bp5-html-select.bp5-minimal select.bp5-intent-primary:hover,
.bp5-select.bp5-minimal select.bp5-intent-primary:hover{
  background:rgba(45, 114, 210, 0.15);
  color:#215db0;
}
.bp5-html-select.bp5-minimal select.bp5-intent-primary:active,
.bp5-select.bp5-minimal select.bp5-intent-primary:active, .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-primary.bp5-active{
  background:rgba(45, 114, 210, 0.3);
  color:#184a90;
}
.bp5-html-select.bp5-minimal select.bp5-intent-primary:disabled,
.bp5-select.bp5-minimal select.bp5-intent-primary:disabled, .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-disabled,
.bp5-select.bp5-minimal select.bp5-intent-primary.bp5-disabled{
  background:none;
  color:rgba(33, 93, 176, 0.5);
}
.bp5-html-select.bp5-minimal select.bp5-intent-primary:disabled.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-primary:disabled.bp5-active, .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-disabled.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-primary.bp5-disabled.bp5-active{
  background:rgba(45, 114, 210, 0.3);
}
.bp5-html-select.bp5-minimal select.bp5-intent-primary .bp5-button-spinner .bp5-spinner-head,
.bp5-select.bp5-minimal select.bp5-intent-primary .bp5-button-spinner .bp5-spinner-head{
  stroke:#215db0;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary{
  color:#8abbff;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary:hover, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary:hover,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary:hover,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary:hover{
  background:rgba(45, 114, 210, 0.2);
  color:#8abbff;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary:active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary:active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary:active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-active{
  background:rgba(45, 114, 210, 0.3);
  color:#99c4ff;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary:disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary:disabled,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary:disabled,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary:disabled, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-disabled,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary.bp5-disabled,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-disabled{
  background:none;
  color:rgba(138, 187, 255, 0.5);
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary:disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary:disabled.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary:disabled.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary:disabled.bp5-active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-disabled.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary.bp5-disabled.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-disabled.bp5-active{
  background:rgba(45, 114, 210, 0.3);
}
.bp5-html-select.bp5-minimal select.bp5-intent-success,
.bp5-select.bp5-minimal select.bp5-intent-success{
  color:#1c6e42;
}
.bp5-html-select.bp5-minimal select.bp5-intent-success:hover,
.bp5-select.bp5-minimal select.bp5-intent-success:hover, .bp5-html-select.bp5-minimal select.bp5-intent-success:active,
.bp5-select.bp5-minimal select.bp5-intent-success:active, .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-success.bp5-active{
  background:none;
  box-shadow:none;
  color:#1c6e42;
}
.bp5-html-select.bp5-minimal select.bp5-intent-success:hover,
.bp5-select.bp5-minimal select.bp5-intent-success:hover{
  background:rgba(35, 133, 81, 0.15);
  color:#1c6e42;
}
.bp5-html-select.bp5-minimal select.bp5-intent-success:active,
.bp5-select.bp5-minimal select.bp5-intent-success:active, .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-success.bp5-active{
  background:rgba(35, 133, 81, 0.3);
  color:#165a36;
}
.bp5-html-select.bp5-minimal select.bp5-intent-success:disabled,
.bp5-select.bp5-minimal select.bp5-intent-success:disabled, .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-disabled,
.bp5-select.bp5-minimal select.bp5-intent-success.bp5-disabled{
  background:none;
  color:rgba(28, 110, 66, 0.5);
}
.bp5-html-select.bp5-minimal select.bp5-intent-success:disabled.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-success:disabled.bp5-active, .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-disabled.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-success.bp5-disabled.bp5-active{
  background:rgba(35, 133, 81, 0.3);
}
.bp5-html-select.bp5-minimal select.bp5-intent-success .bp5-button-spinner .bp5-spinner-head,
.bp5-select.bp5-minimal select.bp5-intent-success .bp5-button-spinner .bp5-spinner-head{
  stroke:#1c6e42;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success{
  color:#72ca9b;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success:hover, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success:hover,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success:hover,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success:hover{
  background:rgba(35, 133, 81, 0.2);
  color:#72ca9b;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success:active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success:active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success:active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-active{
  background:rgba(35, 133, 81, 0.3);
  color:#7cd7a2;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success:disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success:disabled,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success:disabled,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success:disabled, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-disabled,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success.bp5-disabled,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-disabled{
  background:none;
  color:rgba(114, 202, 155, 0.5);
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success:disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success:disabled.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success:disabled.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success:disabled.bp5-active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-disabled.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success.bp5-disabled.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-disabled.bp5-active{
  background:rgba(35, 133, 81, 0.3);
}
.bp5-html-select.bp5-minimal select.bp5-intent-warning,
.bp5-select.bp5-minimal select.bp5-intent-warning{
  color:#935610;
}
.bp5-html-select.bp5-minimal select.bp5-intent-warning:hover,
.bp5-select.bp5-minimal select.bp5-intent-warning:hover, .bp5-html-select.bp5-minimal select.bp5-intent-warning:active,
.bp5-select.bp5-minimal select.bp5-intent-warning:active, .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-warning.bp5-active{
  background:none;
  box-shadow:none;
  color:#935610;
}
.bp5-html-select.bp5-minimal select.bp5-intent-warning:hover,
.bp5-select.bp5-minimal select.bp5-intent-warning:hover{
  background:rgba(200, 118, 25, 0.15);
  color:#935610;
}
.bp5-html-select.bp5-minimal select.bp5-intent-warning:active,
.bp5-select.bp5-minimal select.bp5-intent-warning:active, .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-warning.bp5-active{
  background:rgba(200, 118, 25, 0.3);
  color:#77450d;
}
.bp5-html-select.bp5-minimal select.bp5-intent-warning:disabled,
.bp5-select.bp5-minimal select.bp5-intent-warning:disabled, .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-disabled,
.bp5-select.bp5-minimal select.bp5-intent-warning.bp5-disabled{
  background:none;
  color:rgba(147, 86, 16, 0.5);
}
.bp5-html-select.bp5-minimal select.bp5-intent-warning:disabled.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-warning:disabled.bp5-active, .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-disabled.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-warning.bp5-disabled.bp5-active{
  background:rgba(200, 118, 25, 0.3);
}
.bp5-html-select.bp5-minimal select.bp5-intent-warning .bp5-button-spinner .bp5-spinner-head,
.bp5-select.bp5-minimal select.bp5-intent-warning .bp5-button-spinner .bp5-spinner-head{
  stroke:#935610;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning{
  color:#fbb360;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning:hover, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning:hover,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning:hover,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning:hover{
  background:rgba(200, 118, 25, 0.2);
  color:#fbb360;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning:active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning:active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning:active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-active{
  background:rgba(200, 118, 25, 0.3);
  color:#f5c186;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning:disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning:disabled,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning:disabled,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning:disabled, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-disabled,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning.bp5-disabled,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-disabled{
  background:none;
  color:rgba(251, 179, 96, 0.5);
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning:disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning:disabled.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning:disabled.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning:disabled.bp5-active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-disabled.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning.bp5-disabled.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-disabled.bp5-active{
  background:rgba(200, 118, 25, 0.3);
}
.bp5-html-select.bp5-minimal select.bp5-intent-danger,
.bp5-select.bp5-minimal select.bp5-intent-danger{
  color:#ac2f33;
}
.bp5-html-select.bp5-minimal select.bp5-intent-danger:hover,
.bp5-select.bp5-minimal select.bp5-intent-danger:hover, .bp5-html-select.bp5-minimal select.bp5-intent-danger:active,
.bp5-select.bp5-minimal select.bp5-intent-danger:active, .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-danger.bp5-active{
  background:none;
  box-shadow:none;
  color:#ac2f33;
}
.bp5-html-select.bp5-minimal select.bp5-intent-danger:hover,
.bp5-select.bp5-minimal select.bp5-intent-danger:hover{
  background:rgba(205, 66, 70, 0.15);
  color:#ac2f33;
}
.bp5-html-select.bp5-minimal select.bp5-intent-danger:active,
.bp5-select.bp5-minimal select.bp5-intent-danger:active, .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-danger.bp5-active{
  background:rgba(205, 66, 70, 0.3);
  color:#8e292c;
}
.bp5-html-select.bp5-minimal select.bp5-intent-danger:disabled,
.bp5-select.bp5-minimal select.bp5-intent-danger:disabled, .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-disabled,
.bp5-select.bp5-minimal select.bp5-intent-danger.bp5-disabled{
  background:none;
  color:rgba(172, 47, 51, 0.5);
}
.bp5-html-select.bp5-minimal select.bp5-intent-danger:disabled.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-danger:disabled.bp5-active, .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-disabled.bp5-active,
.bp5-select.bp5-minimal select.bp5-intent-danger.bp5-disabled.bp5-active{
  background:rgba(205, 66, 70, 0.3);
}
.bp5-html-select.bp5-minimal select.bp5-intent-danger .bp5-button-spinner .bp5-spinner-head,
.bp5-select.bp5-minimal select.bp5-intent-danger .bp5-button-spinner .bp5-spinner-head{
  stroke:#ac2f33;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger{
  color:#fa999c;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger:hover, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger:hover,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger:hover,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger:hover{
  background:rgba(205, 66, 70, 0.2);
  color:#fa999c;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger:active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger:active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger:active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-active{
  background:rgba(205, 66, 70, 0.3);
  color:#ffa1a4;
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger:disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger:disabled,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger:disabled,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger:disabled, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-disabled,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger.bp5-disabled,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-disabled{
  background:none;
  color:rgba(250, 153, 156, 0.5);
}
.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger:disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger:disabled.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger:disabled.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger:disabled.bp5-active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-disabled.bp5-active,
.bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger.bp5-disabled.bp5-active,
.bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-disabled.bp5-active{
  background:rgba(205, 66, 70, 0.3);
}

.bp5-html-select.bp5-large select,
.bp5-select.bp5-large select{
  font-size:16px;
  height:40px;
  padding-right:35px;
}

.bp5-dark .bp5-html-select select,
.bp5-dark .bp5-select select{
  background-color:#383e47;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
  color:#f6f7f9;
}
.bp5-dark .bp5-html-select select:hover,
.bp5-dark .bp5-select select:hover, .bp5-dark .bp5-html-select select:active,
.bp5-dark .bp5-select select:active, .bp5-dark .bp5-html-select select.bp5-active,
.bp5-dark .bp5-select select.bp5-active{
  color:#f6f7f9;
}
.bp5-dark .bp5-html-select select:hover,
.bp5-dark .bp5-select select:hover{
  background-color:#2f343c;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-html-select select:active,
.bp5-dark .bp5-select select:active, .bp5-dark .bp5-html-select select.bp5-active,
.bp5-dark .bp5-select select.bp5-active{
  background-color:#1c2127;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-html-select select:disabled,
.bp5-dark .bp5-select select:disabled, .bp5-dark .bp5-html-select select.bp5-disabled,
.bp5-dark .bp5-select select.bp5-disabled{
  background-color:rgba(64, 72, 84, 0.5);
  box-shadow:none;
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-html-select select:disabled.bp5-active,
.bp5-dark .bp5-select select:disabled.bp5-active, .bp5-dark .bp5-html-select select.bp5-disabled.bp5-active,
.bp5-dark .bp5-select select.bp5-disabled.bp5-active{
  background:rgba(64, 72, 84, 0.7);
}
.bp5-dark .bp5-html-select select .bp5-button-spinner .bp5-spinner-head,
.bp5-dark .bp5-select select .bp5-button-spinner .bp5-spinner-head{
  background:rgba(17, 20, 24, 0.5);
  stroke:#8f99a8;
}

.bp5-html-select select:disabled,
.bp5-select select:disabled{
  background-color:rgba(211, 216, 222, 0.5);
  box-shadow:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}

.bp5-select::after, .bp5-html-select .bp5-icon,
.bp5-select .bp5-icon{
  color:#5f6b7c;
  pointer-events:none;
  position:absolute;
  right:10px;
  top:7px;
}
.bp5-disabled.bp5-select::after, .bp5-html-select .bp5-disabled.bp5-icon,
.bp5-select .bp5-disabled.bp5-icon{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-html-select,
.bp5-select{
  display:inline-block;
  letter-spacing:normal;
  position:relative;
  vertical-align:middle;
}
.bp5-html-select .bp5-icon,
.bp5-select .bp5-icon{
  color:#5f6b7c;
}
.bp5-html-select .bp5-icon:hover,
.bp5-select .bp5-icon:hover{
  color:#1c2127;
}
.bp5-dark .bp5-html-select .bp5-icon,
.bp5-dark .bp5-select .bp5-icon{
  color:#abb3bf;
}
.bp5-dark .bp5-html-select .bp5-icon:hover,
.bp5-dark .bp5-select .bp5-icon:hover{
  color:#f6f7f9;
}
.bp5-html-select.bp5-large::after,
.bp5-html-select.bp5-large .bp5-icon,
.bp5-select.bp5-large::after,
.bp5-select.bp5-large .bp5-icon{
  right:12px;
  top:12px;
}
.bp5-html-select.bp5-fill,
.bp5-html-select.bp5-fill select,
.bp5-select.bp5-fill,
.bp5-select.bp5-fill select{
  width:100%;
}
.bp5-dark .bp5-html-select option,
.bp5-dark .bp5-select option{
  background-color:#2f343c;
  color:#f6f7f9;
}
.bp5-dark .bp5-html-select option:disabled,
.bp5-dark .bp5-select option:disabled{
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-html-select::after,
.bp5-dark .bp5-select::after{
  color:#abb3bf;
}

.bp5-select::after{
  font-family:"blueprint-icons-16", sans-serif;
  font-size:16px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:16px;
  line-height:1;
  width:16px;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  content:"\f184";
}
table.bp5-html-table, .bp5-running-text table{
  border-spacing:0;
  font-size:14px;
}
table.bp5-html-table th, .bp5-running-text table th,
table.bp5-html-table td,
.bp5-running-text table td{
  padding:11px;
  text-align:left;
  vertical-align:top;
}
table.bp5-html-table th, .bp5-running-text table th{
  color:#1c2127;
  font-weight:600;
}
table.bp5-html-table td, .bp5-running-text table td{
  color:#1c2127;
}
table.bp5-html-table tbody tr:first-child th, .bp5-running-text table tbody tr:first-child th,
table.bp5-html-table tbody tr:first-child td,
.bp5-running-text table tbody tr:first-child td,
table.bp5-html-table tfoot tr:first-child th,
.bp5-running-text table tfoot tr:first-child th,
table.bp5-html-table tfoot tr:first-child td,
.bp5-running-text table tfoot tr:first-child td{
  box-shadow:inset 0 1px 0 0 rgba(17, 20, 24, 0.15);
}
.bp5-dark table.bp5-html-table th, .bp5-dark .bp5-running-text table th, .bp5-running-text .bp5-dark table th{
  color:#f6f7f9;
}
.bp5-dark table.bp5-html-table td, .bp5-dark .bp5-running-text table td, .bp5-running-text .bp5-dark table td{
  color:#f6f7f9;
}
.bp5-dark table.bp5-html-table tbody tr:first-child th, .bp5-dark .bp5-running-text table tbody tr:first-child th, .bp5-running-text .bp5-dark table tbody tr:first-child th,
.bp5-dark table.bp5-html-table tbody tr:first-child td,
.bp5-dark .bp5-running-text table tbody tr:first-child td,
.bp5-running-text .bp5-dark table tbody tr:first-child td,
.bp5-dark table.bp5-html-table tfoot tr:first-child th,
.bp5-dark .bp5-running-text table tfoot tr:first-child th,
.bp5-running-text .bp5-dark table tfoot tr:first-child th,
.bp5-dark table.bp5-html-table tfoot tr:first-child td,
.bp5-dark .bp5-running-text table tfoot tr:first-child td,
.bp5-running-text .bp5-dark table tfoot tr:first-child td{
  box-shadow:inset 0 1px 0 0 rgba(255, 255, 255, 0.2);
}

table.bp5-html-table.bp5-compact th,
table.bp5-html-table.bp5-compact td{
  padding-bottom:6px;
  padding-top:6px;
}
table.bp5-html-table.bp5-html-table-striped tbody tr:nth-child(odd) td{
  background:rgba(143, 153, 168, 0.15);
}
table.bp5-html-table.bp5-html-table-bordered th:not(:first-child){
  box-shadow:inset 1px 0 0 0 rgba(17, 20, 24, 0.15);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  table.bp5-html-table.bp5-html-table-bordered th:not(:first-child){
    border-left:1px solid buttonborder;
  }
}
table.bp5-html-table.bp5-html-table-bordered tbody tr td,
table.bp5-html-table.bp5-html-table-bordered tfoot tr td{
  box-shadow:inset 0 1px 0 0 rgba(17, 20, 24, 0.15);
}
table.bp5-html-table.bp5-html-table-bordered tbody tr td:not(:first-child),
table.bp5-html-table.bp5-html-table-bordered tfoot tr td:not(:first-child){
  box-shadow:inset 1px 1px 0 0 rgba(17, 20, 24, 0.15);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  table.bp5-html-table.bp5-html-table-bordered tbody tr td:not(:first-child),
  table.bp5-html-table.bp5-html-table-bordered tfoot tr td:not(:first-child){
    border-left:1px solid buttonborder;
    border-top:1px solid buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  table.bp5-html-table.bp5-html-table-bordered tbody tr td,
  table.bp5-html-table.bp5-html-table-bordered tfoot tr td{
    border-top:1px solid buttonborder;
  }
}
table.bp5-html-table.bp5-html-table-bordered.bp5-html-table-striped tbody tr:not(:first-child) td{
  box-shadow:none;
}
table.bp5-html-table.bp5-html-table-bordered.bp5-html-table-striped tbody tr:not(:first-child) td:not(:first-child){
  box-shadow:inset 1px 0 0 0 rgba(17, 20, 24, 0.15);
}
table.bp5-html-table.bp5-interactive tbody tr:hover td{
  background-color:rgba(143, 153, 168, 0.3);
  cursor:pointer;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  table.bp5-html-table.bp5-interactive tbody tr:hover td{
    background-color:highlight;
  }
}
table.bp5-html-table.bp5-interactive tbody tr:active td{
  background-color:rgba(143, 153, 168, 0.35);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  table.bp5-html-table.bp5-interactive tbody tr:active td{
    background-color:highlight;
  }
}
.bp5-dark table.bp5-html-table{
}
.bp5-dark table.bp5-html-table.bp5-html-table-striped tbody tr:nth-child(odd) td{
  background:rgba(95, 107, 124, 0.15);
}
.bp5-dark table.bp5-html-table.bp5-html-table-bordered th:not(:first-child){
  box-shadow:inset 1px 0 0 0 rgba(255, 255, 255, 0.2);
}
.bp5-dark table.bp5-html-table.bp5-html-table-bordered tbody tr td,
.bp5-dark table.bp5-html-table.bp5-html-table-bordered tfoot tr td{
  box-shadow:inset 0 1px 0 0 rgba(255, 255, 255, 0.2);
}
.bp5-dark table.bp5-html-table.bp5-html-table-bordered tbody tr td:not(:first-child),
.bp5-dark table.bp5-html-table.bp5-html-table-bordered tfoot tr td:not(:first-child){
  box-shadow:inset 1px 1px 0 0 rgba(255, 255, 255, 0.2);
}
.bp5-dark table.bp5-html-table.bp5-html-table-bordered.bp5-html-table-striped tbody tr:not(:first-child) td{
  box-shadow:inset 1px 0 0 0 rgba(255, 255, 255, 0.2);
}
.bp5-dark table.bp5-html-table.bp5-html-table-bordered.bp5-html-table-striped tbody tr:not(:first-child) td:first-child{
  box-shadow:none;
}
.bp5-dark table.bp5-html-table.bp5-interactive tbody tr:hover td{
  background-color:rgba(95, 107, 124, 0.3);
  cursor:pointer;
}
.bp5-dark table.bp5-html-table.bp5-interactive tbody tr:active td{
  background-color:rgba(95, 107, 124, 0.4);
}
.bp5-key-combo{
  display:flex;
  flex-direction:row;
  align-items:center;
}
.bp5-key-combo > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-key-combo > .bp5-fill{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-key-combo::before,
.bp5-key-combo > *{
  margin-right:5px;
}
.bp5-key-combo:empty::before,
.bp5-key-combo > :last-child{
  margin-right:0;
}

.bp5-hotkey-dialog{
  padding-bottom:0;
  top:40px;
}
.bp5-hotkey-dialog .bp5-dialog-body{
  margin:0;
  padding:0;
}
.bp5-hotkey-dialog .bp5-hotkey-label{
  flex-grow:1;
}

.bp5-hotkey-column{
  margin:auto;
  padding:30px;
}
.bp5-hotkey-column .bp5-heading{
  margin-bottom:20px;
}
.bp5-hotkey-column .bp5-heading:not(:first-child){
  margin-top:40px;
}

.bp5-hotkey{
  align-items:center;
  display:flex;
  justify-content:space-between;
  margin-left:0;
  margin-right:0;
}
.bp5-hotkey:not(:last-child){
  margin-bottom:10px;
}
.bp5-icon{
  display:inline-block;
  flex:0 0 auto;
  vertical-align:text-bottom;
}
.bp5-icon:not(:empty)::before{
  content:"" !important;
  content:unset !important;
}
.bp5-icon > svg{
  display:block;
}
.bp5-icon > svg:not([fill]){
  fill:currentcolor;
}
.bp5-icon.bp5-icon-muted svg{
  fill-opacity:15%;
  overflow:visible;
}
.bp5-icon.bp5-icon-muted svg path{
  stroke:#8f99a8;
  stroke-opacity:50%;
  stroke-width:0.5px;
}
.bp5-dark .bp5-icon .bp5-icon-muted svg{
  fill-opacity:20%;
}

span.bp5-icon-standard{
  font-family:"blueprint-icons-16", sans-serif;
  font-size:16px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:16px;
  line-height:1;
  width:16px;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  display:inline-block;
}

span.bp5-icon-large{
  font-family:"blueprint-icons-20", sans-serif;
  font-size:20px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:20px;
  line-height:1;
  width:20px;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  display:inline-block;
}

span.bp5-icon:empty{
  font-family:"blueprint-icons-20";
  font-size:inherit;
  font-style:normal;
  font-weight:400;
  line-height:1;
}
span.bp5-icon:empty::before{
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
}
span.bp5-icon:empty.bp5-icon-standard{
  font-size:16px;
}
span.bp5-icon:empty.bp5-icon-large{
  font-size:20px;
}

.bp5-icon-add::before{
  content:"\f109";
}

.bp5-icon-add-clip::before{
  content:"\f101";
}

.bp5-icon-add-column-left::before{
  content:"\f102";
}

.bp5-icon-add-column-right::before{
  content:"\f103";
}

.bp5-icon-add-location::before{
  content:"\f104";
}

.bp5-icon-add-row-bottom::before{
  content:"\f105";
}

.bp5-icon-add-row-top::before{
  content:"\f106";
}

.bp5-icon-add-to-artifact::before{
  content:"\f107";
}

.bp5-icon-add-to-folder::before{
  content:"\f108";
}

.bp5-icon-aimpoints-target::before{
  content:"\f335";
}

.bp5-icon-airplane::before{
  content:"\f10a";
}

.bp5-icon-align-center::before{
  content:"\f10b";
}

.bp5-icon-align-justify::before{
  content:"\f10c";
}

.bp5-icon-align-left::before{
  content:"\f10d";
}

.bp5-icon-align-right::before{
  content:"\f10e";
}

.bp5-icon-alignment-bottom::before{
  content:"\f10f";
}

.bp5-icon-alignment-horizontal-center::before{
  content:"\f110";
}

.bp5-icon-alignment-left::before{
  content:"\f111";
}

.bp5-icon-alignment-right::before{
  content:"\f112";
}

.bp5-icon-alignment-top::before{
  content:"\f113";
}

.bp5-icon-alignment-vertical-center::before{
  content:"\f114";
}

.bp5-icon-ammunition::before{
  content:"\f342";
}

.bp5-icon-anchor::before{
  content:"\f330";
}

.bp5-icon-annotation::before{
  content:"\f115";
}

.bp5-icon-antenna::before{
  content:"\f116";
}

.bp5-icon-app-header::before{
  content:"\f117";
}

.bp5-icon-application::before{
  content:"\f118";
}

.bp5-icon-applications::before{
  content:"\f119";
}

.bp5-icon-archive::before{
  content:"\f11a";
}

.bp5-icon-area-of-interest::before{
  content:"\f11b";
}

.bp5-icon-array::before{
  content:"\f121";
}

.bp5-icon-array-boolean::before{
  content:"\f11c";
}

.bp5-icon-array-date::before{
  content:"\f11d";
}

.bp5-icon-array-floating-point::before{
  content:"\f32d";
}

.bp5-icon-array-numeric::before{
  content:"\f11e";
}

.bp5-icon-array-string::before{
  content:"\f11f";
}

.bp5-icon-array-timestamp::before{
  content:"\f120";
}

.bp5-icon-arrow-bottom-left::before{
  content:"\f122";
}

.bp5-icon-arrow-bottom-right::before{
  content:"\f123";
}

.bp5-icon-arrow-down::before{
  content:"\f124";
}

.bp5-icon-arrow-left::before{
  content:"\f125";
}

.bp5-icon-arrow-right::before{
  content:"\f126";
}

.bp5-icon-arrow-top-left::before{
  content:"\f127";
}

.bp5-icon-arrow-top-right::before{
  content:"\f128";
}

.bp5-icon-arrow-up::before{
  content:"\f129";
}

.bp5-icon-arrows-horizontal::before{
  content:"\f12a";
}

.bp5-icon-arrows-vertical::before{
  content:"\f12b";
}

.bp5-icon-asterisk::before{
  content:"\f12c";
}

.bp5-icon-at::before{
  content:"\f331";
}

.bp5-icon-automatic-updates::before{
  content:"\f12d";
}

.bp5-icon-axle::before{
  content:"\f338";
}

.bp5-icon-backlink::before{
  content:"\f12e";
}

.bp5-icon-backward-ten::before{
  content:"\f35c";
}

.bp5-icon-badge::before{
  content:"\f12f";
}

.bp5-icon-ban-circle::before{
  content:"\f130";
}

.bp5-icon-bank-account::before{
  content:"\f131";
}

.bp5-icon-barcode::before{
  content:"\f132";
}

.bp5-icon-binary-number::before{
  content:"\f357";
}

.bp5-icon-blank::before{
  content:"\f133";
}

.bp5-icon-blocked-person::before{
  content:"\f134";
}

.bp5-icon-bold::before{
  content:"\f135";
}

.bp5-icon-book::before{
  content:"\f136";
}

.bp5-icon-bookmark::before{
  content:"\f137";
}

.bp5-icon-box::before{
  content:"\f138";
}

.bp5-icon-briefcase::before{
  content:"\f139";
}

.bp5-icon-bring-data::before{
  content:"\f13a";
}

.bp5-icon-bring-forward::before{
  content:"\f354";
}

.bp5-icon-bug::before{
  content:"\f32e";
}

.bp5-icon-buggy::before{
  content:"\f13b";
}

.bp5-icon-build::before{
  content:"\f13c";
}

.bp5-icon-bullseye::before{
  content:"\f359";
}

.bp5-icon-calculator::before{
  content:"\f13d";
}

.bp5-icon-calendar::before{
  content:"\f13e";
}

.bp5-icon-camera::before{
  content:"\f13f";
}

.bp5-icon-caret-down::before{
  content:"\f140";
}

.bp5-icon-caret-left::before{
  content:"\f141";
}

.bp5-icon-caret-right::before{
  content:"\f142";
}

.bp5-icon-caret-up::before{
  content:"\f143";
}

.bp5-icon-cargo-ship::before{
  content:"\f144";
}

.bp5-icon-cell-tower::before{
  content:"\f145";
}

.bp5-icon-changes::before{
  content:"\f146";
}

.bp5-icon-chart::before{
  content:"\f147";
}

.bp5-icon-chat::before{
  content:"\f148";
}

.bp5-icon-chevron-backward::before{
  content:"\f149";
}

.bp5-icon-chevron-down::before{
  content:"\f14a";
}

.bp5-icon-chevron-forward::before{
  content:"\f14b";
}

.bp5-icon-chevron-left::before{
  content:"\f14c";
}

.bp5-icon-chevron-right::before{
  content:"\f14d";
}

.bp5-icon-chevron-up::before{
  content:"\f14e";
}

.bp5-icon-circle::before{
  content:"\f153";
}

.bp5-icon-circle-arrow-down::before{
  content:"\f14f";
}

.bp5-icon-circle-arrow-left::before{
  content:"\f150";
}

.bp5-icon-circle-arrow-right::before{
  content:"\f151";
}

.bp5-icon-circle-arrow-up::before{
  content:"\f152";
}

.bp5-icon-citation::before{
  content:"\f154";
}

.bp5-icon-clean::before{
  content:"\f155";
}

.bp5-icon-clip::before{
  content:"\f156";
}

.bp5-icon-clipboard::before{
  content:"\f157";
}

.bp5-icon-clipboard-file::before{
  content:"\f35b";
}

.bp5-icon-cloud::before{
  content:"\f15a";
}

.bp5-icon-cloud-download::before{
  content:"\f158";
}

.bp5-icon-cloud-server::before{
  content:"\f35a";
}

.bp5-icon-cloud-tick::before{
  content:"\f34e";
}

.bp5-icon-cloud-upload::before{
  content:"\f159";
}

.bp5-icon-code::before{
  content:"\f15c";
}

.bp5-icon-code-block::before{
  content:"\f15b";
}

.bp5-icon-cog::before{
  content:"\f15d";
}

.bp5-icon-collapse-all::before{
  content:"\f15e";
}

.bp5-icon-color-fill::before{
  content:"\f328";
}

.bp5-icon-column-layout::before{
  content:"\f15f";
}

.bp5-icon-comment::before{
  content:"\f160";
}

.bp5-icon-comparison::before{
  content:"\f161";
}

.bp5-icon-compass::before{
  content:"\f162";
}

.bp5-icon-compressed::before{
  content:"\f163";
}

.bp5-icon-confirm::before{
  content:"\f164";
}

.bp5-icon-console::before{
  content:"\f165";
}

.bp5-icon-contrast::before{
  content:"\f166";
}

.bp5-icon-control::before{
  content:"\f167";
}

.bp5-icon-credit-card::before{
  content:"\f168";
}

.bp5-icon-crop::before{
  content:"\f353";
}

.bp5-icon-cross::before{
  content:"\f169";
}

.bp5-icon-cross-circle::before{
  content:"\f336";
}

.bp5-icon-crown::before{
  content:"\f16a";
}

.bp5-icon-css-style::before{
  content:"\f36b";
}

.bp5-icon-cube::before{
  content:"\f16d";
}

.bp5-icon-cube-add::before{
  content:"\f16b";
}

.bp5-icon-cube-remove::before{
  content:"\f16c";
}

.bp5-icon-curly-braces::before{
  content:"\f358";
}

.bp5-icon-curved-range-chart::before{
  content:"\f16e";
}

.bp5-icon-cut::before{
  content:"\f16f";
}

.bp5-icon-cycle::before{
  content:"\f170";
}

.bp5-icon-dashboard::before{
  content:"\f171";
}

.bp5-icon-data-connection::before{
  content:"\f172";
}

.bp5-icon-data-lineage::before{
  content:"\f173";
}

.bp5-icon-data-search::before{
  content:"\f36f";
}

.bp5-icon-data-sync::before{
  content:"\f36c";
}

.bp5-icon-database::before{
  content:"\f174";
}

.bp5-icon-delete::before{
  content:"\f175";
}

.bp5-icon-delta::before{
  content:"\f176";
}

.bp5-icon-derive-column::before{
  content:"\f177";
}

.bp5-icon-desktop::before{
  content:"\f178";
}

.bp5-icon-detection::before{
  content:"\f341";
}

.bp5-icon-diagnosis::before{
  content:"\f179";
}

.bp5-icon-diagram-tree::before{
  content:"\f17a";
}

.bp5-icon-direction-left::before{
  content:"\f17b";
}

.bp5-icon-direction-right::before{
  content:"\f17c";
}

.bp5-icon-disable::before{
  content:"\f17d";
}

.bp5-icon-divide::before{
  content:"\f327";
}

.bp5-icon-document::before{
  content:"\f180";
}

.bp5-icon-document-open::before{
  content:"\f17e";
}

.bp5-icon-document-share::before{
  content:"\f17f";
}

.bp5-icon-dollar::before{
  content:"\f181";
}

.bp5-icon-dot::before{
  content:"\f182";
}

.bp5-icon-double-caret-horizontal::before{
  content:"\f183";
}

.bp5-icon-double-caret-vertical::before{
  content:"\f184";
}

.bp5-icon-double-chevron-down::before{
  content:"\f185";
}

.bp5-icon-double-chevron-left::before{
  content:"\f186";
}

.bp5-icon-double-chevron-right::before{
  content:"\f187";
}

.bp5-icon-double-chevron-up::before{
  content:"\f188";
}

.bp5-icon-doughnut-chart::before{
  content:"\f189";
}

.bp5-icon-download::before{
  content:"\f18a";
}

.bp5-icon-drag-handle-horizontal::before{
  content:"\f18b";
}

.bp5-icon-drag-handle-vertical::before{
  content:"\f18c";
}

.bp5-icon-draw::before{
  content:"\f18d";
}

.bp5-icon-drawer-left::before{
  content:"\f18f";
}

.bp5-icon-drawer-left-filled::before{
  content:"\f18e";
}

.bp5-icon-drawer-right::before{
  content:"\f191";
}

.bp5-icon-drawer-right-filled::before{
  content:"\f190";
}

.bp5-icon-drive-time::before{
  content:"\f192";
}

.bp5-icon-duplicate::before{
  content:"\f193";
}

.bp5-icon-edit::before{
  content:"\f194";
}

.bp5-icon-eject::before{
  content:"\f195";
}

.bp5-icon-emoji::before{
  content:"\f196";
}

.bp5-icon-endnote::before{
  content:"\f356";
}

.bp5-icon-endorsed::before{
  content:"\f197";
}

.bp5-icon-envelope::before{
  content:"\f198";
}

.bp5-icon-equals::before{
  content:"\f199";
}

.bp5-icon-eraser::before{
  content:"\f19a";
}

.bp5-icon-error::before{
  content:"\f19b";
}

.bp5-icon-euro::before{
  content:"\f19c";
}

.bp5-icon-excavator::before{
  content:"\f36d";
}

.bp5-icon-exchange::before{
  content:"\f19d";
}

.bp5-icon-exclude-row::before{
  content:"\f19e";
}

.bp5-icon-expand-all::before{
  content:"\f19f";
}

.bp5-icon-explain::before{
  content:"\f34d";
}

.bp5-icon-export::before{
  content:"\f1a0";
}

.bp5-icon-eye-off::before{
  content:"\f1a1";
}

.bp5-icon-eye-on::before{
  content:"\f1a2";
}

.bp5-icon-eye-open::before{
  content:"\f1a3";
}

.bp5-icon-fast-backward::before{
  content:"\f1a4";
}

.bp5-icon-fast-forward::before{
  content:"\f1a5";
}

.bp5-icon-feed::before{
  content:"\f1a7";
}

.bp5-icon-feed-subscribed::before{
  content:"\f1a6";
}

.bp5-icon-film::before{
  content:"\f1a8";
}

.bp5-icon-filter::before{
  content:"\f1ad";
}

.bp5-icon-filter-keep::before{
  content:"\f1a9";
}

.bp5-icon-filter-list::before{
  content:"\f1aa";
}

.bp5-icon-filter-open::before{
  content:"\f1ab";
}

.bp5-icon-filter-remove::before{
  content:"\f1ac";
}

.bp5-icon-flag::before{
  content:"\f1ae";
}

.bp5-icon-flame::before{
  content:"\f1af";
}

.bp5-icon-flash::before{
  content:"\f1b0";
}

.bp5-icon-floating-point::before{
  content:"\f32c";
}

.bp5-icon-floppy-disk::before{
  content:"\f1b1";
}

.bp5-icon-flow-branch::before{
  content:"\f1b2";
}

.bp5-icon-flow-end::before{
  content:"\f1b3";
}

.bp5-icon-flow-linear::before{
  content:"\f1b4";
}

.bp5-icon-flow-review::before{
  content:"\f1b6";
}

.bp5-icon-flow-review-branch::before{
  content:"\f1b5";
}

.bp5-icon-flows::before{
  content:"\f1b7";
}

.bp5-icon-folder-close::before{
  content:"\f1b8";
}

.bp5-icon-folder-new::before{
  content:"\f1b9";
}

.bp5-icon-folder-open::before{
  content:"\f1ba";
}

.bp5-icon-folder-shared::before{
  content:"\f1bc";
}

.bp5-icon-folder-shared-open::before{
  content:"\f1bb";
}

.bp5-icon-follower::before{
  content:"\f1bd";
}

.bp5-icon-following::before{
  content:"\f1be";
}

.bp5-icon-font::before{
  content:"\f1bf";
}

.bp5-icon-fork::before{
  content:"\f1c0";
}

.bp5-icon-form::before{
  content:"\f1c1";
}

.bp5-icon-forward-ten::before{
  content:"\f35d";
}

.bp5-icon-fuel::before{
  content:"\f323";
}

.bp5-icon-full-circle::before{
  content:"\f1c2";
}

.bp5-icon-full-stacked-chart::before{
  content:"\f1c3";
}

.bp5-icon-fullscreen::before{
  content:"\f1c4";
}

.bp5-icon-function::before{
  content:"\f1c5";
}

.bp5-icon-gantt-chart::before{
  content:"\f1c6";
}

.bp5-icon-generate::before{
  content:"\f34c";
}

.bp5-icon-geofence::before{
  content:"\f1c7";
}

.bp5-icon-geolocation::before{
  content:"\f1c8";
}

.bp5-icon-geosearch::before{
  content:"\f1c9";
}

.bp5-icon-geotime::before{
  content:"\f344";
}

.bp5-icon-git-branch::before{
  content:"\f1ca";
}

.bp5-icon-git-commit::before{
  content:"\f1cb";
}

.bp5-icon-git-merge::before{
  content:"\f1cc";
}

.bp5-icon-git-new-branch::before{
  content:"\f1cd";
}

.bp5-icon-git-pull::before{
  content:"\f1ce";
}

.bp5-icon-git-push::before{
  content:"\f1cf";
}

.bp5-icon-git-repo::before{
  content:"\f1d0";
}

.bp5-icon-glass::before{
  content:"\f1d1";
}

.bp5-icon-globe::before{
  content:"\f1d3";
}

.bp5-icon-globe-network::before{
  content:"\f1d2";
}

.bp5-icon-graph::before{
  content:"\f1d5";
}

.bp5-icon-graph-remove::before{
  content:"\f1d4";
}

.bp5-icon-greater-than::before{
  content:"\f1d7";
}

.bp5-icon-greater-than-or-equal-to::before{
  content:"\f1d6";
}

.bp5-icon-grid::before{
  content:"\f1d9";
}

.bp5-icon-grid-view::before{
  content:"\f1d8";
}

.bp5-icon-group-item::before{
  content:"\f34a";
}

.bp5-icon-group-objects::before{
  content:"\f1da";
}

.bp5-icon-grouped-bar-chart::before{
  content:"\f1db";
}

.bp5-icon-hand::before{
  content:"\f1e0";
}

.bp5-icon-hand-down::before{
  content:"\f1dc";
}

.bp5-icon-hand-left::before{
  content:"\f1dd";
}

.bp5-icon-hand-right::before{
  content:"\f1de";
}

.bp5-icon-hand-up::before{
  content:"\f1df";
}

.bp5-icon-hat::before{
  content:"\f1e1";
}

.bp5-icon-header::before{
  content:"\f1e5";
}

.bp5-icon-header-one::before{
  content:"\f1e2";
}

.bp5-icon-header-three::before{
  content:"\f1e3";
}

.bp5-icon-header-two::before{
  content:"\f1e4";
}

.bp5-icon-headset::before{
  content:"\f1e6";
}

.bp5-icon-heart::before{
  content:"\f1e8";
}

.bp5-icon-heart-broken::before{
  content:"\f1e7";
}

.bp5-icon-heat-grid::before{
  content:"\f1e9";
}

.bp5-icon-heatmap::before{
  content:"\f1ea";
}

.bp5-icon-helicopter::before{
  content:"\f1eb";
}

.bp5-icon-help::before{
  content:"\f1ec";
}

.bp5-icon-helper-management::before{
  content:"\f1ed";
}

.bp5-icon-high-priority::before{
  content:"\f1ee";
}

.bp5-icon-high-voltage-pole::before{
  content:"\f333";
}

.bp5-icon-highlight::before{
  content:"\f1ef";
}

.bp5-icon-history::before{
  content:"\f1f0";
}

.bp5-icon-home::before{
  content:"\f1f1";
}

.bp5-icon-horizontal-bar-chart::before{
  content:"\f1f4";
}

.bp5-icon-horizontal-bar-chart-asc::before{
  content:"\f1f2";
}

.bp5-icon-horizontal-bar-chart-desc::before{
  content:"\f1f3";
}

.bp5-icon-horizontal-distribution::before{
  content:"\f1f5";
}

.bp5-icon-horizontal-inbetween::before{
  content:"\f329";
}

.bp5-icon-hurricane::before{
  content:"\f1f6";
}

.bp5-icon-id-number::before{
  content:"\f1f7";
}

.bp5-icon-image-rotate-left::before{
  content:"\f1f8";
}

.bp5-icon-image-rotate-right::before{
  content:"\f1f9";
}

.bp5-icon-import::before{
  content:"\f1fa";
}

.bp5-icon-inbox::before{
  content:"\f1ff";
}

.bp5-icon-inbox-filtered::before{
  content:"\f1fb";
}

.bp5-icon-inbox-geo::before{
  content:"\f1fc";
}

.bp5-icon-inbox-search::before{
  content:"\f1fd";
}

.bp5-icon-inbox-update::before{
  content:"\f1fe";
}

.bp5-icon-info-sign::before{
  content:"\f200";
}

.bp5-icon-inheritance::before{
  content:"\f201";
}

.bp5-icon-inherited-group::before{
  content:"\f202";
}

.bp5-icon-inner-join::before{
  content:"\f203";
}

.bp5-icon-input::before{
  content:"\f34b";
}

.bp5-icon-insert::before{
  content:"\f204";
}

.bp5-icon-intelligence::before{
  content:"\f337";
}

.bp5-icon-intersection::before{
  content:"\f205";
}

.bp5-icon-ip-address::before{
  content:"\f206";
}

.bp5-icon-issue::before{
  content:"\f209";
}

.bp5-icon-issue-closed::before{
  content:"\f207";
}

.bp5-icon-issue-new::before{
  content:"\f208";
}

.bp5-icon-italic::before{
  content:"\f20a";
}

.bp5-icon-join-table::before{
  content:"\f20b";
}

.bp5-icon-key::before{
  content:"\f215";
}

.bp5-icon-key-backspace::before{
  content:"\f20c";
}

.bp5-icon-key-command::before{
  content:"\f20d";
}

.bp5-icon-key-control::before{
  content:"\f20e";
}

.bp5-icon-key-delete::before{
  content:"\f20f";
}

.bp5-icon-key-enter::before{
  content:"\f210";
}

.bp5-icon-key-escape::before{
  content:"\f211";
}

.bp5-icon-key-option::before{
  content:"\f212";
}

.bp5-icon-key-shift::before{
  content:"\f213";
}

.bp5-icon-key-tab::before{
  content:"\f214";
}

.bp5-icon-known-vehicle::before{
  content:"\f216";
}

.bp5-icon-lab-test::before{
  content:"\f217";
}

.bp5-icon-label::before{
  content:"\f218";
}

.bp5-icon-layer::before{
  content:"\f21a";
}

.bp5-icon-layer-outline::before{
  content:"\f219";
}

.bp5-icon-layers::before{
  content:"\f21b";
}

.bp5-icon-layout::before{
  content:"\f225";
}

.bp5-icon-layout-auto::before{
  content:"\f21c";
}

.bp5-icon-layout-balloon::before{
  content:"\f21d";
}

.bp5-icon-layout-bottom-row-three-tiles::before{
  content:"\f364";
}

.bp5-icon-layout-bottom-row-two-tiles::before{
  content:"\f363";
}

.bp5-icon-layout-circle::before{
  content:"\f21e";
}

.bp5-icon-layout-grid::before{
  content:"\f21f";
}

.bp5-icon-layout-group-by::before{
  content:"\f220";
}

.bp5-icon-layout-hierarchy::before{
  content:"\f221";
}

.bp5-icon-layout-left-column-three-tiles::before{
  content:"\f366";
}

.bp5-icon-layout-left-column-two-tiles::before{
  content:"\f365";
}

.bp5-icon-layout-linear::before{
  content:"\f222";
}

.bp5-icon-layout-right-column-three-tiles::before{
  content:"\f368";
}

.bp5-icon-layout-right-column-two-tiles::before{
  content:"\f367";
}

.bp5-icon-layout-skew-grid::before{
  content:"\f223";
}

.bp5-icon-layout-sorted-clusters::before{
  content:"\f224";
}

.bp5-icon-layout-three-columns::before{
  content:"\f361";
}

.bp5-icon-layout-three-rows::before{
  content:"\f362";
}

.bp5-icon-layout-top-row-three-tiles::before{
  content:"\f36a";
}

.bp5-icon-layout-top-row-two-tiles::before{
  content:"\f369";
}

.bp5-icon-layout-two-columns::before{
  content:"\f35f";
}

.bp5-icon-layout-two-rows::before{
  content:"\f360";
}

.bp5-icon-learning::before{
  content:"\f226";
}

.bp5-icon-left-join::before{
  content:"\f227";
}

.bp5-icon-lengthen-text::before{
  content:"\f33e";
}

.bp5-icon-less-than::before{
  content:"\f229";
}

.bp5-icon-less-than-or-equal-to::before{
  content:"\f228";
}

.bp5-icon-lifesaver::before{
  content:"\f22a";
}

.bp5-icon-lightbulb::before{
  content:"\f22b";
}

.bp5-icon-lightning::before{
  content:"\f22c";
}

.bp5-icon-link::before{
  content:"\f22d";
}

.bp5-icon-list::before{
  content:"\f230";
}

.bp5-icon-list-columns::before{
  content:"\f22e";
}

.bp5-icon-list-detail-view::before{
  content:"\f22f";
}

.bp5-icon-locate::before{
  content:"\f231";
}

.bp5-icon-lock::before{
  content:"\f232";
}

.bp5-icon-locomotive::before{
  content:"\f33b";
}

.bp5-icon-log-in::before{
  content:"\f233";
}

.bp5-icon-log-out::before{
  content:"\f234";
}

.bp5-icon-low-voltage-pole::before{
  content:"\f332";
}

.bp5-icon-manual::before{
  content:"\f235";
}

.bp5-icon-manually-entered-data::before{
  content:"\f236";
}

.bp5-icon-many-to-many::before{
  content:"\f237";
}

.bp5-icon-many-to-one::before{
  content:"\f238";
}

.bp5-icon-map::before{
  content:"\f23b";
}

.bp5-icon-map-create::before{
  content:"\f239";
}

.bp5-icon-map-marker::before{
  content:"\f23a";
}

.bp5-icon-maximize::before{
  content:"\f23c";
}

.bp5-icon-media::before{
  content:"\f23d";
}

.bp5-icon-menu::before{
  content:"\f240";
}

.bp5-icon-menu-closed::before{
  content:"\f23e";
}

.bp5-icon-menu-open::before{
  content:"\f23f";
}

.bp5-icon-merge-columns::before{
  content:"\f241";
}

.bp5-icon-merge-links::before{
  content:"\f242";
}

.bp5-icon-microphone::before{
  content:"\f343";
}

.bp5-icon-minimize::before{
  content:"\f243";
}

.bp5-icon-minus::before{
  content:"\f244";
}

.bp5-icon-mobile-phone::before{
  content:"\f245";
}

.bp5-icon-mobile-video::before{
  content:"\f246";
}

.bp5-icon-modal::before{
  content:"\f248";
}

.bp5-icon-modal-filled::before{
  content:"\f247";
}

.bp5-icon-model::before{
  content:"\f33d";
}

.bp5-icon-moon::before{
  content:"\f249";
}

.bp5-icon-more::before{
  content:"\f24a";
}

.bp5-icon-mountain::before{
  content:"\f24b";
}

.bp5-icon-move::before{
  content:"\f24c";
}

.bp5-icon-mugshot::before{
  content:"\f24d";
}

.bp5-icon-multi-select::before{
  content:"\f24e";
}

.bp5-icon-music::before{
  content:"\f24f";
}

.bp5-icon-nest::before{
  content:"\f250";
}

.bp5-icon-new-drawing::before{
  content:"\f251";
}

.bp5-icon-new-grid-item::before{
  content:"\f252";
}

.bp5-icon-new-layer::before{
  content:"\f253";
}

.bp5-icon-new-layers::before{
  content:"\f254";
}

.bp5-icon-new-link::before{
  content:"\f255";
}

.bp5-icon-new-object::before{
  content:"\f256";
}

.bp5-icon-new-person::before{
  content:"\f257";
}

.bp5-icon-new-prescription::before{
  content:"\f258";
}

.bp5-icon-new-shield::before{
  content:"\f349";
}

.bp5-icon-new-text-box::before{
  content:"\f259";
}

.bp5-icon-ninja::before{
  content:"\f25a";
}

.bp5-icon-not-equal-to::before{
  content:"\f25b";
}

.bp5-icon-notifications::before{
  content:"\f25e";
}

.bp5-icon-notifications-snooze::before{
  content:"\f25c";
}

.bp5-icon-notifications-updated::before{
  content:"\f25d";
}

.bp5-icon-numbered-list::before{
  content:"\f25f";
}

.bp5-icon-numerical::before{
  content:"\f260";
}

.bp5-icon-office::before{
  content:"\f261";
}

.bp5-icon-offline::before{
  content:"\f262";
}

.bp5-icon-oil-field::before{
  content:"\f263";
}

.bp5-icon-one-column::before{
  content:"\f264";
}

.bp5-icon-one-to-many::before{
  content:"\f265";
}

.bp5-icon-one-to-one::before{
  content:"\f266";
}

.bp5-icon-open-application::before{
  content:"\f32b";
}

.bp5-icon-outdated::before{
  content:"\f267";
}

.bp5-icon-output::before{
  content:"\f370";
}

.bp5-icon-page-layout::before{
  content:"\f268";
}

.bp5-icon-panel-stats::before{
  content:"\f269";
}

.bp5-icon-panel-table::before{
  content:"\f26a";
}

.bp5-icon-paperclip::before{
  content:"\f26b";
}

.bp5-icon-paragraph::before{
  content:"\f26c";
}

.bp5-icon-paste-variable::before{
  content:"\f346";
}

.bp5-icon-path::before{
  content:"\f26e";
}

.bp5-icon-path-search::before{
  content:"\f26d";
}

.bp5-icon-pause::before{
  content:"\f26f";
}

.bp5-icon-people::before{
  content:"\f270";
}

.bp5-icon-percentage::before{
  content:"\f271";
}

.bp5-icon-person::before{
  content:"\f272";
}

.bp5-icon-phone::before{
  content:"\f273";
}

.bp5-icon-phone-call::before{
  content:"\f347";
}

.bp5-icon-phone-forward::before{
  content:"\f348";
}

.bp5-icon-pie-chart::before{
  content:"\f274";
}

.bp5-icon-pin::before{
  content:"\f275";
}

.bp5-icon-pivot::before{
  content:"\f277";
}

.bp5-icon-pivot-table::before{
  content:"\f276";
}

.bp5-icon-play::before{
  content:"\f278";
}

.bp5-icon-playbook::before{
  content:"\f324";
}

.bp5-icon-plus::before{
  content:"\f279";
}

.bp5-icon-polygon-filter::before{
  content:"\f27a";
}

.bp5-icon-power::before{
  content:"\f27b";
}

.bp5-icon-predictive-analysis::before{
  content:"\f27c";
}

.bp5-icon-prescription::before{
  content:"\f27d";
}

.bp5-icon-presentation::before{
  content:"\f27e";
}

.bp5-icon-print::before{
  content:"\f27f";
}

.bp5-icon-projects::before{
  content:"\f280";
}

.bp5-icon-properties::before{
  content:"\f281";
}

.bp5-icon-property::before{
  content:"\f282";
}

.bp5-icon-publish-function::before{
  content:"\f283";
}

.bp5-icon-pulse::before{
  content:"\f284";
}

.bp5-icon-rain::before{
  content:"\f285";
}

.bp5-icon-random::before{
  content:"\f286";
}

.bp5-icon-range-ring::before{
  content:"\f371";
}

.bp5-icon-record::before{
  content:"\f287";
}

.bp5-icon-rect-height::before{
  content:"\f325";
}

.bp5-icon-rect-width::before{
  content:"\f326";
}

.bp5-icon-rectangle::before{
  content:"\f321";
}

.bp5-icon-redo::before{
  content:"\f288";
}

.bp5-icon-refresh::before{
  content:"\f289";
}

.bp5-icon-regex::before{
  content:"\f32f";
}

.bp5-icon-regression-chart::before{
  content:"\f28a";
}

.bp5-icon-remove::before{
  content:"\f290";
}

.bp5-icon-remove-column::before{
  content:"\f28d";
}

.bp5-icon-remove-column-left::before{
  content:"\f28b";
}

.bp5-icon-remove-column-right::before{
  content:"\f28c";
}

.bp5-icon-remove-row-bottom::before{
  content:"\f28e";
}

.bp5-icon-remove-row-top::before{
  content:"\f28f";
}

.bp5-icon-repeat::before{
  content:"\f291";
}

.bp5-icon-reset::before{
  content:"\f292";
}

.bp5-icon-resolve::before{
  content:"\f293";
}

.bp5-icon-rig::before{
  content:"\f294";
}

.bp5-icon-right-join::before{
  content:"\f295";
}

.bp5-icon-ring::before{
  content:"\f296";
}

.bp5-icon-rocket::before{
  content:"\f298";
}

.bp5-icon-rocket-slant::before{
  content:"\f297";
}

.bp5-icon-rotate-document::before{
  content:"\f299";
}

.bp5-icon-rotate-page::before{
  content:"\f29a";
}

.bp5-icon-route::before{
  content:"\f29b";
}

.bp5-icon-satellite::before{
  content:"\f29c";
}

.bp5-icon-saved::before{
  content:"\f29d";
}

.bp5-icon-scatter-plot::before{
  content:"\f29e";
}

.bp5-icon-search::before{
  content:"\f2a2";
}

.bp5-icon-search-around::before{
  content:"\f29f";
}

.bp5-icon-search-template::before{
  content:"\f2a0";
}

.bp5-icon-search-text::before{
  content:"\f2a1";
}

.bp5-icon-segmented-control::before{
  content:"\f2a3";
}

.bp5-icon-select::before{
  content:"\f2a4";
}

.bp5-icon-selection::before{
  content:"\f2a5";
}

.bp5-icon-send-backward::before{
  content:"\f355";
}

.bp5-icon-send-message::before{
  content:"\f2a6";
}

.bp5-icon-send-to::before{
  content:"\f2a9";
}

.bp5-icon-send-to-graph::before{
  content:"\f2a7";
}

.bp5-icon-send-to-map::before{
  content:"\f2a8";
}

.bp5-icon-sensor::before{
  content:"\f33c";
}

.bp5-icon-series-add::before{
  content:"\f2aa";
}

.bp5-icon-series-configuration::before{
  content:"\f2ab";
}

.bp5-icon-series-derived::before{
  content:"\f2ac";
}

.bp5-icon-series-filtered::before{
  content:"\f2ad";
}

.bp5-icon-series-search::before{
  content:"\f2ae";
}

.bp5-icon-settings::before{
  content:"\f2af";
}

.bp5-icon-shapes::before{
  content:"\f2b0";
}

.bp5-icon-share::before{
  content:"\f2b1";
}

.bp5-icon-shared-filter::before{
  content:"\f2b2";
}

.bp5-icon-shield::before{
  content:"\f2b3";
}

.bp5-icon-ship::before{
  content:"\f2b4";
}

.bp5-icon-shop::before{
  content:"\f2b5";
}

.bp5-icon-shopping-cart::before{
  content:"\f2b6";
}

.bp5-icon-shorten-text::before{
  content:"\f33f";
}

.bp5-icon-signal-search::before{
  content:"\f2b7";
}

.bp5-icon-sim-card::before{
  content:"\f2b8";
}

.bp5-icon-slash::before{
  content:"\f2b9";
}

.bp5-icon-small-cross::before{
  content:"\f2ba";
}

.bp5-icon-small-info-sign::before{
  content:"\f334";
}

.bp5-icon-small-minus::before{
  content:"\f2bb";
}

.bp5-icon-small-plus::before{
  content:"\f2bc";
}

.bp5-icon-small-square::before{
  content:"\f2bd";
}

.bp5-icon-small-tick::before{
  content:"\f2be";
}

.bp5-icon-snowflake::before{
  content:"\f2bf";
}

.bp5-icon-soccer-ball::before{
  content:"\f350";
}

.bp5-icon-social-media::before{
  content:"\f2c0";
}

.bp5-icon-sort::before{
  content:"\f2c7";
}

.bp5-icon-sort-alphabetical::before{
  content:"\f2c2";
}

.bp5-icon-sort-alphabetical-desc::before{
  content:"\f2c1";
}

.bp5-icon-sort-asc::before{
  content:"\f2c3";
}

.bp5-icon-sort-desc::before{
  content:"\f2c4";
}

.bp5-icon-sort-numerical::before{
  content:"\f2c6";
}

.bp5-icon-sort-numerical-desc::before{
  content:"\f2c5";
}

.bp5-icon-spell-check::before{
  content:"\f340";
}

.bp5-icon-split-columns::before{
  content:"\f2c8";
}

.bp5-icon-sports-stadium::before{
  content:"\f351";
}

.bp5-icon-square::before{
  content:"\f2c9";
}

.bp5-icon-stacked-chart::before{
  content:"\f2ca";
}

.bp5-icon-stadium-geometry::before{
  content:"\f2cb";
}

.bp5-icon-star::before{
  content:"\f2cd";
}

.bp5-icon-star-empty::before{
  content:"\f2cc";
}

.bp5-icon-step-backward::before{
  content:"\f2ce";
}

.bp5-icon-step-chart::before{
  content:"\f2cf";
}

.bp5-icon-step-forward::before{
  content:"\f2d0";
}

.bp5-icon-stop::before{
  content:"\f2d1";
}

.bp5-icon-stopwatch::before{
  content:"\f2d2";
}

.bp5-icon-strikethrough::before{
  content:"\f2d3";
}

.bp5-icon-style::before{
  content:"\f2d4";
}

.bp5-icon-subscript::before{
  content:"\f339";
}

.bp5-icon-superscript::before{
  content:"\f33a";
}

.bp5-icon-swap-horizontal::before{
  content:"\f2d5";
}

.bp5-icon-swap-vertical::before{
  content:"\f2d6";
}

.bp5-icon-switch::before{
  content:"\f2d7";
}

.bp5-icon-symbol-circle::before{
  content:"\f2d8";
}

.bp5-icon-symbol-cross::before{
  content:"\f2d9";
}

.bp5-icon-symbol-diamond::before{
  content:"\f2da";
}

.bp5-icon-symbol-rectangle::before{
  content:"\f322";
}

.bp5-icon-symbol-square::before{
  content:"\f2db";
}

.bp5-icon-symbol-triangle-down::before{
  content:"\f2dc";
}

.bp5-icon-symbol-triangle-up::before{
  content:"\f2dd";
}

.bp5-icon-syringe::before{
  content:"\f2de";
}

.bp5-icon-table-sync::before{
  content:"\f36e";
}

.bp5-icon-tag::before{
  content:"\f2df";
}

.bp5-icon-take-action::before{
  content:"\f2e0";
}

.bp5-icon-tank::before{
  content:"\f2e1";
}

.bp5-icon-target::before{
  content:"\f2e2";
}

.bp5-icon-taxi::before{
  content:"\f2e3";
}

.bp5-icon-team::before{
  content:"\f352";
}

.bp5-icon-temperature::before{
  content:"\f2e4";
}

.bp5-icon-text-highlight::before{
  content:"\f2e5";
}

.bp5-icon-th::before{
  content:"\f2ea";
}

.bp5-icon-th-derived::before{
  content:"\f2e6";
}

.bp5-icon-th-disconnect::before{
  content:"\f2e7";
}

.bp5-icon-th-filtered::before{
  content:"\f2e8";
}

.bp5-icon-th-list::before{
  content:"\f2e9";
}

.bp5-icon-third-party::before{
  content:"\f2eb";
}

.bp5-icon-thumbs-down::before{
  content:"\f2ec";
}

.bp5-icon-thumbs-up::before{
  content:"\f2ed";
}

.bp5-icon-tick::before{
  content:"\f2ef";
}

.bp5-icon-tick-circle::before{
  content:"\f2ee";
}

.bp5-icon-time::before{
  content:"\f2f0";
}

.bp5-icon-timeline-area-chart::before{
  content:"\f2f1";
}

.bp5-icon-timeline-bar-chart::before{
  content:"\f2f2";
}

.bp5-icon-timeline-events::before{
  content:"\f2f3";
}

.bp5-icon-timeline-line-chart::before{
  content:"\f2f4";
}

.bp5-icon-tint::before{
  content:"\f2f5";
}

.bp5-icon-torch::before{
  content:"\f2f6";
}

.bp5-icon-tractor::before{
  content:"\f2f7";
}

.bp5-icon-train::before{
  content:"\f2f8";
}

.bp5-icon-translate::before{
  content:"\f2f9";
}

.bp5-icon-trash::before{
  content:"\f2fa";
}

.bp5-icon-tree::before{
  content:"\f2fb";
}

.bp5-icon-trending-down::before{
  content:"\f2fc";
}

.bp5-icon-trending-up::before{
  content:"\f2fd";
}

.bp5-icon-trophy::before{
  content:"\f34f";
}

.bp5-icon-truck::before{
  content:"\f2fe";
}

.bp5-icon-two-columns::before{
  content:"\f2ff";
}

.bp5-icon-unarchive::before{
  content:"\f300";
}

.bp5-icon-underline::before{
  content:"\f301";
}

.bp5-icon-undo::before{
  content:"\f302";
}

.bp5-icon-ungroup-objects::before{
  content:"\f303";
}

.bp5-icon-unknown-vehicle::before{
  content:"\f304";
}

.bp5-icon-unlink::before{
  content:"\f345";
}

.bp5-icon-unlock::before{
  content:"\f305";
}

.bp5-icon-unpin::before{
  content:"\f306";
}

.bp5-icon-unresolve::before{
  content:"\f307";
}

.bp5-icon-updated::before{
  content:"\f308";
}

.bp5-icon-upload::before{
  content:"\f309";
}

.bp5-icon-user::before{
  content:"\f30a";
}

.bp5-icon-variable::before{
  content:"\f30b";
}

.bp5-icon-vector::before{
  content:"\f35e";
}

.bp5-icon-vertical-bar-chart-asc::before{
  content:"\f30c";
}

.bp5-icon-vertical-bar-chart-desc::before{
  content:"\f30d";
}

.bp5-icon-vertical-distribution::before{
  content:"\f30e";
}

.bp5-icon-vertical-inbetween::before{
  content:"\f32a";
}

.bp5-icon-video::before{
  content:"\f30f";
}

.bp5-icon-virus::before{
  content:"\f310";
}

.bp5-icon-volume-down::before{
  content:"\f311";
}

.bp5-icon-volume-off::before{
  content:"\f312";
}

.bp5-icon-volume-up::before{
  content:"\f313";
}

.bp5-icon-walk::before{
  content:"\f314";
}

.bp5-icon-warning-sign::before{
  content:"\f315";
}

.bp5-icon-waterfall-chart::before{
  content:"\f316";
}

.bp5-icon-waves::before{
  content:"\f317";
}

.bp5-icon-widget::before{
  content:"\f31b";
}

.bp5-icon-widget-button::before{
  content:"\f318";
}

.bp5-icon-widget-footer::before{
  content:"\f319";
}

.bp5-icon-widget-header::before{
  content:"\f31a";
}

.bp5-icon-wind::before{
  content:"\f31c";
}

.bp5-icon-wrench::before{
  content:"\f31d";
}

.bp5-icon-zoom-in::before{
  content:"\f31e";
}

.bp5-icon-zoom-out::before{
  content:"\f31f";
}

.bp5-icon-zoom-to-fit::before{
  content:"\f320";
}
.bp5-submenu > .bp5-popover-wrapper{
  display:block;
}
.bp5-submenu .bp5-popover-target{
  display:block;
}
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item{
}
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item:not([class*=bp5-intent-]){
  background-color:rgba(143, 153, 168, 0.15);
  color:inherit;
  cursor:pointer;
  text-decoration:none;
}
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item:not([class*=bp5-intent-]){
  color:inherit;
}
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item:not([class*=bp5-intent-]) .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item:not([class*=bp5-intent-]) .bp5-submenu-icon{
  color:#abb3bf;
}
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-], .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active{
  background-color:rgba(45, 114, 210, 0.1);
  color:#215db0;
}
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-] .bp5-menu-item-label, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover .bp5-menu-item-label, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active .bp5-menu-item-label{
  color:inherit;
}
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-] .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-] .bp5-menu-item-selected-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-] .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover .bp5-menu-item-selected-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active .bp5-menu-item-selected-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active .bp5-submenu-icon{
  color:#215db0;
}
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-success, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-success, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-success{
  background-color:rgba(35, 133, 81, 0.1);
  color:#1c6e42;
}
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-success::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-success .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-success .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-success::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-success .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-success .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-success::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-success .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-success .bp5-submenu-icon{
  color:inherit;
}
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-warning, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-warning, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-warning{
  background-color:rgba(200, 118, 25, 0.1);
  color:#935610;
}
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-warning::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-warning .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-warning .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-warning::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-warning .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-warning .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-warning::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-warning .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-warning .bp5-submenu-icon{
  color:inherit;
}
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-danger, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-danger, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-danger{
  background-color:rgba(205, 66, 70, 0.1);
  color:#ac2f33;
}
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-danger::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-danger .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-danger .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-danger::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-danger .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-danger .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-danger::before,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-danger .bp5-menu-item-icon,
.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-danger .bp5-submenu-icon{
  color:inherit;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-], .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active{
    background-color:highlight;
  }
}
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-], .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active{
  background-color:rgba(45, 114, 210, 0.2);
  color:#8abbff;
}
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-] .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-] .bp5-menu-item-selected-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-] .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover .bp5-menu-item-selected-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active .bp5-menu-item-selected-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active .bp5-submenu-icon{
  color:#8abbff;
}
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-success, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-success, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-success{
  background-color:rgba(35, 133, 81, 0.2);
  color:#72ca9b;
}
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-success::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-success .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-success .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-success::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-success .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-success .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-success::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-success .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-success .bp5-submenu-icon{
  color:inherit;
}
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-warning, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-warning, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-warning{
  background-color:rgba(200, 118, 25, 0.2);
  color:#fbb360;
}
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-warning::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-warning .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-warning .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-warning::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-warning .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-warning .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-warning::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-warning .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-warning .bp5-submenu-icon{
  color:inherit;
}
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-danger, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-danger, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-danger{
  background-color:rgba(205, 66, 70, 0.2);
  color:#fa999c;
}
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-danger::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-danger .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-].bp5-intent-danger .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-danger::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-danger .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover.bp5-intent-danger .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-danger::before,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-danger .bp5-menu-item-icon,
.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active.bp5-intent-danger .bp5-submenu-icon{
  color:inherit;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-], .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:hover, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*=bp5-intent-]:active{
    background-color:highlight;
  }
}
.bp5-submenu.bp5-popover{
  box-shadow:none;
  padding:0 5px;
}
.bp5-submenu.bp5-popover > .bp5-popover-content{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
}
.bp5-dark .bp5-submenu.bp5-popover, .bp5-submenu.bp5-popover.bp5-dark{
  box-shadow:none;
}
.bp5-dark .bp5-submenu.bp5-popover > .bp5-popover-content, .bp5-submenu.bp5-popover.bp5-dark > .bp5-popover-content{
  box-shadow:0 0 0 1px hsl(215, 3%, 38%), inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp5-menu{
  background:#ffffff;
  border-radius:2px;
  color:#1c2127;
  list-style:none;
  margin:0;
  min-width:180px;
  padding:5px;
  text-align:left;
}

.bp5-menu-divider{
  border-top:1px solid rgba(17, 20, 24, 0.15);
  display:block;
  margin:5px -5px;
}
.bp5-dark .bp5-menu-divider{
  border-top-color:rgba(255, 255, 255, 0.2);
}

.bp5-menu-item{
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  border-radius:2px;
  color:inherit;
  line-height:20px;
  padding:5px 7px;
  text-decoration:none;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.bp5-menu-item > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-menu-item > .bp5-fill{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-menu-item::before,
.bp5-menu-item > *{
  margin-right:7px;
}
.bp5-menu-item:empty::before,
.bp5-menu-item > :last-child{
  margin-right:0;
}
.bp5-menu-item > .bp5-fill{
  word-break:break-word;
}
.bp5-menu-item .bp5-menu-item-icon{
  display:flex;
  flex-direction:column;
  height:20px;
  justify-content:center;
}
.bp5-menu-item .bp5-menu-item-label{
  color:#5f6b7c;
}
.bp5-menu-item::before,
.bp5-menu-item .bp5-menu-item-icon,
.bp5-menu-item .bp5-menu-item-selected-icon,
.bp5-menu-item .bp5-submenu-icon{
  color:#5f6b7c;
}
.bp5-menu-item::before,
.bp5-menu-item .bp5-submenu-icon{
  margin-top:2px;
}
.bp5-menu-item:hover{
  background-color:rgba(143, 153, 168, 0.15);
  color:inherit;
  cursor:pointer;
  text-decoration:none;
}
.bp5-menu-item:active{
  background-color:rgba(143, 153, 168, 0.3);
}
.bp5-menu-item:active .bp5-menu-item-label{
  color:#1c2127;
}
.bp5-menu-item.bp5-active{
  background-color:rgba(45, 114, 210, 0.1);
  color:#215db0;
}
.bp5-menu-item.bp5-active .bp5-menu-item-label{
  color:inherit;
}
.bp5-menu-item.bp5-active::before,
.bp5-menu-item.bp5-active .bp5-menu-item-icon,
.bp5-menu-item.bp5-active .bp5-menu-item-selected-icon,
.bp5-menu-item.bp5-active .bp5-submenu-icon{
  color:#215db0;
}
.bp5-menu-item.bp5-active.bp5-intent-success{
  background-color:rgba(35, 133, 81, 0.1);
  color:#1c6e42;
}
.bp5-menu-item.bp5-active.bp5-intent-success::before,
.bp5-menu-item.bp5-active.bp5-intent-success .bp5-menu-item-icon,
.bp5-menu-item.bp5-active.bp5-intent-success .bp5-submenu-icon{
  color:inherit;
}
.bp5-menu-item.bp5-active.bp5-intent-warning{
  background-color:rgba(200, 118, 25, 0.1);
  color:#935610;
}
.bp5-menu-item.bp5-active.bp5-intent-warning::before,
.bp5-menu-item.bp5-active.bp5-intent-warning .bp5-menu-item-icon,
.bp5-menu-item.bp5-active.bp5-intent-warning .bp5-submenu-icon{
  color:inherit;
}
.bp5-menu-item.bp5-active.bp5-intent-danger{
  background-color:rgba(205, 66, 70, 0.1);
  color:#ac2f33;
}
.bp5-menu-item.bp5-active.bp5-intent-danger::before,
.bp5-menu-item.bp5-active.bp5-intent-danger .bp5-menu-item-icon,
.bp5-menu-item.bp5-active.bp5-intent-danger .bp5-submenu-icon{
  color:inherit;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-menu-item.bp5-active{
    background-color:highlight;
  }
}
.bp5-menu-item.bp5-menu-item-is-selectable{
  padding-left:20px;
}
.bp5-menu-item.bp5-menu-item-is-selectable.bp5-selected{
  padding-left:0;
}
.bp5-menu-item.bp5-menu-item-is-selectable .bp5-menu-item-selected-icon{
  align-self:center;
  margin:0 2px;
}
.bp5-menu-item.bp5-disabled{
  background-color:inherit !important;
  color:rgba(95, 107, 124, 0.6) !important;
  cursor:not-allowed !important;
  outline:none !important;
}
.bp5-menu-item.bp5-disabled::before,
.bp5-menu-item.bp5-disabled .bp5-menu-item-icon,
.bp5-menu-item.bp5-disabled .bp5-submenu-icon{
  color:rgba(95, 107, 124, 0.6) !important;
}
.bp5-menu-item.bp5-disabled .bp5-menu-item-label{
  color:rgba(95, 107, 124, 0.6) !important;
}
.bp5-menu-item.bp5-intent-primary{
  color:#215db0;
}
.bp5-menu-item.bp5-intent-primary::before,
.bp5-menu-item.bp5-intent-primary .bp5-menu-item-icon,
.bp5-menu-item.bp5-intent-primary .bp5-menu-item-selected-icon,
.bp5-menu-item.bp5-intent-primary .bp5-submenu-icon,
.bp5-menu-item.bp5-intent-primary .bp5-menu-item-label{
  color:inherit;
}
.bp5-menu-item.bp5-intent-primary:hover{
  background-color:rgba(45, 114, 210, 0.1);
}
.bp5-menu-item.bp5-intent-primary:active, .bp5-menu-item.bp5-intent-primary.bp5-active{
  background-color:rgba(45, 114, 210, 0.2);
  color:#184a90;
}
.bp5-menu-item.bp5-intent-success{
  color:#1c6e42;
}
.bp5-menu-item.bp5-intent-success::before,
.bp5-menu-item.bp5-intent-success .bp5-menu-item-icon,
.bp5-menu-item.bp5-intent-success .bp5-menu-item-selected-icon,
.bp5-menu-item.bp5-intent-success .bp5-submenu-icon,
.bp5-menu-item.bp5-intent-success .bp5-menu-item-label{
  color:inherit;
}
.bp5-menu-item.bp5-intent-success:hover{
  background-color:rgba(35, 133, 81, 0.1);
}
.bp5-menu-item.bp5-intent-success:active, .bp5-menu-item.bp5-intent-success.bp5-active{
  background-color:rgba(35, 133, 81, 0.2);
  color:#165a36;
}
.bp5-menu-item.bp5-intent-warning{
  color:#935610;
}
.bp5-menu-item.bp5-intent-warning::before,
.bp5-menu-item.bp5-intent-warning .bp5-menu-item-icon,
.bp5-menu-item.bp5-intent-warning .bp5-menu-item-selected-icon,
.bp5-menu-item.bp5-intent-warning .bp5-submenu-icon,
.bp5-menu-item.bp5-intent-warning .bp5-menu-item-label{
  color:inherit;
}
.bp5-menu-item.bp5-intent-warning:hover{
  background-color:rgba(200, 118, 25, 0.1);
}
.bp5-menu-item.bp5-intent-warning:active, .bp5-menu-item.bp5-intent-warning.bp5-active{
  background-color:rgba(200, 118, 25, 0.2);
  color:#77450d;
}
.bp5-menu-item.bp5-intent-danger{
  color:#ac2f33;
}
.bp5-menu-item.bp5-intent-danger::before,
.bp5-menu-item.bp5-intent-danger .bp5-menu-item-icon,
.bp5-menu-item.bp5-intent-danger .bp5-menu-item-selected-icon,
.bp5-menu-item.bp5-intent-danger .bp5-submenu-icon,
.bp5-menu-item.bp5-intent-danger .bp5-menu-item-label{
  color:inherit;
}
.bp5-menu-item.bp5-intent-danger:hover{
  background-color:rgba(205, 66, 70, 0.1);
}
.bp5-menu-item.bp5-intent-danger:active, .bp5-menu-item.bp5-intent-danger.bp5-active{
  background-color:rgba(205, 66, 70, 0.2);
  color:#8e292c;
}
.bp5-menu-item::before{
  font-family:"blueprint-icons-16", sans-serif;
  font-size:16px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:16px;
  line-height:1;
  width:16px;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  margin-right:7px;
}
.bp5-large .bp5-menu-item{
  font-size:16px;
  line-height:22px;
  padding-bottom:9px;
  padding-top:9px;
}
.bp5-large .bp5-menu-item .bp5-menu-item-icon{
  height:22px;
}
.bp5-large .bp5-menu-item::before,
.bp5-large .bp5-menu-item .bp5-submenu-icon{
  margin-top:3px;
}
.bp5-large .bp5-menu-item::before{
  font-family:"blueprint-icons-20", sans-serif;
  font-size:20px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:20px;
  line-height:1;
  width:20px;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  margin-right:10px;
}
.bp5-small .bp5-menu-item{
  padding-bottom:2px;
  padding-top:2px;
}

button.bp5-menu-item{
  background:none;
  border:none;
  text-align:left;
  width:100%;
}
.bp5-menu-header{
  border-top:1px solid rgba(17, 20, 24, 0.15);
  display:block;
  margin:5px -5px;
  cursor:default;
  padding-left:2px;
}
.bp5-dark .bp5-menu-header{
  border-top-color:rgba(255, 255, 255, 0.2);
}
.bp5-menu-header:first-of-type{
  border-top:none;
}
.bp5-menu-header > h6{
  color:#1c2127;
  font-weight:600;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  word-wrap:normal;
  line-height:17px;
  margin:0;
  padding:10px 7px 0 6px;
}
.bp5-dark .bp5-menu-header > h6{
  color:#f6f7f9;
}
.bp5-menu-header > h6.bp5-text-muted{
  color:#5f6b7c;
}
.bp5-dark .bp5-menu-header > h6.bp5-text-muted{
  color:#abb3bf;
}
.bp5-menu-header:first-of-type > h6{
  padding-top:0;
}
.bp5-large .bp5-menu-header > h6{
  font-size:18px;
  padding-bottom:5px;
  padding-top:15px;
}
.bp5-large .bp5-menu-header:first-of-type > h6{
  padding-top:0;
}

.bp5-dark .bp5-menu{
  background:#2f343c;
  color:#f6f7f9;
}
.bp5-dark .bp5-menu-item{
  color:inherit;
}
.bp5-dark .bp5-menu-item .bp5-menu-item-label{
  color:#abb3bf;
}
.bp5-dark .bp5-menu-item::before,
.bp5-dark .bp5-menu-item .bp5-menu-item-icon,
.bp5-dark .bp5-menu-item .bp5-menu-item-selected-icon,
.bp5-dark .bp5-menu-item .bp5-submenu-icon{
  color:#abb3bf;
}
.bp5-dark .bp5-menu-item:hover{
  color:inherit;
}
.bp5-dark .bp5-menu-item:hover .bp5-menu-item-icon,
.bp5-dark .bp5-menu-item:hover .bp5-submenu-icon{
  color:#abb3bf;
}
.bp5-dark .bp5-menu-item:active{
  background-color:rgba(143, 153, 168, 0.3);
}
.bp5-dark .bp5-menu-item:active .bp5-menu-item-label{
  color:#f6f7f9;
}
.bp5-dark .bp5-menu-item.bp5-active{
  background-color:rgba(45, 114, 210, 0.2);
  color:#8abbff;
}
.bp5-dark .bp5-menu-item.bp5-active::before,
.bp5-dark .bp5-menu-item.bp5-active .bp5-menu-item-icon,
.bp5-dark .bp5-menu-item.bp5-active .bp5-menu-item-selected-icon,
.bp5-dark .bp5-menu-item.bp5-active .bp5-submenu-icon{
  color:#8abbff;
}
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-success{
  background-color:rgba(35, 133, 81, 0.2);
  color:#72ca9b;
}
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-success::before,
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-success .bp5-menu-item-icon,
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-success .bp5-submenu-icon{
  color:inherit;
}
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-warning{
  background-color:rgba(200, 118, 25, 0.2);
  color:#fbb360;
}
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-warning::before,
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-warning .bp5-menu-item-icon,
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-warning .bp5-submenu-icon{
  color:inherit;
}
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-danger{
  background-color:rgba(205, 66, 70, 0.2);
  color:#fa999c;
}
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-danger::before,
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-danger .bp5-menu-item-icon,
.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-danger .bp5-submenu-icon{
  color:inherit;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-dark .bp5-menu-item.bp5-active{
    background-color:highlight;
  }
}
.bp5-dark .bp5-menu-item.bp5-disabled{
  color:rgba(171, 179, 191, 0.6) !important;
}
.bp5-dark .bp5-menu-item.bp5-disabled::before,
.bp5-dark .bp5-menu-item.bp5-disabled .bp5-menu-item-icon,
.bp5-dark .bp5-menu-item.bp5-disabled .bp5-submenu-icon{
  color:rgba(171, 179, 191, 0.6) !important;
}
.bp5-dark .bp5-menu-item.bp5-disabled .bp5-menu-item-label{
  color:rgba(171, 179, 191, 0.6) !important;
}
.bp5-dark .bp5-menu-item.bp5-intent-primary{
  color:#8abbff;
}
.bp5-dark .bp5-menu-item.bp5-intent-primary::before,
.bp5-dark .bp5-menu-item.bp5-intent-primary .bp5-menu-item-icon,
.bp5-dark .bp5-menu-item.bp5-intent-primary .bp5-menu-item-selected-icon,
.bp5-dark .bp5-menu-item.bp5-intent-primary .bp5-submenu-icon,
.bp5-dark .bp5-menu-item.bp5-intent-primary .bp5-menu-item-label{
  color:inherit;
}
.bp5-dark .bp5-menu-item.bp5-intent-primary:hover{
  background-color:rgba(45, 114, 210, 0.2);
}
.bp5-dark .bp5-menu-item.bp5-intent-primary:active, .bp5-dark .bp5-menu-item.bp5-intent-primary.bp5-active{
  background-color:rgba(45, 114, 210, 0.3);
  color:#99c4ff;
}
.bp5-dark .bp5-menu-item.bp5-intent-success{
  color:#72ca9b;
}
.bp5-dark .bp5-menu-item.bp5-intent-success::before,
.bp5-dark .bp5-menu-item.bp5-intent-success .bp5-menu-item-icon,
.bp5-dark .bp5-menu-item.bp5-intent-success .bp5-menu-item-selected-icon,
.bp5-dark .bp5-menu-item.bp5-intent-success .bp5-submenu-icon,
.bp5-dark .bp5-menu-item.bp5-intent-success .bp5-menu-item-label{
  color:inherit;
}
.bp5-dark .bp5-menu-item.bp5-intent-success:hover{
  background-color:rgba(35, 133, 81, 0.2);
}
.bp5-dark .bp5-menu-item.bp5-intent-success:active, .bp5-dark .bp5-menu-item.bp5-intent-success.bp5-active{
  background-color:rgba(35, 133, 81, 0.3);
  color:#7cd7a2;
}
.bp5-dark .bp5-menu-item.bp5-intent-warning{
  color:#fbb360;
}
.bp5-dark .bp5-menu-item.bp5-intent-warning::before,
.bp5-dark .bp5-menu-item.bp5-intent-warning .bp5-menu-item-icon,
.bp5-dark .bp5-menu-item.bp5-intent-warning .bp5-menu-item-selected-icon,
.bp5-dark .bp5-menu-item.bp5-intent-warning .bp5-submenu-icon,
.bp5-dark .bp5-menu-item.bp5-intent-warning .bp5-menu-item-label{
  color:inherit;
}
.bp5-dark .bp5-menu-item.bp5-intent-warning:hover{
  background-color:rgba(200, 118, 25, 0.2);
}
.bp5-dark .bp5-menu-item.bp5-intent-warning:active, .bp5-dark .bp5-menu-item.bp5-intent-warning.bp5-active{
  background-color:rgba(200, 118, 25, 0.3);
  color:#f5c186;
}
.bp5-dark .bp5-menu-item.bp5-intent-danger{
  color:#fa999c;
}
.bp5-dark .bp5-menu-item.bp5-intent-danger::before,
.bp5-dark .bp5-menu-item.bp5-intent-danger .bp5-menu-item-icon,
.bp5-dark .bp5-menu-item.bp5-intent-danger .bp5-menu-item-selected-icon,
.bp5-dark .bp5-menu-item.bp5-intent-danger .bp5-submenu-icon,
.bp5-dark .bp5-menu-item.bp5-intent-danger .bp5-menu-item-label{
  color:inherit;
}
.bp5-dark .bp5-menu-item.bp5-intent-danger:hover{
  background-color:rgba(205, 66, 70, 0.2);
}
.bp5-dark .bp5-menu-item.bp5-intent-danger:active, .bp5-dark .bp5-menu-item.bp5-intent-danger.bp5-active{
  background-color:rgba(205, 66, 70, 0.3);
  color:#ffa1a4;
}
.bp5-dark .bp5-menu-divider,
.bp5-dark .bp5-menu-header{
  border-color:rgba(255, 255, 255, 0.2);
}
.bp5-dark .bp5-menu-header > h6{
  color:#f6f7f9;
}

.bp5-label .bp5-menu{
  margin-top:5px;
}
.bp5-navbar{
  background-color:#ffffff;
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  height:50px;
  padding:0 15px;
  position:relative;
  width:100%;
  z-index:10;
}
.bp5-navbar.bp5-dark, .bp5-dark .bp5-navbar{
  background-color:#2f343c;
}
.bp5-navbar.bp5-dark{
  box-shadow:inset inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-navbar{
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
}
.bp5-navbar.bp5-fixed-top{
  left:0;
  position:fixed;
  right:0;
  top:0;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-navbar{
    border:1px solid buttonborder;
  }
}

.bp5-navbar-heading{
  font-size:16px;
  margin-right:15px;
}

.bp5-navbar-group{
  align-items:center;
  display:flex;
  height:50px;
}
.bp5-navbar-group.bp5-align-left{
  float:left;
}
.bp5-navbar-group.bp5-align-right{
  float:right;
}

.bp5-navbar-divider{
  border-left:1px solid rgba(17, 20, 24, 0.15);
  height:20px;
  margin:0 10px;
}
.bp5-dark .bp5-navbar-divider{
  border-left-color:rgba(255, 255, 255, 0.2);
}
.bp5-non-ideal-state{
  display:flex;
  flex-direction:column;
  align-items:center;
  color:#5f6b7c;
  height:100%;
  justify-content:center;
  text-align:center;
  width:100%;
}
.bp5-non-ideal-state > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-non-ideal-state > .bp5-fill{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-non-ideal-state::before,
.bp5-non-ideal-state > *{
  margin-bottom:20px;
}
.bp5-non-ideal-state:empty::before,
.bp5-non-ideal-state > :last-child{
  margin-bottom:0;
}
.bp5-non-ideal-state > *{
  max-width:400px;
}
.bp5-non-ideal-state .bp5-heading{
  color:#5f6b7c;
  line-height:20px;
  margin-bottom:10px;
}
.bp5-non-ideal-state .bp5-heading:only-child{
  margin-bottom:0;
}
.bp5-non-ideal-state.bp5-non-ideal-state-horizontal{
  display:flex;
  flex-direction:row;
  text-align:left;
}
.bp5-non-ideal-state.bp5-non-ideal-state-horizontal > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-non-ideal-state.bp5-non-ideal-state-horizontal > .bp5-fill{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-non-ideal-state.bp5-non-ideal-state-horizontal::before,
.bp5-non-ideal-state.bp5-non-ideal-state-horizontal > *{
  margin-right:20px;
}
.bp5-non-ideal-state.bp5-non-ideal-state-horizontal:empty::before,
.bp5-non-ideal-state.bp5-non-ideal-state-horizontal > :last-child{
  margin-right:0;
}
.bp5-non-ideal-state.bp5-non-ideal-state-horizontal > *{
  margin-bottom:0;
}
.bp5-dark .bp5-non-ideal-state{
  color:#abb3bf;
}
.bp5-dark .bp5-non-ideal-state .bp5-heading{
  color:#abb3bf;
}

.bp5-non-ideal-state-visual{
  color:#8f99a8;
}

.bp5-overflow-list{
  display:flex;
  flex-wrap:nowrap;
  min-width:0;
}

.bp5-overflow-list-spacer{
  flex-shrink:1;
  width:1px;
}
body.bp5-overlay-open{
  overflow:hidden;
}

.bp5-overlay{
  bottom:0;
  left:0;
  position:static;
  right:0;
  top:0;
  z-index:20;
}
.bp5-overlay:not(.bp5-overlay-open){
  pointer-events:none;
}
.bp5-overlay.bp5-overlay-container{
  overflow:hidden;
  position:fixed;
}
.bp5-overlay.bp5-overlay-container.bp5-overlay-inline{
  position:absolute;
}
.bp5-overlay.bp5-overlay-scroll-container{
  overflow:auto;
  position:fixed;
}
.bp5-overlay.bp5-overlay-scroll-container.bp5-overlay-inline{
  position:absolute;
}
.bp5-overlay.bp5-overlay-inline{
  display:inline;
  overflow:visible;
}

.bp5-overlay-content{
  position:fixed;
  z-index:20;
}
.bp5-overlay-inline .bp5-overlay-content, .bp5-overlay-scroll-container .bp5-overlay-content{
  position:absolute;
}

.bp5-overlay-backdrop{
  bottom:0;
  left:0;
  position:fixed;
  right:0;
  top:0;
  opacity:1;
  background-color:rgba(17, 20, 24, 0.7);
  overflow:auto;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  z-index:20;
}
.bp5-overlay-backdrop.bp5-overlay-enter, .bp5-overlay-backdrop.bp5-overlay-appear{
  opacity:0;
}
.bp5-overlay-backdrop.bp5-overlay-enter-active, .bp5-overlay-backdrop.bp5-overlay-appear-active{
  opacity:1;
  transition-delay:0;
  transition-duration:200ms;
  transition-property:opacity;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-overlay-backdrop.bp5-overlay-exit{
  opacity:1;
}
.bp5-overlay-backdrop.bp5-overlay-exit-active{
  opacity:0;
  transition-delay:0;
  transition-duration:200ms;
  transition-property:opacity;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-overlay-backdrop:focus{
  outline:none;
}
.bp5-overlay-inline .bp5-overlay-backdrop{
  position:absolute;
}
.bp5-panel-stack{
  overflow:hidden;
  position:relative;
}

.bp5-panel-stack-header{
  align-items:center;
  box-shadow:0 1px rgba(17, 20, 24, 0.15);
  display:flex;
  flex-shrink:0;
  height:30px;
  z-index:1;
}
.bp5-dark .bp5-panel-stack-header{
  box-shadow:0 1px rgba(255, 255, 255, 0.2);
}
.bp5-panel-stack-header > span{
  align-items:stretch;
  display:flex;
  flex:1;
}
.bp5-panel-stack-header .bp5-heading{
  margin:0 5px;
}

.bp5-button.bp5-panel-stack-header-back{
  margin-left:5px;
  padding-left:0;
  white-space:nowrap;
}
.bp5-button.bp5-panel-stack-header-back .bp5-icon{
  margin:0 2px;
}

.bp5-panel-stack-view{
  bottom:0;
  left:0;
  position:absolute;
  right:0;
  top:0;
  background-color:#ffffff;
  border-right:1px solid rgba(17, 20, 24, 0.15);
  display:flex;
  flex-direction:column;
  margin-right:-1px;
  overflow-y:auto;
  z-index:1;
}
.bp5-dark .bp5-panel-stack-view{
  background-color:#2f343c;
}
.bp5-panel-stack-view:nth-last-child(n+4){
  display:none;
}

.bp5-panel-stack-push .bp5-panel-stack-enter, .bp5-panel-stack-push .bp5-panel-stack-appear{
  transform:translateX(100%);
  opacity:0;
}
.bp5-panel-stack-push .bp5-panel-stack-enter-active, .bp5-panel-stack-push .bp5-panel-stack-appear-active{
  transform:translate(0%);
  opacity:1;
  transition-delay:0;
  transition-duration:400ms;
  transition-property:transform, opacity;
  transition-timing-function:ease;
}
.bp5-panel-stack-push .bp5-panel-stack-exit{
  transform:translate(0%);
  opacity:1;
}
.bp5-panel-stack-push .bp5-panel-stack-exit-active{
  transform:translateX(-50%);
  opacity:0;
  transition-delay:0;
  transition-duration:400ms;
  transition-property:transform, opacity;
  transition-timing-function:ease;
}

.bp5-panel-stack-pop .bp5-panel-stack-enter, .bp5-panel-stack-pop .bp5-panel-stack-appear{
  transform:translateX(-50%);
  opacity:0;
}
.bp5-panel-stack-pop .bp5-panel-stack-enter-active, .bp5-panel-stack-pop .bp5-panel-stack-appear-active{
  transform:translate(0%);
  opacity:1;
  transition-delay:0;
  transition-duration:400ms;
  transition-property:transform, opacity;
  transition-timing-function:ease;
}
.bp5-panel-stack-pop .bp5-panel-stack-exit{
  transform:translate(0%);
  opacity:1;
}
.bp5-panel-stack-pop .bp5-panel-stack-exit-active{
  transform:translateX(100%);
  opacity:0;
  transition-delay:0;
  transition-duration:400ms;
  transition-property:transform, opacity;
  transition-timing-function:ease;
}
.bp5-panel-stack2{
  overflow:hidden;
  position:relative;
}

.bp5-panel-stack2-header{
  align-items:center;
  box-shadow:0 1px rgba(17, 20, 24, 0.15);
  display:flex;
  flex-shrink:0;
  height:30px;
  z-index:1;
}
.bp5-dark .bp5-panel-stack2-header{
  box-shadow:0 1px rgba(255, 255, 255, 0.2);
}
.bp5-panel-stack2-header > span{
  align-items:stretch;
  display:flex;
  flex:1;
}
.bp5-panel-stack2-header .bp5-heading{
  margin:0 5px;
}

.bp5-button.bp5-panel-stack2-header-back{
  margin-left:5px;
  padding-left:0;
  white-space:nowrap;
}
.bp5-button.bp5-panel-stack2-header-back .bp5-icon{
  margin:0 2px;
}

.bp5-panel-stack2-view{
  bottom:0;
  left:0;
  position:absolute;
  right:0;
  top:0;
  background-color:#ffffff;
  border-right:1px solid rgba(17, 20, 24, 0.15);
  display:flex;
  flex-direction:column;
  margin-right:-1px;
  overflow-y:auto;
  z-index:1;
}
.bp5-dark .bp5-panel-stack2-view{
  background-color:#2f343c;
}
.bp5-panel-stack2-view:nth-last-child(n+4){
  display:none;
}

.bp5-panel-stack2-push .bp5-panel-stack2-enter, .bp5-panel-stack2-push .bp5-panel-stack2-appear{
  transform:translateX(100%);
  opacity:0;
}
.bp5-panel-stack2-push .bp5-panel-stack2-enter-active, .bp5-panel-stack2-push .bp5-panel-stack2-appear-active{
  transform:translate(0%);
  opacity:1;
  transition-delay:0;
  transition-duration:400ms;
  transition-property:transform, opacity;
  transition-timing-function:ease;
}
.bp5-panel-stack2-push .bp5-panel-stack2-exit{
  transform:translate(0%);
  opacity:1;
}
.bp5-panel-stack2-push .bp5-panel-stack2-exit-active{
  transform:translateX(-50%);
  opacity:0;
  transition-delay:0;
  transition-duration:400ms;
  transition-property:transform, opacity;
  transition-timing-function:ease;
}

.bp5-panel-stack2-pop .bp5-panel-stack2-enter, .bp5-panel-stack2-pop .bp5-panel-stack2-appear{
  transform:translateX(-50%);
  opacity:0;
}
.bp5-panel-stack2-pop .bp5-panel-stack2-enter-active, .bp5-panel-stack2-pop .bp5-panel-stack2-appear-active{
  transform:translate(0%);
  opacity:1;
  transition-delay:0;
  transition-duration:400ms;
  transition-property:transform, opacity;
  transition-timing-function:ease;
}
.bp5-panel-stack2-pop .bp5-panel-stack2-exit{
  transform:translate(0%);
  opacity:1;
}
.bp5-panel-stack2-pop .bp5-panel-stack2-exit-active{
  transform:translateX(100%);
  opacity:0;
  transition-delay:0;
  transition-duration:400ms;
  transition-property:transform, opacity;
  transition-timing-function:ease;
}
.bp5-button-group:not(.bp5-minimal) > .bp5-popover-target:not(:first-child) .bp5-button{
  border-bottom-left-radius:0;
  border-top-left-radius:0;
}
.bp5-button-group:not(.bp5-minimal) > .bp5-popover-target:not(:last-child) .bp5-button{
  border-bottom-right-radius:0;
  border-top-right-radius:0;
  margin-right:-1px;
}
.bp5-button-group .bp5-popover-target{
  display:flex;
  flex:1 1 auto;
}
.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-popover-target:first-child .bp5-button{
  border-radius:2px 2px 0 0;
}
.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-popover-target:last-child .bp5-button{
  border-radius:0 0 2px 2px;
}
.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-popover-target:not(:last-child) .bp5-button{
  margin-bottom:-1px;
}
.bp5-control-group .bp5-popover-target{
  border-radius:inherit;
}
label.bp5-label .bp5-popover-target{
  display:block;
  margin-top:5px;
  text-transform:none;
}
.bp5-submenu .bp5-popover-target{
  display:block;
}
.bp5-submenu.bp5-popover{
  box-shadow:none;
  padding:0 5px;
}
.bp5-submenu.bp5-popover > .bp5-popover-content{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
}
.bp5-dark .bp5-submenu.bp5-popover, .bp5-submenu.bp5-popover.bp5-dark{
  box-shadow:none;
}
.bp5-dark .bp5-submenu.bp5-popover > .bp5-popover-content, .bp5-submenu.bp5-popover.bp5-dark > .bp5-popover-content{
  box-shadow:0 0 0 1px hsl(215, 3%, 38%), inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp5-tree-node-secondary-label .bp5-popover-target{
  align-items:center;
  display:flex;
}

.bp5-popover{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  transform:scale(1);
  border-radius:2px;
  display:inline-block;
  z-index:20;
}
.bp5-popover .bp5-popover-arrow{
  height:30px;
  position:absolute;
  width:30px;
}
.bp5-popover .bp5-popover-arrow::before{
  height:20px;
  margin:5px;
  width:20px;
}
.bp5-popover .bp5-popover-content{
  background:#ffffff;
}
.bp5-popover .bp5-popover-content,
.bp5-popover .bp5-heading{
  color:inherit;
}
.bp5-popover .bp5-popover-arrow::before{
  box-shadow:1px 1px 6px rgba(17, 20, 24, 0.2);
}
.bp5-popover .bp5-popover-arrow-border{
  fill:#111418;
  fill-opacity:0.1;
}
.bp5-popover .bp5-popover-arrow-fill{
  fill:#ffffff;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-popover .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-popover{
    border:1px solid buttonborder;
  }
}
.bp5-popover-enter > .bp5-popover, .bp5-popover-appear > .bp5-popover{
  transform:scale(0.3);
}
.bp5-popover-enter-active > .bp5-popover, .bp5-popover-appear-active > .bp5-popover{
  transform:scale(1);
  transition-delay:0;
  transition-duration:300ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp5-popover-exit > .bp5-popover{
  transform:scale(1);
}
.bp5-popover-exit-active > .bp5-popover{
  transform:scale(0.3);
  transition-delay:0;
  transition-duration:300ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp5-popover .bp5-popover-content{
  border-radius:2px;
  position:relative;
}
.bp5-popover.bp5-popover-content-sizing .bp5-popover-content{
  max-width:350px;
  padding:20px;
}
.bp5-popover-target + .bp5-overlay .bp5-popover.bp5-popover-content-sizing{
  width:350px;
}
.bp5-popover.bp5-minimal{
  margin:0 !important;
}
.bp5-popover.bp5-minimal .bp5-popover-arrow{
  display:none;
}
.bp5-popover.bp5-minimal.bp5-popover{
  transform:scale(1);
}
.bp5-popover-enter > .bp5-popover.bp5-minimal.bp5-popover, .bp5-popover-appear > .bp5-popover.bp5-minimal.bp5-popover{
  transform:scale(1);
}
.bp5-popover-enter-active > .bp5-popover.bp5-minimal.bp5-popover, .bp5-popover-appear-active > .bp5-popover.bp5-minimal.bp5-popover{
  transform:scale(1);
  transition-delay:0;
  transition-duration:100ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-popover-exit > .bp5-popover.bp5-minimal.bp5-popover{
  transform:scale(1);
}
.bp5-popover-exit-active > .bp5-popover.bp5-minimal.bp5-popover{
  transform:scale(1);
  transition-delay:0;
  transition-duration:100ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-popover.bp5-popover-match-target-width{
  width:100%;
}
.bp5-popover.bp5-dark, .bp5-dark .bp5-popover{
  box-shadow:0 0 0 1px hsl(215, 3%, 38%), inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp5-popover.bp5-dark .bp5-popover-content, .bp5-dark .bp5-popover .bp5-popover-content{
  background:#2f343c;
}
.bp5-popover.bp5-dark .bp5-popover-content,
.bp5-popover.bp5-dark .bp5-heading, .bp5-dark .bp5-popover .bp5-popover-content,
.bp5-dark .bp5-popover .bp5-heading{
  color:inherit;
}
.bp5-popover.bp5-dark .bp5-popover-arrow::before, .bp5-dark .bp5-popover .bp5-popover-arrow::before{
  box-shadow:0 0 0 1px #777a7e, 1px 1px 6px rgba(17, 20, 24, 0.4);
}
.bp5-popover.bp5-dark .bp5-popover-arrow-border, .bp5-dark .bp5-popover .bp5-popover-arrow-border{
  fill:#111418;
  fill-opacity:0.2;
}
.bp5-popover.bp5-dark .bp5-popover-arrow-fill, .bp5-dark .bp5-popover .bp5-popover-arrow-fill{
  fill:#2f343c;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-popover.bp5-dark .bp5-popover-arrow-fill, .bp5-dark .bp5-popover .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-popover.bp5-dark, .bp5-dark .bp5-popover{
    border:1px solid buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-popover{
    border:1px solid buttonborder;
    box-shadow:none;
  }
}

.bp5-popover-arrow::before{
  border-radius:1px;
  content:"";
  display:block;
  position:absolute;
  transform:rotate(45deg);
}

.bp5-overlay-backdrop.bp5-popover-backdrop{
  background:rgba(255, 255, 255, 0);
}

.bp5-popover-transition-container{
  opacity:1;
  display:flex;
  z-index:20;
}
.bp5-popover-transition-container.bp5-popover-enter, .bp5-popover-transition-container.bp5-popover-appear{
  opacity:0;
}
.bp5-popover-transition-container.bp5-popover-enter-active, .bp5-popover-transition-container.bp5-popover-appear-active{
  opacity:1;
  transition-delay:0;
  transition-duration:100ms;
  transition-property:opacity;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-popover-transition-container.bp5-popover-exit{
  opacity:1;
}
.bp5-popover-transition-container.bp5-popover-exit-active{
  opacity:0;
  transition-delay:0;
  transition-duration:100ms;
  transition-property:opacity;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-popover-transition-container:focus{
  outline:none;
}
.bp5-popover-transition-container.bp5-popover-leave .bp5-popover-content{
  pointer-events:none;
}

span.bp5-popover-target{
  display:inline-block;
}

.bp5-popover-target.bp5-fill{
  width:100%;
}

.bp5-portal{
  left:0;
  position:absolute;
  right:0;
  top:0;
}
@keyframes linear-progress-bar-stripes{
  from{
    background-position:0 0;
  }
  to{
    background-position:30px 0;
  }
}
.bp5-progress-bar{
  background:rgba(95, 107, 124, 0.2);
  border-radius:40px;
  display:block;
  height:8px;
  overflow:hidden;
  position:relative;
  width:100%;
}
.bp5-progress-bar .bp5-progress-meter{
  background:linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%);
  background-color:rgba(95, 107, 124, 0.8);
  background-size:30px 30px;
  border-radius:40px;
  height:100%;
  position:absolute;
  transition:width 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  width:100%;
}
.bp5-progress-bar:not(.bp5-no-animation):not(.bp5-no-stripes) .bp5-progress-meter{
  animation:linear-progress-bar-stripes 300ms linear infinite reverse;
}
.bp5-progress-bar.bp5-no-stripes .bp5-progress-meter{
  background-image:none;
}

.bp5-dark .bp5-progress-bar{
  background:rgba(17, 20, 24, 0.5);
}
.bp5-dark .bp5-progress-bar .bp5-progress-meter{
  background-color:#8f99a8;
}

.bp5-progress-bar.bp5-intent-primary .bp5-progress-meter{
  background-color:#2d72d2;
}

.bp5-progress-bar.bp5-intent-success .bp5-progress-meter{
  background-color:#238551;
}

.bp5-progress-bar.bp5-intent-warning .bp5-progress-meter{
  background-color:#c87619;
}

.bp5-progress-bar.bp5-intent-danger .bp5-progress-meter{
  background-color:#cd4246;
}
.bp5-section{
  overflow:hidden;
  width:100%;
}
.bp5-section, .bp5-section.bp5-compact{
  padding:0;
}
.bp5-section-header{
  align-items:center;
  border-bottom:1px solid rgba(17, 20, 24, 0.15);
  display:flex;
  gap:20px;
  justify-content:space-between;
  min-height:50px;
  padding:0 20px;
  position:relative;
  width:100%;
}
.bp5-section-header.bp5-dark, .bp5-dark .bp5-section-header{
  border-color:rgba(255, 255, 255, 0.2);
}
.bp5-section-header-left{
  align-items:center;
  display:flex;
  gap:10px;
  padding:10px 0;
}
.bp5-section-header-title{
  margin-bottom:0;
}
.bp5-section-header-sub-title{
  margin-top:2px;
}
.bp5-section-header-right{
  align-items:center;
  display:flex;
  gap:10px;
  margin-left:auto;
}
.bp5-section-header-divider{
  align-self:stretch;
  margin:15px 0;
}
.bp5-section-header.bp5-interactive{
  cursor:pointer;
}
.bp5-section-header.bp5-interactive:hover, .bp5-section-header.bp5-interactive:active{
  background:#f6f7f9;
}
.bp5-section-header.bp5-interactive:hover.bp5-dark, .bp5-dark .bp5-section-header.bp5-interactive:hover, .bp5-section-header.bp5-interactive:active.bp5-dark, .bp5-dark .bp5-section-header.bp5-interactive:active{
  background:#383e47;
}
.bp5-section-card.bp5-padded{
  padding:20px;
}
.bp5-section-card:not(:last-child){
  border-bottom:1px solid rgba(17, 20, 24, 0.15);
}
.bp5-section-card:not(:last-child).bp5-dark, .bp5-dark .bp5-section-card:not(:last-child){
  border-color:rgba(255, 255, 255, 0.2);
}
.bp5-section.bp5-section-collapsed .bp5-section-header{
  border:none;
}
.bp5-section.bp5-compact .bp5-section-header{
  min-height:40px;
  padding:0 15px;
}
.bp5-section.bp5-compact .bp5-section-header-left{
  padding:7px 0;
}
.bp5-section.bp5-compact .bp5-section-card.bp5-padded{
  padding:15px;
}
.bp5-segmented-control{
  background-color:#f6f7f9;
  border-radius:2px;
  display:flex;
  gap:3px;
  padding:3px;
}
.bp5-segmented-control.bp5-inline{
  display:inline-flex;
}
.bp5-segmented-control.bp5-fill{
  width:100%;
}
.bp5-segmented-control.bp5-fill > .bp5-button{
  flex-grow:1;
}
.bp5-segmented-control > .bp5-button:not(.bp5-minimal):not(.bp5-intent-primary){
  background-color:#ffffff;
}
.bp5-dark .bp5-segmented-control > .bp5-button:not(.bp5-minimal):not(.bp5-intent-primary){
  background-color:#404854;
}
.bp5-segmented-control > .bp5-button.bp5-minimal{
  color:#5f6b7c;
}
.bp5-dark .bp5-segmented-control > .bp5-button.bp5-minimal{
  color:#abb3bf;
}
.bp5-segmented-control > .bp5-button.bp5-minimal:disabled{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-dark .bp5-segmented-control > .bp5-button.bp5-minimal:disabled{
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-segmented-control{
  background-color:#2f343c;
}
@keyframes skeleton-glow{
  from{
    background:rgba(211, 216, 222, 0.2);
    border-color:rgba(211, 216, 222, 0.2);
  }
  to{
    background:rgba(95, 107, 124, 0.2);
    border-color:rgba(95, 107, 124, 0.2);
  }
}
.bp5-skeleton{
  animation:1000ms linear infinite alternate skeleton-glow;
  background:rgba(211, 216, 222, 0.2);
  background-clip:padding-box !important;
  border-color:rgba(211, 216, 222, 0.2) !important;
  border-radius:2px;
  box-shadow:none !important;
  color:transparent !important;
  cursor:default;
  pointer-events:none;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.bp5-skeleton::before, .bp5-skeleton::after,
.bp5-skeleton *{
  visibility:hidden !important;
}
.bp5-tooltip{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  transform:scale(1);
  color:#f6f7f9;
}
.bp5-tooltip .bp5-popover-arrow{
  height:22px;
  position:absolute;
  width:22px;
}
.bp5-tooltip .bp5-popover-arrow::before{
  height:14px;
  margin:4px;
  width:14px;
}
.bp5-tooltip .bp5-popover-content{
  background:#404854;
}
.bp5-tooltip .bp5-popover-content,
.bp5-tooltip .bp5-heading{
  color:#f6f7f9;
}
.bp5-tooltip .bp5-popover-arrow::before{
  box-shadow:1px 1px 6px rgba(17, 20, 24, 0.2);
}
.bp5-tooltip .bp5-popover-arrow-border{
  fill:#111418;
  fill-opacity:0.1;
}
.bp5-tooltip .bp5-popover-arrow-fill{
  fill:#404854;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip{
    border:1px solid buttonborder;
  }
}
.bp5-popover-enter > .bp5-tooltip, .bp5-popover-appear > .bp5-tooltip{
  transform:scale(0.8);
}
.bp5-popover-enter-active > .bp5-tooltip, .bp5-popover-appear-active > .bp5-tooltip{
  transform:scale(1);
  transition-delay:0;
  transition-duration:100ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-popover-exit > .bp5-tooltip{
  transform:scale(1);
}
.bp5-popover-exit-active > .bp5-tooltip{
  transform:scale(0.8);
  transition-delay:0;
  transition-duration:100ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-tooltip .bp5-text-muted{
  color:#abb3bf;
}
.bp5-tooltip .bp5-text-disabled{
  color:rgba(171, 179, 191, 0.6);
}
.bp5-tooltip .bp5-running-text hr{
  border-color:rgba(255, 255, 255, 0.2);
}
.bp5-tooltip a{
  color:#8abbff;
}
.bp5-tooltip a:hover{
  color:#8abbff;
}
.bp5-tooltip a .bp5-icon, .bp5-tooltip a .bp5-icon-standard, .bp5-tooltip a .bp5-icon-large{
  color:inherit;
}
.bp5-tooltip a code{
  color:inherit;
}
.bp5-tooltip .bp5-code,
.bp5-tooltip .bp5-running-text code{
  background:rgba(17, 20, 24, 0.3);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color:#abb3bf;
}
a > .bp5-tooltip .bp5-code,
a > .bp5-tooltip .bp5-running-text code{
  color:inherit;
}
.bp5-tooltip .bp5-code-block,
.bp5-tooltip .bp5-running-text pre{
  background:rgba(17, 20, 24, 0.3);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color:#f6f7f9;
}
.bp5-tooltip .bp5-code-block > code,
.bp5-tooltip .bp5-running-text pre > code{
  background:none;
  box-shadow:none;
  color:inherit;
}
.bp5-tooltip .bp5-key,
.bp5-tooltip .bp5-running-text kbd{
  background:#383e47;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
  color:#abb3bf;
}
.bp5-tooltip .bp5-icon.bp5-intent-primary, .bp5-tooltip .bp5-icon-standard.bp5-intent-primary, .bp5-tooltip .bp5-icon-large.bp5-intent-primary{
  color:#8abbff;
}
.bp5-tooltip .bp5-icon.bp5-intent-success, .bp5-tooltip .bp5-icon-standard.bp5-intent-success, .bp5-tooltip .bp5-icon-large.bp5-intent-success{
  color:#72ca9b;
}
.bp5-tooltip .bp5-icon.bp5-intent-warning, .bp5-tooltip .bp5-icon-standard.bp5-intent-warning, .bp5-tooltip .bp5-icon-large.bp5-intent-warning{
  color:#fbb360;
}
.bp5-tooltip .bp5-icon.bp5-intent-danger, .bp5-tooltip .bp5-icon-standard.bp5-intent-danger, .bp5-tooltip .bp5-icon-large.bp5-intent-danger{
  color:#fa999c;
}
.bp5-tooltip .bp5-popover-content{
  padding:10px 12px;
}
.bp5-tooltip.bp5-compact .bp5-popover-content{
  line-height:1rem;
  padding:5px 7px;
}
.bp5-tooltip.bp5-compact .bp5-code{
  vertical-align:text-bottom;
}
.bp5-tooltip.bp5-popover-placement-top .bp5-popover-arrow{
  transform:translateY(-3px);
}
.bp5-tooltip.bp5-popover-placement-left .bp5-popover-arrow{
  transform:translateX(-3px);
}
.bp5-tooltip.bp5-popover-placement-bottom .bp5-popover-arrow{
  transform:translateY(3px);
}
.bp5-tooltip.bp5-popover-placement-right .bp5-popover-arrow{
  transform:translateX(3px);
}
.bp5-tooltip.bp5-dark, .bp5-dark .bp5-tooltip{
  box-shadow:0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp5-tooltip.bp5-dark .bp5-popover-content, .bp5-dark .bp5-tooltip .bp5-popover-content{
  background:#e5e8eb;
}
.bp5-tooltip.bp5-dark .bp5-popover-content,
.bp5-tooltip.bp5-dark .bp5-heading, .bp5-dark .bp5-tooltip .bp5-popover-content,
.bp5-dark .bp5-tooltip .bp5-heading{
  color:#404854;
}
.bp5-tooltip.bp5-dark .bp5-popover-arrow::before, .bp5-dark .bp5-tooltip .bp5-popover-arrow::before{
  box-shadow:1px 1px 6px rgba(17, 20, 24, 0.4);
}
.bp5-tooltip.bp5-dark .bp5-popover-arrow-border, .bp5-dark .bp5-tooltip .bp5-popover-arrow-border{
  fill:#111418;
  fill-opacity:0.2;
}
.bp5-tooltip.bp5-dark .bp5-popover-arrow-fill, .bp5-dark .bp5-tooltip .bp5-popover-arrow-fill{
  fill:#e5e8eb;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-dark .bp5-popover-arrow-fill, .bp5-dark .bp5-tooltip .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-dark, .bp5-dark .bp5-tooltip{
    border:1px solid buttonborder;
  }
}
.bp5-tooltip.bp5-dark .bp5-text-muted, .bp5-dark .bp5-tooltip .bp5-text-muted{
  color:#5f6b7c;
}
.bp5-tooltip.bp5-dark .bp5-text-disabled, .bp5-dark .bp5-tooltip .bp5-text-disabled{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-tooltip.bp5-dark .bp5-running-text hr, .bp5-dark .bp5-tooltip .bp5-running-text hr{
  border-color:rgba(17, 20, 24, 0.15);
}
.bp5-tooltip.bp5-dark a, .bp5-dark .bp5-tooltip a{
  color:#215db0;
}
.bp5-tooltip.bp5-dark a:hover, .bp5-dark .bp5-tooltip a:hover{
  color:#215db0;
}
.bp5-tooltip.bp5-dark a .bp5-icon, .bp5-tooltip.bp5-dark a .bp5-icon-standard, .bp5-tooltip.bp5-dark a .bp5-icon-large, .bp5-dark .bp5-tooltip a .bp5-icon, .bp5-dark .bp5-tooltip a .bp5-icon-standard, .bp5-dark .bp5-tooltip a .bp5-icon-large{
  color:inherit;
}
.bp5-tooltip.bp5-dark a code, .bp5-dark .bp5-tooltip a code{
  color:inherit;
}
.bp5-tooltip.bp5-dark .bp5-code,
.bp5-tooltip.bp5-dark .bp5-running-text code, .bp5-dark .bp5-tooltip .bp5-code,
.bp5-dark .bp5-tooltip .bp5-running-text code{
  background:rgba(255, 255, 255, 0.7);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2);
  color:#5f6b7c;
}
a > .bp5-tooltip.bp5-dark .bp5-code,
a > .bp5-tooltip.bp5-dark .bp5-running-text code, a > .bp5-dark .bp5-tooltip .bp5-code,
a > .bp5-dark .bp5-tooltip .bp5-running-text code{
  color:#2d72d2;
}
.bp5-tooltip.bp5-dark .bp5-code-block,
.bp5-tooltip.bp5-dark .bp5-running-text pre, .bp5-dark .bp5-tooltip .bp5-code-block,
.bp5-dark .bp5-tooltip .bp5-running-text pre{
  background:rgba(255, 255, 255, 0.7);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.15);
  color:#1c2127;
}
.bp5-tooltip.bp5-dark .bp5-code-block > code,
.bp5-tooltip.bp5-dark .bp5-running-text pre > code, .bp5-dark .bp5-tooltip .bp5-code-block > code,
.bp5-dark .bp5-tooltip .bp5-running-text pre > code{
  background:none;
  box-shadow:none;
  color:inherit;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-dark .bp5-code-block,
  .bp5-tooltip.bp5-dark .bp5-running-text pre, .bp5-dark .bp5-tooltip .bp5-code-block,
  .bp5-dark .bp5-tooltip .bp5-running-text pre{
    border:1px solid buttonborder;
    box-shadow:none;
  }
}
.bp5-tooltip.bp5-dark .bp5-key,
.bp5-tooltip.bp5-dark .bp5-running-text kbd, .bp5-dark .bp5-tooltip .bp5-key,
.bp5-dark .bp5-tooltip .bp5-running-text kbd{
  background:#ffffff;
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  color:#5f6b7c;
}
.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-primary, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-primary, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-primary, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-primary, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-primary, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-primary{
  color:#215db0;
}
.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-success, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-success, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-success, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-success, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-success, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-success{
  color:#1c6e42;
}
.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-warning, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-warning, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-warning, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-warning, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-warning, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-warning{
  color:#935610;
}
.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-danger, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-danger, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-danger, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-danger, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-danger, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-danger{
  color:#ac2f33;
}
.bp5-tooltip.bp5-intent-primary .bp5-popover-content{
  background:#2d72d2;
  color:#ffffff;
}
.bp5-tooltip.bp5-intent-primary .bp5-popover-arrow-fill{
  fill:#2d72d2;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-intent-primary .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
.bp5-tooltip.bp5-intent-success .bp5-popover-content{
  background:#238551;
  color:#ffffff;
}
.bp5-tooltip.bp5-intent-success .bp5-popover-arrow-fill{
  fill:#238551;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-intent-success .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
.bp5-tooltip.bp5-intent-warning .bp5-popover-content{
  background:#c87619;
  color:#ffffff;
}
.bp5-tooltip.bp5-intent-warning .bp5-popover-arrow-fill{
  fill:#c87619;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-intent-warning .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
.bp5-tooltip.bp5-intent-danger .bp5-popover-content{
  background:#cd4246;
  color:#ffffff;
}
.bp5-tooltip.bp5-intent-danger .bp5-popover-arrow-fill{
  fill:#cd4246;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-intent-danger .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}

.bp5-tooltip-indicator{
  border-bottom:dotted 1px;
  cursor:help;
}
.bp5-slider{
  height:40px;
  min-width:150px;
  width:100%;
  cursor:default;
  outline:none;
  position:relative;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.bp5-slider:hover{
  cursor:pointer;
}
.bp5-slider:active{
  cursor:grabbing;
}
.bp5-slider.bp5-disabled{
  cursor:not-allowed;
  opacity:0.5;
}
.bp5-slider.bp5-slider-unlabeled{
  height:16px;
}

.bp5-slider-track,
.bp5-slider-progress{
  height:6px;
  left:0;
  right:0;
  top:5px;
  position:absolute;
}

.bp5-slider-track{
  border-radius:2px;
  overflow:hidden;
}

.bp5-slider-progress{
  background:rgba(95, 107, 124, 0.2);
}
.bp5-dark .bp5-slider-progress{
  background:rgba(17, 20, 24, 0.5);
}
.bp5-slider-progress.bp5-intent-primary{
  background-color:#2d72d2;
}
.bp5-slider-progress.bp5-intent-success{
  background-color:#238551;
}
.bp5-slider-progress.bp5-intent-warning{
  background-color:#c87619;
}
.bp5-slider-progress.bp5-intent-danger{
  background-color:#cd4246;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-slider-progress{
    background:ButtonText;
  }
}

.bp5-slider-handle{
  background-color:#f6f7f9;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color:#1c2127;
  border-radius:2px;
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.5), 0 1px 1px rgba(17, 20, 24, 0.5);
  cursor:pointer;
  height:16px;
  left:0;
  position:absolute;
  top:0;
  width:16px;
}
.bp5-slider-handle:hover{
  background-clip:padding-box;
  background-color:#edeff2;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-slider-handle:active, .bp5-slider-handle.bp5-active{
  background-color:#dce0e5;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-slider-handle:active, .bp5-slider-handle.bp5-active{
    background:highlight;
  }
}
.bp5-slider-handle:disabled, .bp5-slider-handle.bp5-disabled{
  background-color:rgba(211, 216, 222, 0.5);
  box-shadow:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
  outline:none;
}
.bp5-slider-handle:disabled.bp5-active, .bp5-slider-handle:disabled.bp5-active:hover, .bp5-slider-handle.bp5-disabled.bp5-active, .bp5-slider-handle.bp5-disabled.bp5-active:hover{
  background:rgba(211, 216, 222, 0.7);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-slider-handle{
    border:1px solid buttonborder;
  }
}
.bp5-slider-handle:focus{
  z-index:1;
}
.bp5-slider-handle:hover{
  background-clip:padding-box;
  background-color:#edeff2;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.5), 0 1px 2px rgba(17, 20, 24, 0.6);
  cursor:grab;
  z-index:2;
}
.bp5-slider-handle.bp5-active{
  background-color:#dce0e5;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
  box-shadow:inset 0 1px 1px rgba(17, 20, 24, 0.1), 0 0 0 1px rgba(17, 20, 24, 0.5), 0 1px 2px rgba(17, 20, 24, 0.2);
  cursor:grabbing;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-slider-handle.bp5-active{
    background:highlight;
  }
}
.bp5-disabled .bp5-slider-handle{
  background:#c5cbd3;
  box-shadow:none;
  pointer-events:none;
}
.bp5-dark .bp5-slider-handle{
  background-color:#abb3bf;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp5-dark .bp5-slider-handle:hover{
  background-color:#8f99a8;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-slider-handle.bp5-active{
  background-color:#738091;
  box-shadow:inset 0 1px 1px rgba(17, 20, 24, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp5-dark .bp5-disabled .bp5-slider-handle{
  background:#5f6b7c;
  border-color:#5f6b7c;
  box-shadow:none;
}
.bp5-slider-handle .bp5-slider-label{
  background:#404854;
  border-radius:2px;
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  color:#f6f7f9;
  margin-left:8px;
}
.bp5-dark .bp5-slider-handle .bp5-slider-label{
  background:#e5e8eb;
  box-shadow:0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
  color:#404854;
}
.bp5-disabled .bp5-slider-handle .bp5-slider-label{
  box-shadow:none;
}
.bp5-slider-handle.bp5-start, .bp5-slider-handle.bp5-end{
  width:8px;
}
.bp5-slider-handle.bp5-start{
  border-bottom-right-radius:0;
  border-top-right-radius:0;
}
.bp5-slider-handle.bp5-end{
  border-bottom-left-radius:0;
  border-top-left-radius:0;
  margin-left:8px;
}
.bp5-slider-handle.bp5-end .bp5-slider-label{
  margin-left:0;
}

.bp5-slider-label{
  transform:translate(-50%, 20px);
  display:inline-block;
  font-size:12px;
  line-height:1;
  padding:2px 5px;
  position:absolute;
  vertical-align:top;
}

.bp5-slider.bp5-vertical{
  height:150px;
  min-width:40px;
  width:40px;
}
.bp5-slider.bp5-vertical .bp5-slider-track,
.bp5-slider.bp5-vertical .bp5-slider-progress{
  bottom:0;
  height:auto;
  left:5px;
  top:0;
  width:6px;
}
.bp5-slider.bp5-vertical .bp5-slider-progress{
  top:auto;
}
.bp5-slider.bp5-vertical .bp5-slider-label{
  transform:translate(20px, 50%);
}
.bp5-slider.bp5-vertical .bp5-slider-handle{
  top:auto;
}
.bp5-slider.bp5-vertical .bp5-slider-handle .bp5-slider-label{
  margin-left:0;
  margin-top:-8px;
}
.bp5-slider.bp5-vertical .bp5-slider-handle.bp5-end, .bp5-slider.bp5-vertical .bp5-slider-handle.bp5-start{
  height:8px;
  margin-left:0;
  width:16px;
}
.bp5-slider.bp5-vertical .bp5-slider-handle.bp5-start{
  border-bottom-right-radius:2px;
  border-top-left-radius:0;
}
.bp5-slider.bp5-vertical .bp5-slider-handle.bp5-start .bp5-slider-label{
  transform:translate(20px);
}
.bp5-slider.bp5-vertical .bp5-slider-handle.bp5-end{
  border-bottom-left-radius:0;
  border-bottom-right-radius:0;
  border-top-left-radius:2px;
  margin-bottom:8px;
}
@keyframes pt-spinner-animation{
  from{
    transform:rotate(0deg);
  }
  to{
    transform:rotate(360deg);
  }
}
.bp5-spinner{
  align-items:center;
  display:flex;
  justify-content:center;
  overflow:visible;
  vertical-align:middle;
}
.bp5-spinner svg{
  display:block;
}
.bp5-spinner path{
  fill-opacity:0%;
}
.bp5-spinner .bp5-spinner-head{
  stroke:rgba(95, 107, 124, 0.8);
  stroke-linecap:round;
  transform-origin:center;
  transition:stroke-dashoffset 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-spinner .bp5-spinner-track{
  stroke:rgba(95, 107, 124, 0.2);
}

.bp5-spinner-animation{
  animation:pt-spinner-animation 500ms linear infinite;
}
.bp5-no-spin > .bp5-spinner-animation{
  animation:none;
}

.bp5-dark .bp5-spinner .bp5-spinner-head{
  stroke:#8f99a8;
}
.bp5-dark .bp5-spinner .bp5-spinner-track{
  stroke:rgba(17, 20, 24, 0.5);
}

.bp5-spinner.bp5-intent-primary .bp5-spinner-head{
  stroke:#2d72d2;
}

.bp5-spinner.bp5-intent-success .bp5-spinner-head{
  stroke:#238551;
}

.bp5-spinner.bp5-intent-warning .bp5-spinner-head{
  stroke:#c87619;
}

.bp5-spinner.bp5-intent-danger .bp5-spinner-head{
  stroke:#cd4246;
}
.bp5-tabs:not(.bp5-vertical).bp5-fill{
  height:100%;
}
.bp5-tabs:not(.bp5-vertical).bp5-fill .bp5-tab-list{
  height:100%;
}

.bp5-tabs.bp5-vertical{
  display:flex;
}
.bp5-tabs.bp5-vertical > .bp5-tab-list{
  align-items:flex-start;
  flex-direction:column;
}
.bp5-tabs.bp5-vertical > .bp5-tab-list .bp5-tab{
  align-items:center;
  border-radius:2px;
  display:flex;
  padding:0 10px;
  width:100%;
}
.bp5-tabs.bp5-vertical > .bp5-tab-list .bp5-tab[aria-selected=true]{
  background-color:rgba(45, 114, 210, 0.2);
  box-shadow:none;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tabs.bp5-vertical > .bp5-tab-list .bp5-tab[aria-selected=true]{
    background-color:highlight;
    color:#111418;
  }
}
.bp5-tabs.bp5-vertical > .bp5-tab-list .bp5-tab-indicator-wrapper .bp5-tab-indicator{
  background-color:rgba(45, 114, 210, 0.2);
  border-radius:2px;
  bottom:0;
  height:auto;
  left:0;
  right:0;
  top:0;
}
.bp5-tabs.bp5-vertical > .bp5-tab-panel{
  margin-top:0;
  padding-left:20px;
}

.bp5-tab-list{
  align-items:flex-end;
  border:none;
  -moz-column-gap:20px;
       column-gap:20px;
  display:flex;
  flex:0 0 auto;
  list-style:none;
  margin:0;
  padding:0;
  position:relative;
}

.bp5-tab{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  word-wrap:normal;
  align-items:center;
  align-self:stretch;
  color:#1c2127;
  cursor:pointer;
  display:flex;
  flex:0 0 auto;
  font-size:14px;
  line-height:30px;
  max-width:100%;
  position:relative;
  vertical-align:top;
}
.bp5-tab a{
  color:inherit;
  display:block;
  text-decoration:none;
}
.bp5-tab-indicator-wrapper ~ .bp5-tab{
  background-color:transparent !important;
  box-shadow:none !important;
}
.bp5-tab[aria-disabled=true]{
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tab[aria-disabled=true]{
    color:graytext;
  }
}
.bp5-tab[aria-selected=true]{
  border-radius:0;
  box-shadow:inset 0 -3px 0 #215db0;
}
.bp5-tab[aria-selected=true], .bp5-tab:not([aria-disabled=true]):hover{
  color:#215db0;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tab[aria-selected=true], .bp5-tab:not([aria-disabled=true]):hover{
    color:highlight;
  }
}
.bp5-tab:focus{
  -moz-outline-radius:0;
}
.bp5-large > .bp5-tab{
  font-size:16px;
  line-height:40px;
}

.bp5-tab-panel{
  margin-top:20px;
}
.bp5-tab-panel[aria-hidden=true]{
  display:none;
}

.bp5-tab-icon{
  margin-right:7px;
}

.bp5-tab-tag{
  margin-left:7px;
}

.bp5-tab-indicator-wrapper{
  left:0;
  pointer-events:none;
  position:absolute;
  top:0;
  transform:translateX(0), translateY(0);
  transition:height, transform, width;
  transition-duration:200ms;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-tab-indicator-wrapper .bp5-tab-indicator{
  background-color:#215db0;
  bottom:0;
  height:3px;
  left:0;
  position:absolute;
  right:0;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tab-indicator-wrapper .bp5-tab-indicator{
    background-color:highlight;
  }
}
.bp5-tab-indicator-wrapper.bp5-no-animation{
  transition:none;
}
.bp5-tabs:not(.bp5-vertical) .bp5-tab-indicator-wrapper{
  height:100%;
}

.bp5-dark .bp5-tab{
  color:#f6f7f9;
}
.bp5-dark .bp5-tab[aria-disabled=true]{
  color:rgba(171, 179, 191, 0.6);
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-dark .bp5-tab[aria-disabled=true]{
    color:graytext;
  }
}
.bp5-dark .bp5-tab[aria-selected=true]{
  box-shadow:inset 0 -3px 0 #8abbff;
}
.bp5-dark .bp5-tab[aria-selected=true], .bp5-dark .bp5-tab:not([aria-disabled=true]):hover{
  color:#8abbff;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-dark .bp5-tab[aria-selected=true], .bp5-dark .bp5-tab:not([aria-disabled=true]):hover{
    color:highlight;
  }
}
.bp5-dark .bp5-tab-indicator{
  background-color:#8abbff;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-dark .bp5-tab-indicator{
    background-color:highlight;
  }
}

.bp5-flex-expander{
  flex:1 1;
}
.bp5-tag{
  display:inline-flex;
  flex-direction:row;
  align-items:center;
  background-color:#5f6b7c;
  border:none;
  border-radius:2px;
  box-shadow:none;
  color:#ffffff;
  font-size:12px;
  line-height:16px;
  max-width:100%;
  min-height:20px;
  min-width:20px;
  padding:2px 6px;
  position:relative;
}
.bp5-tag > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-tag > .bp5-fill{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-tag::before,
.bp5-tag > *{
  margin-right:4px;
}
.bp5-tag:empty::before,
.bp5-tag > :last-child{
  margin-right:0;
}
.bp5-tag:focus{
  outline:rgba(33, 93, 176, 0.752) solid 2px;
  outline-offset:0;
  -moz-outline-radius:6px;
}
.bp5-dark .bp5-tag:focus{
  outline-color:rgba(138, 187, 255, 0.752);
}
.bp5-tag.bp5-interactive{
  cursor:pointer;
}
.bp5-tag.bp5-interactive:hover{
  background:#404854;
}
.bp5-tag.bp5-interactive:active, .bp5-tag.bp5-interactive.bp5-active{
  background:#383e47;
}
.bp5-tag.bp5-round{
  border-radius:30px;
  padding-left:8px;
  padding-right:8px;
}
.bp5-dark .bp5-tag{
  background-color:#c5cbd3;
  color:#1c2127;
}
.bp5-dark .bp5-tag > .bp5-icon, .bp5-dark .bp5-tag .bp5-icon-standard, .bp5-dark .bp5-tag .bp5-icon-large{
  fill:currentcolor;
}
.bp5-dark .bp5-tag.bp5-interactive:hover{
  background:#abb3bf;
}
.bp5-dark .bp5-tag.bp5-interactive:active, .bp5-dark .bp5-tag.bp5-interactive.bp5-active{
  background:#8f99a8;
}
.bp5-tag > .bp5-icon, .bp5-tag .bp5-icon-standard, .bp5-tag .bp5-icon-large{
  fill:#ffffff;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tag{
    border:1px solid buttonborder;
  }
}
.bp5-tag.bp5-large, .bp5-large .bp5-tag{
  font-size:14px;
  line-height:20px;
  min-height:30px;
  min-width:30px;
  padding:5px 10px;
}
.bp5-tag.bp5-large::before,
.bp5-tag.bp5-large > *, .bp5-large .bp5-tag::before,
.bp5-large .bp5-tag > *{
  margin-right:7px;
}
.bp5-tag.bp5-large:empty::before,
.bp5-tag.bp5-large > :last-child, .bp5-large .bp5-tag:empty::before,
.bp5-large .bp5-tag > :last-child{
  margin-right:0;
}
.bp5-tag.bp5-large.bp5-round, .bp5-large .bp5-tag.bp5-round{
  padding-left:12px;
  padding-right:12px;
}
.bp5-tag.bp5-intent-primary{
  background:#2d72d2;
  color:#ffffff;
}
.bp5-tag.bp5-intent-primary.bp5-interactive:hover{
  background-color:#215db0;
}
.bp5-tag.bp5-intent-primary.bp5-interactive:active, .bp5-tag.bp5-intent-primary.bp5-interactive.bp5-active{
  background-color:#184a90;
}
.bp5-tag.bp5-intent-primary .bp5-tag-remove{
  color:rgba(255, 255, 255, 0.7);
}
.bp5-tag.bp5-intent-primary .bp5-tag-remove:hover, .bp5-tag.bp5-intent-primary .bp5-tag-remove:active{
  color:#ffffff;
}
.bp5-tag.bp5-intent-success{
  background:#238551;
  color:#ffffff;
}
.bp5-tag.bp5-intent-success.bp5-interactive:hover{
  background-color:#1c6e42;
}
.bp5-tag.bp5-intent-success.bp5-interactive:active, .bp5-tag.bp5-intent-success.bp5-interactive.bp5-active{
  background-color:#165a36;
}
.bp5-tag.bp5-intent-success .bp5-tag-remove{
  color:rgba(255, 255, 255, 0.7);
}
.bp5-tag.bp5-intent-success .bp5-tag-remove:hover, .bp5-tag.bp5-intent-success .bp5-tag-remove:active{
  color:#ffffff;
}
.bp5-tag.bp5-intent-warning{
  background:#fbb360;
  color:#1c2127;
}
.bp5-tag.bp5-intent-warning.bp5-interactive:hover{
  background-color:#ec9a3c;
}
.bp5-tag.bp5-intent-warning.bp5-interactive:active, .bp5-tag.bp5-intent-warning.bp5-interactive.bp5-active{
  background-color:#c87619;
}
.bp5-tag.bp5-intent-warning .bp5-tag-remove{
  color:rgba(28, 33, 39, 0.7);
}
.bp5-tag.bp5-intent-warning .bp5-tag-remove:hover, .bp5-tag.bp5-intent-warning .bp5-tag-remove:active{
  color:#1c2127;
}
.bp5-tag.bp5-intent-danger{
  background:#cd4246;
  color:#ffffff;
}
.bp5-tag.bp5-intent-danger.bp5-interactive:hover{
  background-color:#ac2f33;
}
.bp5-tag.bp5-intent-danger.bp5-interactive:active, .bp5-tag.bp5-intent-danger.bp5-interactive.bp5-active{
  background-color:#8e292c;
}
.bp5-tag.bp5-intent-danger .bp5-tag-remove{
  color:rgba(255, 255, 255, 0.7);
}
.bp5-tag.bp5-intent-danger .bp5-tag-remove:hover, .bp5-tag.bp5-intent-danger .bp5-tag-remove:active{
  color:#ffffff;
}
.bp5-tag.bp5-fill{
  display:flex;
  width:100%;
}
.bp5-tag.bp5-minimal > .bp5-icon, .bp5-tag.bp5-minimal .bp5-icon-standard, .bp5-tag.bp5-minimal .bp5-icon-large{
  fill:#5f6b7c;
}
.bp5-tag.bp5-minimal:not([class*=bp5-intent-]){
  background-color:rgba(143, 153, 168, 0.15);
  color:#1c2127;
}
.bp5-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive{
  cursor:pointer;
}
.bp5-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:hover{
  background-color:rgba(143, 153, 168, 0.3);
  color:#111418;
}
.bp5-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive.bp5-active, .bp5-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:active{
  background-color:rgba(143, 153, 168, 0.35);
  color:#111418;
}
.bp5-tag.bp5-minimal:not([class*=bp5-intent-]) .bp5-tag-remove{
  color:#5f6b7c;
}
.bp5-tag.bp5-minimal:not([class*=bp5-intent-]) .bp5-tag-remove:hover, .bp5-tag.bp5-minimal:not([class*=bp5-intent-]) .bp5-tag-remove:active{
  color:#404854;
}
.bp5-dark .bp5-tag.bp5-minimal:not([class*=bp5-intent-]){
  background-color:rgba(143, 153, 168, 0.15);
  color:#f6f7f9;
}
.bp5-dark .bp5-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive{
  cursor:pointer;
}
.bp5-dark .bp5-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:hover{
  background-color:rgba(143, 153, 168, 0.3);
  color:#ffffff;
}
.bp5-dark .bp5-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive.bp5-active, .bp5-dark .bp5-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:active{
  background-color:rgba(143, 153, 168, 0.35);
  color:#ffffff;
}
.bp5-dark .bp5-tag.bp5-minimal:not([class*=bp5-intent-]) .bp5-tag-remove{
  color:#abb3bf;
}
.bp5-dark .bp5-tag.bp5-minimal:not([class*=bp5-intent-]) .bp5-tag-remove:hover, .bp5-dark .bp5-tag.bp5-minimal:not([class*=bp5-intent-]) .bp5-tag-remove:active{
  color:#d3d8de;
}
.bp5-tag.bp5-minimal.bp5-intent-primary{
  background-color:rgba(45, 114, 210, 0.1);
  color:#215db0;
}
.bp5-tag.bp5-minimal.bp5-intent-primary > .bp5-icon, .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-icon-standard, .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-icon-large{
  fill:#215db0;
}
.bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover{
  background-color:rgba(45, 114, 210, 0.2);
  color:#184a90;
}
.bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active, .bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active{
  background-color:rgba(45, 114, 210, 0.3);
  color:#184a90;
}
.bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove{
  color:#215db0;
}
.bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove:hover, .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove:active{
  color:#184a90;
}
.bp5-tag.bp5-minimal.bp5-intent-success{
  background-color:rgba(35, 133, 81, 0.1);
  color:#1c6e42;
}
.bp5-tag.bp5-minimal.bp5-intent-success > .bp5-icon, .bp5-tag.bp5-minimal.bp5-intent-success .bp5-icon-standard, .bp5-tag.bp5-minimal.bp5-intent-success .bp5-icon-large{
  fill:#1c6e42;
}
.bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover{
  background-color:rgba(35, 133, 81, 0.2);
  color:#165a36;
}
.bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active, .bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active{
  background-color:rgba(35, 133, 81, 0.3);
  color:#165a36;
}
.bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove{
  color:#1c6e42;
}
.bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove:hover, .bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove:active{
  color:#165a36;
}
.bp5-tag.bp5-minimal.bp5-intent-warning{
  background-color:rgba(200, 118, 25, 0.1);
  color:#935610;
}
.bp5-tag.bp5-minimal.bp5-intent-warning > .bp5-icon, .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-icon-standard, .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-icon-large{
  fill:#935610;
}
.bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover{
  background-color:rgba(200, 118, 25, 0.2);
  color:#77450d;
}
.bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active, .bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active{
  background-color:rgba(200, 118, 25, 0.3);
  color:#77450d;
}
.bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove{
  color:#935610;
}
.bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove:hover, .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove:active{
  color:#77450d;
}
.bp5-tag.bp5-minimal.bp5-intent-danger{
  background-color:rgba(205, 66, 70, 0.1);
  color:#ac2f33;
}
.bp5-tag.bp5-minimal.bp5-intent-danger > .bp5-icon, .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-icon-standard, .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-icon-large{
  fill:#ac2f33;
}
.bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover{
  background-color:rgba(205, 66, 70, 0.2);
  color:#8e292c;
}
.bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active, .bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active{
  background-color:rgba(205, 66, 70, 0.3);
  color:#8e292c;
}
.bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove{
  color:#ac2f33;
}
.bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove:hover, .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove:active{
  color:#8e292c;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary{
  background-color:rgba(45, 114, 210, 0.2);
  color:#8abbff;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover{
  background-color:rgba(45, 114, 210, 0.3);
  color:#99c4ff;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active{
  background-color:rgba(45, 114, 210, 0.35);
  color:#99c4ff;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove{
  color:#8abbff;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove:hover, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove:active{
  color:#99c4ff;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success{
  background-color:rgba(35, 133, 81, 0.2);
  color:#72ca9b;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover{
  background-color:rgba(35, 133, 81, 0.3);
  color:#7cd7a2;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active{
  background-color:rgba(35, 133, 81, 0.35);
  color:#7cd7a2;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove{
  color:#72ca9b;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove:hover, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove:active{
  color:#7cd7a2;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning{
  background-color:rgba(200, 118, 25, 0.2);
  color:#fbb360;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover{
  background-color:rgba(200, 118, 25, 0.3);
  color:#f5c186;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active{
  background-color:rgba(200, 118, 25, 0.35);
  color:#f5c186;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove{
  color:#fbb360;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove:hover, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove:active{
  color:#f5c186;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger{
  background-color:rgba(205, 66, 70, 0.2);
  color:#fa999c;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover{
  background-color:rgba(205, 66, 70, 0.3);
  color:#ffa1a4;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active{
  background-color:rgba(205, 66, 70, 0.35);
  color:#ffa1a4;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove{
  color:#fa999c;
}
.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove:hover, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove:active{
  color:#ffa1a4;
}

.bp5-tag-remove{
  background:none;
  border:none;
  color:rgba(255, 255, 255, 0.7);
  cursor:pointer;
  display:flex;
  margin-bottom:-2px;
  margin-right:-6px !important;
  margin-top:-2px;
  padding:2px;
  padding-left:0;
}
.bp5-tag-remove:hover{
  background:none;
  color:inherit;
  text-decoration:none;
}
.bp5-tag-remove .bp5-icon:first-child{
  color:inherit;
}
.bp5-tag-remove:hover, .bp5-tag-remove:active{
  color:#ffffff;
}
.bp5-tag-remove:empty::before{
  font-family:"blueprint-icons-16", sans-serif;
  font-size:16px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:16px;
  line-height:1;
  width:16px;
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  content:"\f2ba";
}
.bp5-large .bp5-tag-remove{
  margin-right:-10px !important;
  padding:0 5px 0 0;
}
.bp5-large .bp5-tag-remove:empty::before{
  font-family:"blueprint-icons-20", sans-serif;
  font-size:20px;
  font-style:normal;
  font-variant:normal;
  font-weight:400;
  height:20px;
  line-height:1;
  width:20px;
}
.bp5-dark .bp5-tag-remove{
  color:rgba(28, 33, 39, 0.7);
}
.bp5-dark .bp5-tag-remove:hover, .bp5-dark .bp5-tag-remove:active{
  color:#1c2127;
}
.bp5-compound-tag{
  background:none;
  padding:0;
}
.bp5-compound-tag .bp5-compound-tag-left{
  background-color:#404854;
}
.bp5-compound-tag .bp5-compound-tag-right{
  background-color:#5f6b7c;
}
.bp5-compound-tag.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:#383e47;
}
.bp5-compound-tag.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:#404854;
}
.bp5-compound-tag.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:#2f343c;
}
.bp5-compound-tag.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:#383e47;
}
.bp5-compound-tag .bp5-compound-tag-left,
.bp5-compound-tag .bp5-compound-tag-right{
  align-items:center;
  display:inline-flex;
  padding:2px 4px;
}
.bp5-compound-tag .bp5-compound-tag-left{
  border-bottom-left-radius:2px;
  border-top-left-radius:2px;
  margin-right:0;
}
.bp5-compound-tag .bp5-compound-tag-left > .bp5-icon, .bp5-compound-tag .bp5-compound-tag-left .bp5-icon-standard, .bp5-compound-tag .bp5-compound-tag-left .bp5-icon-large{
  margin-right:4px;
}
.bp5-compound-tag .bp5-compound-tag-right{
  border-bottom-right-radius:2px;
  border-top-right-radius:2px;
  flex-grow:1;
  padding:2px 4px;
}
.bp5-compound-tag .bp5-compound-tag-right > .bp5-icon, .bp5-compound-tag .bp5-compound-tag-right .bp5-icon-standard, .bp5-compound-tag .bp5-compound-tag-right .bp5-icon-large{
  margin-left:4px;
}
.bp5-compound-tag .bp5-compound-tag-right .bp5-compound-tag-right-text{
  flex-grow:1;
}
.bp5-compound-tag .bp5-compound-tag-right .bp5-tag-remove{
  margin-left:2px;
  margin-right:-4px !important;
}
.bp5-compound-tag.bp5-round{
  padding:0;
}
.bp5-compound-tag.bp5-round .bp5-compound-tag-left{
  border-bottom-left-radius:20px;
  border-top-left-radius:20px;
  padding-left:8px;
}
.bp5-compound-tag.bp5-round .bp5-compound-tag-right{
  border-bottom-right-radius:20px;
  border-top-right-radius:20px;
  padding-right:8px;
}
.bp5-compound-tag.bp5-large{
  padding:0;
}
.bp5-compound-tag.bp5-large .bp5-compound-tag-left,
.bp5-compound-tag.bp5-large .bp5-compound-tag-right{
  padding:5px 8px;
}
.bp5-compound-tag.bp5-large .bp5-compound-tag-left > .bp5-icon, .bp5-compound-tag.bp5-large .bp5-compound-tag-left .bp5-icon-standard, .bp5-compound-tag.bp5-large .bp5-compound-tag-left .bp5-icon-large{
  margin-right:7px;
}
.bp5-compound-tag.bp5-large .bp5-compound-tag-right > .bp5-icon, .bp5-compound-tag.bp5-large .bp5-compound-tag-right .bp5-icon-standard, .bp5-compound-tag.bp5-large .bp5-compound-tag-right .bp5-icon-large{
  margin-left:7px;
}
.bp5-compound-tag.bp5-large .bp5-tag-remove{
  margin-left:7px;
  margin-right:-10px !important;
}
.bp5-compound-tag.bp5-large.bp5-round{
  padding:0;
}
.bp5-compound-tag.bp5-large.bp5-round .bp5-compound-tag-left{
  border-bottom-left-radius:30px;
  border-top-left-radius:30px;
  padding-left:12px;
}
.bp5-compound-tag.bp5-large.bp5-round .bp5-compound-tag-right{
  border-bottom-right-radius:30px;
  border-top-right-radius:30px;
  padding-right:12px;
}
.bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]){
  background:none;
}
.bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]) .bp5-compound-tag-left{
  background-color:rgba(95, 107, 124, 0.2);
}
.bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]) .bp5-compound-tag-right{
  background-color:rgba(95, 107, 124, 0.1);
}
.bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:hover .bp5-compound-tag-left{
  background-color:rgba(95, 107, 124, 0.3);
}
.bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:hover .bp5-compound-tag-right{
  background-color:rgba(95, 107, 124, 0.2);
}
.bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:rgba(95, 107, 124, 0.4);
}
.bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:rgba(95, 107, 124, 0.3);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-primary{
  background:none;
}
.bp5-compound-tag.bp5-minimal.bp5-intent-primary .bp5-compound-tag-left{
  background-color:rgba(45, 114, 210, 0.2);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-primary .bp5-compound-tag-right{
  background-color:rgba(45, 114, 210, 0.1);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:rgba(45, 114, 210, 0.3);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:rgba(45, 114, 210, 0.2);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:rgba(45, 114, 210, 0.4);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:rgba(45, 114, 210, 0.3);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-success{
  background:none;
}
.bp5-compound-tag.bp5-minimal.bp5-intent-success .bp5-compound-tag-left{
  background-color:rgba(35, 133, 81, 0.2);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-success .bp5-compound-tag-right{
  background-color:rgba(35, 133, 81, 0.1);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:rgba(35, 133, 81, 0.3);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:rgba(35, 133, 81, 0.2);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:rgba(35, 133, 81, 0.4);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:rgba(35, 133, 81, 0.3);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-warning{
  background:none;
}
.bp5-compound-tag.bp5-minimal.bp5-intent-warning .bp5-compound-tag-left{
  background-color:rgba(200, 118, 25, 0.2);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-warning .bp5-compound-tag-right{
  background-color:rgba(200, 118, 25, 0.1);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:rgba(200, 118, 25, 0.3);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:rgba(200, 118, 25, 0.2);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:rgba(200, 118, 25, 0.4);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:rgba(200, 118, 25, 0.3);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-danger{
  background:none;
}
.bp5-compound-tag.bp5-minimal.bp5-intent-danger .bp5-compound-tag-left{
  background-color:rgba(205, 66, 70, 0.2);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-danger .bp5-compound-tag-right{
  background-color:rgba(205, 66, 70, 0.1);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:rgba(205, 66, 70, 0.3);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:rgba(205, 66, 70, 0.2);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:rgba(205, 66, 70, 0.4);
}
.bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:rgba(205, 66, 70, 0.3);
}
.bp5-dark .bp5-compound-tag{
  background:none;
}
.bp5-dark .bp5-compound-tag .bp5-compound-tag-left{
  background-color:#abb3bf;
}
.bp5-dark .bp5-compound-tag .bp5-compound-tag-right{
  background-color:#c5cbd3;
}
.bp5-dark .bp5-compound-tag.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:#8f99a8;
}
.bp5-dark .bp5-compound-tag.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:#abb3bf;
}
.bp5-dark .bp5-compound-tag.bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:#738091;
}
.bp5-dark .bp5-compound-tag.bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:gray3;
}
.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]){
  background:none;
}
.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]) .bp5-compound-tag-left{
  background-color:rgba(95, 107, 124, 0.4);
}
.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]) .bp5-compound-tag-right{
  background-color:rgba(95, 107, 124, 0.2);
}
.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:hover .bp5-compound-tag-left{
  background-color:rgba(95, 107, 124, 0.5);
}
.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:hover .bp5-compound-tag-right{
  background-color:rgba(95, 107, 124, 0.3);
}
.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:rgba(95, 107, 124, 0.55);
}
.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-minimal:not([class*=bp5-intent-]).bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:rgba(95, 107, 124, 0.35);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary{
  background:none;
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary .bp5-compound-tag-left{
  background-color:rgba(45, 114, 210, 0.4);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary .bp5-compound-tag-right{
  background-color:rgba(45, 114, 210, 0.2);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:rgba(45, 114, 210, 0.5);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:rgba(45, 114, 210, 0.3);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:rgba(45, 114, 210, 0.55);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:rgba(45, 114, 210, 0.35);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success{
  background:none;
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success .bp5-compound-tag-left{
  background-color:rgba(35, 133, 81, 0.4);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success .bp5-compound-tag-right{
  background-color:rgba(35, 133, 81, 0.2);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:rgba(35, 133, 81, 0.5);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:rgba(35, 133, 81, 0.3);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:rgba(35, 133, 81, 0.55);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:rgba(35, 133, 81, 0.35);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning{
  background:none;
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning .bp5-compound-tag-left{
  background-color:rgba(200, 118, 25, 0.4);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning .bp5-compound-tag-right{
  background-color:rgba(200, 118, 25, 0.2);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:rgba(200, 118, 25, 0.5);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:rgba(200, 118, 25, 0.3);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:rgba(200, 118, 25, 0.55);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:rgba(200, 118, 25, 0.35);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger{
  background:none;
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger .bp5-compound-tag-left{
  background-color:rgba(205, 66, 70, 0.4);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger .bp5-compound-tag-right{
  background-color:rgba(205, 66, 70, 0.2);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:rgba(205, 66, 70, 0.5);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:rgba(205, 66, 70, 0.3);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:rgba(205, 66, 70, 0.55);
}
.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:rgba(205, 66, 70, 0.35);
}
.bp5-compound-tag.bp5-intent-primary{
  background:none;
}
.bp5-compound-tag.bp5-intent-primary .bp5-compound-tag-left{
  background-color:#215db0;
}
.bp5-compound-tag.bp5-intent-primary .bp5-compound-tag-right{
  background-color:#2d72d2;
}
.bp5-compound-tag.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:#184a90;
}
.bp5-compound-tag.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:#215db0;
}
.bp5-compound-tag.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:#11376b;
}
.bp5-compound-tag.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:#184a90;
}
.bp5-compound-tag.bp5-intent-success{
  background:none;
}
.bp5-compound-tag.bp5-intent-success .bp5-compound-tag-left{
  background-color:#1c6e42;
}
.bp5-compound-tag.bp5-intent-success .bp5-compound-tag-right{
  background-color:#238551;
}
.bp5-compound-tag.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:#165a36;
}
.bp5-compound-tag.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:#1c6e42;
}
.bp5-compound-tag.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:#0f3e25;
}
.bp5-compound-tag.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:#165a36;
}
.bp5-compound-tag.bp5-intent-warning{
  background:none;
}
.bp5-compound-tag.bp5-intent-warning .bp5-compound-tag-left{
  background-color:#ec9a3c;
}
.bp5-compound-tag.bp5-intent-warning .bp5-compound-tag-right{
  background-color:#fbb360;
}
.bp5-compound-tag.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:#c87619;
}
.bp5-compound-tag.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:#ec9a3c;
}
.bp5-compound-tag.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:#935610;
}
.bp5-compound-tag.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:#c87619;
}
.bp5-compound-tag.bp5-intent-danger{
  background:none;
}
.bp5-compound-tag.bp5-intent-danger .bp5-compound-tag-left{
  background-color:#ac2f33;
}
.bp5-compound-tag.bp5-intent-danger .bp5-compound-tag-right{
  background-color:#cd4246;
}
.bp5-compound-tag.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-left{
  background-color:#8e292c;
}
.bp5-compound-tag.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-right{
  background-color:#ac2f33;
}
.bp5-compound-tag.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-left{
  background-color:#782326;
}
.bp5-compound-tag.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-right{
  background-color:#8e292c;
}
.bp5-tag-input{
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  cursor:text;
  height:auto;
  line-height:inherit;
  min-height:30px;
  padding-left:5px;
  padding-right:0;
}
.bp5-tag-input > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-tag-input > .bp5-tag-input-values{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-tag-input .bp5-tag-input-icon{
  color:#5f6b7c;
  margin-left:2px;
  margin-right:7px;
  margin-top:7px;
}
.bp5-tag-input .bp5-tag-input-values{
  display:flex;
  flex-direction:row;
  align-items:center;
  align-self:stretch;
  flex-wrap:wrap;
  margin-right:7px;
  margin-top:5px;
  min-width:0;
  position:relative;
}
.bp5-tag-input .bp5-tag-input-values > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-tag-input .bp5-tag-input-values > .bp5-fill{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-tag-input .bp5-tag-input-values::before,
.bp5-tag-input .bp5-tag-input-values > *{
  margin-right:5px;
}
.bp5-tag-input .bp5-tag-input-values:empty::before,
.bp5-tag-input .bp5-tag-input-values > :last-child{
  margin-right:0;
}
.bp5-tag-input .bp5-tag-input-values:first-child .bp5-tag ~ .bp5-input-ghost{
  padding-left:0;
}
.bp5-tag-input .bp5-tag-input-values:first-child .bp5-input-ghost{
  padding-left:5px;
}
.bp5-tag-input .bp5-tag-input-values > *{
  margin-bottom:5px;
}
.bp5-tag-input .bp5-tag{
  overflow-wrap:break-word;
}
.bp5-tag-input .bp5-tag.bp5-active{
  outline:rgba(33, 93, 176, 0.752) solid 2px;
  outline-offset:0;
  -moz-outline-radius:6px;
}
.bp5-dark .bp5-tag-input .bp5-tag.bp5-active{
  outline-color:rgba(138, 187, 255, 0.752);
}
.bp5-tag-input .bp5-input-ghost{
  flex:1 1 auto;
  line-height:20px;
  width:80px;
}
.bp5-tag-input .bp5-input-ghost:disabled, .bp5-tag-input .bp5-input-ghost.bp5-disabled{
  cursor:not-allowed;
}
.bp5-tag-input .bp5-button,
.bp5-tag-input .bp5-spinner{
  margin:3px;
  margin-left:0;
}
.bp5-tag-input .bp5-button{
  min-height:24px;
  min-width:24px;
  padding:0 7px;
}
.bp5-tag-input.bp5-large{
  height:auto;
  min-height:40px;
}
.bp5-tag-input.bp5-large::before,
.bp5-tag-input.bp5-large > *{
  margin-right:10px;
}
.bp5-tag-input.bp5-large:empty::before,
.bp5-tag-input.bp5-large > :last-child{
  margin-right:0;
}
.bp5-tag-input.bp5-large .bp5-tag-input-icon{
  margin-left:5px;
  margin-top:10px;
}
.bp5-tag-input.bp5-large .bp5-input-ghost{
  line-height:30px;
}
.bp5-tag-input.bp5-large .bp5-button{
  min-height:30px;
  min-width:30px;
  padding:5px 10px;
  margin:5px;
  margin-left:0;
}
.bp5-tag-input.bp5-large .bp5-spinner{
  margin:8px;
  margin-left:0;
}
.bp5-tag-input.bp5-active{
  background-color:#ffffff;
  box-shadow:inset 0 0 0 1px rgba(33, 93, 176, 0.752), 0 0 0 1px rgba(33, 93, 176, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-tag-input.bp5-active.bp5-intent-primary{
  box-shadow:inset 0 0 0 1px rgba(45, 114, 210, 0.752), 0 0 0 1px rgba(45, 114, 210, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-tag-input.bp5-active.bp5-intent-success{
  box-shadow:inset 0 0 0 1px rgba(35, 133, 81, 0.752), 0 0 0 1px rgba(35, 133, 81, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-tag-input.bp5-active.bp5-intent-warning{
  box-shadow:inset 0 0 0 1px rgba(200, 118, 25, 0.752), 0 0 0 1px rgba(200, 118, 25, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-tag-input.bp5-active.bp5-intent-danger{
  box-shadow:inset 0 0 0 1px rgba(205, 66, 70, 0.752), 0 0 0 1px rgba(205, 66, 70, 0.752), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp5-dark .bp5-tag-input .bp5-tag-input-icon, .bp5-tag-input.bp5-dark .bp5-tag-input-icon{
  color:#abb3bf;
}
.bp5-dark .bp5-tag-input .bp5-input-ghost, .bp5-tag-input.bp5-dark .bp5-input-ghost{
  color:#f6f7f9;
}
.bp5-dark .bp5-tag-input .bp5-input-ghost::-moz-placeholder, .bp5-tag-input.bp5-dark .bp5-input-ghost::-moz-placeholder{
  color:#abb3bf;
}
.bp5-dark .bp5-tag-input .bp5-input-ghost:-ms-input-placeholder, .bp5-tag-input.bp5-dark .bp5-input-ghost:-ms-input-placeholder{
  color:#abb3bf;
}
.bp5-dark .bp5-tag-input .bp5-input-ghost::placeholder, .bp5-tag-input.bp5-dark .bp5-input-ghost::placeholder{
  color:#abb3bf;
}
.bp5-dark .bp5-tag-input.bp5-active, .bp5-tag-input.bp5-dark.bp5-active{
  background-color:rgba(17, 20, 24, 0.3);
  box-shadow:inset 0 0 0 1px rgba(138, 187, 255, 0.752), 0 0 0 1px rgba(138, 187, 255, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-tag-input.bp5-active.bp5-intent-primary, .bp5-tag-input.bp5-dark.bp5-active.bp5-intent-primary{
  box-shadow:inset 0 0 0 1px rgba(76, 144, 240, 0.752), 0 0 0 1px rgba(76, 144, 240, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-tag-input.bp5-active.bp5-intent-success, .bp5-tag-input.bp5-dark.bp5-active.bp5-intent-success{
  box-shadow:inset 0 0 0 1px rgba(50, 164, 103, 0.752), 0 0 0 1px rgba(50, 164, 103, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-tag-input.bp5-active.bp5-intent-warning, .bp5-tag-input.bp5-dark.bp5-active.bp5-intent-warning{
  box-shadow:inset 0 0 0 1px rgba(236, 154, 60, 0.752), 0 0 0 1px rgba(236, 154, 60, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}
.bp5-dark .bp5-tag-input.bp5-active.bp5-intent-danger, .bp5-tag-input.bp5-dark.bp5-active.bp5-intent-danger{
  box-shadow:inset 0 0 0 1px rgba(231, 106, 110, 0.752), 0 0 0 1px rgba(231, 106, 110, 0.752), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
}

.bp5-input-ghost{
  background:none;
  border:none;
  box-shadow:none;
  padding:0;
}
.bp5-input-ghost::-moz-placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-input-ghost:-ms-input-placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-input-ghost::placeholder{
  color:#5f6b7c;
  opacity:1;
}
.bp5-input-ghost:focus{
  outline:none !important;
}
.bp5-resizable-input-span{
  max-height:0;
  max-width:100%;
  min-width:80px;
  opacity:0;
  overflow:hidden;
  position:absolute;
  white-space:nowrap;
  z-index:-1;
}
.bp5-toast{
  align-items:flex-start;
  background-color:#ffffff;
  border-radius:2px;
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  display:flex;
  margin:20px 0 0;
  max-width:500px;
  min-width:300px;
  pointer-events:all;
  position:relative !important;
}
.bp5-toast.bp5-toast-enter, .bp5-toast.bp5-toast-appear{
  transform:translateY(-40px);
}
.bp5-toast.bp5-toast-enter-active, .bp5-toast.bp5-toast-appear-active{
  transform:translateY(0);
  transition-delay:0;
  transition-duration:300ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp5-toast.bp5-toast-enter ~ .bp5-toast, .bp5-toast.bp5-toast-appear ~ .bp5-toast{
  transform:translateY(-40px);
}
.bp5-toast.bp5-toast-enter-active ~ .bp5-toast, .bp5-toast.bp5-toast-appear-active ~ .bp5-toast{
  transform:translateY(0);
  transition-delay:0;
  transition-duration:300ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp5-toast.bp5-toast-exit{
  opacity:1;
  filter:blur(0);
}
.bp5-toast.bp5-toast-exit-active{
  opacity:0;
  filter:blur(10px);
  transition-delay:0;
  transition-duration:300ms;
  transition-property:opacity, filter;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-toast.bp5-toast-exit ~ .bp5-toast{
  transform:translateY(0);
}
.bp5-toast.bp5-toast-exit-active ~ .bp5-toast{
  transform:translateY(-40px);
  transition-delay:50ms;
  transition-duration:100ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-toast .bp5-button-group{
  flex:0 0 auto;
  padding:5px;
  padding-left:0;
}
.bp5-toast > .bp5-icon{
  color:#5f6b7c;
  margin:12px;
  margin-right:0;
}
.bp5-toast.bp5-dark, .bp5-dark .bp5-toast{
  background-color:#404854;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp5-toast.bp5-dark > .bp5-icon, .bp5-dark .bp5-toast > .bp5-icon{
  color:#abb3bf;
}
.bp5-toast.bp5-dark .bp5-button .bp5-icon, .bp5-dark .bp5-toast .bp5-button .bp5-icon{
  color:rgba(255, 255, 255, 0.7);
}
.bp5-toast[class*=bp5-intent-] a{
  color:rgba(255, 255, 255, 0.7);
}
.bp5-toast[class*=bp5-intent-] a:hover{
  color:#ffffff;
}
.bp5-toast[class*=bp5-intent-] > .bp5-icon{
  color:#ffffff;
}
.bp5-toast.bp5-intent-primary{
  background-color:#2d72d2;
  color:#ffffff;
}
.bp5-toast.bp5-intent-primary .bp5-icon:first-child{
  color:rgba(255, 255, 255, 0.7);
}
.bp5-toast.bp5-intent-primary .bp5-button{
  background-color:#2d72d2 !important;
  color:#ffffff !important;
}
.bp5-toast.bp5-intent-primary .bp5-button:hover{
  background-color:#215db0 !important;
  color:#ffffff !important;
}
.bp5-toast.bp5-intent-primary .bp5-button:active{
  background-color:#184a90 !important;
  color:#ffffff !important;
}
.bp5-toast.bp5-intent-primary .bp5-button:focus{
  outline-color:rgba(255, 255, 255, 0.75);
}
.bp5-toast.bp5-intent-primary .bp5-button:last-child > .bp5-icon-cross{
  color:rgba(255, 255, 255, 0.7) !important;
}
.bp5-toast.bp5-intent-success{
  background-color:#238551;
  color:#ffffff;
}
.bp5-toast.bp5-intent-success .bp5-icon:first-child{
  color:rgba(255, 255, 255, 0.7);
}
.bp5-toast.bp5-intent-success .bp5-button{
  background-color:#238551 !important;
  color:#ffffff !important;
}
.bp5-toast.bp5-intent-success .bp5-button:hover{
  background-color:#1c6e42 !important;
  color:#ffffff !important;
}
.bp5-toast.bp5-intent-success .bp5-button:active{
  background-color:#165a36 !important;
  color:#ffffff !important;
}
.bp5-toast.bp5-intent-success .bp5-button:focus{
  outline-color:rgba(255, 255, 255, 0.75);
}
.bp5-toast.bp5-intent-success .bp5-button:last-child > .bp5-icon-cross{
  color:rgba(255, 255, 255, 0.7) !important;
}
.bp5-toast.bp5-intent-warning{
  background-color:#fbb360;
  color:#1c2127;
}
.bp5-toast.bp5-intent-warning .bp5-icon:first-child{
  color:rgba(28, 33, 39, 0.7);
}
.bp5-toast.bp5-intent-warning .bp5-button{
  background-color:#fbb360 !important;
  color:#1c2127 !important;
}
.bp5-toast.bp5-intent-warning .bp5-button:hover{
  background-color:#ec9a3c !important;
  color:#1c2127 !important;
}
.bp5-toast.bp5-intent-warning .bp5-button:active{
  background-color:#c87619 !important;
  color:#1c2127 !important;
}
.bp5-toast.bp5-intent-warning .bp5-button:focus{
  outline-color:rgba(28, 33, 39, 0.75);
}
.bp5-toast.bp5-intent-warning .bp5-button:last-child > .bp5-icon-cross{
  color:rgba(28, 33, 39, 0.7) !important;
}
.bp5-toast.bp5-intent-danger{
  background-color:#cd4246;
  color:#ffffff;
}
.bp5-toast.bp5-intent-danger .bp5-icon:first-child{
  color:rgba(255, 255, 255, 0.7);
}
.bp5-toast.bp5-intent-danger .bp5-button{
  background-color:#cd4246 !important;
  color:#ffffff !important;
}
.bp5-toast.bp5-intent-danger .bp5-button:hover{
  background-color:#ac2f33 !important;
  color:#ffffff !important;
}
.bp5-toast.bp5-intent-danger .bp5-button:active{
  background-color:#8e292c !important;
  color:#ffffff !important;
}
.bp5-toast.bp5-intent-danger .bp5-button:focus{
  outline-color:rgba(255, 255, 255, 0.75);
}
.bp5-toast.bp5-intent-danger .bp5-button:last-child > .bp5-icon-cross{
  color:rgba(255, 255, 255, 0.7) !important;
}

.bp5-toast-message{
  flex:1 1 auto;
  padding:11px;
  word-break:break-word;
}

.bp5-toast-container{
  align-items:center;
  display:flex !important;
  flex-direction:column;
  left:0;
  overflow:hidden;
  padding:0 20px 20px;
  pointer-events:none;
  right:0;
  z-index:40;
}
.bp5-toast-container.bp5-toast-container-in-portal{
  position:fixed;
}
.bp5-toast-container.bp5-toast-container-inline{
  position:absolute;
}
.bp5-toast-container.bp5-toast-container-top{
  top:0;
}
.bp5-toast-container.bp5-toast-container-bottom{
  bottom:0;
  flex-direction:column-reverse;
  top:auto;
}
.bp5-toast-container.bp5-toast-container-left{
  align-items:flex-start;
}
.bp5-toast-container.bp5-toast-container-right{
  align-items:flex-end;
}

.bp5-toast-container-bottom .bp5-toast.bp5-toast-enter:not(.bp5-toast-enter-active), .bp5-toast-container-bottom .bp5-toast.bp5-toast-enter:not(.bp5-toast-enter-active) ~ .bp5-toast, .bp5-toast-container-bottom .bp5-toast.bp5-toast-appear:not(.bp5-toast-appear-active), .bp5-toast-container-bottom .bp5-toast.bp5-toast-appear:not(.bp5-toast-appear-active) ~ .bp5-toast, .bp5-toast-container-bottom .bp5-toast.bp5-toast-exit-active ~ .bp5-toast, .bp5-toast-container-bottom .bp5-toast.bp5-toast-leave-active ~ .bp5-toast{
  transform:translateY(60px);
}
.bp5-tooltip{
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  transform:scale(1);
  color:#f6f7f9;
}
.bp5-tooltip .bp5-popover-arrow{
  height:22px;
  position:absolute;
  width:22px;
}
.bp5-tooltip .bp5-popover-arrow::before{
  height:14px;
  margin:4px;
  width:14px;
}
.bp5-tooltip .bp5-popover-content{
  background:#404854;
}
.bp5-tooltip .bp5-popover-content,
.bp5-tooltip .bp5-heading{
  color:#f6f7f9;
}
.bp5-tooltip .bp5-popover-arrow::before{
  box-shadow:1px 1px 6px rgba(17, 20, 24, 0.2);
}
.bp5-tooltip .bp5-popover-arrow-border{
  fill:#111418;
  fill-opacity:0.1;
}
.bp5-tooltip .bp5-popover-arrow-fill{
  fill:#404854;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip{
    border:1px solid buttonborder;
  }
}
.bp5-popover-enter > .bp5-tooltip, .bp5-popover-appear > .bp5-tooltip{
  transform:scale(0.8);
}
.bp5-popover-enter-active > .bp5-tooltip, .bp5-popover-appear-active > .bp5-tooltip{
  transform:scale(1);
  transition-delay:0;
  transition-duration:100ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-popover-exit > .bp5-tooltip{
  transform:scale(1);
}
.bp5-popover-exit-active > .bp5-tooltip{
  transform:scale(0.8);
  transition-delay:0;
  transition-duration:100ms;
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-tooltip .bp5-text-muted{
  color:#abb3bf;
}
.bp5-tooltip .bp5-text-disabled{
  color:rgba(171, 179, 191, 0.6);
}
.bp5-tooltip .bp5-running-text hr{
  border-color:rgba(255, 255, 255, 0.2);
}
.bp5-tooltip a{
  color:#8abbff;
}
.bp5-tooltip a:hover{
  color:#8abbff;
}
.bp5-tooltip a .bp5-icon, .bp5-tooltip a .bp5-icon-standard, .bp5-tooltip a .bp5-icon-large{
  color:inherit;
}
.bp5-tooltip a code{
  color:inherit;
}
.bp5-tooltip .bp5-code,
.bp5-tooltip .bp5-running-text code{
  background:rgba(17, 20, 24, 0.3);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color:#abb3bf;
}
a > .bp5-tooltip .bp5-code,
a > .bp5-tooltip .bp5-running-text code{
  color:inherit;
}
.bp5-tooltip .bp5-code-block,
.bp5-tooltip .bp5-running-text pre{
  background:rgba(17, 20, 24, 0.3);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color:#f6f7f9;
}
.bp5-tooltip .bp5-code-block > code,
.bp5-tooltip .bp5-running-text pre > code{
  background:none;
  box-shadow:none;
  color:inherit;
}
.bp5-tooltip .bp5-key,
.bp5-tooltip .bp5-running-text kbd{
  background:#383e47;
  box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
  color:#abb3bf;
}
.bp5-tooltip .bp5-icon.bp5-intent-primary, .bp5-tooltip .bp5-icon-standard.bp5-intent-primary, .bp5-tooltip .bp5-icon-large.bp5-intent-primary{
  color:#8abbff;
}
.bp5-tooltip .bp5-icon.bp5-intent-success, .bp5-tooltip .bp5-icon-standard.bp5-intent-success, .bp5-tooltip .bp5-icon-large.bp5-intent-success{
  color:#72ca9b;
}
.bp5-tooltip .bp5-icon.bp5-intent-warning, .bp5-tooltip .bp5-icon-standard.bp5-intent-warning, .bp5-tooltip .bp5-icon-large.bp5-intent-warning{
  color:#fbb360;
}
.bp5-tooltip .bp5-icon.bp5-intent-danger, .bp5-tooltip .bp5-icon-standard.bp5-intent-danger, .bp5-tooltip .bp5-icon-large.bp5-intent-danger{
  color:#fa999c;
}
.bp5-tooltip .bp5-popover-content{
  padding:10px 12px;
}
.bp5-tooltip.bp5-compact .bp5-popover-content{
  line-height:1rem;
  padding:5px 7px;
}
.bp5-tooltip.bp5-compact .bp5-code{
  vertical-align:text-bottom;
}
.bp5-tooltip.bp5-popover-placement-top .bp5-popover-arrow{
  transform:translateY(-3px);
}
.bp5-tooltip.bp5-popover-placement-left .bp5-popover-arrow{
  transform:translateX(-3px);
}
.bp5-tooltip.bp5-popover-placement-bottom .bp5-popover-arrow{
  transform:translateY(3px);
}
.bp5-tooltip.bp5-popover-placement-right .bp5-popover-arrow{
  transform:translateX(3px);
}
.bp5-tooltip.bp5-dark, .bp5-dark .bp5-tooltip{
  box-shadow:0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp5-tooltip.bp5-dark .bp5-popover-content, .bp5-dark .bp5-tooltip .bp5-popover-content{
  background:#e5e8eb;
}
.bp5-tooltip.bp5-dark .bp5-popover-content,
.bp5-tooltip.bp5-dark .bp5-heading, .bp5-dark .bp5-tooltip .bp5-popover-content,
.bp5-dark .bp5-tooltip .bp5-heading{
  color:#404854;
}
.bp5-tooltip.bp5-dark .bp5-popover-arrow::before, .bp5-dark .bp5-tooltip .bp5-popover-arrow::before{
  box-shadow:1px 1px 6px rgba(17, 20, 24, 0.4);
}
.bp5-tooltip.bp5-dark .bp5-popover-arrow-border, .bp5-dark .bp5-tooltip .bp5-popover-arrow-border{
  fill:#111418;
  fill-opacity:0.2;
}
.bp5-tooltip.bp5-dark .bp5-popover-arrow-fill, .bp5-dark .bp5-tooltip .bp5-popover-arrow-fill{
  fill:#e5e8eb;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-dark .bp5-popover-arrow-fill, .bp5-dark .bp5-tooltip .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-dark, .bp5-dark .bp5-tooltip{
    border:1px solid buttonborder;
  }
}
.bp5-tooltip.bp5-dark .bp5-text-muted, .bp5-dark .bp5-tooltip .bp5-text-muted{
  color:#5f6b7c;
}
.bp5-tooltip.bp5-dark .bp5-text-disabled, .bp5-dark .bp5-tooltip .bp5-text-disabled{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-tooltip.bp5-dark .bp5-running-text hr, .bp5-dark .bp5-tooltip .bp5-running-text hr{
  border-color:rgba(17, 20, 24, 0.15);
}
.bp5-tooltip.bp5-dark a, .bp5-dark .bp5-tooltip a{
  color:#215db0;
}
.bp5-tooltip.bp5-dark a:hover, .bp5-dark .bp5-tooltip a:hover{
  color:#215db0;
}
.bp5-tooltip.bp5-dark a .bp5-icon, .bp5-tooltip.bp5-dark a .bp5-icon-standard, .bp5-tooltip.bp5-dark a .bp5-icon-large, .bp5-dark .bp5-tooltip a .bp5-icon, .bp5-dark .bp5-tooltip a .bp5-icon-standard, .bp5-dark .bp5-tooltip a .bp5-icon-large{
  color:inherit;
}
.bp5-tooltip.bp5-dark a code, .bp5-dark .bp5-tooltip a code{
  color:inherit;
}
.bp5-tooltip.bp5-dark .bp5-code,
.bp5-tooltip.bp5-dark .bp5-running-text code, .bp5-dark .bp5-tooltip .bp5-code,
.bp5-dark .bp5-tooltip .bp5-running-text code{
  background:rgba(255, 255, 255, 0.7);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2);
  color:#5f6b7c;
}
a > .bp5-tooltip.bp5-dark .bp5-code,
a > .bp5-tooltip.bp5-dark .bp5-running-text code, a > .bp5-dark .bp5-tooltip .bp5-code,
a > .bp5-dark .bp5-tooltip .bp5-running-text code{
  color:#2d72d2;
}
.bp5-tooltip.bp5-dark .bp5-code-block,
.bp5-tooltip.bp5-dark .bp5-running-text pre, .bp5-dark .bp5-tooltip .bp5-code-block,
.bp5-dark .bp5-tooltip .bp5-running-text pre{
  background:rgba(255, 255, 255, 0.7);
  box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.15);
  color:#1c2127;
}
.bp5-tooltip.bp5-dark .bp5-code-block > code,
.bp5-tooltip.bp5-dark .bp5-running-text pre > code, .bp5-dark .bp5-tooltip .bp5-code-block > code,
.bp5-dark .bp5-tooltip .bp5-running-text pre > code{
  background:none;
  box-shadow:none;
  color:inherit;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-dark .bp5-code-block,
  .bp5-tooltip.bp5-dark .bp5-running-text pre, .bp5-dark .bp5-tooltip .bp5-code-block,
  .bp5-dark .bp5-tooltip .bp5-running-text pre{
    border:1px solid buttonborder;
    box-shadow:none;
  }
}
.bp5-tooltip.bp5-dark .bp5-key,
.bp5-tooltip.bp5-dark .bp5-running-text kbd, .bp5-dark .bp5-tooltip .bp5-key,
.bp5-dark .bp5-tooltip .bp5-running-text kbd{
  background:#ffffff;
  box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  color:#5f6b7c;
}
.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-primary, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-primary, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-primary, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-primary, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-primary, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-primary{
  color:#215db0;
}
.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-success, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-success, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-success, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-success, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-success, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-success{
  color:#1c6e42;
}
.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-warning, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-warning, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-warning, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-warning, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-warning, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-warning{
  color:#935610;
}
.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-danger, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-danger, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-danger, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-danger, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-danger, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-danger{
  color:#ac2f33;
}
.bp5-tooltip.bp5-intent-primary .bp5-popover-content{
  background:#2d72d2;
  color:#ffffff;
}
.bp5-tooltip.bp5-intent-primary .bp5-popover-arrow-fill{
  fill:#2d72d2;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-intent-primary .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
.bp5-tooltip.bp5-intent-success .bp5-popover-content{
  background:#238551;
  color:#ffffff;
}
.bp5-tooltip.bp5-intent-success .bp5-popover-arrow-fill{
  fill:#238551;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-intent-success .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
.bp5-tooltip.bp5-intent-warning .bp5-popover-content{
  background:#c87619;
  color:#ffffff;
}
.bp5-tooltip.bp5-intent-warning .bp5-popover-arrow-fill{
  fill:#c87619;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-intent-warning .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}
.bp5-tooltip.bp5-intent-danger .bp5-popover-content{
  background:#cd4246;
  color:#ffffff;
}
.bp5-tooltip.bp5-intent-danger .bp5-popover-arrow-fill{
  fill:#cd4246;
}
@media (forced-colors: active) and (prefers-color-scheme: dark){
  .bp5-tooltip.bp5-intent-danger .bp5-popover-arrow-fill{
    fill:buttonborder;
  }
}

.bp5-tooltip-indicator{
  border-bottom:dotted 1px;
  cursor:help;
}
.bp5-tree .bp5-icon, .bp5-tree .bp5-icon-standard, .bp5-tree .bp5-icon-large{
  color:#5f6b7c;
}
.bp5-tree .bp5-icon.bp5-intent-primary, .bp5-tree .bp5-icon-standard.bp5-intent-primary, .bp5-tree .bp5-icon-large.bp5-intent-primary{
  color:#2d72d2;
}
.bp5-tree .bp5-icon.bp5-intent-success, .bp5-tree .bp5-icon-standard.bp5-intent-success, .bp5-tree .bp5-icon-large.bp5-intent-success{
  color:#238551;
}
.bp5-tree .bp5-icon.bp5-intent-warning, .bp5-tree .bp5-icon-standard.bp5-intent-warning, .bp5-tree .bp5-icon-large.bp5-intent-warning{
  color:#c87619;
}
.bp5-tree .bp5-icon.bp5-intent-danger, .bp5-tree .bp5-icon-standard.bp5-intent-danger, .bp5-tree .bp5-icon-large.bp5-intent-danger{
  color:#cd4246;
}

.bp5-tree-node-list{
  list-style:none;
  margin:0;
  padding-left:0;
}

.bp5-tree-root{
  background-color:transparent;
  cursor:default;
  padding-left:0;
  position:relative;
}

.bp5-tree-node-content-0{
  padding-left:0px;
}

.bp5-tree-node-content-1{
  padding-left:23px;
}

.bp5-tree-node-content-2{
  padding-left:46px;
}

.bp5-tree-node-content-3{
  padding-left:69px;
}

.bp5-tree-node-content-4{
  padding-left:92px;
}

.bp5-tree-node-content-5{
  padding-left:115px;
}

.bp5-tree-node-content-6{
  padding-left:138px;
}

.bp5-tree-node-content-7{
  padding-left:161px;
}

.bp5-tree-node-content-8{
  padding-left:184px;
}

.bp5-tree-node-content-9{
  padding-left:207px;
}

.bp5-tree-node-content-10{
  padding-left:230px;
}

.bp5-tree-node-content-11{
  padding-left:253px;
}

.bp5-tree-node-content-12{
  padding-left:276px;
}

.bp5-tree-node-content-13{
  padding-left:299px;
}

.bp5-tree-node-content-14{
  padding-left:322px;
}

.bp5-tree-node-content-15{
  padding-left:345px;
}

.bp5-tree-node-content-16{
  padding-left:368px;
}

.bp5-tree-node-content-17{
  padding-left:391px;
}

.bp5-tree-node-content-18{
  padding-left:414px;
}

.bp5-tree-node-content-19{
  padding-left:437px;
}

.bp5-tree-node-content-20{
  padding-left:460px;
}

.bp5-tree-node-content{
  align-items:center;
  background:none;
  display:flex;
  height:30px;
  padding-right:5px;
  width:100%;
}
.bp5-tree-node-content:hover{
  background-color:rgba(143, 153, 168, 0.15);
}
.bp5-tree-node-content:active{
  background-color:rgba(143, 153, 168, 0.3);
}

.bp5-tree-node-caret,
.bp5-tree-node-caret-none{
  min-width:30px;
}

.bp5-tree-node-caret{
  color:#5f6b7c;
  cursor:pointer;
  padding:7px;
  transform:rotate(0deg);
  transition:transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp5-tree-node-caret:hover{
  color:#1c2127;
}
.bp5-dark .bp5-tree-node-caret{
  color:#abb3bf;
}
.bp5-dark .bp5-tree-node-caret:hover{
  color:#f6f7f9;
}
.bp5-tree-node-caret:hover{
  color:#1c2127;
}
.bp5-tree-node-caret.bp5-tree-node-caret-open{
  transform:rotate(90deg);
}
.bp5-tree-node-caret.bp5-icon-standard::before{
  content:"\f14d";
}

.bp5-tree-node-icon{
  margin-right:7px;
  position:relative;
}

.bp5-tree-node-label{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  word-wrap:normal;
  flex:1 1 auto;
  position:relative;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.bp5-tree-node-label span{
  display:inline;
}

.bp5-tree-node-secondary-label{
  padding:0 5px;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.bp5-tree-node-secondary-label .bp5-popover-wrapper,
.bp5-tree-node-secondary-label .bp5-popover-target{
  align-items:center;
  display:flex;
}

.bp5-tree-node.bp5-disabled .bp5-tree-node-content{
  background-color:inherit;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}
.bp5-tree-node.bp5-disabled .bp5-tree-node-caret,
.bp5-tree-node.bp5-disabled .bp5-tree-node-icon{
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}

.bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content{
  background-color:#2d72d2;
}
.bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content,
.bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon, .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon-standard, .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon-large{
  color:#ffffff;
}
.bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-tree-node-caret::before{
  color:rgba(255, 255, 255, 0.7);
}
.bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-tree-node-caret:hover::before{
  color:#ffffff;
}

.bp5-tree.bp5-compact .bp5-tree-node-content{
  height:24px;
}
.bp5-tree.bp5-compact .bp5-tree-node-caret{
  margin-right:3px;
  min-width:24px;
  padding:4px;
}

.bp5-dark .bp5-tree-node-content:hover{
  background-color:rgba(95, 107, 124, 0.3);
}
.bp5-dark .bp5-tree .bp5-icon, .bp5-dark .bp5-tree .bp5-icon-standard, .bp5-dark .bp5-tree .bp5-icon-large{
  color:#abb3bf;
}
.bp5-dark .bp5-tree .bp5-icon.bp5-intent-primary, .bp5-dark .bp5-tree .bp5-icon-standard.bp5-intent-primary, .bp5-dark .bp5-tree .bp5-icon-large.bp5-intent-primary{
  color:#8abbff;
}
.bp5-dark .bp5-tree .bp5-icon.bp5-intent-success, .bp5-dark .bp5-tree .bp5-icon-standard.bp5-intent-success, .bp5-dark .bp5-tree .bp5-icon-large.bp5-intent-success{
  color:#72ca9b;
}
.bp5-dark .bp5-tree .bp5-icon.bp5-intent-warning, .bp5-dark .bp5-tree .bp5-icon-standard.bp5-intent-warning, .bp5-dark .bp5-tree .bp5-icon-large.bp5-intent-warning{
  color:#fbb360;
}
.bp5-dark .bp5-tree .bp5-icon.bp5-intent-danger, .bp5-dark .bp5-tree .bp5-icon-standard.bp5-intent-danger, .bp5-dark .bp5-tree .bp5-icon-large.bp5-intent-danger{
  color:#fa999c;
}
.bp5-dark .bp5-tree-node:not(.bp5-disabled) .bp5-tree-node-caret:hover{
  color:#f6f7f9;
}
.bp5-dark .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content{
  background-color:#2d72d2;
}
.bp5-dark .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon, .bp5-dark .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon-standard, .bp5-dark .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon-large{
  color:#ffffff;
}