@import '../variables.scss';

.Map {
	width: 100%;
	height: 100%;
	position: relative;

	.tools {
		position: absolute;
		right: 10px;
		top: 10px;

		display: flex;
		flex-direction: column;
		gap: 10px;

		z-index: 10;
	}

	.container {
		width: 100%;
		height: 100%;
	}

	.mapboxgl-control-container {
		display: none;
	}
}

.device-marker {
	position: relative;

	// padding: 0.0625rem;
	width: 1.1rem;
	aspect-ratio: 1 / 1;

	background: var(--fillColor);
	border: 3px solid var(--ringColor);
	border-radius: 100%;
	box-shadow: 0px 0px 7px 3px var(--lightingProfileColor);

	display: flex;
	align-items: center;
	justify-content: center;

	i {
		color: white;
		font-size: 0.65rem;
	}

	&.event {
		& > i {
			color: orange;
			font-size: 0.75rem;
		}

		&.ended {
			& > i {
				color: green;
				opacity: 0.375;
			}
		}
	}

	.label {
		font-weight: bold;
		position: absolute;
		font-size: 0.65rem;
		left: calc(100% + 0.25rem);
		background: white;
		border-radius: 0.25rem;
		padding-inline: 0.125rem;

		box-shadow: 1px 1px 3px #00000033;
		transition: box-shadow 200ms ease-in-out;

		border: 2px solid transparent;
	}

	.gateway-icon {
		font-size: 0.5rem;
		background: lime;
		padding: 0.25rem;
		border-radius: 100%;
		box-shadow: 1px 1px 3px #00000033;
		color: black;
		position: absolute;
		right: -0.5rem;
		top: -0.5rem;
		width: auto;
	}

	.event-icon {
		font-size: 0.5rem;
		background: orange;
		padding: 0.25rem;
		border-radius: 100%;
		box-shadow: 1px 1px 3px #00000033;
		color: black;
		position: absolute;
		left: -0.5rem;
		top: -0.5rem;
		width: auto;

		&.error {
			background: red;
		}
	}

	.core {
		background-color: var(--coreColor);

		position: absolute;
		inset: 0;
		margin: auto;
		width: 0.45rem;
		aspect-ratio: 1 / 1;
		border-radius: 100%;
	}

	transition: box-shadow 200ms ease-in-out;

	&.selected {
		// border-color: $primary-color;
		box-shadow: 0px 0px 10px 5px $primary-color;

		.label {
			box-shadow: 0px 0px 10px 5px $primary-color;
		}
	}

	&.segment {
		border-radius: 0.125rem;
	}

	& .hover-tooltip-container {
		position: absolute;
		border-radius: 0.25rem;
		padding: 0.25rem;
		background-color: #f1f1f1;
		left: 50%;
		top: 150%;

		opacity: 0;
		pointer-events: none;
		transform: translateX(-50%);

		text-wrap: nowrap;

		display: flex;
		flex-direction: column;
		z-index: 12;

		& > .id {
			font-weight: bold;

			& > .title {
				font-weight: normal;
			}
		}

		& .status-ring {
			box-sizing: content-box;
			width: calc(1.1rem - 0.175rem - 0.175rem);
			height: calc(1.1rem - 0.175rem - 0.175rem);
			border-radius: 50%;
			border: 0.175rem solid;
		}

		& .glow-circle {
			box-sizing: content-box;
			width: 1.1rem;
			height: 1.1rem;
			border-radius: 50%;
		}
	}
}

.icon-text-box {
	margin-top: 0.125rem;
	display: flex;
	gap: 0.25rem;
	align-items: center;

	& > .icon-wrapper {
		min-width: 1.25rem;
		display: flex;
		justify-content: center;
		align-items: center;

		& i {
			font-size: 0.65rem;
		}
	}

	& .colored-icon {
		padding: 0.125rem !important;
		height: 1.1rem;
		width: 1.1rem;

		display: grid;
		place-items: center;
		border-radius: 100%;

		&.event-flag {
			& i {
				color: black;
				font-size: 0.59rem;
			}

			&.error {
				background-color: red;
			}

			&.warning {
				background-color: orange;
			}
		}
	}
}

.mapboxgl-marker:hover {
	&:not(:has(.device-marker.selected)) {
		z-index: 10 !important;

		& .device-marker {
			box-shadow: 0px 0px 10px 5px transparentize($color: $primary-color, $amount: 0.5);

			& .label {
				box-shadow: 0px 0px 10px 5px transparentize($color: $primary-color, $amount: 0.5);
			}
		}
	}

	& .hover-tooltip-container {
		opacity: 1;
	}
}

.mapboxgl-marker:has(.device-marker.selected) {
	z-index: 11 !important;
}

.heatmap-input-item {
	padding-inline: 1rem;

	margin-top: 0.5rem;
}
