@import '../variables.scss';

.Page {
	flex-grow: 1;
	height: 100vh;
	background: white;
	display: flex;
	flex-direction: column;
	flex-shrink: 1;
	overflow: hidden;

	.PageContainer {
		flex-grow: 1;
		flex-shrink: 1;
		overflow: hidden;
		padding: $default-padding;
	}

	&.noPadding {
		.PageContainer {
			padding: 0;
		}
	}
}
