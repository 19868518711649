@import '../../../../variables.scss';

.MapPage .DeviceDetails,
.FaultsPage .DeviceDetails {
	background: #f1f1f1;
	width: 340px;
	overflow: scroll;
	border-right: 1px solid $divider-small-color;
	position: relative;
	overflow-x: hidden;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;

	.closeButton {
		position: absolute;
		right: 8px;
		top: 8px;
	}

	.modelBlock {
		margin: 0 auto;
		font-size: 14px;
		opacity: 0.7;
	}

	.idBlock {
		text-align: center;
		font-weight: bold;
		font-size: 34px;
		margin-bottom: 8px;
	}

	.sectionTabs .bp5-tab-list {
		justify-content: center;
	}

	.DeviceDetailsBlock {
		border-radius: 10px;
		padding: $default-padding;
		background: white;
		margin-bottom: 12px;
		margin-inline: 8px;

		.heading {
			font-weight: bold;
			font-size: 14px;
			margin-bottom: 12px;
		}
		table {
			width: 100%;
		}
		table td {
			text-align: right;
		}
		.value {
			text-align: left;
			font-weight: bold;
		}
		.small {
			font-size: smaller;
		}
		.large {
			font-size: larger;
		}

		&.loading {
			opacity: 0.5;
			& .content * {
				cursor: not-allowed;

				&:active {
					pointer-events: none;
				}
			}
		}
	}

	.actions .content {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	textarea {
		min-width: 100%;
		font-family: monospace;
		font-size: 12px;
	}

	.buttons {
		display: flex;
		gap: 8px;
		padding-top: 8px;
	}

	.event {
		display: flex;
		justify-content: space-between;
		line-height: 24px;
	}

	.reportLine {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #888;
		line-height: 24px;

		.value {
			color: black;
		}
	}
}

.box {
	margin-bottom: 0.5rem;
}
