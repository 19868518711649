.accordion {
	& > .header {
		cursor: pointer;
		height: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 10px;
		box-sizing: border-box;
		transition: background-color 250ms ease-in-out;

		&:hover {
			background-color: #e4e4e4;
		}

		& .title {
			margin: 0px;
			margin-left: 0.5rem;
			font-weight: bold;
			font-size: 16px;
		}

		& > .expander {
			transition: transform 150ms ease;
			&.open {
				transform: rotate(90deg);
			}
		}
	}

	&.selected {
		& > .header {
			background-color: #e1e1e1;
		}
	}

	& .left-wrapper {
		margin-left: 0.5rem;
	}
}
