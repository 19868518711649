.periodPicker {
	display: flex;
	gap: 1rem;

	& > div {
		display: flex;
		gap: 0.5rem;
		align-items: center;
	}
}
