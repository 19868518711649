.report-page {
	overflow-y: scroll;
	padding: 1rem;

	& .PageContainer {
		padding: 0px;

		display: flex;
		flex-direction: column;
		overflow: visible;
	}

	& .view {
		flex: 1;
		display: flex;
		position: relative;
	}
}

.chart-table {
	height: 80vh;
	overflow-y: scroll;

	& > table {
		width: 100%;
		max-height: 100%;
		margin-top: 1rem;
		border-top: 1px solid #11141826;

		& thead th {
			position: sticky;
			background-color: white;
			border-bottom: 1px solid black;
			top: 0px;
		}
	}
}

.toolbar {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	& > div {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	& .top-left {
		display: flex;
		gap: 0.25rem;
	}
}

.search-box {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	& > span {
		color: red;
	}
	& > div {
		display: flex;
		gap: 0.25rem;
	}
}

.data-avg-btn-group {
	display: flex;
	width: 100%;
	& button {
		flex: 1 !important;
	}
}

.formatted-date {
	font-size: 1rem;
	font-weight: bold;
	margin-left: auto;
	margin-right: 1rem;
}

.clickable-divider-title {
	user-select: none;
	cursor: pointer;
	padding-inline: 10px;

	&:hover {
		background-color: rgb(225, 225, 225);
	}
}
