.chartGroup {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	.dateChanger {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: white;
		padding: 0.25rem;
		border-radius: 0.5rem;

		&:nth-of-type(1) {
			position: sticky;
			top: 0.5rem;
			z-index: 5;
		}

		& .value {
			font-weight: bold;
		}
	}

	.charts {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
}

.chartBlock {
	margin-bottom: 0 !important; // DeviceDetailsBlock
	margin-inline: 0 !important; // DeviceDetailsBlock

	&.hide {
		display: none;
	}
}
