@import '../../node_modules/@blueprintjs/core/lib/scss/variables';
@import '../variables.scss';

.MainMenu {
	display: flex;
	padding: 0.5rem;
	box-shadow: 0 2px 4px #00000033;
	z-index: 10;
	background: #582cc7;
	color: #ffffff;
	overflow: hidden;
	align-items: center;
	flex-shrink: 0;

	.LogoContainer {
		margin-right: 20px;

		.Logo {
			width: 160px;
		}
	}

	.Spacer {
		flex-grow: 1;
	}

	.AccountHeader {
		cursor: pointer;
		font-size: 16px;
	}

	.MenuItems {
		display: flex;
		flex: 1;

		h4 {
			font-size: 16px;
			margin: 0;
			margin-bottom: 20px;
			i {
				font-size: 20px;
				margin-right: 4px;
			}

			&.switchable {
				cursor: pointer;
				.switcher {
					font-size: 14px;
					opacity: 0.6;
					margin-left: 4px;
				}
			}
		}

		& > .row {
			display: flex;
			margin-inline: auto;
		}
	}

	.LanguageSelector {
		cursor: pointer;
		font-size: 16px;
		margin-right: 12px;
	}

	.User {
		cursor: pointer;

		.UserContent {
			display: flex;
			i {
				margin: 0 10px;
			}

			& .UserName {
				text-wrap: nowrap;
			}
		}
	}
}

.MenuButton {
	line-height: 30px;
	display: flex;
	align-items: center;
	padding: 4px 10px 5px;
	color: #f2f2f2;
	text-decoration: none;
	font-size: 15px;
	cursor: pointer;

	i {
		font-size: 24px;
		margin-right: 7px;
	}

	.fa-caret-down {
		font-size: 14px;
		margin-left: 5px;
		opacity: 0.6;
		margin-right: -5px;
	}

	&.active {
		background: #26045d;
		border-radius: 10px;
	}

	&:hover {
		color: #f2f2f2;
		text-decoration: none;
	}
}

.small-popover {
	border-radius: 0.5rem;
	overflow: hidden;

	.bp4-popover2-arrow {
		& path {
			fill: $primary-color;
		}
	}

	.bp4-popover2-content {
		span {
			width: 100%;
		}

		.MenuButton {
			border-radius: 0px !important;

			background-color: $primary-color;

			&.active {
				background: #26045d;
				border-radius: 10px;
			}
		}
	}
}
